import AdicinoarTreinadorMolda from "components/modals/adicionar_trienador_modal"
import { Treinador } from "models/treinador"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import { Button, Col, Container, Row, Table } from "reactstrap"
import { criarTreinador } from "services/treinadores/treinadores_Services"
import firebase from "firebase/app"
import "firebase/firestore"
import { treinadoresCollection } from "services/consts"
import { element } from "prop-types"
import { Trash, Trash2 } from "react-bootstrap-icons"
import RemoveTreinadoresModal from "components/modals/remove_treinadores.modal"
import { useSelector } from "react-redux"
import { selecttreinadores } from "store/treinadores/treinadores_reducer"

function TreinadoresMainScreen() {
  const { t, i18n } = useTranslation()
  var treinadoresRed = useSelector(selecttreinadores)

  // Trocar cor de fundo da tabela
  const style = {
    "--bs-table-bg": "#ececf1",
  }
 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={10}>
                <h6 className="page-title">{t("Treinadores")}</h6>
              </Col>
              <Col md={2}>
                <AdicinoarTreinadorMolda></AdicinoarTreinadorMolda>
              </Col>
            </Row>
          </div>

          <Table bordered striped responsive className="myList" style={style}>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {treinadoresRed.map((elemnt, index) => {
                const treinadorData = elemnt
                return (
                  <tr
                    key={index}
                    className={index % 2 == 0 ? "" : "myList-even"}
                  >
                    <td>{treinadorData.nome}</td>
                    <td>{treinadorData.email}</td>
                    <td>
                      <RemoveTreinadoresModal email={treinadorData.email}></RemoveTreinadoresModal>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default TreinadoresMainScreen
