import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from "firebase/app"
import "firebase/firestore"
import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function HistoricoDeAulas(props) {
    let { id } = useParams()
    const { t, i18n } = useTranslation()

    const [listaDeAulas, setListaDeAUlas] = useState([])

    async function getHistoricoDeAulas() {
        firebase.firestore().collection("pagamentosAulas").where("userID", "==", id).get().then((value) => {
            var listaAUx = []
            value.docs.forEach((elem) => {
                listaAUx.push(elem)
            })
            listaAUx.sort((a,b) => {
                if(a.data().createdAt.toDate() > b.data().createdAt.toDate()) {
                    return -1 
                } else {
                    return 1
                }
            })
            setListaDeAUlas(listaAUx)
        })
    }

    useEffect(() => {
        getHistoricoDeAulas()
    }, [])
    return (  
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <div className="page-title-box">
                    <Row className="align-items-center">
                    <Col md={8}>
                        <h6 className="page-title">{t("Historico de Aulas")}</h6>
                    </Col>
                       
                    </Row>
                </div>
                
               { listaDeAulas.length == 0 ? <h3 style={{padding: "40px"}}>Sem Aulas compradas</h3> :
               <Container>
                <Row style={{ paddingRight: "30px" }}>
                <Col md={2}><h5>Classe</h5></Col>
                <Col md={3}><h5>Tipo</h5></Col>
                <Col md={2}><h5>Valor</h5></Col>
                <Col md={2}><h5>Data da Compra</h5></Col>
                <Col md={2}><h5>Expira Em</h5></Col>
                
            </Row>
            <Row>
                
                { listaDeAulas.map((elem,index) => {
                    console.log(elem.data())
                    return <Row
                    key={index + "row"}
                    className={index % 2 == 0 ? "myList-even" : "myList-odd"}
                  >
                     <Col md={2}>{elem.data().classe}</Col>
                     <Col md={3}>{elem.data().tipo}</Col>
                     <Col md={2}>{elem.data().valor + "€"}</Col>
                    <Col md={2}>{elem.data().createdAt.toDate().toLocaleString().substring(0,10)}</Col>
                    { typeof elem.data().expiryAt == "undefined" ?  <Col md={2}>  </Col> : <Col md={2}>{elem.data().expiryAt.toDate().toLocaleString().substring(0,10)}  </Col>  }
                  
                  </Row>
                })}
            </Row>
            </Container>
               }
                
            </Container>
            </div>

        </React.Fragment>
    );
}

export default HistoricoDeAulas;