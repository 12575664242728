import firebase from "firebase/app"
import "firebase/firestore"

export async function getLocalizacaoFromEmail(email) {
  try {
    return await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .limit(1)
      .get()
      .then(value => {
        return value.docs.at(0).data();
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getLocalValues(localizacao) {
  try {
    return firebase
      .firestore()
      .collection("localizacao")
      .where("localizacao", "==", localizacao)
      .limit(1)
      .get()
      .then(value => {
        return value.docs.at(0).data()
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function duplicate(localizacao) {
  try {
    return firebase
      .firestore()
      .collection("localizacao")
      .where("localizacao", "==", localizacao)
      .limit(1)
      .get()
      .then(value => {
       firebase.firestore().collection("localizacaoBck").add(
        {
          createdAt: firebase.firestore.Timestamp.fromDate( new Date),
          data: value.docs.at(0).data()
        }
       )
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function updateValoresDasAulas(localizacaoID, valoresDasAulas) {
  try {
    await firebase.firestore().collection("localizacao").doc("localizacaoStaging").update(
      {valoresDasAulas: valoresDasAulas}
    );
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateFeriados(localizacaoID, feriados) {
  try {
    await firebase.firestore().collection("localizacao").doc(localizacaoID).update(
      {feriados: feriados}
    );
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function backUpHorariosBloqueados(localizacaoID) {
  try {
    return await firebase.firestore()
      .collection("localizacao")
      .doc(localizacaoID)
      .get().then((value) => {
        return value.data().horariosBloqueadosJson;
    })
  } catch (error) {
    console.log(error)
    return null;
  }
}

export async function gravaHorariosBloqueados(localizaoID, docID, map) {
  try {
    await firebase
        .firestore()
        .collection("localizacao")
        .doc(localizaoID)
        .collection("horaiosBloqueados")
        .doc(docID).set(map/* , {merge: true} */)
  } catch (error) {
    console.log(error)
    return null;
  }
}

export async function removeHorariosAnteriores(docID, map) {
  try {
    await firebase
        .firestore()
        .collection("localizacao")
        .doc(docID).update({
          "horariosBloqueadosJson": map
        });
       
  } catch (error) {
    console.log(error)
    return null;
  }
}

export async function docDeTeste(localizacaoID) {
  try {
      const response = await firebase.firestore()
          .collection("localizacao")
          .doc(localizacaoID)
          .collection("horaiosBloqueados")
          .doc("2023").get()
      
      return response;
  } catch (error) {
    console.log(error)
    return null;
  }
}


// ###############################################################################
// ###############################################################################
// -------------------------- GESTÃO DOS ROLES -------------------------------- //
// ###############################################################################
// ###############################################################################

/**
 * Função que recebe um id de documento para a localização e devolve os roles.
 * @param {*} localizacaoID 
 * @returns 
 */
export async function getGruposStaff(localizacaoID){
  try {
    return await firebase.firestore().collection("localizacao").doc(localizacaoID).get().then(value => {
      return value.data().userRoles
    })
  } catch (error) {
    console.log(error);
    return null;
  }
}


/**
 * Função que adiciona um novo email a um role
 * @param {*} localizacaoID (ID do documento da localizacao)
 * @param {*} role (nome do role)
 * @param {*} email (email do user a adicionar)
 * @returns 
 */
export async function adicionarEmailAosRoles(localizacaoID, role, email) {
  try {
    await firebase.firestore().collection("localizacao").doc(localizacaoID).set(
      {userRoles: {[role] : firebase.firestore.FieldValue.arrayUnion(email) } }, {merge: true}
    );
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Função que vai ao utilizador por email e adiciona o role
 * @param {*} role - role a adicionar
 * @param {*} email - email do user a adicionar
 * @returns 
 */
export async function adicionarRoleAoUser(role, email) {
  try {
    await firebase.firestore().collection("users").where("email", "==", email).limit(1).get().then(async value => {
      await firebase.firestore().collection("users").doc(value.docs.at(0).id
      ).set(
        {user_role: role}, {merge: true}
      );
    })
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
}


/**
 * Vai à localização e remove o email do role pretendido
 * @param {*} localizacaoID - ID do documento da localizacao
 * @param {*} role - role a ser pesquisada
 * @param {*} email - email do user a remover
 * @returns 
 */
export async function removerEmailAosRoles(localizacaoID, role, email) {
  try {
    await firebase.firestore().collection("localizacao").doc(localizacaoID).set(
      {userRoles: {[role] : firebase.firestore.FieldValue.arrayRemove(email) } }, {merge: true}
    );
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Função que recebe um email e remove o role do user
 * @param {*} email - Email do User
 * @returns 
 */
export async function removerRoleDoUser(email){
  try {
    await firebase.firestore().collection("users").where("email", "==", email).limit(1).get().then(async value => {
      await firebase.firestore().collection("users").doc(value.docs.at(0).id
      ).set(
        {user_role: firebase.firestore.FieldValue.delete()}, {merge: true}
      );
    })
    return true
  } catch (error) {
    console.log(error);
    return null;
  }
}