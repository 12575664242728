import firebase from "firebase/app"
import "firebase/firestore"
import { ProcuraReserva } from "models/reserva";
import React, { useEffect, useState } from "react"
import { Check } from "react-bootstrap-icons";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { convertCamps } from "services/consts";
import { checkIfUserExists, checkIfUserExistsEmaiLOrNum, editarDadosReserva, retornaCamposIndisponíveisNaHora } from "services/reservas/reservas_services"

function EditarReservasModal(props) {
  const [isOpen, setIsOpen] = useState(false)

  const [campo,setCampo] = useState(props.reserva.campo);
  const [camposDisponiv, setCamposDisponiv] = useState([])
  const numeroDeCampos = 6
  const [campoEscolhido, setCampoEscolhido] = useState(props.reserva.campo)

  function removeCamposRepetidos(campoosInsdis) {
    console.log("A localizacao dos campos é :")
    console.log(props.reserva.localizacao);
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i
      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }
    setCamposDisponiv(listaDeCampos)
  }
  
  const [email1, setEmail1] = useState(props.reserva.jogador1estado.email)
  const [email2, setEmail2] = useState(props.reserva.jogador2estado.email)
  const [email3, setEmail3] = useState(props.reserva.jogador3estado.email)
  const [email4, setEmail4] = useState(props.reserva.jogador4estado.email)

  const [nome1, setNome1] = useState(props.reserva.jogador1estado.nome)
  const [nome2, setNome2] = useState(props.reserva.jogador2estado.nome)
  const [nome3, setNome3] = useState(props.reserva.jogador3estado.nome)
  const [nome4, setNome4] = useState(props.reserva.jogador4estado.nome)

  const [cc1, setCC1] = useState(props.reserva.jogador1estado.cc)
  const [cc2, setCC2] = useState(props.reserva.jogador2estado.cc)
  const [cc3, setCC3] = useState(props.reserva.jogador3estado.cc)
  const [cc4, setCC4] = useState(props.reserva.jogador4estado.cc)

  const [nr1, setNr1] = useState(props.reserva.jogador1estado.numertl)
  const [nr2, setNr2] = useState(props.reserva.jogador2estado.numertl)
  const [nr3, setNr3] = useState(props.reserva.jogador3estado.numertl)
  const [nr4, setNr4] = useState(props.reserva.jogador4estado.numertl)

  const [colorBTN1 , setColorBTN1] = useState("secondary")
  const [colorBTN2 , setColorBTN2] = useState("secondary")
  const [colorBTN3 , setColorBTN3] = useState("secondary")
  const [colorBTN4 , setColorBTN4] = useState("secondary")

  async function emailNumeroCheck(email, setEmailV, setNomeV, setCCV, setNrTeleV) {
    var primeiroNome = ""
    var ultimoNome = ""
    var numeroTele = ""
    var isEmail1Aux = await checkIfUserExistsEmaiLOrNum(email)

    console.log(email)
    console.log("isEmail1")
    console.log(isEmail1Aux) 
    
    if (isEmail1Aux.length > 1) {
      alert("Mais de um user encontrado com este numero\nDiga qual email deseja reservar")
      return false
    }
    if (isEmail1Aux.length == 0) {
      alert("Os dados não estão associados a nenhuma conta")
      return false
    }
    var isEmail1 = isEmail1Aux.at(0)
    setEmailV(isEmail1.email)
    if (typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if (typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }

    if (typeof isEmail1.numeroDoCC != "undefined") {
      setCCV(isEmail1.numeroDoCC)
      
    } else {
      setCCV("")
    }

    if (typeof isEmail1.numeroTele != "undefined") {
      numeroTele = isEmail1.numeroTele
      setNrTeleV(isEmail1.numeroTele)
    }
    setNomeV(primeiroNome + ultimoNome)
  
    if (
      typeof isEmail1.primeiroNome != "undefined" &&
      typeof isEmail1.ultimoNome != "undefined" &&
      typeof isEmail1.numeroDoCC != "undefined"
    ) {
      return true
    } else {
      return false
    }
  }

  async function emailCheck(email, ccId) {
    var primeiroNome = ""
    var ultimoNome= ""
    var isEmail1 = await checkIfUserExists(email)

    if(Object.keys(isEmail1).length == 0){
      alert('O email não está registado')
      return false
    }
    if(typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if(typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if(typeof isEmail1.numeroDoCC != "undefined") {
      setCC1(isEmail1.numeroDoCC)
    } else {
      document.getElementById(ccId).value = null
    }
    setNome1(primeiroNome + ultimoNome)

    if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
      return true
    } 
    else {
      return false;
    }
  }

  async function emailNumeroCheck2(email, nomeId, ccId, emailID, nmrTele) {
    var primeiroNome = ""
    var ultimoNome = ""
    var numeroTele = ""
    var isEmail1Aux = await checkIfUserExistsEmaiLOrNum(email)

    console.log(email)
    console.log("isEmail1")
    console.log(isEmail1Aux) 
    
    if (isEmail1Aux.length > 1) {
      alert("Mais de um user encontrado com este numero\nDiga qual email deseja reservar")
      return false
    }

    if (isEmail1Aux.length == 0) {
      alert("Os dados não estão associados a nenhuma conta")
      return false
    }

    var isEmail1 = isEmail1Aux.at(0)
    setEmail2(isEmail1.email)
    if (typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }

    if (typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }

    if (typeof isEmail1.numeroDoCC != "undefined") {
      setCC2(isEmail1.numeroDoCC)
      document.getElementById(ccId).value = isEmail1.numeroDoCC.trim()
    } else {
      document.getElementById(ccId).value = null
    }

    if (typeof isEmail1.numeroTele != "undefined") {
      numeroTele = isEmail1.numeroTele
      setNr2(isEmail1.numeroTele)
    }
    setNome2(primeiroNome + ultimoNome)
  
    if (
      typeof isEmail1.primeiroNome != "undefined" &&
      typeof isEmail1.ultimoNome != "undefined" &&
      typeof isEmail1.numeroDoCC != "undefined"
    ) {
      return true
    } else {
      return false
    }
  }

  async function emailCheck2(email, ccId) {
    var primeiroNome = ""
    var ultimoNome= ""
    var isEmail1 = await checkIfUserExists(email)

    if(Object.keys(isEmail1).length == 0){
      alert('O email não está registado')
      return false
    }
    if(typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if(typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if(typeof isEmail1.numeroDoCC != "undefined") {
      setCC2(isEmail1.numeroDoCC)
    } else {
      document.getElementById(ccId).value = null
    }
    setNome2(primeiroNome + ultimoNome)

    if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
      return true
    } 
    else {
      return false;
    }
  }

  async function emailNumeroCheck3(email, nomeId, ccId, emailID, nmrTele, setCCAux) {
    var primeiroNome = ""
    var ultimoNome = ""
    var numeroTele = ""
    var isEmail1Aux = await checkIfUserExistsEmaiLOrNum(email)

    console.log(email)
    console.log("isEmail1")
    console.log(isEmail1Aux) 
    
    if (isEmail1Aux.length > 1) {
      alert("Mais de um user encontrado com este numero\nDiga qual email deseja reservar")
      return false
    }

    if (isEmail1Aux.length == 0) {
      alert("Os dados não estão associados a nenhuma conta")
      return false
    }

    var isEmail1 = isEmail1Aux.at(0)
    document.getElementById(emailID).value = isEmail1.email
    if (typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }

    if (typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }

    if (typeof isEmail1.numeroDoCC != "undefined") {
      document.getElementById(ccId).value = isEmail1.numeroDoCC.trim()
    } else {
      document.getElementById(ccId).value = null
    }

    if (typeof isEmail1.numeroTele != "undefined") {
      numeroTele = isEmail1.numeroTele
      document.getElementById(nmrTele).value = isEmail1.numeroTele.trim()
    }
    document.getElementById(nomeId).value = primeiroNome + ultimoNome
    setNome2(primeiroNome + ultimoNome)
    
    setCCAux(isEmail1.numeroDoCC)

    if (
      typeof isEmail1.primeiroNome != "undefined" &&
      typeof isEmail1.ultimoNome != "undefined" &&
      typeof isEmail1.numeroDoCC != "undefined"
    ) {
      return true
    } else {
      return false
    }
  }

  async function emailCheck3(email, ccId) {
    var primeiroNome = ""
    var ultimoNome= ""
    var isEmail1 = await checkIfUserExists(email)

    if(Object.keys(isEmail1).length == 0){
      alert('O email não está registado')
      return false
    }
    if(typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if(typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if(typeof isEmail1.numeroDoCC != "undefined") {
      setCC3(isEmail1.numeroDoCC)
    } else {
      document.getElementById(ccId).value = null
    }
    setNome3(primeiroNome + ultimoNome)

    if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
      return true
    } 
    else {
      return false;
    }
  }

  async function emailCheck4(email, ccId) {
    var primeiroNome = ""
    var ultimoNome= ""
    var isEmail1 = await checkIfUserExists(email)

    if(Object.keys(isEmail1).length == 0){
      alert('O email não está registado')
      return false
    }
    if(typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if(typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if(typeof isEmail1.numeroDoCC != "undefined") {
      setCC4(isEmail1.numeroDoCC)
    } else {
      document.getElementById(ccId).value = null
    }
    setNome4(primeiroNome + ultimoNome)

    if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
      return true
    } 
    else {
      return false;
    }
  }

  useEffect(() => {
   
  },)
  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        color="primary"
      >
        Editar Reserva
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          Editar Reserva
        </ModalHeader>
        <ModalBody>
         
          <Form style={{ paddingBottom: "20px" }}>
          <h5>Jogador 1</h5>
          <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email1}
                    onChange={e => {
                      setEmail1(e.target.value)
                    }}
                    type="email"
                    name="email1Input"
                    id="email1Input"
                  />
                </Col>
                <Col md={2}>
                  <Button id="ckeckButton1" color={colorBTN1} onClick={async() => {
                    var check = await emailNumeroCheck( email1,
                    setEmail1,
                    setNome1,
                    setCC1,
                    setNr1, )
                    if(check) {
                      setColorBTN1("primary")
                    } else {
                      setColorBTN1("secondary")
                    }
                  }} >
                    <Check></Check>
                  </Button>
                  </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nr Tele</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={nr1}
                    onChange={e => {
                      setNr1(e.target.value)
                    }}
                    type="number"
                    name="nr1"
                    id="nr1"
                  />
                </Col>
                <Col md={2}>
                  <Button id="ckeckButton1" color={colorBTN1} onClick={async() => {
                    var check = await emailNumeroCheck( nr1,
                    setEmail1,
                    setNome1,
                    setCC1,
                    setNr1, )
                    if(check) {
                      setColorBTN1("primary")
                    } else {
                      setColorBTN1("secondary")
                    }
                  }} >
                    <Check></Check>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome1}
                    onChange={e => {
                      setNome1(e.target.value)
                    }}
                    type="text"
                    name="nome1Input"
                    id="nome1Input"
                  />
                </Col>
                
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc1}
                    onChange={e => {
                      setCC1(e.target.value)
                    }}
                    type="number"
                    name="cc1Input"
                    id="cc1Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            
            <h5>Jogador 2</h5>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email2}
                    onChange={e => {
                      setEmail2(e.target.value)
                    }}
                    type="email"
                    name="email2Input"
                    id="email2Input"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton2" color={colorBTN2} onClick={async() => {
                    var check =  await emailNumeroCheck( email2,
                      setEmail2,
                      setNome2,
                      setCC2,
                      setNr2, )
                    if (check) {
                        setColorBTN2("primary")
                    } else {
                        setColorBTN2("secondary")
                    }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nr Tele</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={nr2}
                    onChange={e => {
                      setNr2(e.target.value)
                    }}
                    type="number"
                    name="nr2"
                    id="nr2"
                  />
                </Col>
                <Col md={2}>
                  <Button id="ckeckButton2" color={colorBTN2} onClick={async() => {
                    var check = await emailNumeroCheck( nr2,
                    setEmail2,
                    setNome2,
                    setCC2,
                    setNr2, )
                    if(check) {
                      setColorBTN2("primary")
                    } else {
                      setColorBTN2("secondary")
                    }
                  }} >
                    <Check></Check>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={nome2}
                    onChange={e => {
                      setNome2(e.target.value)
                    }}
                    type="text"
                    name="nome2Input"
                    id="nome2Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc2}
                    onChange={e => {
                      setCC2(e.target.value)
                    }}
                    type="number"
                    name="cc2nput"
                    id="cc2Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            
            <h5>Jogador 3</h5>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email3}
                    onChange={e => {
                      setEmail3(e.target.value)
                    }}
                    type="email"
                    name="email3Input"
                    id="email3Input"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton3" color={colorBTN3} onClick={async() => {
                    var check = await emailNumeroCheck( email3,
                      setEmail3,
                      setNome3,
                      setCC3,
                      setNr3, )
                    if(check) {
                        setColorBTN3("primary")
                    } else {
                        setColorBTN3("secondary")
                    }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
              
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nr Tele</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={nr3}
                    onChange={e => {
                      setNr3(e.target.value)
                    }}
                    type="number"
                    name="nr3"
                    id="nr3"
                  />
                </Col>
                <Col md={2}>
                  <Button id="ckeckButton3" color={colorBTN3} onClick={async() => {
                    var check = await emailNumeroCheck( nr3,
                    setEmail3,
                    setNome3,
                    setCC3,
                    setNr3, )
                    if(check) {
                      setColorBTN3("primary")
                    } else {
                      setColorBTN3("secondary")
                    }
                  }} >
                    <Check></Check>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome3}
                    onChange={e => {
                      setNome3(e.target.value)
                      console.log(nome3);
                      console.log(e.target.value)
                    }}
                    type="text"
                    name="nome3Input"
                    id="nome3Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc3}
                    onChange={e => {
                      setCC3(e.target.value)
                    }}
                    type="number"
                    name="cc3Input"
                    id="cc3Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <h5>Jogador 4</h5>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email4}
                    onChange={e => {
                      setEmail4(e.target.value)
                    }}
                    type="email"
                    name="email4Input"
                    id="email4Input"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton4" color={colorBTN4} onClick={async() => {
                    var check = await emailNumeroCheck( email4,
                      setEmail4,
                      setNome4,
                      setCC4,
                      setNr4, )
                      if(check) {
                        setColorBTN4("primary")
                      } else {
                        setColorBTN4("secondary")
                      }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nr Tele</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={nr4}
                    onChange={e => {
                      setNr4(e.target.value)
                    }}
                    type="number"
                    name="nr4"
                    id="nr4"
                  />
                </Col>
                <Col md={2}>
                  <Button id="ckeckButton4" color={colorBTN4} onClick={async() => {
                    var check = await emailNumeroCheck( nr4,
                    setEmail4,
                    setNome4,
                    setCC4,
                    setNr4, )
                    if(check) {
                      setColorBTN4("primary")
                    } else {
                      setColorBTN4("secondary")
                    }
                  }} >
                    <Check></Check>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome4}
                    onChange={e => {
                      setNome4(e.target.value)
                    }}
                    type="text"
                    name="nome4Input"
                    id="nome4Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc4}
                    onChange={e => {
                      setCC4(e.target.value)
                    }}
                    type="number"
                    name="cc4Input"
                    id="cc4Input"
                  />
                </Col>
              </Row>
            </FormGroup>
        <h5>Campos</h5>
        <Row style={{ paddingBottom: "20px"}}>Campo da reserva: {campo}</Row>
        <Button style={{marginBottom: "20px"}} color="primary" onClick={async() => {
             var procuraReserva = new ProcuraReserva()
             procuraReserva.duracaoDaReserva = props.reserva.duracao
             procuraReserva.horaDaReserva = props.reserva.horaIncial.toDate()
             procuraReserva.localizacao = props.reserva.localizacao
             console.log('BP0')
             var camposs = await retornaCamposIndisponíveisNaHora(
               procuraReserva
             )
             removeCamposRepetidos(camposs)
        }}>Mostrar campos disponíveis</Button>

        <Row>
        <Row>
                        {camposDisponiv.map((value, index) => {
                          return (
                            <Col
                              key={index}
                              md={4}
                              style={{ paddingBottom: "10px" }}
                            >
                              <Button
                                outline={campoEscolhido == value ? false : true}
                                color="primary"
                                onClick={() => {
                                  if (campoEscolhido != value) {
                                    setCampoEscolhido(value)
                                  } else {
                                    setCampoEscolhido(null)
                                  }
                                }}
                              >
                                { convertCamps[value]}
                              </Button>
                            </Col>
                          )
                        })}
                      </Row>
        </Row>
          </Form>
          
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async() => {
                  var json = {}
                json.jogador1estado = {}
                json.jogador2estado = {}
                json.jogador3estado = {}
                json.jogador4estado = {}
                json.campo = campoEscolhido;
                json.jogador1estado.cc = cc1.toString()
                json.jogador1estado.nome = nome1
                json.jogador1estado.email = email1
                json.jogador2estado.cc = cc2.toString()
                json.jogador2estado.nome = nome2
                json.jogador2estado.email = email2
                json.jogador3estado.cc = cc3.toString()
                json.jogador3estado.nome = nome3
                json.jogador3estado.email = email3
                json.jogador4estado.cc = cc4.toString()
                json.jogador4estado.nome = nome4
                json.jogador4estado.email = email4
                json.novaListaDeJogadores = [email1,email2,email3,email4]
                json.editadaPor = {email: JSON.parse(localStorage.getItem("authUser")).email,date: firebase.firestore.Timestamp.fromDate(new Date()), jaEditou: typeof props.reserva.editadaPor == "undefined" ? false: true };

                var res = await editarDadosReserva(props.reservaID, json.jogador1estado,json.jogador2estado,json.jogador3estado,json.jogador4estado, json.campo, json.novaListaDeJogadores, json.editadaPor)
                if(res) {
                    alert('Dados alterados com sucesso')
                    setIsOpen(!isOpen)
                } else {
                    alert('Erro a alterar dados. Confirme se todos os dados estão preenchidos e se estão preenchidos corretamente!')
                }   
            }}
            color="primary"
          >
            Editar
          </Button>
          <Button
            onClick={() => {
                
              setIsOpen(!isOpen)
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default EditarReservasModal
