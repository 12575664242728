import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link , useHistory} from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"


// Middleware testes
import { userRoutes } from "routes/allRoutes"
import { useSelector } from "react-redux"
import { selectCurrentUserAuth } from "store/middlewarerotas/CurrentUserAuth"
import { Hr } from "react-bootstrap-icons"
import { Button } from "reactstrap"

const SidebarContent = props => {
  const ref = useRef()
  

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    
    initMenu()
  }, [props.location.pathname])

  const userAuthRole = useSelector(selectCurrentUserAuth) 
  /* const userAuthRole = "Administrador" */
  const history = useHistory();

  useEffect(() => {
    ref.current.recalculate()
  },[userAuthRole])


  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }


  // ----------------- Middleware -----------------
  

  function getAllRotasDesteRole(userAuthRole){

    const rotas = userRoutes.filter((route) => {
      return route.roles.includes(userAuthRole);
    })

    console.warn("Rotas deste role: ", rotas)
  }
  getAllRotasDesteRole(userAuthRole);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>

            {/* Dashboard */}
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i>
                <span className="badge rounded-pill bg-primary float-end">
                  2
                </span>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>


            {/* Sidebar - Clube */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  ( route.path ==="/imagemDaApp" ||  route.path === "/descontos" || route.path === "/feriados" || route.path === "/valorDasAulas" || route.path === "/faturasRef" || route.path === "/valorDosContratos" || route.path === "/grupos_staff" || route.path === "/classes" || route.path === "/horarioDeFuncionamento" )
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-folder"></i>
                    <span>{props.t("Clube")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                          (route.path ==="/imagemDaApp" ||  route.path === "/descontos" || route.path === "/feriados" || route.path === "/valorDasAulas" || route.path === "/faturasRef" || route.path === "/valorDosContratos" || route.path === "/grupos_staff" || route.path === "/classes" || route.path === "/horarioDeFuncionamento" ) &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>


            {/* Sidebar - Reservas */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  (route.path === "/adicionarReservas" || route.path === "/reservas" || route.path === "/pesquisareservas" || route.path === "/desativarCampos" )
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-notepad"></i>
                    <span>{props.t("Reservas")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                        (route.path === "/adicionarReservas" || route.path === "/reservas" || route.path === "/pesquisareservas" || route.path === "/desativarCampos" ) &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>

            {/* Sidebar - Aulas */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  (route.path === "/aulas" || route.path === "/visaoCalendario" || route.path === "/historicoDeAula" )
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-clipboard"></i>
                    <span>{props.t("Aulas")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                        (route.path === "/aulas" || route.path === "/visaoCalendario" || route.path === "/historicoDeAula" ) &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>

          {/* ------- */}

            {/* Sidebar - Jogadores */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  (route.path === "/procurarUtilizadores" || route.path === "/adicionarBancoDeHoras" || route.path === "/historicoDeAulas" || route.path === "https://webapp.greatpadel.pt/" || route.path === "/dadosDoRequestID" || route.path === "/email_jogadores")
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("Jogadores")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                        (route.path === "/procurarUtilizadores" || route.path === "/adicionarBancoDeHoras" || route.path === "https://webapp.greatpadel.pt/" || route.path === "/dadosDoRequestID" || route.path === "/email_jogadores" ) &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          {route.path === "https://webapp.greatpadel.pt/" ? (
                            <a href="https://webapp.greatpadel.pt" target="_blank" rel="noopener noreferrer">{props.t(route.custom_name)}</a>
                          ) : (

                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                          )}
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>

            {/* Sidebar - Treinadores */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  (route.path === "/treinadores" )
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user"></i>
                    <span>{props.t("Treinadores")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                        (route.path === "/treinadores" /* || route.path === "/treinadoresFatura" */ ) &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>
            

            {/* Sidebar - Contratos */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  (route.path === "/contratos" || route.path === "/contratosPeriodicos" )
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-book"></i>
                    <span>{props.t("Contratos")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                        (/* route.path === "/contratos" || */ route.path === "/contratosPeriodicos") &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>
               {/* Sidebar - Nutrição */}
            <li>
              {userRoutes.some(
                (route) =>
                  route.roles.includes(userAuthRole) &&
                  (route.path === "/nutricao"  )
              ) && (
                <React.Fragment>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-apple"></i>
                    <span>{props.t("Nutrição")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {userRoutes
                      .filter(
                        (route) =>
                        (/* route.path === "/contratos" || */ route.path === "/nutricao") &&
                          route.roles.includes(userAuthRole)
                      )
                      .map((route, idx) => (
                        <li key={idx}>
                          <Link to={route.path}>{props.t(route.custom_name)}</Link>
                        </li>
                      ))}
                  </ul>
                </React.Fragment>
              )}
            </li>
            


          </ul>
          <hr style={{  paddingBottom: "10px" , paddingTop: "10px" }}></hr>
     
          
                  
                   
           
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
