import { createSlice } from "@reduxjs/toolkit";

export const userRoles = createSlice({
    name: "userRoles",
    initialState: {
        value: [],
    },
    reducers: {
        setRoles: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setRoles } = userRoles.actions;

export const selectRoles = state => state.userRoles.value;

export default userRoles.reducer;
