import firebase from "firebase/app";
import "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import axios from 'axios';
import { addImagemToStorage, calculaReservasEbloqueiosDiarios, getBackUpURL, imagemDaApp, imagemDaAppEstado } from 'services/clube/clube_services';
import { useSelector } from 'react-redux';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';
import { selectimagemDaApp } from 'store/clube/imagem_da_app_reducer';
import { backUpHorariosBloqueados, docDeTeste, gravaHorariosBloqueados, removeHorariosAnteriores } from 'services/localizacao/localizacao_services';
import { deleteBloqueio } from "services/reservas/reservas_services";
import { listAllUsers } from "services/useres/jogadores_services";

function ImagemDaAppScreen() {
    const [isAtiva, setIsAtiva] = useState(true)
    const { t, i18n } = useTranslation()
    const clubeId = useSelector(selectclubeid)
    const [isLoading, setIsLoading] = useState(false)

    function addDays(date, days) {
      return new Date(date.getTime() + days*60000 * 60 * 24);
    }

    const imagemDaAppJson = useSelector(selectimagemDaApp);
    var imagemDaAppValue = null;
    if(typeof imagemDaAppJson != "undefined"){
      imagemDaAppValue = JSON.parse(imagemDaAppJson)
    }
    const [file,setFile] = useState(null)
    const [image, setImage] = useState(null)

    const [imagmeAtual, setImagemAtual] = useState(null)

    async function CDNUpload() {
        if(file != ""){
        var formData = new FormData();
        formData.append('ficheiroEmail', file);
        formData.append('requestKey', process.env.REACT_APP_CDN_API_KEY);
        return await axios
        .post('https://cdn.greatpadel.pt/uploadFicheiro', formData, {
          headers: {
          'Content-Type': 'multipart/form-data',
          },
          })
          .then(function (response) {
          return response.data;
          })
          .catch(function (error) {
          console.log(error);
          return ""
        });
      } else {
        return ""
      }
    
    }

    const handleChange = event => {
        if (event.target.checked) {
          console.log("✅ Checkbox is checked")
        } else {
          console.log("⛔️ Checkbox is NOT checked")
        }
        setIsAtiva(current => !current)
      }

      useEffect(() =>{
        if(imagemDaAppValue != null){
        setIsAtiva(imagemDaAppValue.isAtiva)
        setImage(imagemDaAppValue.imagem)
        setImagemAtual(imagemDaAppValue.imagem)
      }
      }, [])

    return (  
    <React.Fragment>
        <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
              {/* Título */}
              <Row className="align-items-center">
                <Col md={10}>
                  <h6 className="page-title">{t("Imagem da App")}</h6>
                </Col>
                 {/*  <Button
                  onClick={async () => {
                      listAllUsers()
                  }}
                >BackUp Horarios bloqueados </Button>    
                 */}
              </Row>
          </div>
          {/* <Button  onClick={async () => {
           
            var listaAux = []
            var mapDepois = {}
            var newJson = json.horariosBloqueadosJson
            for (const key in newJson) {
              
              if (newJson[key].horaFinal != undefined ) {
                
                var horaInicial =  new Date(newJson[key].horaInicial)
                var horaFinal =  new Date(newJson[key].horaFinal)
                newJson[key].horaInicial = horaInicial
                newJson[key].horaFinal = horaFinal 
                console.log(newJson[key])
               if (newJson[key].horaFinal < addDays(new Date(), 10 )) {
                  let mapAux = {};
                  mapAux = newJson[key];
                  mapAux.nome = key;
                  listaAux.push(mapAux);
                } else {
                  mapDepois[key] = newJson[key];
                } 
              }
            }
            listaAux.sort((a,b) => {
              if(a.horaInicial < b.horaInicial ) {
                return 1
              } else {
                return - 1
              }
            })
            console.log(listaAux)
            var newMap = {}
            for (let index = 0; index < listaAux.length -1; index++) {
              if (listaAux[index].horaInicial != undefined) {
                  const element = listaAux[index].horaInicial;
                  const key =("0" + ((element.getMonth() + 1).toString() )).slice(-2) + ("0" + element.getDate().toString()).slice(-2)
                  if ( newMap[key] == undefined) {
                    newMap[key] = {}
                    newMap[key][listaAux[index].nome] = listaAux[index]
                  } else {
                    newMap[key][listaAux[index].nome] = listaAux[index]
                  }
                }
              }
             
              await gravaHorariosBloqueados(clubeId,"2023",newMap)
            

          }}>
              ASD ASD ASD ASD
          </Button>  */}
          <Row>
            <Col>
            <Label check>
                <Input
                  type="checkbox"
                  checked={isAtiva}
                  value={isAtiva}
                  onChange={handleChange}
                  id="isAulaAtiva"
                  name="subscribe"
                />{" "}
                Imagem Ativa
              </Label>
            </Col>
            <Col>
               
            </Col>
          </Row>
          <Row style={{paddingTop: "20px"}} >
            <Input type='file' onChange={(e) => {
                console.log("File")
                if( typeof e.target.files[0] != "undefined" && e.target.files[0] != null  ) {
                if(e.target.files[0].size > 1000000) {
                    alert("Imagem grande demais")
                } else {
                    setFile(e.target.files[0])
                    console.log(URL.createObjectURL(e.target.files[0]) );
                    setImage(URL.createObjectURL(e.target.files[0]) );
                }} else {
                    setFile(null)
                    setImage(null);
                }
                }} ></Input>
                
            <Row>
                <Col md={6}>
             <img  style={{ marginTop: "40px", marginBottom: "40px"}} width="100%"  alt="preview image"  src={image}/>
             </Col>
             </Row>
             
          </Row>
        </Container>
        { isLoading ? <Spinner></Spinner> : 
        <Button color='primary' onClick={async () => {
            setIsLoading(true);
          if( imagemDaAppValue == null && (file == null || file == "")  ){
             alert("Imagem Inválida")
          } else if(imagemDaAppValue != null && (file == null || file == ""))  {
            var res =  await imagemDaAppEstado(clubeId,isAtiva)
            if(res) {
             alert("Dados alterados com successo")
            } else {
             alert("Erro a editar dados")
            }
        } else {
          await addImagemToStorage(clubeId,"imagem",file).then(async (value) => {
            console.log(value)
             if(value != "") {
                await imagemDaApp(clubeId,value,isAtiva);
                alert("Imagem adicionada com sucesso");
            } else {
                alert("Erro a adiconar a Imagem");
            }
            setIsLoading(false);
        })
        }
        setIsLoading(false);
        }} > Editar </Button>}
       
        </div>
    </React.Fragment>);
}

export default ImagemDaAppScreen;

const json = {"horariosBloqueadosJson": {
  "Non_Stop_M5_dia_24": {
    "horaInicial": "2023-11-24 22:00:00 UTC",
    "horaFinal": "2023-11-25 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3"
    ]
  },
  "Team_Building_Espaue7_o_Livre": {
    "horaInicial": "2023-12-16 15:00:00 UTC",
    "horaFinal": "2023-12-16 17:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_5_TARDE": {
    "horaInicial": "2023-10-05 13:00:00 UTC",
    "horaFinal": "2023-10-05 18:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Non_Stop_M4_dia_23": {
    "horaInicial": "2023-11-23 22:00:00 UTC",
    "horaFinal": "2023-11-24 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Non_Stop_M5_dia_21": {
    "horaInicial": "2023-11-21 22:00:00 UTC",
    "horaFinal": "2023-11-22 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3"
    ]
  },
  "Non_Stop_M5_dia_27": {
    "horaInicial": "2023-11-27 22:00:00 UTC",
    "horaFinal": "2023-11-28 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "non_stop_mistos_dia_5": {
    "horaInicial": "2023-11-05 18:00:00 UTC",
    "horaFinal": "2023-11-05 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 2"
    ]
  },
  "NON_STOP_dia_7_M5_CAMPO": {
    "horaInicial": "2023-09-07 21:00:00 UTC",
    "horaFinal": "2023-09-07 23:00:00 UTC",
    "campos": [
      "Campo 6"
    ]
  },
  "encerrado_dia_17": {
    "horaInicial": "2023-09-17 15:00:00 UTC",
    "horaFinal": "2023-09-17 19:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Non_Stop_M4_dia_28": {
    "horaInicial": "2023-11-28 22:00:00 UTC",
    "horaFinal": "2023-11-29 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3"
    ]
  },
  "fds_10_set_manha": {
    "horaInicial": "2023-09-10 06:00:00 UTC",
    "horaFinal": "2023-09-10 07:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_M3_dia_29": {
    "horaInicial": "2023-11-29 22:00:00 UTC",
    "horaFinal": "2023-11-30 00:00:00 UTC",
    "campos": [
      "Campo 6",
      "Campo 3",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Non_Stop_dia_25_quarta": {
    "horaInicial": "2023-10-25 21:00:00 UTC",
    "horaFinal": "2023-10-25 23:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "fim_de_semana_dia_2_tarde": {
    "horaInicial": "2023-09-02 12:30:00 UTC",
    "horaFinal": "2023-09-02 14:00:00 UTC"
  },
  "non_stop_dia_25_09": {
    "horaInicial": "2023-09-25 21:00:00 UTC",
    "horaFinal": "2023-09-25 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_OktoberGreat_dia_31": {
    "horaInicial": "2023-10-31 22:00:00 UTC",
    "horaFinal": "2023-11-01 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3"
    ]
  },
  "Segunda_dia_27": {
    "horaInicial": "2023-11-27 09:00:00 UTC",
    "horaFinal": "2023-11-27 12:30:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 4",
      "Campo 6",
      "Campo 3",
      "Campo 5"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_6": {
    "horaInicial": "2023-10-06 18:00:00 UTC",
    "horaFinal": "2023-10-06 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 6",
      "Campo 2",
      "Campo 3"
    ]
  },
  "Atividade_IPCA": {
    "horaInicial": "2023-11-30 10:00:00 UTC",
    "horaFinal": "2023-11-30 12:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Non_Stop_M3_6_Dez": {
    "horaInicial": "2023-12-06 22:00:00 UTC",
    "horaFinal": "2023-12-07 00:00:00 UTC",
    "campos": [
      "Campo 5",
      "Campo 3",
      "Campo 4"
    ]
  },
  "Manutencao_Tourniquetes_2": {
    "horaInicial": "2023-09-19 08:30:00 UTC",
    "horaFinal": "2023-09-19 13:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_5": {
    "horaInicial": "2023-10-05 06:00:00 UTC",
    "horaFinal": "2023-10-05 13:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Non_Stop_m4_hoje": {
    "horaInicial": "2023-11-28 22:00:00 UTC",
    "horaFinal": "2023-11-29 00:00:00 UTC",
    "campos": [
      "Campo 6"
    ]
  },
  "fds_set_10_noite": {
    "horaInicial": "2023-09-10 19:00:00 UTC",
    "horaFinal": "2023-09-10 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "non_stop_20_09": {
    "horaInicial": "2023-09-20 21:00:00 UTC",
    "horaFinal": "2023-09-20 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5"
    ]
  },
  "fds_9_set_tarde": {
    "horaInicial": "2023-09-09 12:30:00 UTC",
    "horaFinal": "2023-09-09 14:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_stop_M5_dia_17": {
    "horaInicial": "2023-11-17 22:00:00 UTC",
    "horaFinal": "2023-11-18 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 4",
      "Campo 2",
      "Campo 5"
    ]
  },
  "Non_stop_M4_dia_16": {
    "horaInicial": "2023-11-16 22:00:00 UTC",
    "horaFinal": "2023-11-17 00:00:00 UTC",
    "campos": [
      "Campo 6",
      "Campo 3",
      "Campo 5",
      "Campo 4"
    ]
  },
  "fim_de_semana_dia_3_noite": {
    "horaInicial": "2023-09-03 19:00:00 UTC",
    "horaFinal": "2023-09-03 23:00:00 UTC"
  },
  "Open_Crue9_dito_Agrued_cola_DIA_8_TARDE": {
    "horaInicial": "2023-10-08 12:30:00 UTC",
    "horaFinal": "2023-10-08 18:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Non_Stop_M5_SEXTA": {
    "horaInicial": "2023-10-20 21:00:00 UTC",
    "horaFinal": "2023-10-20 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "OktoberGreat_dia_1_22_23": {
    "horaInicial": "2023-11-01 22:00:00 UTC",
    "horaFinal": "2023-11-01 23:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Non_Stop_M4": {
    "horaInicial": "2023-10-12 21:00:00 UTC",
    "horaFinal": "2023-10-12 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_M5": {
    "horaInicial": "2023-10-11 21:00:00 UTC",
    "horaFinal": "2023-10-11 23:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 6",
      "Campo 5"
    ]
  },
  "Non_Stop_semana_3": {
    "horaInicial": "2023-10-18 21:00:00 UTC",
    "horaFinal": "2023-10-18 23:00:00 UTC",
    "campos": [
      "Campo 5",
      "Campo 6",
      "Campo 3",
      "Campo 4"
    ]
  },
  "Non_Stop_semana_2": {
    "horaInicial": "2023-10-17 21:00:00 UTC",
    "horaFinal": "2023-10-17 23:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 3",
      "Campo 5"
    ]
  },
  "Non_Stop_mistos_fim_de_semana_dia_15": {
    "horaInicial": "2023-10-15 10:00:00 UTC",
    "horaFinal": "2023-10-15 12:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "non_stop_feminino_oktober_great": {
    "horaInicial": "2023-11-01 14:30:00 UTC",
    "horaFinal": "2023-11-01 16:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "Teste": {
    "horaInicial": "2023-10-16 09:00:00 UTC",
    "horaFinal": "2023-10-16 10:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "Non_Stop_M5_dia_30": {
    "horaInicial": "2023-11-30 22:00:00 UTC",
    "horaFinal": "2023-12-01 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "non_stop_M4_dia_4": {
    "horaInicial": "2023-11-04 18:00:00 UTC",
    "horaFinal": "2023-11-04 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "Non_stop_M5_2": {
    "horaInicial": "2023-10-11 21:00:00 UTC",
    "horaFinal": "2023-10-11 23:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "non_stop_quem_fecha_manhue3_": {
    "horaInicial": "2023-09-16 08:00:00 UTC",
    "horaFinal": "2023-09-16 13:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "OktoberGreat_dia_1_19_20": {
    "horaInicial": "2023-11-01 19:00:00 UTC",
    "horaFinal": "2023-11-01 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 6"
    ]
  },
  "fds_set_9_manha": {
    "horaInicial": "2023-09-09 06:30:00 UTC",
    "horaFinal": "2023-09-09 07:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_Mistos_dia_22_outubro": {
    "horaInicial": "2023-10-22 17:00:00 UTC",
    "horaFinal": "2023-10-22 19:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "fds_10_set_tarde": {
    "horaInicial": "2023-09-10 12:30:00 UTC",
    "horaFinal": "2023-09-10 15:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_M3_SEGUNDA": {
    "horaInicial": "2023-09-11 21:00:00 UTC",
    "horaFinal": "2023-09-11 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_dia_6u2f_11": {
    "horaInicial": "2023-11-06 22:00:00 UTC",
    "horaFinal": "2023-11-07 00:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6",
      "Campo 1"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_8_NOITE": {
    "horaInicial": "2023-10-08 18:00:00 UTC",
    "horaFinal": "2023-10-08 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "fim_de_semana_dia_3_tarde": {
    "horaInicial": "2023-09-03 12:30:00 UTC",
    "horaFinal": "2023-09-03 15:00:00 UTC"
  },
  "fds_9_set_noite": {
    "horaInicial": "2023-09-09 19:00:00 UTC",
    "horaFinal": "2023-09-09 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "fim_de_semana_dia_3_manhue3_": {
    "horaInicial": "2023-09-03 06:00:00 UTC",
    "horaFinal": "2023-09-03 07:30:00 UTC"
  },
  "Non_Stop_mistos_dia_8": {
    "horaInicial": "2023-09-08 18:30:00 UTC",
    "horaFinal": "2023-09-08 20:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5"
    ]
  },
  "non_stop_M5_dia_26": {
    "horaInicial": "2023-09-26 21:00:00 UTC",
    "horaFinal": "2023-09-26 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3"
    ]
  },
  "OktoberGreat_dia_1_23_00": {
    "horaInicial": "2023-11-01 23:00:00 UTC",
    "horaFinal": "2023-11-02 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Non_stop_M5_sexta_1": {
    "horaInicial": "2023-11-03 22:00:00 UTC",
    "horaFinal": "2023-11-04 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Stop_M5_dia_13": {
    "horaInicial": "2023-11-13 22:00:00 UTC",
    "horaFinal": "2023-11-14 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 4",
      "Campo 6",
      "Campo 5"
    ]
  },
  "Non_Stop_M4_dia_2": {
    "horaInicial": "2023-10-02 21:00:00 UTC",
    "horaFinal": "2023-10-02 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 6",
      "Campo 3",
      "Campo 4"
    ]
  },
  "OktoberGreat_dia_1_20_21": {
    "horaInicial": "2023-11-01 20:00:00 UTC",
    "horaFinal": "2023-11-01 21:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 6"
    ]
  },
  "Non_Stop_M4_dia_3": {
    "horaInicial": "2023-10-03 21:00:00 UTC",
    "horaFinal": "2023-10-03 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_8_MANHuc3_": {
    "horaInicial": "2023-10-08 06:00:00 UTC",
    "horaFinal": "2023-10-08 12:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "fim_de_semana_dia_2_noite": {
    "horaInicial": "2023-09-02 19:00:00 UTC",
    "horaFinal": "2023-09-02 23:00:00 UTC"
  },
  "TOurns_manut": {
    "horaInicial": "2023-10-20 10:30:00 UTC",
    "horaFinal": "2023-10-20 13:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "non_stop_m3___06u2f_09": {
    "horaInicial": "2023-09-06 21:00:00 UTC",
    "horaFinal": "2023-09-06 23:00:00 UTC"
  },
  "non_stop_mx": {
    "horaInicial": "2023-09-01 20:00:00 UTC",
    "horaFinal": "2023-09-01 22:00:00 UTC"
  },
  "non_stop_dia_28_M5": {
    "horaInicial": "2023-09-28 21:00:00 UTC",
    "horaFinal": "2023-09-28 23:00:00 UTC",
    "campos": [
      "Campo 4",
      "Campo 5",
      "Campo 6",
      "Campo 3"
    ]
  },
  "torneio_CA": {
    "horaInicial": "2023-10-05 19:30:00 UTC",
    "horaFinal": "2023-10-05 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 6"
    ]
  },
  "Non_Stop_dia_27_sexta": {
    "horaInicial": "2023-10-27 21:00:00 UTC",
    "horaFinal": "2023-10-27 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6"
    ]
  },
  "non_stop_M5_dia_21_hoje": {
    "horaInicial": "2023-09-21 21:00:00 UTC",
    "horaFinal": "2023-09-21 23:00:00 UTC",
    "campos": [
      "Campo 2"
    ]
  },
  "Non_Stop_dia_9u2f_11": {
    "horaInicial": "2023-11-09 22:00:00 UTC",
    "horaFinal": "2023-11-10 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 3",
      "Campo 5",
      "Campo 2",
      "Campo 6"
    ]
  },
  "jantar_2": {
    "horaInicial": "2023-12-15 22:00:00 UTC",
    "horaFinal": "2023-12-15 23:00:00 UTC",
    "campos": [
      "Campo 3"
    ]
  },
  "Gravaue7_ue3_o_vued_deo_natal": {
    "horaInicial": "2023-12-02 18:00:00 UTC",
    "horaFinal": "2023-12-02 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 4",
      "Campo 6",
      "Campo 3",
      "Campo 5"
    ]
  },
  "jantar_3": {
    "horaInicial": "2023-12-15 23:00:00 UTC",
    "horaFinal": "2023-12-16 00:00:00 UTC",
    "campos": [
      "Campo 3"
    ]
  },
  "Lexus": {
    "horaInicial": "2023-09-15 17:00:00 UTC",
    "horaFinal": "2023-09-15 21:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "fim_d_semana_quem_fecha_o_verao_dia_15": {
    "horaInicial": "2023-09-15 18:00:00 UTC",
    "horaFinal": "2023-09-15 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Oktobergreat_7": {
    "horaInicial": "2023-11-01 22:00:00 UTC",
    "horaFinal": "2023-11-01 23:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "Domingo_fechado_dia_1": {
    "horaInicial": "2023-10-01 15:00:00 UTC",
    "horaFinal": "2023-10-01 19:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 2",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Oktobergreat_6": {
    "horaInicial": "2023-11-01 21:00:00 UTC",
    "horaFinal": "2023-11-01 22:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "Oktobergreat_5": {
    "horaInicial": "2023-11-01 12:00:00 UTC",
    "horaFinal": "2023-11-01 14:00:00 UTC",
    "campos": [
      "Campo 2"
    ]
  },
  "non_stop_13_09": {
    "horaInicial": "2023-09-13 21:00:00 UTC",
    "horaFinal": "2023-09-13 23:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5"
    ]
  },
  "non_stop_m4___": {
    "horaInicial": "2023-09-04 21:00:00 UTC",
    "horaFinal": "2023-09-04 23:00:00 UTC"
  },
  "fim_de_semana_dia_1_manhue3_": {
    "horaInicial": "2023-09-02 06:00:00 UTC",
    "horaFinal": "2023-09-02 07:30:00 UTC"
  },
  "Manuetencao_Tourniquetes_1": {
    "horaInicial": "2023-09-18 10:00:00 UTC",
    "horaFinal": "2023-09-18 13:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "non_stop_M5_QUARTA": {
    "horaInicial": "2023-10-04 21:00:00 UTC",
    "horaFinal": "2023-10-04 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4"
    ]
  },
  "escola_bue1_sica_7G": {
    "horaInicial": "2023-10-30 11:30:00 UTC",
    "horaFinal": "2023-10-30 13:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "escola_bue1_sica_7D": {
    "horaInicial": "2023-12-06 14:30:00 UTC",
    "horaFinal": "2023-12-06 16:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "escola_bue1_sica_7C": {
    "horaInicial": "2023-11-30 14:30:00 UTC",
    "horaFinal": "2023-11-30 16:30:00 UTC",
    "campos": [
      "Campo 5",
      "Campo 6"
    ]
  },
  "escola_bue1_sica_7F": {
    "horaInicial": "2023-11-08 11:30:00 UTC",
    "horaFinal": "2023-11-08 13:30:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4"
    ]
  },
  "escola_bue1_sica_7E": {
    "horaInicial": "2023-11-14 14:30:00 UTC",
    "horaFinal": "2023-11-14 16:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "non_stop_m3": {
    "horaInicial": "2023-08-29 20:00:00 UTC",
    "horaFinal": "2023-08-29 22:00:00 UTC"
  },
  "non_stop_m4_1": {
    "horaInicial": "2023-08-30 20:00:00 UTC",
    "horaFinal": "2023-08-30 22:00:00 UTC"
  },
  "escola_bue1_sica_7B": {
    "horaInicial": "2023-11-23 10:30:00 UTC",
    "horaFinal": "2023-11-23 12:30:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4"
    ]
  },
  "non_stop_m5_1": {
    "horaInicial": "2023-08-28 21:00:00 UTC",
    "horaFinal": "2023-08-28 23:00:00 UTC"
  },
  "Aniversue1_rio_Aurelie_Pinto_non_stop": {
    "horaInicial": "2023-11-11 18:00:00 UTC",
    "horaFinal": "2023-11-11 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4"
    ]
  },
  "Non_Stop_Pais_e_Filhos_Dia_1": {
    "horaInicial": "2023-12-01 10:30:00 UTC",
    "horaFinal": "2023-12-01 12:30:00 UTC",
    "campos": [
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Escola_Bue1_sica": {
    "horaInicial": "2023-10-24 14:30:00 UTC",
    "horaFinal": "2023-10-24 16:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "quem_fecha_tarde": {
    "horaInicial": "2023-09-16 17:00:00 UTC",
    "horaFinal": "2023-09-16 18:00:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_7_TARDE": {
    "horaInicial": "2023-10-07 12:30:00 UTC",
    "horaFinal": "2023-10-07 18:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "non_stop_dia_7_M5": {
    "horaInicial": "2023-09-07 21:00:00 UTC",
    "horaFinal": "2023-09-07 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 4"
    ]
  },
  "Non_Stop_dia_10u2f_11": {
    "horaInicial": "2023-11-10 22:00:00 UTC",
    "horaFinal": "2023-11-11 00:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 3",
      "Campo 4"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_7_MANHuc3_": {
    "horaInicial": "2023-10-07 07:30:00 UTC",
    "horaFinal": "2023-10-07 12:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_7_NOITE": {
    "horaInicial": "2023-10-07 18:00:00 UTC",
    "horaFinal": "2023-10-07 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "non_stop_m5___2": {
    "horaInicial": "2023-08-31 21:00:00 UTC",
    "horaFinal": "2023-08-31 23:00:00 UTC"
  },
  "Torneio": {
    "horaInicial": "2023-07-01 06:00:00 UTC"
  },
  "Non_Stop_dia_7u2f_11": {
    "horaInicial": "2023-11-07 22:00:00 UTC",
    "horaFinal": "2023-11-08 00:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 3",
      "Campo 5"
    ]
  },
  "OktoberGreat_dia_1_21_22": {
    "horaInicial": "2023-11-01 21:00:00 UTC",
    "horaFinal": "2023-11-01 22:00:00 UTC",
    "campos": [
      "Campo 4",
      "Campo 6"
    ]
  },
  "Fotos": {
    "horaInicial": "2023-11-25 10:00:00 UTC",
    "horaFinal": "2023-11-25 13:00:00 UTC",
    "campos": [
      "Campo 3"
    ]
  },
  "non_stop_m3_quinta_26": {
    "horaInicial": "2023-10-26 21:00:00 UTC",
    "horaFinal": "2023-10-26 23:00:00 UTC",
    "campos": [
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "non_stop_21_09": {
    "horaInicial": "2023-09-21 21:00:00 UTC",
    "horaFinal": "2023-09-21 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 6",
      "Campo 5"
    ]
  },
  "non_stop_gabriela_30_09": {
    "horaInicial": "2023-09-30 10:00:00 UTC",
    "horaFinal": "2023-09-30 12:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 5"
    ]
  },
  "Non_Stop_M5_5_Dez": {
    "horaInicial": "2023-12-05 22:00:00 UTC",
    "horaFinal": "2023-12-06 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6"
    ]
  },
  "OktoberGreat_dia_1_18_19": {
    "horaInicial": "2023-11-01 18:00:00 UTC",
    "horaFinal": "2023-11-01 19:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 6",
      "Campo 2",
      "Campo 3"
    ]
  },
  "Fotos_2": {
    "horaInicial": "2023-11-25 15:00:00 UTC",
    "horaFinal": "2023-11-25 18:00:00 UTC",
    "campos": [
      "Campo 3"
    ]
  },
  "non_stop_mistos_dia_10_domingo": {
    "horaInicial": "2023-09-10 17:00:00 UTC",
    "horaFinal": "2023-09-10 19:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 2",
      "Campo 3",
      "Campo 6"
    ]
  },
  "Vued_deo_4": {
    "horaInicial": "2023-12-03 18:00:00 UTC",
    "horaFinal": "2023-12-03 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 2",
      "Campo 5",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Non_Stop_Mistos_dia_29": {
    "horaInicial": "2023-10-29 18:00:00 UTC",
    "horaFinal": "2023-10-29 20:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Non_stop_4_01": {
    "horaInicial": "2023-09-04 21:00:00 UTC",
    "horaFinal": "2023-09-04 23:00:00 UTC"
  },
  "Vued_deo_Natal_3": {
    "horaInicial": "2023-12-01 18:00:00 UTC",
    "horaFinal": "2023-12-01 21:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "Non_Stop_dia_23_segunda": {
    "horaInicial": "2023-10-23 21:00:00 UTC",
    "horaFinal": "2023-10-23 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Non_Stop_semana_outubro_1": {
    "horaInicial": "2023-10-16 21:00:00 UTC",
    "horaFinal": "2023-10-16 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Non_Stop_M4_4_Dez": {
    "horaInicial": "2023-12-04 22:00:00 UTC",
    "horaFinal": "2023-12-05 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "Segunda_dia_27_1": {
    "horaInicial": "2023-11-27 09:00:00 UTC",
    "horaFinal": "2023-11-27 10:30:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "oktober_great_dia_1_campo_lexus": {
    "horaInicial": "2023-11-01 16:30:00 UTC",
    "horaFinal": "2023-11-01 18:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 5",
      "Campo 3",
      "Campo 6"
    ]
  },
  "non_stop_quem_fecha_o_verue3_o_tarde": {
    "horaInicial": "2023-09-16 13:00:00 UTC",
    "horaFinal": "2023-09-16 16:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 5",
      "Campo 6",
      "Campo 3"
    ]
  },
  "Non_Stop_M3_dia_14": {
    "horaInicial": "2023-11-14 22:00:00 UTC",
    "horaFinal": "2023-11-15 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "TORNEIO_ca_2": {
    "horaInicial": "2023-10-06 19:00:00 UTC",
    "horaFinal": "2023-10-06 23:00:00 UTC",
    "campos": [
      "Campo 4"
    ]
  },
  "NX_5_OCT": {
    "horaInicial": "2023-10-05 22:00:00 UTC",
    "horaFinal": "2023-10-05 23:00:00 UTC",
    "campos": [
      "Campo 2"
    ]
  },
  "Open_Crue9_dito_Agrued_cola_DIA_5_NOITE": {
    "horaInicial": "2023-10-05 18:00:00 UTC",
    "horaFinal": "2023-10-05 23:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5"
    ]
  },
  "OktoberGreat_dia_1_manhue3_": {
    "horaInicial": "2023-11-01 11:00:00 UTC",
    "horaFinal": "2023-11-01 12:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "OktoberGreat_dia_1_hora_de_almoue7_o": {
    "horaInicial": "2023-11-01 12:00:00 UTC",
    "horaFinal": "2023-11-01 14:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 3",
      "Campo 6",
      "Campo 4"
    ]
  },
  "jantar_natal_staff": {
    "horaInicial": "2023-12-15 21:00:00 UTC",
    "horaFinal": "2023-12-16 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 5",
      "Campo 6",
      "Campo 2",
      "Campo 4"
    ]
  },
  "non_stop_m5___": {
    "horaInicial": "2023-09-05 21:00:00 UTC",
    "horaFinal": "2023-09-05 23:00:00 UTC"
  },
  "fim_d_semana_quem_fecha_o_verao_dia_16_noite": {
    "horaInicial": "2023-09-16 18:00:00 UTC",
    "horaFinal": "2023-09-16 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Segunda_dia_27_2": {
    "horaInicial": "2023-11-27 11:30:00 UTC",
    "horaFinal": "2023-11-27 12:30:00 UTC",
    "campos": [
      "Campo 1"
    ]
  },
  "Non_Stop_m5_sue1_bado_14_out": {
    "horaInicial": "2023-10-14 17:00:00 UTC",
    "horaFinal": "2023-10-14 19:00:00 UTC",
    "campos": [
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "non_stop_18_09": {
    "horaInicial": "2023-09-18 21:00:00 UTC",
    "horaFinal": "2023-09-18 23:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Torn_dez_2023": {
    "horaInicial": "2023-12-08 07:00:00 UTC",
    "horaFinal": "2023-12-08 09:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Torne_20203_dez": {
    "horaInicial": "2023-12-08 11:30:00 UTC",
    "horaFinal": "2023-12-08 13:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 5",
      "Campo 4"
    ]
  },
  "Great_christmas": {
    "horaInicial": "2023-12-08 11:00:00 UTC",
    "horaFinal": "2023-12-08 11:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 5",
      "Campo 4",
      "Campo 2",
      "Campo 6"
    ]
  },
  "Saf": {
    "horaInicial": "2023-12-08 20:00:00 UTC",
    "horaFinal": "2023-12-08 21:00:00 UTC",
    "campos": [
      "Campo 6"
    ]
  },
  "Non_Stop_M5_dia_12": {
    "horaInicial": "2023-12-12 22:00:00 UTC",
    "horaFinal": "2023-12-13 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 4",
      "Campo 2",
      "Campo 6",
      "Campo 3"
    ]
  },
  "Escola_bue1_sica_ufa_ltima_turma": {
    "horaInicial": "2023-12-12 15:30:00 UTC",
    "horaFinal": "2023-12-12 17:30:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2"
    ]
  },
  "Non_Stop_M3_dia_13": {
    "horaInicial": "2023-12-13 22:00:00 UTC",
    "horaFinal": "2023-12-14 00:00:00 UTC",
    "campos": [
      "Campo 4",
      "Campo 5",
      "Campo 6"
    ]
  },
  "Non_Sop_M4_Dia_14": {
    "horaInicial": "2023-12-14 22:00:00 UTC",
    "horaFinal": "2023-12-15 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 6",
      "Campo 3",
      "Campo 5"
    ]
  },
  "Non_Stop_M5_Dia_15": {
    "horaInicial": "2023-12-15 19:00:00 UTC",
    "horaFinal": "2023-12-15 21:00:00 UTC",
    "campos": [
      "Campo 2",
      "Campo 6",
      "Campo 4",
      "Campo 5"
    ]
  },"Non_Stop_Dia_17_Dez": {
    "horaInicial": "2023-12-17 18:00:00 UTC",
    "horaFinal": "2023-12-17 20:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 6",
      "Campo 4",
      "Campo 5"
    ]
  },
  "Non_Stop_M5_18_Dez": {
    "horaInicial": "2023-12-18 22:00:00 UTC",
    "horaFinal": "2023-12-19 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 6",
      "Campo 4",
      "Campo 5"
    ]
  },
  "Non_Stop_M5_19_Dez": {
    "horaInicial": "2023-12-19 22:00:00 UTC",
    "horaFinal": "2023-12-20 00:00:00 UTC",
    "campos": [
      "Campo 1",
      "Campo 3",
      "Campo 6",
      "Campo 4",
      "Campo 2"
    ]
  }
}
}