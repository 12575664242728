import { createSlice } from "@reduxjs/toolkit"

export const userlocalstorageSlice = createSlice({
  name: "userlocalstorage",
  initialState: {
    value: "",
  },
  reducers: {
    setuserlocalstorage: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setuserlocalstorage } = userlocalstorageSlice.actions

export const selectuserlocalstorage = state => state.userlocalstorage.value

export default userlocalstorageSlice.reducer
