import React, { useEffect, useState } from "react"
import { Check, DashCircle, PlusCircle } from "react-bootstrap-icons"
import DatePicker from "react-date-picker"
import { useSelector } from "react-redux"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import {
  adicinarAlunosAAula,
  adicionarAlunoEData,
  checkaNrAulasAcademia,
} from "services/aulas/aulas_services"
import { checkIfUserExists, checkIfUserExistsEmaiLOrNum } from "services/reservas/reservas_services"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"
import firebase from "firebase/app"

function AdicionarAlunos(props) {

  function getRandomQrcode(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [cc, setCC] = useState("")
  const [nome, setNome] = useState("")

  const [experimental, setExperimental] = useState(false)
  const [podeFazer, setPodeFazer] = useState(false)

  const [dataDoProximoPagamento, setDataDoProximoPagamento] = useState(null)
  const [fidelizacao, setFidelizacao] = useState("Não")
  const [academia, setAcademia] = useState("Não")
  const [tipoDeAulasValue, setTipoDeAulasValue] = useState({})
  const [aulasPax, setAulasPax] = useState(0)

  const [colorBTN1, setColorBTN1] = useState("secondary")

  const [contratoAcademia, setContratoAcademia] = useState("")
  const [contratoAcademiaDate, setContratoAcademiaDate ] = useState("")

  const tipoDeAulasStringfied = useSelector(selectaulasvalores)
  const tipoDeAulasJson = JSON.parse(tipoDeAulasStringfied)
  const mapAualsV = [" "].concat(Object.entries(tipoDeAulasJson))
  /* const [aulasSuficientes,setAulasSuficientes] = useState(true) */

  const sortedList = mapAualsV.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })

  function addDays(date, days) {
    return new Date(date.getTime() + days * 60000 * 60 * 24)
  }

  const [nextDate, setnextDate] = useState(addDays(new Date(), 14))
  const [numeroDeAulasAcademia, setNumeroDeAulasAcademia] = useState(0)

  async function emailNumeroCheck(email, setEmailV, setNomeV, setCCV, setNrTeleV) {
    var primeiroNome = ""
    var ultimoNome = ""
    var numeroTele = ""
    var isEmail1Aux = await checkIfUserExistsEmaiLOrNum(email)

    console.log(email)
    console.log("isEmail1")
    console.log(isEmail1Aux) 
    
    
    if (isEmail1Aux.length > 1) {
      alert("Mais de um user encontrado com este numero\nDiga qual email deseja reservar")
      return false
    }
    if (isEmail1Aux.length == 0) {
      alert("Os dados não estão associados a nenhuma conta")
      return false
    }
    var isEmail1 = isEmail1Aux.at(0)

    if (typeof isEmail1.aulasPax != "undefined") {
      setAulasPax(isEmail1.aulasPax)
    } else {
      setAulasPax(0)
    }
    if (typeof isEmail1.aulasAcamida != "undefined") {
      setNumeroDeAulasAcademia(isEmail1.aulasAcamida.numeroDeAulas);
      setContratoAcademia(isEmail1.aulasAcamida.tipo)
      setContratoAcademiaDate(isEmail1.aulasAcamida.expiryAt.toDate())
    } else {
      setContratoAcademia("")
      setContratoAcademiaDate("")
    }
    console.log("isEmail1.aulaExperimental")
    console.log(isEmail1.aulaExperimental)
    if(typeof isEmail1.aulaExperimental == "undefined" || isEmail1.aulaExperimental == false ) {
      console.log("A Por o pode fazer TRUE")
      setPodeFazer(true)
    }
    setEmailV(isEmail1.email)
    if (typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if (typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if (typeof isEmail1.numeroDoCC != "undefined") {
      setCCV(isEmail1.numeroDoCC)
    } else {
      setCCV(null)
    }

    if (typeof isEmail1.numeroTele != "undefined") {
      numeroTele = isEmail1.numeroTele
      setNrTeleV(isEmail1.numeroTele)
    } else {
      setNrTeleV("")
    }
    setNomeV(primeiroNome + ultimoNome)
  
    if (
      typeof isEmail1.primeiroNome != "undefined" &&
      typeof isEmail1.ultimoNome != "undefined" &&
      typeof isEmail1.numeroDoCC != "undefined"
    ) {
      return true
    } else {
      return false
    }
  }

  async function emailCheck(email, nomeId, ccId) {
    var primeiroNome = ""
    var ultimoNome = ""
    var isEmail1 = await checkIfUserExists(email)

    if (Object.keys(isEmail1).length == 0) {
      alert("O email não está registado")
      return false
    }
    if (typeof isEmail1.aulasPax != "undefined") {
      setAulasPax(isEmail1.aulasPax)
    } else {
      setAulasPax(0)
    }

    if (typeof isEmail1.aulasAcamida != "undefined") {
      setNumeroDeAulasAcademia(isEmail1.aulasAcamida.numeroDeAulas);
      setContratoAcademia(isEmail1.aulasAcamida.tipo)
      setContratoAcademiaDate(isEmail1.aulasAcamida.expiryAt.toDate())
    } else {
      setContratoAcademia("")
      setContratoAcademiaDate("")
    }
    console.log("isEmail1.aulaExperimental")
    console.log(isEmail1.aulaExperimental)
    if(typeof isEmail1.aulaExperimental == "undefined" || isEmail1.aulaExperimental == false ) {
      console.log("A Por o pode fazer TRUE")
      setPodeFazer(true)
    }

    if(typeof isEmail1.numeroTele != "undefined") {
      setTel(isEmail1.numeroTele)
    }

    if (typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if (typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if (typeof isEmail1.numeroDoCC != "undefined") {
      setCC(isEmail1.numeroDoCC.trim())
    } else {
      document.getElementById(ccId).value = null
    }
    setNome(primeiroNome + ultimoNome)

    
      return true
    
  }

  function resetDados() {
    setCC("")
    setNome("")
    setContratoAcademia("")
    setContratoAcademiaDate("")
  }

  function resetDadosTotal() {
    setColorBTN1("secondary")
    setEmail("")
    setCC("")
    setTel("")
    setNome("")
    setContratoAcademia("")
    setContratoAcademiaDate("")
  }

  useEffect(() =>  {
    
  }, [])

  return (
    <React.Fragment>
      <Button
      className="btn btn-primary m-2"
        onClick={() => {
          if(props.aula != null ){
            console.log(props.aula.tipo)
            if(typeof props.aula.tipo != "undefined")
             {
                if(props.aula.tipo == "Academia") {
                  setAcademia("Sim")
                }
                if(props.aula.tipo == "Experimental") {
                  setExperimental(true)
                }
             }
          }
          resetDadosTotal()
          setIsOpen(!isOpen)
        }}
        color="transparent"
      >
        <PlusCircle></PlusCircle>
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
          resetDadosTotal()
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(!isOpen)
            resetDadosTotal()
          }}
        >
          {"Adicionar Jogador " }
        </ModalHeader>
        <ModalBody>
          <Form style={{ paddingBottom: "20px" }}>
          {/* <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Academia</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={academia}
                    style={
                      academia == "Não" ? { color: "red" } : { color: "green" }
                    }
                    onChange={e => setAcademia(e.target.value)}
                    type="select"
                  >
                    <option>Não</option>
                    <option>Sim</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup> */}
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                      setColorBTN1("secondary")
                      setTel("")
                      resetDados()
                    }}
                    type="email"
                    name="email"
                    id="emailId"
                  />
                </Col>
                <Col md={2}>
                  <Button
                    id="ckeckButton1"
                    color={colorBTN1}
                    onClick={async () => {
                      resetDados()
                      var check = await emailNumeroCheck(
                        email,
                       setEmail,
                        setNome,
                        setCC,
                        setTel
                      )
                      if (check) {
                        setColorBTN1("primary")
                      } else {
                        setColorBTN1("secondary")
                      }
                    }}
                  >
                    <Check></Check>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <p>Tel</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={tel}
                    onChange={e => {
                      setTel(e.target.value)
                      setColorBTN1("secondary")
                      setEmail("")
                      resetDados()}
                    }
                    type="number"
                    name="tel"
                    id="telId"
                  />
                </Col>
                <Col md={2}>
                  <Button
                    id="ckeckButton1"
                    color={colorBTN1}
                    onClick={async () => {
                      var check = await emailNumeroCheck(
                        tel,
                       setEmail,
                        setNome,
                        setCC,
                        setTel
                      )
                      if (check) {
                        setColorBTN1("primary")
                      } else {
                        setColorBTN1("secondary")
                      }
                    }}
                  >
                    <Check></Check>
                  </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={ colorBTN1 == "secondary"}>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome}
                    onChange={e => setNome(e.target.value)}
                    type="text"
                    name="nome"
                    id="nomeId"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={ colorBTN1 == "secondary"} >
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc}
                    onChange={e => setCC(e.target.value)}
                    type="cc"
                    name="cc"
                    id="ccId"
                  />
                </Col>
              </Row>
            </FormGroup>
           {/*  <FormGroup hidden={academia == "Sim" ? false : true}>
              <Row>
                <Col md={3}>
                  <p>Fidelização</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={fidelizacao}
                    onChange={e => setFidelizacao(e.target.value)}
                    type="select"
                    style={
                      fidelizacao == "Não"
                        ? { color: "red" }
                        : { color: "green" }
                    }
                  >
                    <option>Não</option>
                    <option>Sim</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup> */}
             <FormGroup hidden={academia == "Sim" || experimental == true || colorBTN1 == "secondary" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Número de aulas</p>
                </Col>
                <Col md={9}>{aulasPax}</Col>
              </Row>
            </FormGroup> 
            <FormGroup hidden={academia != "Sim" || colorBTN1 == "secondary" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Contrato</p>
                </Col>
                <Col md={9}>{contratoAcademia}</Col>
              </Row>
            </FormGroup> 
            <FormGroup hidden={academia != "Sim" || colorBTN1 == "secondary" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Expira em</p>
                </Col>
                <Col md={9}>{contratoAcademiaDate.toString()}</Col>
              </Row>
            </FormGroup> 
            {/* <FormGroup>
              <Row>
                <Label md={3}>
                  <p>Próximo Pagamento</p>
                </Label>
                <Col md={9}>
                  <DatePicker
                    value={dataDoProximoPagamento}
                    onChange={date => {
                      setDataDoProximoPagamento(date)
                    }}
                  ></DatePicker>
                </Col>
              </Row>
            </FormGroup> */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              var aulasSuficientes = true;
              if(academia== "Sim" ) {
                var numeroDeAulasDisponiveis = await checkaNrAulasAcademia(email, "Great Padel Vila Verde")
                console.log(numeroDeAulasDisponiveis)
                console.log(numeroDeAulasAcademia)
                if(numeroDeAulasDisponiveis >= numeroDeAulasAcademia ) {
                  alert("O jogador já não tem mais aulas disponíveis no contrato")
                  aulasSuficientes = false;
                } 
              }
              if(aulasSuficientes){
              console.log("Patig")
              console.log(academia)
              console.log(experimental)
              console.log("PODE FAZER: " + podeFazer)
              if(aulasPax == 0 && academia=="Não" && experimental == false) {
                alert("O utilizador não tem aulas suficientes")
              } else 
               if((contratoAcademia == "" || contratoAcademiaDate < new Date()) && experimental == false && academia=="Sim" ) {
                alert("O contrato do jogador não é válido")
              } else 
              if(experimental == true && podeFazer != true) {
                alert("O aluno já fez a aula Experimental")
              } else

              {
                const qrCode = getRandomQrcode(1, 16777215)
                var data = {}
                data.email = email
                data.cc = cc
                data.nome = nome
                data.tel = tel
                data.qrCode = qrCode
                /* data.fidelizacao = academia == "Não" ? null : fidelizacao
                data.academia = academia 
                data.tipoDeAula = academia == "Não" ? null : tipoDeAulasValue
                 data.valor = academia == "Não" ? null : tipoDeAulasJson[tipoDeAulasValue]
                data.proxPag = academia == "Não" ? firebase.firestore.Timestamp.fromDate(new Date()) : firebase.firestore.Timestamp.fromDate(nextDate) */

                await adicionarAlunoEData(email, data, props.aulaId).then(
                  value => {
                    if (value) {
                      alert("Dados atualizados com sucesso")
                      setIsOpen(!isOpen)
                      resetDadosTotal()
                    } else {
                      alert("Erro ao criar dados")
                      setIsOpen(!isOpen)
                      resetDadosTotal()
                    }
                  }
                ) 
              }}
            }}
            color="primary"
          >
            Adicionar
          </Button>
          <Button
            onClick={() => {
              setIsOpen(!isOpen)
              resetDadosTotal()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default AdicionarAlunos
