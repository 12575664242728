import AdicionarContratosModal from 'components/modals/adicionar_contratos_modal';
import AdicionarContratroEntidadesModal from 'components/modals/adicionar_contrato_entidades';
import AdicionarContratosPeriodicoModal from 'components/modals/adicionar_contrato_periodico';
import firebase from "firebase/app"
import React, { useEffect, useState } from 'react';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { contratosCollection, convertCamps, nomesCampos } from 'services/consts';
import EditarContratosPeriodicoModal from 'components/modals/editar_contratos_periodicos';
import { removeAula } from 'services/aulas/aulas_services';
import { element } from 'prop-types';



function ContratosPeriodicos() {
    
    function addDays(date, days) {
      return new Date(date.getTime() + days * 60000 * 60 * 24);
    }

    const { t, i18n } = useTranslation();
    const [contratos, setContratos] = useState([])

    var weekday = new Array(7)
    weekday[0] = " "
    weekday[1] = "Segunda"
    weekday[2] = "Terça"
    weekday[3] = "Quarta"
    weekday[4] = "Quinta"
    weekday[5] = "Sexta"
    weekday[6] = "Sábado"
    weekday[7] = "Domingo"

    async function getContratos(localizacao) {
      try {
        firebase
          .firestore()
          .collection("aulas").where("tipo","==", "Contrato")
          .where("localizacao", "==", localizacao)
          .onSnapshot(docsSnap => {
            var listaAux = []
            docsSnap.docs.forEach(element => {
              console.log(element.data())
              listaAux.push(element)
            })
            listaAux.sort(function (a,b) {  if(a.data().weekDay <= b.data().weekDay) {
              return -1
            } else {
              return 1
            } }  )
            setContratos(listaAux)
          })
      } catch (error) {
        console.error(error)
      }
    }

    async function removeExpiredContratos(localizacao, expiryDate) {
      try {
        firebase
          .firestore()
          .collection("aulas").where("tipo","==", "Contrato")
          .where("expiryAt", "<", expiryDate)
          .where("localizacao", "==", localizacao)
          .get().then((docsSnap) => {
            console.log(docsSnap.docs.length)
              docsSnap.forEach((elemental) => {
                  firebase.firestore()
                    .collection("aulas")
                    .doc(elemental.id).delete()
              } )
          })
      } catch (error) {
        console.error(error)
      }
    }
      
      useEffect(() => {
        getContratos("Great Padel Vila Verde")
      }, [])

      const stylesTable = {
        "--bs-table-bg": "#ececf1",
      }

    return (  
        <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={10}>
                <h6 className="page-title">{t("Contratos")}</h6>
              </Col>
              <Col color="primary" md={2}>
                <AdicionarContratosPeriodicoModal />
              </Col>
            </Row>
            
            <Row className='pt-4'>
              <Table bordered striped responsive className="myList" style={stylesTable} >
                  <thead>
                      <tr>
                          <th>Dia da Semana</th>
                          <th>Hora Inicial</th>
                          <th>Hora Final</th>
                          <th>Campo</th>
                          <th>Estado</th>
                          <th>Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                      {contratos.map((element, index) => {
                      const data = element.data()
                      return (
                        <tr key={index + data.horaInicial + weekday[data.weekDay]}>
                          <td>{weekday[data.weekDay]}</td>
                          <td>{data.horaInicial}</td>
                          <td>{data.horaFinal}</td>
                          <td>
                            { data.campos == null ? null : data.campos.map((elem, index) => {
                              return <p>{ convertCamps[elem]}</p> 
                            })}
                          </td>
                          <td>
                          {data.isAtiva == true ? (
                            <span className="badge bg-success fs-6 px-3">Ativa</span>
                          ) : (
                            <span className="badge bg-warning fs-6 px-3">Ativa</span>
                          )}
                        </td>
                        <div className="d-flex gap-3 align-items-center">
                            <Col  style={{ marginBottom: "5px" }}>
                                <EditarContratosPeriodicoModal isEdit={true} data={data} id={element.id} ></EditarContratosPeriodicoModal>
                            </Col>
                            <Col  style={{ marginBottom: "5px" }}>
                         
                         <Button className='btn btn-danger' onClick={async() => {
                          var res = removeAula(element.id)
                            if(res) {
                              alert("Contrato removido com sucesso")
                            } else {
                              alert("Erro a remover contrato")
                            }
                         }} >
                             <Trash></Trash>
                          </Button>
                      
                         </Col>
                          </div>
                          
                        </tr>
                      )
                    })}
                  </tbody>
              </Table>
            </Row>

        
             </div>
            </Container>
            </div>
            </React.Fragment>
    );
}

export default ContratosPeriodicos;