import { element, func } from "prop-types"
import React, { useEffect, useState } from "react"
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker"
import { useTranslation } from "react-i18next"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import {
  addContratoPDF,
  adicionarAulasAcademia,
  adicionarAulasPax,
  adicionarFeriasAcademia,
  adicionarFeriasContratos,
  alteraBancoDeHoras,
  alteraCC,
  alteraNIF,
  alteraTEL,
  pesquisarJogadoresByEmail,
  pesquisarJogadoresByNumeroDeTele,
  pesquisarJogadoresByPrimeiroNome,
  pesquisarJogadoresByUltimoNome,
  removeAulasAcademia,
  testeAddRemoveContratos,
} from "services/useres/jogadores_services"
import firebase from "firebase/app"
import "firebase/firestore"
import { merge } from "lodash"
import { useId } from "react"
import {
  ArrowRightCircle,
  ForwardFill,
  Pencil,
  PlusCircle,
} from "react-bootstrap-icons"
import EditarBancoDeHorasMOdal from "components/modals/editar_banco_de_horas_modal"
import { useDispatch, useSelector } from "react-redux"
import { selectclassesespeciais } from "store/localizacao/classes_especiais"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"
import DatePicker from "react-date-picker"
import { selectlocalizacao } from "store/localizacao/localizacao_reducer"
import { color } from "echarts"
import { Link } from "react-router-dom"
import { checkaAsAulasAcademia } from "services/aulas/aulas_services"
import { selectcontratosvalores } from "store/clube/valores_dos_contratos_reducer"
import { adicionarTipoDeContratosAoUser, removeValoresDosContratosDoUser } from "services/contratos/contratos_services"
import Swal from "sweetalert2"
import { selectCurrentUserAuth } from "store/middlewarerotas/CurrentUserAuth"
import { adminRole, recepRole } from "services/consts"
import { selectuserlocalstorage, setuserlocalstorage } from "store/localStorage/user_localstorage"

function ProcurarUtilzadoresScreen() {
  const dispatch = useDispatch()
  const userlocalstorage = useSelector(selectuserlocalstorage)

  const userRole = useSelector(selectCurrentUserAuth)
  const localizaco = useSelector(selectlocalizacao)
  const classesEspeciaisJson = useSelector(selectclassesespeciais)
  console.log("classesEspeciaisJson")
  console.log(classesEspeciaisJson)
  const classesEsp = JSON.parse(classesEspeciaisJson)
  var  mapClasses;
  if( typeof classesEsp != "undefined" )
  {
   mapClasses = [" "].concat(Object.entries(classesEsp))
}

  const { t, i18n } = useTranslation()
  const [userId, setUserId] = useState(null)
  const [user, setUser] = useState(null)
  const [isUser, setIsUser] = useState(false)

  const [email, setEmail] = useState(null)
  const [tel, setTel] = useState(null)
  const [numeroTELAnterior, setNumeroTELAnterior] = useState(null)
  const [isLoadingTEL, setIsLoadingTEL] = useState(false)
  const [numeroCC, setNumeroCC] = useState(null)
  const [numeroCCAnterior, setNumeroCCAnterior] = useState(null)
  const [isLoadingCC, setISLoadingCC] = useState(false)
  const [nome, setNome] = useState(null)
  const [dataDeNa, setDataDeNa] = useState(null)
  const [seguro, setSeguro] = useState(null)
  const [nif, setNif] = useState(null)
  const [numeroNIFAnterior, setNumeroNIFAnterior] = useState(null)
  const [isLoadingNIF, setIsLoadingNIF] = useState(false)
  const [bancoDeHoras, setBancoDeHoras] = useState(null)
  const [bancoDeHorasParaAlterar, setBancoDeHorasParaAlterar] = useState(null)
  const [contrato, setContrato] = useState(null)
  const [historicoDeContratos, setHistoricoDecontratos] = useState([])
  const [historicoDePagaments, sethistoricoDePagaments] = useState([])
  const [listaDeAulasAcademia, setListaDeAulasAcademia] = useState([])

  const [classesEspeciais, setclassesEspeciais] = useState({})
  const [isValidDate, setIsValidDate] = useState(false)
  const [value, onChange] = useState(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenPax, setIsOpenPax] = useState(false)
  const [isOpenAcademia, setIsOpenAcaemia] = useState(false)

  const [alteradoPor, setAlteradoPor] = useState("")
  const [notas, setNotas] = useState("")

  const [isListaDeUsers, setIsListaDeUSers] = useState(false)
  const [listaDeUsers, setListaDeUsers] = useState([])

  const [aulasPax, setAulasPax] = useState(0)
  const [aulasAcademia, setAulasAcademia] = useState({})
  const [aulaExperimental, setAulaExperimental] = useState("")

  const [aulasAcademiaTipo, setAulasAcademiaTipo] = useState("")
  const [aulasAcademiaFide, setAulasAcademiaFide] = useState("")
  const [aulasAcademiaExpr, setAulasAcademiaExpr] = useState("")
  const dataParaCompara = new Date( new Date().getFullYear(),new Date().getMonth(), new Date().getDate(),23,59)

  const [isOpenCC, setIsOpenCC] = useState(false)
  const [isOpenNif, setIsOpenNif] = useState(false)
  const [isOpenTel, setIsOpenTel] = useState(false)

  const [dataInicialDeFerais1, setDataInicialDeFerias1] = useState(null)
  const [dataInicialDeFerais2, setDataInicialDeFerias2] = useState(null)

  function resetDadosDoBancoDehoras() {
    setNotas("")
    setAlteradoPor("");
    setBancoDeHorasParaAlterar(null)
  }

  function toggle() {
    resetDadosDoBancoDehoras()
    setIsOpen(!isOpen)
  }

  function togglePax() {
    setIsOpenPax(!isOpenPax)
  }

  function toggleAcademia() {
    setAcademiaList(academiaListConst)
    setFidelizacaoAcademiaTemp(fidelizacaoAcademia);
    if (fidelizacaoAcademia != undefined && fidelizacaoAcademia != null) {
      if(fidelizacaoAcademia == "Sim") {
        var listaaux = acadList.filter((elem) => elem[1].fidelizacao == "Sim")
        setAcademiaList([" "].concat(listaaux)); 
      } if(fidelizacaoAcademia == "Não") {
        var listaaux = acadList.filter((elem) => elem[1].fidelizacao == "Não")
        setAcademiaList([" "].concat(listaaux)); 
      }
      
    }
    setTipoDeAulaTemp(aulasAcademiaTipo)
    setAulasAcademiaFerias1Antes(aulasAcademiaFerias1)
    setAulasAcademiaFerias2Antes(aulasAcademiaFerias2)
    setAulasAcademiaFerias3Antes(aulasAcademiaFerias3)
    setAulasAcademiaFerias4Antes(aulasAcademiaFerias4)
    setIsOpenAcaemia(!isOpenAcademia)
  }

  function toggleCC() {
    setIsOpenCC(!isOpenCC)
  }

  function toggleNIF() {
    setIsOpenNif(!isOpenNif)
  }

  function toggleTEL() {
    setIsOpenTel(!isOpenTel)
  }

  const [isLoadingEM, setIsLoaingEM] = useState(false)
  const [isLoadingUN, setIsLoaingUN] = useState(false)
  const [isLoadingPN, setIsLoaingPN] = useState(false)
  const [isLoadingNT, setIsLoaingNT] = useState(false)

  const [fidelizacaoAcademia, setFidelizacaoAcademia] = useState("")
  const [fidelizacaoAcademiaTemp, setFidelizacaoAcademiaTemp] = useState("")

  const [numeroDeAulas, setNumeroDeAulas] = useState(0)
  const [tipoDeAula, setTipoDeAula] = useState("")
  const [tipoDeAulaTemp, setTipoDeAulaTemp] = useState("")
  const [tipoDeAulaPAX, setTipoDeAulaPAX] = useState("")
  const [tipoDeAulaAcademia, setTipoDeAulaAcademia] = useState("")
  const [aulasAcademiaFerias1, setAulasAcademiaFerias1] = useState(null)
  const [aulasAcademiaFerias2, setAulasAcademiaFerias2] = useState(null)
  const [aulasAcademiaFerias3, setAulasAcademiaFerias3] = useState(null)
  const [aulasAcademiaFerias4, setAulasAcademiaFerias4] = useState(null)

  const [aulasAcademiaFerias1Antes, setAulasAcademiaFerias1Antes] = useState(null)
  const [aulasAcademiaFerias2Antes, setAulasAcademiaFerias2Antes] = useState(null)
  const [aulasAcademiaFerias3Antes, setAulasAcademiaFerias3Antes] = useState(null)
  const [aulasAcademiaFerias4Antes, setAulasAcademiaFerias4Antes] = useState(null)

  const tipoDeAulasStringfied = useSelector(selectaulasvalores)
  const tipoDeAulasJson = JSON.parse(tipoDeAulasStringfied)
  const mapAualsV = [" "].concat(Object.entries(tipoDeAulasJson))

  const aulas = useSelector(selectaulasvalores)
  const aulasJson = JSON.parse(aulas)

  const aulasPAxJson = aulasJson['PAX']
  const aulasAcademiaJson = aulasJson['Academia']
  const aulasPAxList = Object.entries(aulasPAxJson)
  const aulasAcaList = Object.entries(aulasAcademiaJson)

  const [paxLista, setPaxLista] = useState([])
  const [paxListaConst, setPaxListaConst] = useState([])

  var paxList = aulasPAxList.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })

  const [academiaList, setAcademiaList] = useState([])
  const [academiaListConst, setAcademiaListConst] = useState([])

  var acadList = aulasAcaList.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })
  function addDays(date, days) {
    return new Date(date.getTime() + days * 60000 * 60 * 24)
  }

  const [nextDate, setnextDate] = useState(addDays(new Date(), 14))

  const [tipoDeContraroValor, setTipoDeContraroValor] = useState("")
  const [tipoDeContraroValorTemp, setTipoDeContraroValorTemp] = useState("")
  const [pdfDoContrato, setPDFdoContrato] = useState(null)
  const [pdfDoContratoName, setPDFdoContratoName] = useState(null)
  const tipoDeContratosStringfied = useSelector(selectcontratosvalores)
  const valoresContratosJson = JSON.parse(tipoDeContratosStringfied)
  const valoresContratosList = Object.entries(valoresContratosJson)
  const [isContratoOpen, setIsContratoOpen] = useState(false)
  const [ispdfContratoOpen, setIsPdfContraoOpen] = useState(false)
  /* const aulasAcademiaJson = aulasJson['Academia']
  const aulasPAxList = Object.entries(aulasPAxJson) */
  const [contratosList, setContratosList] = useState([])
  const [nextDateContratos, setnextDateConstratos] = useState(null)
  const [nextDateContratosTemp, setnextDateConstratosTemp] = useState(addDays(new Date(), 14))
  const [expiryDateContrato, setExpiryDateContrato] = useState(null)
  const [expiryDateContratoTemp, setExpiryDateContratoTemp] = useState(null)

  const [contratoFerias1, setContratoFerias1] = useState(null)
  const [contratoFerias2, setContratoFerias2] = useState(null)
  const [contratoFerias3, setContratoFerias3] = useState(null)
  const [contratoFerias4, setContratoFerias4] = useState(null)

  function toggleContrato() {
    setIsContratoOpen(!isContratoOpen)
  }




  async function gravarAlteracoes(userId, seguro, classesEspeciais, valor) {
    var myTimestamp = firebase.firestore.Timestamp.fromDate(seguro)
    var map = {
      seguro: myTimestamp,
    }
    if (typeof valor != "undefined") {
      map.classesEspeciais = {}
      map.classesEspeciais[classesEspeciais] = valor
    }
    console.log("gravarAlteracoes")
    console.log(map)
    try {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .update(map, { merge: true })

      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function showUser(value) {
    console.log(value.data())
    setUserId(value.id)
    setUser(value.data())
    const userAux = value.data()
    var mapClassesEE;
    if ( typeof userAux.classesEspeciais != "undefined"){
    mapClassesEE = [" "].concat(Object.entries(userAux.classesEspeciais))
    for (var asdasd of mapClassesEE) {
      console.log("patig")
      setclassesEspeciais(asdasd[0])
    }
}
    
    setEmail(userAux.email)
    if (typeof userAux.primeiroNome != "undefined") {
      setNome(userAux.primeiroNome + " " + userAux.ultimoNome)
    }
    if (typeof userAux.numeroDoCC != "undefined" && userAux.numeroDoCC != "") {
      setNumeroCC(userAux.numeroDoCC)
    }
    if (typeof userAux.nif != "undefined") {
      setNif(userAux.nif)
    }
    
    if (typeof userAux.dataDeNascimento == "undefined") {
      setDataDeNa(null)
    } else {
      setDataDeNa(userAux.dataDeNascimento.toDate().toDateString())
    }
    if (typeof userAux.numeroTele != "undefined") {
      setTel(userAux.numeroTele)
    }

    if (userAux.seguro != null && typeof userAux.seguro != "undefined") {
      setSeguro(userAux.seguro.toDate())
    } else {
      setSeguro(null)
    }
    setContrato(userAux.contrato)
    setBancoDeHoras(userAux.bancoDeHoras)
    setBancoDeHorasParaAlterar(userAux.bancoDeHoras)

    if (typeof userAux.aulasPax != "undefined") {
      setAulasPax(userAux.aulasPax)
    }
    if (typeof userAux.aulaExperimental != "undefined") {
      setAulaExperimental(userAux.aulaExperimental);
    }
    if (typeof userAux.aulasAcamida != "undefined") {
      setTipoDeAula(userAux.aulasAcamida.tipo);
      setTipoDeAulaTemp(userAux.aulasAcamida.tipo);
      setFidelizacaoAcademia(userAux.aulasAcamida.fidelizacao);
      setFidelizacaoAcademiaTemp(userAux.aulasAcamida.fidelizacao);
    }
    if (typeof userAux.aulasAcamida != "undefined") {
      setAulasAcademiaTipo(userAux.aulasAcamida['tipo'])
      setAulasAcademiaFide(userAux.aulasAcamida['fidelizacao'])
      if(userAux.aulasAcamida['expiryAt'] != null){
      setAulasAcademiaExpr(userAux.aulasAcamida['expiryAt'].toDate())
      setnextDate(addDays( userAux.aulasAcamida['expiryAt'].toDate(), 14) )
    }
    }
    if (typeof userAux.aulasAcademiaFerias != "undefined") {
      if (userAux.aulasAcademiaFerias.ferias1 != null) {
        setAulasAcademiaFerias1(userAux.aulasAcademiaFerias.ferias1.toDate())
        setAulasAcademiaFerias1Antes(userAux.aulasAcademiaFerias.ferias1.toDate())
      }
      if (userAux.aulasAcademiaFerias.ferias2 != null) {
        setAulasAcademiaFerias2(userAux.aulasAcademiaFerias.ferias2.toDate())
        setAulasAcademiaFerias2Antes(userAux.aulasAcademiaFerias.ferias2.toDate())
      }
      if (userAux.aulasAcademiaFerias.ferias3 != null) {
        setAulasAcademiaFerias3(userAux.aulasAcademiaFerias.ferias3.toDate())
        setAulasAcademiaFerias3Antes(userAux.aulasAcademiaFerias.ferias3.toDate())
      }
      if (userAux.aulasAcademiaFerias.ferias4 != null) {
        setAulasAcademiaFerias4(userAux.aulasAcademiaFerias.ferias4.toDate())
        setAulasAcademiaFerias4Antes(userAux.aulasAcademiaFerias.ferias4.toDate())
      }
    }
    if (typeof userAux.tipoDeContrato != "undefined") {
      setTipoDeContraroValor(userAux.tipoDeContrato.tipo)
      setTipoDeContraroValorTemp(userAux.tipoDeContrato.tipo)
      setExpiryDateContrato(userAux.tipoDeContrato.expiryAt == null ? null : userAux.tipoDeContrato.expiryAt.toDate())
      setExpiryDateContratoTemp(userAux.tipoDeContrato.expiryAt == null ? null : userAux.tipoDeContrato.expiryAt.toDate())
      setnextDateConstratos(userAux.tipoDeContrato.proximoPagamento == null ? null : userAux.tipoDeContrato.proximoPagamento.toDate())
      setnextDateConstratosTemp(userAux.tipoDeContrato.proximoPagamento == null ? null : addDays(userAux.tipoDeContrato.proximoPagamento.toDate(), 14))
    }

    var listaDeAulas = await checkaAsAulasAcademia(userAux.email, "Great Padel Vila Verde");
    setListaDeAulasAcademia(listaDeAulas);

    /* listaDeContratos(userAux.historicoDeContratos) */
    /* listaDePagamentos(userAux.historicoDePagamentos) */

    setIsUser(true)
  }

  function shwoListaDeAulas() { }

  function resetTodosOsDados() {
    setUserId(null)
    setUser(null)

    setclassesEspeciais(null)

    setEmail(null)
    setListaDeAulasAcademia([])
    setNome(null)
    setNumeroCC(null)
    setNif(null)

    setDataDeNa(null)

    setDataDeNa(null)
    setTel(null)
    setSeguro(null)

    setContrato(null)
    setBancoDeHoras(null)
    setBancoDeHorasParaAlterar(null)
    setnextDate(addDays(new Date(), 14))

    setAulasPax(0)
    setNumeroCCAnterior("")
    setNumeroNIFAnterior("")
    setNumeroTELAnterior("")
    setAulaExperimental("")
    setTipoDeAula("")
    setTipoDeAulaTemp("")
    setTipoDeAulaPAX("")
    setAulasAcademiaTipo("")
    setAulasAcademiaFide("")
    setAulasAcademiaExpr("")
    setFidelizacaoAcademia("")
    setFidelizacaoAcademiaTemp("")
    setDataInicialDeFerias1(null)
    setDataInicialDeFerias2(null)
    setAulasAcademiaFerias1(null)
    setAulasAcademiaFerias2(null)
    setAulasAcademiaFerias3(null)
    setAulasAcademiaFerias4(null)

    setTipoDeContraroValor("")
    setExpiryDateContrato(null)
    setnextDateConstratos(null)
    setTipoDeContraroValorTemp("")
    setExpiryDateContratoTemp(null)
    setnextDateConstratosTemp(addDays(new Date(), 14))

    setIsUser(true)
  }

  function resetDadosDosContratos() {
    setTipoDeContraroValor("")
    setExpiryDateContrato(null)
    setnextDateConstratos(null)
    setTipoDeContraroValorTemp("")
    setExpiryDateContratoTemp(null)
    setnextDateConstratosTemp(null)
  }

  function resetDadosAcademia() {
    setAulasAcademiaTipo("")
    setAulasAcademiaFide("")
    setAulasAcademiaExpr("")
    setFidelizacaoAcademia("")
    setFidelizacaoAcademiaTemp("")
    setDataInicialDeFerias1(null)
    setDataInicialDeFerias2(null)
    setAulasAcademiaFerias1(null)
    setAulasAcademiaFerias2(null)
    setAulasAcademiaFerias3(null)
    setAulasAcademiaFerias4(null)
  }

  useEffect(async () => {
    console.log("A correr isto")
    console.log(userlocalstorage)
    if(userlocalstorage != "" && userlocalstorage != null) {
      console.log("userlocalstorage")
      console.log(userlocalstorage)
      await pesquisarJogadoresByEmail(userlocalstorage).then(async value => {
        if (value != null) {
          showUser(value)
          setIsUser(true)
          setIsListaDeUSers(false)
        } else {
          console.log(email)
          setIsUser(false)
          Swal.fire({
            title: 'Jogador não encontrado',
            text: "Procure novamente ou então crie uma conta para este utilizador",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
          })
        }
        
      })
    }
  }, [])

  useEffect(() => {
    setAcademiaList([""].concat(acadList))
    setAcademiaListConst([""].concat(acadList))
    setPaxLista([""].concat(paxList))
    setPaxListaConst([""].concat(paxList))
    setContratosList([""].concat(valoresContratosList))
  }, [
    isUser,
    isListaDeUsers,
    isLoadingEM,
    isLoadingPN,
    isLoadingUN,
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">{t("Procurar Jogadores")}</h6>
              </Col>
            </Row> 
          </div>
        
          {/* Pesquisar Email */}
          <Row style={{ alignItems: "center", paddingBottom: "20px" }}>
            <Col md={1}>
              <Label>Email</Label>
            </Col>
            <Col md={8}>
              <Input 
              /* value={userlocalstorage}  */
              onChange={(e) => {
                
              }}
              id="email" 
              type="email" 
              defaultValue={userlocalstorage}></Input>
            </Col>
            <Col md={3}>
              {isLoadingEM ? (
                <Spinner color="primary"></Spinner>
              ) : (
                <Button
                  onClick={async () => {
                    resetTodosOsDados()
                    setIsLoaingEM(true)
                    var email = document.getElementById("email").value
                    await pesquisarJogadoresByEmail(email).then(async value => {
                      if (value != null) {
                        showUser(value)
                        setIsUser(true)
                        setIsListaDeUSers(false)
                        console.log("A dispachar isto")
                        const emailAux = value.data().email
                        console.log(emailAux)
                        dispatch(setuserlocalstorage(emailAux))
                      } else {

                        setIsUser(false)
                        Swal.fire({
                          title: 'Jogador não encontrado',
                          text: "Procure novamente ou então crie uma conta para este utilizador",
                          icon: 'warning',
                          confirmButtonColor: '#3085d6',
                        })
                      }
                    })
                    setIsLoaingEM(false)
                  }}
                  color="primary"
                >
                  Pesquisar
                </Button>
              )}
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col md={1}>
              <Label>Primeiro Nome</Label>
            </Col>
            <Col md={8}>
              <Input id="primeiroNome" type="text" defaultValue={""}></Input>
            </Col>
            <Col md={3}>
              {isLoadingPN ? (
                <Spinner color="primary"></Spinner>
              ) : (
                <Button
                  onClick={async () => {
                    /*  if( document.getElementById("ultimoNome").value != "" ) {

                } else { */
                    setIsLoaingPN(true)
                    await pesquisarJogadoresByPrimeiroNome(
                      document.getElementById("primeiroNome").value
                    ).then(value => {
                      if (value != null) {
                        setIsUser(false)
                        setIsListaDeUSers(true)
                        setListaDeUsers(value)
                      } else {
                        setIsUser(false)
                        alert(
                          "Não foi encontrado nenhum jogador com esse nome"
                        )
                      }
                    }) /* } */
                    setIsLoaingPN(false)
                  }}
                  color="primary"
                >
                  {" "}
                  Pesquisar{" "}
                </Button>
              )}
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col md={1}>
              <Label>Último Nome</Label>
            </Col>
            <Col md={8}>
              <Input id="ultimoNome" type="text" defaultValue={""}></Input>
            </Col>
            <Col md={3}>
              {isLoadingUN ? (
                <Spinner color="primary"></Spinner>
              ) : (
                <Button
                  onClick={async () => {
                    /* if( document.getElementById("primeiroNome").value != "" ) {

                 } else { */
                    setIsLoaingUN(true)
                    await pesquisarJogadoresByUltimoNome(
                      document.getElementById("ultimoNome").value
                    ).then(value => {
                      if (value != null) {
                        setIsUser(false)
                        setIsListaDeUSers(true)
                        setListaDeUsers(value)
                      } else {
                        setIsUser(false)
                        alert(
                          "Não foi encontrado nenhum jogador com esse nome"
                        )
                      }
                    }) /* } */
                    setIsLoaingUN(false)
                  }}
                  color="primary"
                >
                  {" "}
                  Pesquisar{" "}
                </Button>
              )}
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col md={1}>
              <Label>Número de telemóvel</Label>
            </Col>
            <Col md={8}>
              <Input id="nrTelemv" type="text"></Input>
            </Col>
            <Col md={3}>
              {isLoadingNT ? (
                <Spinner color="primary"></Spinner>
              ) : (
                <Button
                  onClick={async () => {
                    /* if( document.getElementById("primeiroNome").value != "" ) {

                 } else { */
                    setIsLoaingNT(true)
                    await pesquisarJogadoresByNumeroDeTele(
                      document.getElementById("nrTelemv").value
                    ).then(value => {
                      if (value != null) {
                        setIsUser(false)
                        setIsListaDeUSers(true)
                        setListaDeUsers(value)
                      } else {
                        setIsUser(false)
                        alert(
                          "Não foi encontrado nenhum jogador com esse numero de telemovel"
                        )
                      }
                    }) /* } */
                    setIsLoaingNT(false)
                  }}
                  color="primary"
                >
                  {" "}
                  Pesquisar{" "}
                </Button>
              )}
            </Col>
          </Row>

          <Row
            hidden={isUser || isListaDeUsers}
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <h4>Nenhum jogador para mostrar</h4>
          </Row>

          <div hidden={!isListaDeUsers}>
            <Row style={{ paddingTop: "40px", paddingBottom: "20px" }}>
              <Col md={5}>
                <h5>Email</h5>
              </Col>
              <Col md={6}>
                <h5>Nome</h5>
              </Col>
            </Row>
            <Row style={{ paddingTop: "00px", paddingBottom: "20px" }}>
              {listaDeUsers.map((element, index) => {
                console.log(element.data().email)
                return (
                  <Row
                    key={element.id + "row"}
                    className={index % 2 == 0 ? "myList-even" : "myList-odd"}
                  >
                    <Col md={5}>{element.data().email}</Col>
                    <Col md={6}>
                      {element.data().primeiroNome +
                        " " +
                        element.data().ultimoNome}
                    </Col>
                    <Col md={1}>
                      <Button
                        onClick={async () => {
                          resetTodosOsDados()
                          setIsLoaingEM(true)
                          await pesquisarJogadoresByEmail(element.data().email).then(
                            async value => {
                              if (value != null) {
                                showUser(value)
                                setIsUser(true)
                                setIsListaDeUSers(false)
                                /* var listaDeAulas = await checkaAsAulasAcademia(email, "Great Padel Vila Verde");
                                setListaDeAulasAcademia(listaDeAulas); */
                                dispatch(setuserlocalstorage(element.data().email))
                              } else {
                                setIsUser(false)
                                alert(
                                  "Não foi encontrado nenhum jogador com esse email"
                                )
                              }
                            }
                          )
                          setIsLoaingEM(false)
                        }}
                      >
                        <ArrowRightCircle></ArrowRightCircle>
                      </Button>
                    </Col>
                  </Row>
                )
              })}
            </Row>
          </div>

          {/* Lista do utilizador pesquisado */}
          <Card hidden={!isUser} className="mt-5 p-5 rounded" style={{ background: "#e8e8e8" }} >

            {/* Nome e Botão Gravar Alterações */}
            <Row>
              <Col md={9}>
                <h4>{nome}</h4>
              </Col>
              <Col hidden={ userRole == recepRole || userRole == adminRole ? false : true } md={3}>
                <Button
                  onClick={() => {
                    gravarAlteracoes(
                      userId,
                      seguro,
                      classesEspeciais,
                      classesEsp[classesEspeciais]
                    ).then(value => {
                      if (value) {
                        alert("Alterações gravadas com sucesso")
                      } else {
                        alert("Falhar a gravar altreçaões")
                      }
                    })
                  }}
                  color="primary"
                >
                  Gravar Alterações
                </Button>
              </Col>
            </Row>

            {/* Dados Pessoais */}
            <Row className="mt-5">

              <div className="heading mb-3">
                <h5>Dados Pessoais</h5>
              </div>

              <Col md={3} className="mt-2">
                <Label for="emailValue">Email</Label>
                <Input type="email" name="email" disabled defaultValue={email} style={{ background: "#c2c2c2" }}>{email}</Input>
              </Col>

              <Col md={3} className="mt-2">
                <Label for="emailValue">Data de Nascimento</Label>
                <Input type="text" className="form-control" defaultValue={dataDeNa} name="data_nascimento" style={{ background: "#c2c2c2" }}>{dataDeNa}</Input>
              </Col>

              <Col  md={2}>
                <Label for="bancoDeHoras">Número CC</Label>
                <Button hidden={ userRole == recepRole || userRole == adminRole ? false : true } color="transparent" onClick={() => {
                  setNumeroCCAnterior(numeroCC)
                  toggleCC()
                }}>
                  <Pencil></Pencil>
                </Button>
                <Input type="number" className="form-control" defaultValue={numeroCC} disabled style={{ background: "#c2c2c2" }}>{numeroCC}</Input>
              </Col>

              <Col md={2}>
                <Label for="emailValue">NIF</Label>
                <Button hidden={ userRole == recepRole || userRole == adminRole ? false : true } color="transparent" onClick={() => {
                  setNumeroNIFAnterior(nif)
                  toggleNIF()
                }}>
                  <Pencil></Pencil>
                </Button>
                <Input type="number" className="form-control" defaultValue={nif} disabled style={{ background: "#c2c2c2" }} />
              </Col>

              <Col md={2}>
                <Label for="bancoDeHoras">Telemóvel</Label>
                <Button hidden={ userRole == recepRole || userRole == adminRole ? false : true } color="transparent" onClick={() => {

                  toggleTEL()
                }}>
                  <Pencil></Pencil>
                </Button>

                <Input type="tel" className="form-control" disabled defaultValue={tel} style={{ background: "#c2c2c2" }} />
              </Col>

              { userRole == recepRole || userRole == adminRole ? <Col md={3} className="mt-4">
                  <Label for="emailValue" className="block">Seguro Válido até</Label>
                  <DateTimePicker
                    className="form-control"
                    disableClock={true}
                    onChange={value => {
                      console.log(value)
                      setSeguro(value)
                    }}
                    value={seguro}
                  />
              </Col> : <Col md={3} className="mt-4">
              <Label  className="block">Seguro Válido até</Label>
              <DateTimePicker className="form-control" value={seguro} disableClock={true}  disableCalendar={true} />
              </Col> }
              <Col className="mt-4" md={2}>
                <Row>
                  <Col>
                    <Label for="bancoDeHoras">Saldo</Label>
                    <React.Fragment>
                      <Button hidden={  userRole == adminRole ? false : true } color="transparent" onClick={() => toggle()}>
                        <Pencil />
                      </Button>

                    </React.Fragment>
                  </Col>
                </Row>
                <Input type="number" className="form-control" defaultValue={bancoDeHoras} disabled style={{ background: "#c2c2c2" }} />
              </Col>


              <Col md={4} className="mt-4" hidden={userRole == recepRole ? true : false}  >
                <Label for="classes">Classes Especiais</Label>
                <Input
                  value={classesEspeciais}
                  onChange={e => {
                    setclassesEspeciais(e.target.value)
                  }}
                  id="weekdaySelect"
                  type="select"
                  name="select"
                >
                  {mapClasses.map((elem, index) => {
                    return <option key={index}>
                      {elem[0]}
                    </option>
                  })}
                </Input>
              </Col>
              <Col md={4} className="mt-4" hidden={userRole == recepRole ? false : true}  >
                <Label for="classes">Classes Especiais</Label>
               <p>{ classesEspeciais == null || Object.keys(classesEspeciais).length == 0  ?  "" : classesEspeciais    }</p>
              </Col>

            </Row>

            <hr className="mt-5" /> {/* Divider */}

            {/* Aulas */}
            <Row className="mt-3">

              <div className="heading mb-3">
                <h5>Aulas</h5>
              </div>
                  {/* <Button color="primary" onClick={()=> {
                    console.log(aulaExperimental)
                  }} >Log Aula Exper</Button> */}
              <Col md={2} className="mt-2">
                <Label>Aula Experimental </Label>
                <Input type="text" className="form-control" disabled
                  value={(aulaExperimental == "" || aulaExperimental == false) ? "Disponível" : "Indisponível"}
                  style={{ background: "#c2c2c2" }}
                />
              </Col>

              <Col md={2}>
                <Label for="aulasPax">Aulas Pax</Label>

                <Button
                  hidden={ userRole == recepRole || userRole == adminRole ? false : true }
                  color="transparent"
                  onClick={() => {
                    togglePax()
                  }}
                >
                  <PlusCircle />
                </Button>


                <Input type="number" className="form-control" name="aulasPax" disabled value={aulasPax ? aulasPax : 'Erro a ir buscar aulasPax'} style={{ background: "#c2c2c2" }} />
              </Col>

              <Col  md={4}>
                <Label for="aulasAcademia">Aulas Academia</Label>
                <Button hidden={ userRole == recepRole || userRole == adminRole ? false : true }
                  color="transparent"
                  onClick={() => {
                    toggleAcademia()
                  }}
                >
                  <Pencil />
                </Button>

                <Card className="px-4 py-4">
                {
                  aulasAcademiaTipo == "" ? <p>{"Sem Aulas de academia"} </p> :
                    <p style={aulasAcademiaExpr > dataParaCompara ? { color: "green" } : { color: "red" }}  >

                    {aulasAcademiaTipo}<br></br>

                    {aulasAcademiaExpr.toLocaleString("pt-PT").substring(0, 10)}<br></br>

                    {aulasAcademiaJson[aulasAcademiaTipo].valor + "€"}<br></br>

                    {fidelizacaoAcademia == "Sim" ? "Fidelizado" : "Não Fidelizado"}<br></br>

                    {listaDeAulasAcademia.length == 0 ? "Ainda não está inscrito em nenhuma aula" : [...listaDeAulasAcademia] + ", "}  </p>
                }
                </Card>
              </Col>
            </Row>
            <hr className="mt-3" /> {/* Divider */}
        

            {/* Contratos */}
            <Row className="mt-3">

              <div className="heading mb-3">
                <h5>Contratos</h5>
              </div>

              <Col md={4}>
                  <Label for="contratoo">Contrato</Label>
                  <Button hidden={ userRole == recepRole || userRole == adminRole ? false : true }
                    color="transparent"
                    onClick={() => {
                      toggleContrato()
                    }}
                  >
                    <Pencil></Pencil>
                  </Button>
                  {/* <Button hidden={ userRole == recepRole || userRole == adminRole ? false : true } color="transparent"
                  onClick={() => {
                    setPDFdoContrato(null)
                    setPDFdoContratoName(null)
                    setIsPdfContraoOpen(!ispdfContratoOpen)
                  }}
                  >
                    <PlusCircle ></PlusCircle>
                  </Button> */}

                  <Card className="p-4 pb-0 pt-3">
                    {
                      tipoDeContraroValor == "" ? <p>{"Sem Contrato"} </p> :
                      <p style={nextDateContratos > dataParaCompara ? { color: "green" } : { color: "red" }}  >
                        {tipoDeContraroValor}<br />
                        {nextDateContratos == null ? null : nextDateContratos.toLocaleString("pt-PT").substring(0, 10)}<br />
                        {valoresContratosJson[tipoDeContraroValor].valor + "€"}<br/>
                      </p>
                    }
                </Card>
              </Col>

                 
              <Col md={2} className="mt-2">
                <Label for="emailValue">N° de jogos do Contrato</Label>
                <Input type="number" className="form-control" defaultValue={contrato} disabled style={{ background: "#c2c2c2" }} />
              </Col>
            </Row>
            {/* Classes  */}
            <Row className="mt-4" hidden>
             

              <Col md={4} >
                <Label for="classeJogador">Classes</Label>
                <Input
                  onChange={() => { }}
                  type="select"
                  name="select"
                  id="weekdaySelect"
                >
                  <option>Todos</option>
                  <option>Estudantes</option>
                  <option>Parceiros</option>
                </Input>
              </Col>

            </Row>


            <Row style={{ paddingTop: "40px", paddingBottom: "20px" }} >
              <h4>Históricos</h4>
              <Col md={2}>
                <Link to={"/historicoDejogos/" + email}>
                  <Button color="primary" className="mt-2">
                    Histórico de Jogos
                  </Button>
                </Link>
              </Col>
              <Col md={2}>
                <Link to={"/historicoDeAulas/" + userId}>
                  <Button color="primary" className="mt-2">
                    Histórico de aulas compradas
                  </Button>
                </Link>
              </Col>
              <Col md={2}>
                <Link to={"/historicoDePagamentos/" + email + "/" + userId}>
                  <Button color="primary" className="mt-2">
                    Histórico de Pagamentos
                  </Button>
                </Link>
              </Col>  
             {/*  <Col md={2}>
                <Link to={"/historicoDeAulas/" + userId}>
                  <Button color="primary" className="mt-2">
                    Histórico de compras
                  </Button>
                </Link>
              </Col> */}
            </Row>

          </Card>





        </Container>
      </div>


      {/* Modal Academia */}
      <Modal  isOpen={isOpenAcademia} toggle={() => toggleAcademia()}>

        <ModalHeader
          toggle={() => {
            toggleAcademia()
          }}
        >
          Aulas Academia
        </ModalHeader>

        <ModalBody>
          <Form>

            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Fidelização</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={fidelizacaoAcademiaTemp}
                    onChange={e => {
                      console.log(e.target.value)
                      setFidelizacaoAcademiaTemp(e.target.value)
                      var listaaux = acadList.filter((elem) => elem[1].fidelizacao == e.target.value)
                      setAcademiaList([" "].concat(listaaux)); 
                      setTipoDeAulaTemp("")
                      
                    }}
                    type="select"
                    name="select"
                    id="fidelizacaoAcademia"
                  >
                    <option></option>
                    <option>Sim</option>
                    <option>Não</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup hidden={fidelizacaoAcademiaTemp == "" ? true : false} >
              <Row>
                <Col md={3}>
                  <p>Tipo de aula</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={tipoDeAulaTemp}
                    onChange={e => {
                      console.log(e.target.value)
                      setTipoDeAulaTemp(e.target.value)
                    }}
                    type="select"
                    name="select"
                    id="nivelSelect"
                  >
                    {academiaList.map((elem, index) => {
                      return <option key={index}>{elem[0]}</option>
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Preço</p>
                </Col>
                <Col md={9}>
                  {typeof aulasAcademiaJson[tipoDeAulaTemp] == "undefined"
                    ? " "
                    : aulasAcademiaJson[tipoDeAulaTemp].valor + "€"}
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Próximo Pagamento</p>
                </Col>
                <Col md={9}>
                  <DatePicker
                    value={nextDate}
                    onChange={date => {
                      setnextDate(date)
                    }}
                  ></DatePicker>
                </Col>
              </Row>

            </FormGroup>

            <FormGroup hidden={fidelizacaoAcademiaTemp == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>1ª Semana de Férias</p>
                </Col>
                <Col md={4}>
                  <DatePicker
                    value={aulasAcademiaFerias1Antes}
                    onChange={date => {
                      setAulasAcademiaFerias1Antes(date)
                    }}></DatePicker>

                </Col>
                <Col md={4}>
                  <DatePicker
                    value={aulasAcademiaFerias2Antes}
                    onChange={date => {
                      setAulasAcademiaFerias2Antes(date)
                    }}></DatePicker>

                </Col>
              </Row>
            </FormGroup>

            <FormGroup hidden={fidelizacaoAcademiaTemp == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>2ª Semana de Férias</p>
                </Col>
                <Col md={4}>
                  <DatePicker
                    value={aulasAcademiaFerias3Antes}
                    onChange={date => {
                      setAulasAcademiaFerias3Antes(date)
                    }}></DatePicker>

                </Col>
                <Col md={4}>
                  <DatePicker
                    value={aulasAcademiaFerias4Antes}
                    onChange={date => {
                      setAulasAcademiaFerias4Antes(date)
                    }}></DatePicker>

                </Col>
              </Row>
            </FormGroup>

          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="primary"
            onClick={async () => {
              var map = {}
              map.localizacao = localizaco
              map.tipo = tipoDeAulaTemp
              map.expiryAt = nextDate
              map.valores = aulasAcademiaJson[tipoDeAulaTemp]
              var map2 = {}
              map2.ferias1 = aulasAcademiaFerias1Antes
              map2.ferias2 = aulasAcademiaFerias2Antes
              map2.ferias3 = aulasAcademiaFerias3Antes
              map2.ferias4 = aulasAcademiaFerias4Antes
              const res = await adicionarAulasAcademia(userId, map, email)
              if (res) {
                alert("Aula adicionada com sucesso")
                setFidelizacaoAcademia(fidelizacaoAcademiaTemp)
                setAulasAcademiaTipo(tipoDeAulaTemp)
                setAulasAcademiaExpr(nextDate)
                setnextDate(addDays( nextDate, 14) )
                toggleAcademia()
              } else {
                alert("Erro a adicionar aula")
              }
              console.log(map)
            }}
          >Adicionar Contrato</Button>

          <Button color="success" onClick={async () => {
            var map2 = {}
            map2.ferias1 = aulasAcademiaFerias1Antes
            map2.ferias2 = aulasAcademiaFerias2Antes
            map2.ferias3 = aulasAcademiaFerias3Antes
            map2.ferias4 = aulasAcademiaFerias4Antes
            var res = await adicionarFeriasAcademia(userId, map2)
            if (res) {
              alert("Férias adicionadas com sucesso")
              setAulasAcademiaFerias1(aulasAcademiaFerias1Antes)
              setAulasAcademiaFerias2(aulasAcademiaFerias2Antes)
              setAulasAcademiaFerias3(aulasAcademiaFerias3Antes)
              setAulasAcademiaFerias4(aulasAcademiaFerias4Antes)
              toggleAcademia()
            } else {
              alert("Erro a adicionar férias")
            }
            console.log(map2)
            toggleAcademia()
          }} >Gravar férias</Button>

          <Button color="danger" onClick={async () => {
            var res = await removeAulasAcademia(userId, localizaco)
            if (res) {
              alert("Aula removida com sucesso")
              resetDadosAcademia()
              toggleAcademia()
            } else {
              alert("Erro a remover aula")
            }
          }} >Remover contrato</Button>

          <Button onClick={() => {
            toggleAcademia()
          }} >Cancelar</Button>

        </ModalFooter>
      </Modal>

      <Modal isOpen={ispdfContratoOpen} toggle={() =>{ 
       
        setIsPdfContraoOpen(!ispdfContratoOpen)}}  >
        <ModalHeader toggle={() => setIsPdfContraoOpen(!ispdfContratoOpen)} >
          Adicionar Pdf do contrato
        </ModalHeader>
        <ModalBody>
          <Form>
          <FormGroup>
              <Row>
                <Col md={3}>
                  <p>PDF</p>
                </Col>
                <Col md={9}>
                  <Input
                  id="ficheiroEmail"
                  accept="application/pdf"
                    onChange={e => {
                      setPDFdoContrato(e.target.files[0])
                      setPDFdoContratoName(e.target.files[0].name)
                    }}
                    type="file"
                    name="select"
                  >
                    
                  </Input>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {/* <Button onClick={() => {
            console.log("A mostra toda a gente com contratos")
            testeAddRemoveContratos()
          }}> Teste </Button> */}
          <Button onClick={async () => {
            if(pdfDoContrato == null) {
              alert("É necessário escolher o ficheiro")
            } else {
              var res = await addContratoPDF(userId,pdfDoContratoName,pdfDoContrato )
              if(res) {
                alert("Conrtato adicionado com sucesso")
                setIsPdfContraoOpen(!ispdfContratoOpen)
              }
              else {
                alert("Erro a adicionar contrato")
              }
            }
          }} color="primary">Adicionar</Button>
          <Button onClick={() => {
            setIsPdfContraoOpen(!ispdfContratoOpen)
          }}  >Cancelar</Button>
        </ModalFooter>
      </Modal>
      {/* Modal Contrato */}
      <Modal isOpen={isContratoOpen} toggle={() => toggleContrato()}>
        <ModalHeader
          toggle={() => {
            toggleContrato()
          }}
        >
          Contrato
        </ModalHeader>
        <ModalBody>
          <Form>

            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Contrato</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={tipoDeContraroValorTemp}
                    onChange={e => {
                      console.log(e.target.value)
                      setTipoDeContraroValorTemp(e.target.value)
                    }}
                    type="select"
                    name="select"
                    id="nivelSelect"
                  >
                    {contratosList.map((elem, index) => {
                      return <option key={index}>{elem[0]}</option>
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={{ paddingTop: "20px" }} hidden={tipoDeContraroValorTemp == "" ? true : false}  >
              <Row>
                <Col md={3}>
                  <p>Nr de jogos por semana</p>
                </Col>
                <Col md={9}>
                  {typeof valoresContratosJson[tipoDeContraroValorTemp] == "undefined"
                    ? " "
                    : valoresContratosJson[tipoDeContraroValorTemp].nrJogosPSemana}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeContraroValorTemp == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Periodo</p>
                </Col>
                <Col md={9}>
                  {typeof valoresContratosJson[tipoDeContraroValorTemp] == "undefined"
                    ? " "
                    : valoresContratosJson[tipoDeContraroValorTemp].periodo}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeContraroValorTemp == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Preço</p>
                </Col>
                <Col md={9}>
                  {typeof valoresContratosJson[tipoDeContraroValorTemp] == "undefined"
                    ? " "
                    : valoresContratosJson[tipoDeContraroValorTemp].valor + "€"}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeContraroValorTemp == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Próximo pagamento</p>
                </Col>
                <Col md={9}>
                  <DatePicker
                    value={nextDateContratosTemp}
                    onChange={(e) => setnextDateConstratosTemp(e)}
                  ></DatePicker>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeContraroValorTemp == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Expira em</p>
                </Col>
                <Col md={9}>
                  <DatePicker
                    value={expiryDateContratoTemp}
                    onChange={(e) => setExpiryDateContratoTemp(e)}
                  ></DatePicker>
                </Col>

              </Row>
            </FormGroup>
            <FormGroup style={{paddingTop: "20px"}} >
              <Row>
                <Col md={3}>
                  <p>1ª Semana de Férias</p>
                </Col>
                <Col md={4}>
                  <DatePicker
                    value={contratoFerias1}
                    onChange={date => {
                      setContratoFerias1(date)
                    }}></DatePicker>

                </Col>
                <Col md={4}>
                  <DatePicker
                    value={contratoFerias2}
                    onChange={date => {
                      setContratoFerias2(date)
                    }}></DatePicker>

                </Col>
              </Row>
            </FormGroup>

            <FormGroup >
              <Row>
                <Col md={3}>
                  <p>2ª Semana de Férias</p>
                </Col>
                <Col md={4}>
                  <DatePicker
                    value={contratoFerias3}
                    onChange={date => {
                      setContratoFerias3(date)
                    }}></DatePicker>

                </Col>
                <Col md={4}>
                  <DatePicker
                    value={contratoFerias4}
                    onChange={date => {
                      setContratoFerias4(date)
                    }}></DatePicker>

                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={async () => {
            if (expiryDateContratoTemp == null || nextDateContratosTemp == null || tipoDeContraroValorTemp == "") {
              alert("Todos os dados tem que estar preenchidos ")
            } else {
              var map = {}
              map.localizacao = localizaco
              map.tipo = tipoDeContraroValorTemp
              map.periodo = valoresContratosJson[tipoDeContraroValorTemp].periodo
              map.nrDeJogos = valoresContratosJson[tipoDeContraroValorTemp].nrJogosPSemana
              map.valor = valoresContratosJson[tipoDeContraroValorTemp].valor
              map.expiryAt = firebase.firestore.Timestamp.fromDate(expiryDateContratoTemp)
              map.proximoPagamento = firebase.firestore.Timestamp.fromDate(nextDateContratosTemp)

              var res = await adicionarTipoDeContratosAoUser(userId, map)
              if (res) {
                alert("Contrato adicionado com sucesso")
                setTipoDeContraroValor(tipoDeContraroValorTemp)
                setExpiryDateContrato(expiryDateContrato == null ? null : expiryDateContratoTemp)
                setnextDateConstratos(nextDateContratosTemp == null ? null : nextDateContratosTemp)
              } else {
                alert("Erro a adicionar contrato")
              }
              toggleContrato()
            }
          }} >Adicionar</Button>
          <Button color="success" onClick={async () => {
            var map2 = {}
            map2.ferias1 = contratoFerias1
            map2.ferias2 = contratoFerias2
            map2.ferias3 = contratoFerias3
            map2.ferias4 = contratoFerias4
            var res = await adicionarFeriasContratos(userId, map2)
            if (res) {
              alert("Férias adicionadas com sucesso")
              setContratoFerias1(contratoFerias1)
              setContratoFerias2(contratoFerias2)
              setContratoFerias3(contratoFerias3)
              setContratoFerias4(contratoFerias4)
              toggleAcademia()
            } else {
              alert("Erro a adicionar férias")
            }
            console.log(map2)
            toggleContrato()
          }} >Gravar férias</Button>
          <Button color="danger" onClick={async () => {
            var res = await removeValoresDosContratosDoUser(userId, localizaco)
            if (res) {
              alert("Contrato removida com sucesso")
              resetDadosDosContratos()
            } else {
              alert("Erro a remover Contrato")
            }
            toggleContrato()
          }} >Remover</Button>
          <Button onClick={() => {
            toggleContrato()
          }} >Cancelar</Button>
        </ModalFooter>
      </Modal>
      {/* Modal CC */}
      <Modal isOpen={isOpenCC} toggle={() => toggleCC()}>
        <ModalHeader
          toggle={() => {
            toggleCC()
          }}
        >
          Editar Número do Cartão de Cidadão
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup style={{ paddingBottom: "10px" }}>
              <Row>
                <Col md={3}>
                  <Label>Valor Inicial: </Label>
                </Col>
                <Col md={9}>
                  <h5>{numeroCC}</h5>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <Label>Novo Valor: </Label>
                </Col>
                <Col md={9}>
                  <Input
                    type="numeber"
                    value={numeroCCAnterior}
                    onChange={(e) => {
                      setNumeroCCAnterior(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          {isLoadingCC ? <Spinner></Spinner> : <Button
            color="primary"
            onClick={async () => {
              setISLoadingCC(true)
              var res = await alteraCC(userId, numeroCCAnterior)
              if (res) {
                alert("CC atualizado com sucesso")
                setNumeroCC(numeroCCAnterior)
                toggleCC()
              } else {
                alert("Erro a alterar o CC")
              }
              setISLoadingCC(false)
            }}
          >
            Alterar
          </Button>}
          <Button
            onClick={() => {
              toggleCC()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>


      {/* Modal NIF */}
      <Modal isOpen={isOpenNif} toggle={() => toggleNIF()}>
        <ModalHeader
          toggle={() => {
            toggleNIF()
          }}
        >
          Editar Número de Identificação Fiscal
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup style={{ paddingBottom: "10px" }}>
              <Row>
                <Col md={3}>
                  <Label>Valor Inicial: </Label>
                </Col>
                <Col md={9}>
                  <h5>{nif}</h5>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <Label>Novo Valor: </Label>
                </Col>
                <Col md={9}>
                  <Input
                    type="numeber"
                    value={numeroNIFAnterior}
                    onChange={(e) => {
                      setNumeroNIFAnterior(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          {isLoadingNIF ? <Spinner></Spinner> : <Button
            color="primary"
            onClick={async () => {
              setIsLoadingNIF(true)
              var res = await alteraNIF(userId, numeroNIFAnterior)
              if (res) {
                alert("NIF atualizado com sucesso")
                setNif(numeroNIFAnterior)
                toggleNIF()
              } else {
                alert("Erro a alterar o NIF")
              }
              setIsLoadingNIF(false)
            }}
          >
            Alterar
          </Button>}
          <Button
            onClick={() => {
              toggleNIF()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>


      {/* Modal Telemóvel */}
      <Modal isOpen={isOpenTel} toggle={() => toggleTEL()}>
        <ModalHeader
          toggle={() => {
            toggleTEL()
          }}
        >
          Editar Número de Telemóvel
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup style={{ paddingBottom: "10px" }}>
              <Row>
                <Col md={3}>
                  <Label>Valor Inicial: </Label>
                </Col>
                <Col md={9}>
                  <h5>{tel}</h5>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <Label>Novo Valor: </Label>
                </Col>
                <Col md={9}>
                  <Input
                    type="numeber"
                    value={numeroTELAnterior}
                    onChange={(e) => {
                      setNumeroTELAnterior(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          {isLoadingTEL ? <Spinner></Spinner> : <Button
            color="primary"
            onClick={async () => {
              setIsLoadingTEL(true)
              var res = await alteraTEL(userId, numeroTELAnterior)
              if (res) {
                alert("Número de Telemóvel atualizado com sucesso")
                setTel(numeroTELAnterior)
                toggleTEL()
              } else {
                alert("Erro a alterar o NIF")
              }
              setIsLoadingTEL(false)
            }}
          >
            Alterar
          </Button>}
          <Button
            onClick={() => {
              toggleTEL()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>


      {/* Modal Saldo */}
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader
          toggle={() => {
            toggle()
          }}
        >
          Editar Saldo
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup style={{ paddingBottom: "40px" }}>
              <Row>
                <Col md={3}>
                  <Label>Valor Inicial: </Label>
                </Col>
                <Col md={9}>
                  <h4>{bancoDeHoras}</h4>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={{ paddingBottom: "20px" }}>
              <Row>
                <Col md={3}>
                  <Label>Novo valor</Label>
                </Col>
                <Col md={9}>
                  <Input
                    defaultValue={bancoDeHorasParaAlterar}
                    id="bancoHorasID"
                    onChange={e => {
                      setBancoDeHorasParaAlterar(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <Label>Alterado Por</Label>
                </Col>
                <Col md={9}>
                  <Input
                    defaultValue={alteradoPor}
                    onChange={e => {
                      setAlteradoPor(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <Label
              style={{ paddingTop: "20px" }}
              for="exampleText"
            >
              Razão da Alteração
            </Label>
            <Input
              type="textarea"
              name="text"
              defaultValue={notas}
              id="notasID"
              onChange={e => {
                setNotas(e.target.value)
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              console.log(userId)
              if (
                notas.trim() == "" ||
                alteradoPor.trim() == ""
              ) {
                alert(
                  "Todos os dados tem que estar preenchidos"
                )
              } else {
                var res = await alteraBancoDeHoras(
                  userId,
                  bancoDeHorasParaAlterar,
                  bancoDeHoras,
                  alteradoPor,
                  notas
                )
                if (res) {
                  setBancoDeHoras(bancoDeHorasParaAlterar)
                  alert("Banco de horas alterdo com sucesso")
                  toggle()
                } else {
                  alert("Erro a alterar dados")
                  toggle()
                }
              }
            }}
          >
            {" "}
            Alterar Valor{" "}
          </Button>
          <Button
            onClick={() => {
              toggle()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>


      {/* Modal Aulas pax */}
      <Modal isOpen={isOpenPax} toggle={() => togglePax()}>
        <ModalHeader
          toggle={() => {
            togglePax()
          }}
        >
          Aulas Pax
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Row>
              <Col md={3}>
                <p>Tipo de aula</p>
              </Col>
              <Col md={9}>
                <Input
                  defaultValue={tipoDeAulaPAX}
                  onChange={e => {
                    console.log(e.target.value)
                    setTipoDeAulaPAX(e.target.value)
                  }}
                  type="select"
                  name="select"
                  id="nivelSelect"
                >
                  {paxLista.map((elem, index) => {
                    return <option key={index}>{elem[0]}</option>
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md={3}>
                <p>Preço</p>
              </Col>
              <Col md={9}>
                {typeof aulasPAxJson[tipoDeAulaPAX] == "undefined"
                  ? " "
                  : aulasPAxJson[tipoDeAulaPAX].valor + "€"}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={3}>
                <p>Número de Aulas</p>
              </Col>
              <Col md={9}>
                {typeof aulasPAxJson[tipoDeAulaPAX] == "undefined"
                  ? " "
                  : aulasPAxJson[tipoDeAulaPAX].numeroDeAulas}

              </Col>
            </Row>
          </FormGroup>


        </ModalBody>

        <ModalFooter>

          <Button
            color="primary"
            onClick={async () => {
              if (aulasPAxJson[tipoDeAulaPAX].numeroDeAulas == 0 || tipoDeAulaPAX == "") {
                alert(
                  "Todos os dados tem que estar preenchidos corretamente"
                )
                togglePax()
              } else {
                var res = await adicionarAulasPax(
                  userId,
                  tipoDeAulaPAX,
                  aulasPAxJson[tipoDeAulaPAX].valor,
                  aulasPAxJson[tipoDeAulaPAX].numeroDeAulas,
                  aulasPax,
                  localizaco,
                  email
                )
                if (res) {
                  setAulasPax(parseInt(aulasPax) + parseInt(aulasPAxJson[tipoDeAulaPAX].numeroDeAulas))
                  alert("Aulas adicionadas com sucesso")
                  togglePax()
                } else {
                  alert("Erro a adicionar aulas")
                  togglePax()
                }
              }
            }}
          >
            Adicionar
          </Button>
          <Button
            onClick={() => {
              setNumeroDeAulas(0)
              togglePax()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  )
}

export default ProcurarUtilzadoresScreen
