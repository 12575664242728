import React, { useEffect, useState } from 'react';
import { Arrow90degRight, ArrowRightCircle, Pencil, Trash2, Trash2Fill, Trash3Fill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { deleteAulaDoHistorico, historicoDeAulasDate, historicoDeAulasFunc } from 'services/aulas/aulas_services';
import { vaiBuscarAsAulasAosJogadores } from 'services/useres/jogadores_services';
import { selectlocalizacao } from 'store/localizacao/localizacao_reducer';
import { Link } from "react-router-dom"
import DatePicker from 'react-date-picker';

function HistoricoDeAulasScreen() {

    const localizacao = useSelector(selectlocalizacao)
    const [historicoDeAulas, setHistoricoDeAulas] = useState([])

    

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const [horaInicial, setHoraInicial] = useState(new Date())
    const [horaFinal, setHoraFInal] = useState(new Date())

    const { t, i18n } = useTranslation()
    const [listaAux, setListaAux] = useState([])

    async function historicoDeAulasss(dataInicial, dataFinal) {
        try {
            var listaAux = await historicoDeAulasDate(localizacao,dataInicial, dataFinal)
            var newList = []
            listaAux.forEach((elem) => {
                if (typeof elem.data().aula != "undefined") {
                    newList.push(elem)
                }
            })
            newList.sort((a, b) => {
                if (a.data().createdAt.toDate() > b.data().createdAt.toDate()) {
                    return -1
                } else {
                    return 1
                }
            })
            setHistoricoDeAulas(newList)
        } catch (error) {
            console.log(error)
            setHistoricoDeAulas([])
        }
       
    }

    async function removeAulas0() {
       
       /*  = historicoDeAulas.forEach((elem) => {
        console.log(elem.data().aula.alunos.length == 0)
       }) */
        const newAuals =  historicoDeAulas.filter((elem) => elem.data().aula.alunos.length == 0) 
        
        for (let index = 0; index < newAuals.length; index++) {
            const element = newAuals[index];
      
             await deleteAulaDoHistorico(element.id)
             console.log("A remover: " + element.id) 
            
        }
    }

    useEffect(async () => {
        var date = new Date();
        setHoraInicial(date.addDays(-15))
        setHoraFInal(date.addDays(1))
        await historicoDeAulasss(date.addDays(-15), date.addDays(1))
    }, [])

    const stylesTable = {
        "--bs-table-bg": "#ececf1",
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col xs={7} sm={8} md={9} lg={10}>
                                <h6 className="page-title">{t("Histórico de Aulas")}</h6>
                            </Col>
                            {/* <Button  onClick={() => {
                                removeAulas0()
                            }} >ASD ASD ASD A</Button> */}
                        </Row>
                        <Row style={{ paddingTop: "20px", paddingBottom: "40px" }}>
                            <Col md={2}>
                            <h5>Hora Inicial</h5>
                            </Col>
                            <Col md={3}>
                                <DatePicker 
                                disableCalendar={true}
                                value={horaInicial}
                                onChange={value => {
                                    setHoraInicial(value)
                                }}
                                
                                >
                            </DatePicker>
                            </Col>
                            <Col md={3}>
                                <DatePicker 
                                disableCalendar={true}
                                value={horaFinal}
                                onChange={value => {
                                    setHoraFInal(value)
                                }}
                               
                                >
                            </DatePicker>
                            </Col>
                            <Col md={2}>
                            <Button color="primary"  onClick={async () => {
                               await historicoDeAulasss(horaInicial, horaFinal);
                            }} >
                                Pesquisar
                            </Button>
                            </Col>
                        </Row>
                        {/* TABELA */}
                        <Row className='pt-2'>
                        <Table bordered striped responsive className="myList" style={stylesTable} >
                            <thead>
                                <tr>
                                    <th>Dia</th>
                                    <th>Horário</th>
                                    <th>Professor</th>
                                    <th>Tipo</th>
                                    <th>N° Alunos</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historicoDeAulas.map((elem, index) => {
                                    return <tr key={index + elem.id}>
                                        <td>
                                            {elem.data().createdAt.toDate().toLocaleString().substring(0, 10)}
                                        </td>
                                        <td>
                                            {elem.data().aula.horaInicial}
                                        </td>
                                        <td>
                                            {elem.data().aula.professor}
                                        </td>
                                        <td>
                                            {elem.data().aula.tipo}
                                        </td>
                                        <td>
                                            {elem.data().aula.alunos.length}
                                        </td>
                                        
                                        <td>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div>
                                                    <Link className="btn btn-primary" key={elem.id + "nivel" + elem.data().aula.nivel} to={"/historicoDeAula/" + elem.id}>
                                                        <ArrowRightCircle key={elem.id + "arrow" + elem.data().aula.nivel}></ArrowRightCircle>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Button className='btn btn-danger'>
                                                        <Trash2 onClick={async () => {
                                                            var res = await deleteAulaDoHistorico(elem.id)
                                                            if (res) {
                                                                alert("Aula Removida com Sucesso")
                                                            } else {
                                                                alert("Falha a remover aula")
                                                            }
                                                        }}  ></Trash2>
                                                    </Button>
                                                </div>
                                            </div>
                                          
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        </Row>

                        {/* <Row style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <Col md={2} className='list-title' >
                                Dia
                            </Col>
                            <Col md={2} className='list-title'>
                                Horário
                            </Col>
                            <Col md={2} className='list-title'>
                                Professor
                            </Col>
                            <Col md={2} className='list-title'>
                                Tipo
                            </Col>
                            <Col md={2} className='list-title'>
                                Nr de alunos
                            </Col>
                            <Col md={2}>

                            </Col>
                        </Row> */}

                        

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default HistoricoDeAulasScreen;