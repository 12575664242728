import React, { useState } from 'react';
import { Trash, Trash2 } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { adicionaClassEspecial, backUp, removeClasseEspecial } from 'services/clube/clube_services';
import { selectclassesespeciais } from 'store/localizacao/classes_especiais';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';

function ClassesEspeciais() {
  const { t, i18n } = useTranslation()
  const [nome, setNome] = useState("")

  const [valor, setValor] = useState("")
  const [numeroDeRefs, setNumeroDeRefs] = useState(1)

  const clubeId = useSelector(selectclubeid)
  const classesEspeciais = useSelector(selectclassesespeciais)
  const jsonCE = JSON.parse(classesEspeciais);
  const mapClsEsp = Object.entries(jsonCE)


  const [isOpen, setIsOpen] = useState(false)

  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          
          <div className="page-title-box">

              {/* Título */}
              <Row className="align-items-center">
                <Col md={10}>
                  <h6 className="page-title">{t("Classes ")}</h6>
                </Col>
                <Col md={2}>
                  <Button color='primary' onClick={() => {
                    setIsOpen(!isOpen)
                  }} > Adicionar</Button>
                </Col>
              </Row>

          </div>

          {/* Tabela */}
          <Row className="pt-2">
            <Table bordered striped responsive className="myList" style={stylesTable}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {mapClsEsp.map((elem, index) => {
                  return (
                    <tr key={elem[1] + index} >
                      <td>{elem[0]}</td>
                      <td>
                        <Button className='btn btn-danger' onClick={async () => {
                          var map = {}

                          for (let index = 0; index < mapClsEsp.length; index++) {
                            if (mapClsEsp[index][0] != elem[0]) {
                              map[mapClsEsp[index][0]] = elem[1]
                            }
                          }
                          console.log(map)

                          var res = await removeClasseEspecial(clubeId, map);
                          if (res) {
                            alert("Classe removida com sucesso")

                          } else {
                            alert("Falha ao remover classe")

                          }
                        }}>
                          <Trash2 />
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>


      {/* Modal Adicionar Aula */}
      <Modal isOpen={isOpen}
                toggle={() => {
                  setIsOpen(!isOpen)
                }} >
                <ModalHeader
                  toggle={() => {
                    setIsOpen(!isOpen)
                  }}
                >
                  Adicionar Aula
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Row>
                        <Col md={3}>
                          Nome
                        </Col>
                        <Col md={9}>
                          <Input
                            value={nome}
                            onChange={e => {
                              setNome(e.target.value)
                            }}
                          ></Input>
                        </Col>
                      </Row>
                    </FormGroup>

                    {/* <FormGroup style={{paddingTop: "20px"}}>
                    <Row>
                    <Col md={3}>
                        Valor
                    </Col>
                    <Col md={9}>
                    <Input
                      value={valor}
                      onChange={e => {
                        setValor(e.target.value)
                      }}
                    ></Input>
                    </Col>
                    </Row>
                </FormGroup> */}
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color='primary' onClick={async () => {
                    var res = await adicionaClassEspecial(clubeId, nome, "0.10")

                    if (res) {
                      alert("Classe adicionada com sucess")
                      setIsOpen(!isOpen)
                    } else {
                      alert("Falha ao adicionar classe")
                      setIsOpen(!isOpen)
                    }
                  }} >Adicionar</Button>
                  <Button onClick={() => {
                    setIsOpen(!isOpen)
                  }} >Cancelar</Button>
                </ModalFooter>

      </Modal>

    </React.Fragment>
  );
}

export default ClassesEspeciais;