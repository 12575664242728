import { Carregamento } from 'models/carregamento';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input } from 'reactstrap';
import { editaValoresDosCarregamentos } from 'services/clube/clube_services';
import { selectcarregamentos } from 'store/clube/carregamentos_reducer_services';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';

function AdicionarCarregamentos() {
    const carregamentos = useSelector(selectcarregamentos)
    const carregamentosJson = JSON.parse(carregamentos)
    const clubeId = useSelector(selectclubeid)

    const [preco, setPreco] = useState(0)
    const [nome, setNome] = useState("")
    const [desconto, setDesconto] = useState(0)
    const [precoFinal, setPrecoFinal] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    function toggle() {
        setIsOpen(!isOpen)
      }
    return (  
        <React.Fragment>
            <Button
        color="primary"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Adicionar Carregamento
      </Button>
            <Modal  isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}>
            <ModalHeader
            toggle={() => {
                toggle()
            }}
            >
            Adicionar Carregamento
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                    <Row>
                        <Col md={3}>
                        <Label>Nome</Label>
                        </Col>
                        <Col md={9}>
                        <Input
                        type="text"
                            value={nome}
                            onChange={e => {
                            setNome(e.target.value)
                            }}
                        ></Input>
                        </Col>
                    </Row>
                    </FormGroup>
                    <FormGroup style={{ paddingTop: "20px"}}>
                    <Row>
                        <Col md={3}>
                        <Label>Preço</Label>
                        </Col>
                        <Col md={9}>
                        <Input
                            type="number"
                            value={preco}
                            onChange={e => {
                            setPreco(e.target.value)
                            setPrecoFinal( parseFloat(e.target.value) + parseFloat(desconto))
                            }}
                        ></Input>
                        </Col>
                    </Row>
                    </FormGroup>
                    <FormGroup style={{ paddingTop: "20px"}}>
                    <Row>
                        <Col md={3}>
                        <Label>Desconto</Label>
                        </Col>
                        <Col md={9}>
                        <Input
                        type="number"
                            value={desconto}
                            onChange={e => {
                            setDesconto(e.target.value)
                            setPrecoFinal(  parseFloat(preco) + parseFloat(e.target.value))
                            }}
                        ></Input>
                        </Col>
                    </Row>
                    </FormGroup>
                    <FormGroup style={{ paddingTop: "20px"}}>
                    <Row>
                        <Col md={3}>
                        <Label>Preço Final</Label>
                        </Col>
                        <Col md={9}>
                           {precoFinal}
                        </Col>
                    </Row>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => {
                    console.log(carregamentos)
                }}> Teste </Button>
                <Button onClick={async() => {
                    var carregamnt = new Carregamento();
                    carregamnt.desconto = desconto
                    carregamnt.preco = preco
                    carregamnt.precoFinal = precoFinal
                    carregamnt.nome = nome

                    carregamentosJson[nome] = {}
                    carregamentosJson[nome].desconto = desconto
                    carregamentosJson[nome].preco  = preco
                    carregamentosJson[nome].precoFinal = precoFinal

                    console.log(carregamentosJson)
                    
                    await editaValoresDosCarregamentos(clubeId,carregamentosJson );
                }} color='primary'>
                    Adicionar
                </Button>
                <Button onClick={() => {
                    setIsOpen(!isOpen)
                    }}>
                    Cancelar
                </Button>
            </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default AdicionarCarregamentos;