import React, { useState } from "react"
import { Trash2 } from "react-bootstrap-icons"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import firebase from "firebase/app"
import "firebase/firestore"
import { editaValoresDasAulas, removeValoresDasAulas } from "services/clube/clube_services"
import { selectclubeid } from "store/localizacao/clube_id_reducer"
import { useSelector } from "react-redux"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"

function RemoverValorDasAulas(props) {
const clubeId = useSelector(selectclubeid)

const aulas = useSelector(selectaulasvalores)
const aulasJson = JSON.parse(aulas)

  const [isOpen, setIsOpen] = useState(false)
  return (
    <React.Fragment>
      <Button
        color="danger"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <Trash2 size={15}></Trash2>
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          Tem a certeza que deseja remover este valor?
        </ModalHeader>

        <ModalFooter>
          <Button color="primary"
            onClick={async () => {
                var map = {}
                map = aulasJson
                delete map[props.tipo][props.data[0]]

                console.log(map)
               
                var res = await removeValoresDasAulas(clubeId, map)
                if (res) {
                  alert("Valores adicionados com sucesso")
                  setIsOpen(!isOpen)
                } else {
                  alert("Erro a adicionar valores")
                  setIsOpen(!isOpen)
                } 
            }}
          >
            Remover
          </Button>
          <Button onClick={() => setIsOpen(!isOpen)}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default RemoverValorDasAulas
