import AdicionarDescontoModal from "components/modals/adicionar_desconto_modal"
import { map } from "lodash"
import { element } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Container, Row, Table } from "reactstrap"
import { Trash2, TrashFill } from "react-bootstrap-icons"
import { useSelector } from "react-redux"
import { selectclasses } from "store/localizacao/classes_reducer"
import { selectclubeid } from "store/localizacao/clube_id_reducer"
import { selectlocalizacao } from "store/localizacao/localizacao_reducer"
import { selectdescontos } from "store/descontos/descontos_reducer"
import { transformaDescontos } from "services/descontos/descontos_services"
import { Desconto } from "models/desconto"
import RemoveDescontoModal from "components/modals/remove_desconto_modal"
import { asdasdasd } from "services/reservas/reservas_services"

const Descontos = () => {
  var classes = useSelector(selectclasses)
  var clubeId = useSelector(selectclubeid)
  var localizacao = useSelector(selectlocalizacao)
  var descontos = useSelector(selectdescontos)

  const { t, i18n } = useTranslation()
  var listaAux = [1, 2, 3]
  console.log("Descontos:", descontos)
  var listaDeDescontos = transformaDescontos(JSON.parse(descontos), localizacao)


  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">

              {/* Título */}
              <Row className="align-items-center">
                <Col md={10}>
                  <h6 className="page-title">{t("Descontos")}</h6>
                  <Button onClick={() => {
                    asdasdasd().then(value => {
                      if(value) {
                        alert("Deu")
                      } else {
                        alert ("EORROEROEORROEEOROR")
                      }
                    }) 
                  }} >ASDASD</Button>
                </Col>
                <Col md={2}>
                  <AdicionarDescontoModal classes={classes} clubeId={clubeId} localizacao={localizacao} />
                </Col>
              </Row>

             
              {/* Tabela */}
              <Row className="pt-2">
                <Table bordered striped responsive className="myList" style={stylesTable}>
                  <thead>
                    <tr>
                      <th>Classe</th>
                      <th>Horário</th>
                      <th>Dias</th>
                      <th>Preço</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaDeDescontos.map((element, index) => {
                      var desconto = new Desconto()
                      desconto = element
                      return (
                        <tr key={index}>
                          <td>
                            <p>{desconto.classe}</p>
                          </td>
                          <td>
                            <p>{desconto.horaInicial + " : " + desconto.horaFinal}</p>
                          </td>
                          <td>
                            <p>{desconto.semana}</p>
                          </td>
                          <td>
                            <p>
                              <strong>60m: {desconto.preco["60"] + "€"}</strong>
                            </p>
                            <p>
                              <strong>
                                90m: {desconto.preco["90"] + "€"}
                              </strong>
                            </p>
                            <p>
                              <strong>
                                120m: {desconto.preco["120"] + "€"}
                              </strong>
                            </p>
                          </td>
                          <td>
                            <RemoveDescontoModal index={index} desconto={desconto} clubeId={clubeId} />
                          </td>
                        </tr>
                      )
                    })}
                    <tr>

                    </tr>
                  </tbody>
                </Table>
              </Row>
            
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Descontos
