import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Modal, Spinner } from 'reactstrap';
import { logOut, registaUser } from 'services/clube/clube_services';
import { adicionarEmailAosRoles, adicionarRoleAoUser } from 'services/localizacao/localizacao_services';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';
import { selectlocalizacao } from 'store/localizacao/localizacao_reducer';
import Swal from 'sweetalert2';

function InsertEmail({isOpen, role, setIsOpen}) {

    const clubeID = useSelector(selectclubeid)
    const localizacao = useSelector(selectlocalizacao)
    const [isLoading, setIsLoading] = useState(false)


    const toggle = () => setIsOpen(!isOpen);

    const [userRole, setUserRole] = useState(role)

    const addUserRoleFirebase = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        
        const email = document.getElementById("formrole_email").value;
        const password = document.getElementById("formrole_password").value;
        
        const user = await registaUser(email, password, userRole,localizacao );
        const adicionadoRoleaoUser = await adicionarRoleAoUser(userRole, email);
        const adicionadoEmailAosRoles = await adicionarEmailAosRoles(clubeID, userRole, email);

        if((user)) {
            if((adicionadoRoleaoUser) && (adicionadoEmailAosRoles)) {

                // If success, show success message
                Swal.fire({
                    title: "Sucesso!",
                    text: `O email ${email} foi adicionado ao grupo de ${userRole} `,
                    icon: "success",
                    confirmButtonText: "Fechar",
                });
                setIsLoading(false)
                logOut()
            }
            else{
                Swal.fire({
                    title: "Erro!",
                    text: `O email ${email} não foi adicionado ao grupo de ${userRole}`,
                    icon: "error",
                    confirmButtonText: "Fechar",
                });
                setIsLoading(false)
            }
        } else {
            Swal.fire({
                title: "Erro!",
                text: `O email ${email} não foi adicionado ao grupo de ${userRole}`,
                icon: "error",
                confirmButtonText: "Fechar",
            });
            setIsLoading(false)
        }
        setIsLoading(false);
    }

  return (
    <>
    
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <form onSubmit={addUserRoleFirebase}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Adicionar Email ao grupo de {role} 
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            toggle();
                        }}
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        <label htmlFor="formrole_email" className="form-label">
                            Email
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="formrole_email"
                            placeholder="Email"
                        />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        <label htmlFor="formrole_password" className="form-label">
                            Password
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="formrole_password"
                            placeholder="Email"
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            toggle();
                        }}
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Cancelar
                    </button>
                   {isLoading ? <Spinner></Spinner> : <button type="submit" className="btn btn-primary">
                        Adicionar
                    </button>}
                </div>
            </form>
        </Modal>

    
    
    
    </>
  )
}

export default InsertEmail