import firebase from "firebase/app"
import "firebase/firestore"
import { forEach } from "lodash";
import { Reserva } from "models/reserva"
import QRCode from "qrcode"

function criaEmailBOdy(nome, campo) {
  const aretornar =
    " <style>" +
    ".text {" +
    " align-self: flex-end;" +
    "}" +
    ".image {" +
    " width: 120px;" +
    " height: 120px;" +
    "}" +
    ".paragraph {" +
    "display: flex;" +
    "}" +
    "</style>" +
    '<div style="font-weight: normal; font-size: 14px;">' +
    nome +
    "," +
    "</div>" +
    "<p>A sua reserva foi confirmada para o " +
    campo +
    ". Segue em anexo o seu QrCode de acesso</p>"
  ;("</div>")

  return aretornar
}

export async function enviaEmailParaUmJogador(reserva, jogadorEstado) {
  try {
    if (
      reserva[jogadorEstado].email != null ||
      typeof reserva[jogadorEstado].email != "undefined"
    ) {
      var hInicial = reserva.horaIncial.toDate()
      var dia = ("0" + hInicial.getDate()).slice(-2)
      var mes = ("0" + (hInicial.getMonth() + 1)).slice(-2)
      var ano = hInicial.getYear() + 1900
      var hor = hInicial.getHours()
      var min = ("0" + hInicial.getMinutes()).slice(-2)
      var assunto =
        reserva.localizacao +
        " || " +
        "Reserva dia " +
        dia +
        "-" +
        mes +
        "-" +
        ano +
        " às " +
        hor +
        "h e " +
        min +
        "m"
      QRCode.toDataURL(reserva[jogadorEstado].qrcode.toString())
        .then(async url => {
          console.log(url)
          const message = criaEmailBOdy(
            reserva[jogadorEstado].nome,
            reserva.campo
          )
          await enviarEmail(reserva[jogadorEstado].email, message, assunto, url)
        })
        .catch(err => {
          console.error(err)
          return null
        })
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function enviaEmails(reserva) {
  try {
    if (
      reserva.jogador1estado.email != null ||
      typeof reserva.jogador1estado.email != "undefined"
    ) {
      enviaEmailParaUmJogador(reserva, "jogador1estado")
    }
    if (
      reserva.jogador2estado.email != null ||
      typeof reserva.jogador2estado.email != "undefined"
    ) {
      enviaEmailParaUmJogador(reserva, "jogador2estado")
    }
    if (
      reserva.jogador3estado.email != null ||
      typeof reserva.jogador3estado.email != "undefined"
    ) {
      enviaEmailParaUmJogador(reserva, "jogador3estado")
    }
    if (
      reserva.jogador4estado.email != null ||
      typeof reserva.jogador4estado.email != "undefined"
    ) {
      enviaEmailParaUmJogador(reserva, "jogador4estado")
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

async function enviarEmail(to, message, assunto, image) {
  try {
    await firebase
      .firestore()
      .collection("mail")
      .add({
        to: to,
        message: {
          subject: assunto,
          html: message,
          attachments: [
            {
              path: image,
            },
          ],
        },
      })
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function enviaEmailParaTodosPorCF(message, assunto, attachment,fileName, teste) {
/* const emailData = req.data();
const attachment = req.data().attachment;
const assunto = req.data().assunto;
const message = req.data().message; */
  try {
    await firebase.firestore().collection("enviaEmail").add({
      teste: teste,
      message:message,
      assunto:assunto,
      fileName: fileName,
      attachment:attachment,
    })
    return true;
  } catch (error) {
      console.log(error)
      return null
  }
}

export async function enviarEmaiParaTodos(tos, message, assunto, attachment, filename) {

  if (attachment != "" && filename!= "") {
    for (let index = 0; index < tos.length; index++) {
      console.log("A Enviar email para " + tos[index])
      try {
        await firebase
          .firestore()
          .collection("mail")
          .add({
            to: tos[index],
            message: {
              subject: assunto,
              html: message,
              attachments: [
                {
                  path: attachment,
                },
              ],
            },
          })
      } catch (e) {
        console.log(e)
        return null
      }
    }
  } else {
    for (let index = 0; index < tos.length; index++) {
      console.log("A Enviar email para " + tos[index])
      try {
        await firebase
          .firestore()
          .collection("mail")
          .add({
            to: tos[index],
            message: {
              subject: assunto,
              html: message,
            },
          })
      } catch (e) {
        console.log(e)
        return null
      }
    }
  }

  
}

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes*60000);
}

export async function enviaEmailsQueDeramErro() {
  var date = new Date(2023,7,22,10,27,34)
  var date2 = new Date(2023,7,22,10,30,34)

  /* date = addMinutes(date, -15)
  date2 = addMinutes(date, -45) */
  try {
    await firebase.firestore().collection("mail")
    .where("delivery.endTime", ">=", firebase.firestore.Timestamp.fromDate(date))
    .where("delivery.endTime", "<=", firebase.firestore.Timestamp.fromDate(date2))
    .where("delivery.state", "==", "ERROR").get().then(async (value) => {
      for (let index = 0; index < value.docs.length; index++) {

         try {
          await firebase
            .firestore()
            .collection("mail")
            .add({
              to: value.docs.at(index).data().to,
              message: value.docs.at(index).data().message,
            })
            console.log("Email enviado para" + value.docs.at(index).data().to)
        } catch (e) {
          console.log(e)
          return null
        } 
      }
    })
  } catch (error) {
    console.log(error)
    return null;
    
  }
 
}

export async function duplicaEmail(id) {
  try {
       var docData = await firebase.firestore().collection("enviaEmail").doc(id).get();
      await firebase.firestore().collection("enviaEmail").add(
        docData.data()
      )
      return true 
  } catch (error) {
    console.log(error)
    return false;
  }

  

}

export async function getEmailPostion(email) {
  try {
    const users = await firebase
            .firestore().collection("users").get();
        for (let index = 0; index < users.docs.length; index++) {
          const email = users.docs.at(index).data().email;
          if(email == email){
            console.log("Valor do " + email)
            console.log(index);
          }
        }
      var docData = await firebase.firestore().collection("enviaEmail").doc(id).get();
      await firebase.firestore().collection("enviaEmail").add(
        docData.data()
      ) 
      return true
  } catch (error) {
    console.log(error)
    return false;
  }
}

/**
 * @param {Date} date 
 */
export async function getAllPendingEmails(date) {
  try {
    var listaAUx = []
    await firebase.firestore()
      .collection("mail")
      .where("delivery.startTime", ">=", date).limit(20)
      .where("delivery.state", "==", "PROCESSING").get().then(async (value) => {
        console.log(value.docs.length)
         for (let index = 0; index < value.docs.length; index++) {
          if (value.docs.at(index).data().message.attachments.at(0).filename == "jogar solidário.jpeg" ) {
            console.log(value.docs.at(index).id)
            await firebase.firestore().collection("mail").doc(value.docs.at(index).id).set({
              delivery : {state: "RETRY"},
            },{merge: true})
          }
        } 
      },)
  } catch (error) {
    console.log(error)

  }
}

/**
 * resend emails pendentes
 * @param {Date} date data ate onde procurar os emails
 */
export async function sendPendingEmails(date) {
  try {
    await firebase.firestore()
      .collection("mail")
      .where("delivery.startTime", ">=", date).limit(5)
      .where("delivery.state", "==", "PROCESSING").get().then(async (value) => {
        console.log(value.docs.length)
         for (let index = 0; index < value.docs.length; index++) {
            console.log(value.docs.at(index).id)
            await firebase.firestore().collection("mail").doc(value.docs.at(index).id).set({
              delivery : {state: "RETRY"},
            },{merge: true})
        } 
      },)
  } catch (error) {
    console.log(error)
  }
}
