//FB Collections e docs --------
export const reservasCollection = "reservas"
export const aulasCollection = "aulas"
export const historicoDeAulasCollection = "historicoDeAulas"
export const localizacaoCollection = "localizacao"
export const usersCollection = "users"
export const contratosCollection = "contratos"
export const historicoDeContratosCollection = "historicoDeContratos"
export const historicoDePagamentos = "historicoDePagamentos"
export const treinadoresCollection = "treinadores"

export const diasDaSemana = ["","Segunda","Terça","Quarta","Quinta","Sexta","Sábado","Domingo"]

export const nomesCampos = ['Lexus', 'NX', 'ES', 'RX', 'UX', 'LC'];
export const niveis = ["5+", "5" , "5-" , "4+" , "4" , "4-","3+", "3" , "3-" , "2" , "1"]

export const convertCamps = {
  "Campo 1": "Lexus",
  "Campo 2": "NX",
  "Campo 3": "ES",
  "Campo 4": "RX",
  "Campo 5": "UX",
  "Campo 6": "LC",
};

export const client_id = 'greatsportslda';
export const client_secret = 'd07bfd992b0de1e518f497f4c7c19feef910ea1b';
export const moloniUser = 'francisco.mota@greatpadel.pt';
export const moloniPw = 'Great_516';
export const companyID = "205427";

//FB values --------------------
export const semana = "Semana"
export const fdsEFeriados = "Fins de semana e Feriados"
export const saldo = "Saldo"
export const mbway = "MBway"
export const contrato = "Contrato"
export const numerario = "Numerário"
//App usage --------------------
export const listaDeFeriados2022 = {
  "01-01": "Ano Novo",
  "15-04": "Sexta-Feira Santa",
  "17-04": "Páscoa",
  "25-04": "Dia da Liberdade",
  "01-05": "Dia do Trabalhador",
  "10-06": "Dia de Portugal",
  "16-06": "Corpo de Deus",
  "08-15": "Assunção de Nossa Senhora",
  "05-10": "Implantação da República",
  "01-11": "Dia de Todos os Santos",
  "12-01": "Restauração da Independência",
  "12-08": "Imaculada Conceição",
  "12-25": "Natal",
}

export const adminRole = "Administrador"
export const nutriRole = "Nutricionista"
export const recepRole = "Recepcionista"
export const treinRole = "Treinador"


export function dateToUTC(date) {
  var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
  date.getUTCDate(), date.getUTCHours(),
  date.getUTCMinutes(), date.getUTCSeconds());

  var date = new Date(now_utc)

  return date;
}

export function dateToStringCF(date) {

  const dia = ("0" + date.getDate()).slice(-2);
  const mes = ("0" + (date.getMonth() + 1)).slice(-2);
  const ano = date.getYear() + 1900;
  const hor = ("0" + (date.getHours() ) ).slice(-2); // 20231028
  const min = ("0" + date.getMinutes() ).slice(-2);
  const sec = ("0" + date.getSeconds() ).slice(-2);
  const dateString = ano + "-" + mes + "-" + dia + " " +
  hor + ":" + min + ":" + sec;

  return dateString;

}

export function getGMTOffset() {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  // Constructing the GMT offset string
  
  return hours * 3600;
}

