import React, { useEffect, useState } from "react"
import { DashCircle, PlusCircle } from "react-bootstrap-icons"
import { useSelector } from "react-redux"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { backUp, editarFaturaRefs } from "services/clube/clube_services"
import { selectfaturasref } from "store/clube/faturas_referencias_reducer"
import { selectclubeid } from "store/localizacao/clube_id_reducer"

function AdicionarFaturaRefModal() {
  const [isOpen, setIsOpen] = useState(false)

  const [nome, setNome] = useState("")
  const [refs, setRefs] = useState([])
  const [numeroDeRefs, setNumeroDeRefs] = useState(1)

  const faturasVals = useSelector(selectfaturasref)
  const faturasValsJson = JSON.parse(faturasVals)
  const mapFaturas = Object.entries(faturasValsJson)

  const clubeId = useSelector(selectclubeid)

  function incrementaRefs() {
    setNumeroDeRefs(numeroDeRefs + 1)
  }

  function decremntaRefs() {
    if (numeroDeRefs > 1) {
      setNumeroDeRefs(numeroDeRefs - 1)
    }
  }

  function criarInputDeRfs() {
    var listaAux = []
    for (let index = 0; index < numeroDeRefs; index++) {
      listaAux.push(index)
    }
    setRefs(listaAux)
  }

  function toggle() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    criarInputDeRfs()
  }, [numeroDeRefs])

  return (
    <React.Fragment>
      <Button color="primary" onClick={() => toggle()}>
        Adicionar Referência de faturação
      </Button>
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader
          toggle={() => {
            toggle()
          }}
        >
          {" "}
          Adicionar Faturacao
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <Label>Nome</Label>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome}
                    onChange={e => {
                      setNome(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <Row style={{ paddingTop: "40px" }}>
              <Row>
                <Label md={2}>Referências</Label>
                <Col md={1}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      incrementaRefs()
                    }}
                  >
                    {" "}
                    <PlusCircle></PlusCircle>{" "}
                  </Button>
                </Col>
                <Col md={1}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      decremntaRefs()
                    }}
                  >
                    {" "}
                    <DashCircle></DashCircle>{" "}
                  </Button>
                </Col>
              </Row>
              {refs.map((elemnt, index) => {
                const indexp1 = index + 1
                return (
                  <FormGroup
                    style={{ paddingBottom: "10px", paddingTop: "20px" }}
                    key={index}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col md={3}>
                        <Label for="refs">Referência</Label>
                      </Col>
                      <Col md={9}>
                        <Input
                          className="input-refs"
                          type="text"
                          name={"refs" + index}
                          id={"idrefs" + index}
                          placeholder={"Refs " + indexp1}
                          onChange={e => {
                            let items = [...refs]
                            items[index] = e.target.value
                            setRefs(items)
                            console.log(refs)
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                )
              })}
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              var listaAux = []
              var controlador = true
              
              for (let index = 0; index < numeroDeRefs; index++) {
                var vals = document.getElementById("idrefs" + index).value
                listaAux.push(vals)
                if (vals.trim() == "") {
                  controlador = false
                }
              }
              if (!controlador || nome.trim() == "") {
                alert("Todos os dados tem que estar preenchidos")
              } else {
                faturasValsJson[nome] = listaAux
                console.log(faturasValsJson)
                var res = await editarFaturaRefs(clubeId, faturasValsJson)
                if (res) {
                  alert("Faturação adicionada com sucesso")
                } else {
                  alert("Erro")
                }
              }
            }}
            color="primary"
          >
            Adicionar
          </Button>
          <Button
            onClick={() => {
              toggle()
            }}
            color="secondary"
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default AdicionarFaturaRefModal
