import AdicionarValorDeAulas from "components/modals/Adicioanr_valores_de_aulas"
import EditarFeriadosModal from "components/modals/editar_feriado_modal"
import EditarValoresDasAulas from "components/modals/editar_valores_das_aulas"
import RemoverValorDasAulas from "components/modals/remove_valor_das_aulas_modal"
import React from "react"
import { Pencil, Trash, Trash2Fill } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"
import { Button, Col, Container, Row, Table } from "reactstrap"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"
import { selectferiados } from "store/descontos/feriados"

function ValorDasAulas() {
  const { t, i18n } = useTranslation()
  const aulas = useSelector(selectaulasvalores)
  const aulasJson = JSON.parse(aulas)

  const aulasPAx = aulasJson['PAX']
  const aulasAcademia = aulasJson['Academia']
  const aulasPAxList = Object.entries(aulasPAx)
  const aulasAcaList = Object.entries(aulasAcademia)

  const paxList = aulasPAxList.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })

  const acadList = aulasAcaList.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })

/*   const academiaList = aulasAcademia.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  }) */

  console.log(paxList)
  console.log("paxList")

  console.log("AULAS Acadmia")
  console.log(aulasAcademia)

  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Great Padel - Aulas Valores</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={10}>
                <h6 className="page-title">{t("Valores das Aulas")}</h6>
              </Col>
              <Col md={2}>
                <AdicionarValorDeAulas></AdicionarValorDeAulas>
              </Col>
            </Row>
          </div>
          <Row style={{paddingBottom: "10px"}}>
            <h4>Aulas Academia</h4>
          </Row>

          <Row>
            <Table bordered striped responsive className="myList" style={stylesTable}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Horário</th>
                  <th>Idade</th>
                  <th>Nr Aulas</th>
                  <th>Duração</th>
                  <th>Fidelização</th>
                  <th>Valor</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {acadList.map((elem, index) => {
                return (
                  <tr key={elem[1] + index}>
                    <td>{elem[0]}</td>
                    <td>{elem[1].horario}</td>
                    <td>{elem[1].idade}</td>
                    <td>{elem[1].numeroDeAulas}</td>
                    <td>{elem[1].duracao}</td>
                    <td>{elem[1].fidelizacao}</td>
                    <td>{elem[1].valor + " €"}</td>
                    <td> <div style={{ marginBottom: "5px" }}>
                      <EditarValoresDasAulas  data={elem} tipo={"Academia"} ></EditarValoresDasAulas>  
                      
                      <RemoverValorDasAulas data={elem} tipo={"Academia"} ></RemoverValorDasAulas>
                      </div></td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </Row>
          {/* Aulax PAX */}
          <Row style={{paddingBottom: "10px", paddingTop:"40px"}}>
            <h4>Aulas Pax</h4>
          </Row>

          <Row>
            <Table bordered striped responsive className="myList" style={stylesTable}>
              <thead>
                <tr>
                <th>Nome</th>
                <th>Nr Aulas</th>
                <th>Nr Jogadores</th>
                <th>Valor</th>
                <th></th>
                </tr>
              </thead>
              <tbody>
              {
                paxList.map((elem, index) => {
                  return (
                    <tr key={elem[1] + index}>
                      <td>{elem[0]}</td>
                      <td>{elem[1].numeroDeAulas}</td>
                      <td>{elem[1].numeroDeJogadores}</td>
                      <td>{elem[1].valor + " €"}</td> 
                      <td> <div style={{ marginBottom: "5px" }}>
                          <EditarValoresDasAulas data={elem} tipo={"PAX"} ></EditarValoresDasAulas>
                          <RemoverValorDasAulas data={elem} tipo={"PAX"} ></RemoverValorDasAulas>
                      </div></td>
                    </tr>
                  )
                })
              }
              </tbody>
            </Table>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ValorDasAulas
