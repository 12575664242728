import React, { useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import 'moment-timezone'
import "firebase/firestore"
import firebase from "firebase/app"
import { aulasCollection } from 'services/consts';
import { useTranslation } from 'react-i18next';
import { selecttreinadores } from 'store/treinadores/treinadores_reducer';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import EnventComponent from './event_component';
import 'moment/locale/pt'; // Import Portuguese locale for Moment.js




function VisaoCalendario() {

    function adddays(date, days) {
        return new Date(date.getTime() + days*60000 *60 * 24);
      }

     /*  const {views, ...otherProps} = useMemo(() => ({
        views: {
            month: true
        }
    }), []) */

    const { t, i18n } = useTranslation()
    const [listaDeAulas, setListaDeAulas] = useState([])
    const [listaDeEventos, setListaDeEnvtos] = useState([])
    const [listaDeAulasExper, setListaDeAulasExper] = useState([])
    const [listaDeAulasPacks, setListaDeAulasPacks] = useState([])
    const [listaDeAulasAcadm, setListaDeAulasAcadm] = useState([])
    const professores = [" "].concat(useSelector(selecttreinadores))
    const [diaDaSemana, setDiaDaSemana] = useState(0)
  
    const [trienador, setTreinador] = useState(professores[0].nome)
  
    const [filtroProfessor, setFiltroProfessor] = useState([])
    const [filtroCampo, setfiltroCampo] = useState([])
    const [filtroDiaDaSemana, setfiltroDiaDaSemana] = useState([])
  
    const [listaFiltrada, setListaFiltrada] = useState([])


    const treinadoresREDUX = useSelector(selecttreinadores)

    function convertAulasEmEvents(aula,id) {
        
        var listaAux = []
        /*   {
            'title': 'DTS STARTS',
            'start': new Date(2016, 2, 13, 0, 0, 0),
            'end': new Date(2016, 2, 20, 0, 0, 0)
            }, */
        

        // Concat Data
        const horasFinais = aula.horaFinal.substring(0,2)
        const horasInicias = aula.horaInicial.substring(0,2)
        const minituFinais = aula.horaFinal.substring(3,5)
        const minutsInicais = aula.horaInicial.substring(3,5)

        const ano = new Date().getFullYear();
        const month = new Date().getMonth();
        const weekDayControl = 1 -(new Date(ano,month,1).getDay())
        console.log("^^^^^^^^^^^^^^^^")
        console.log(ano,month)
        console.log(weekDayControl)

        for (let index = -5; index < 6; index++) {
            const envt = {}
            envt.title = aula.nome;
            envt.start = new Date(ano,month, aula.weekDay + (7*index) +weekDayControl ,horasInicias,minutsInicais )
            envt.end = new Date(ano,month, aula.weekDay + (7*index)+weekDayControl,horasFinais,minituFinais )
            envt.professor = aula.professor
            envt.id = id
            
            
            envt.campo = aula.campos
            
            let treinador = treinadoresREDUX.find(treinador => treinador.nome == aula.professor)
            if(treinador) {
              envt.color = treinador.color
            }

            listaAux.push(envt)
        }

        console.log(".,.,-.-.-.-.-.-..-.")
            

        return listaAux;
    }



    async function verListaDeAulas(localizacao) {
        try {
          return firebase
            .firestore()
            .collection(aulasCollection)
            .where("localizacao", "==", localizacao).where("tipo", "!=", "Contrato")
            .onSnapshot(docsSnap => {
              console.log(docsSnap.docs.length)
              var listaDeEventossAux = []
              for (const element of docsSnap.docs) {
                console.log(element.data())
                listaDeEventossAux.push(...convertAulasEmEvents(element.data(), element.id)) 
              }
              setListaDeEnvtos(listaDeEventossAux)
              setListaDeAulas(listaDeEventossAux)
            })
        } catch (e) {
          console.error(e)
          return null
        }
    }
    

    // React Big Calendar Stuff
    moment.locale('pt');
    moment.tz.setDefault('Europe/Lisbon')


    const mensagensPT = {
      next: 'Próximo',
      previous: 'Anterior',
      today: 'Hoje',
      month: 'Mês',
      week: 'Semana',
      day: 'Dia',
      agenda: 'Agenda',
    }

    const localizer = momentLocalizer(moment)

    


    useEffect(() => {
        verListaDeAulas("Great Padel Vila Verde")
    },[])

    return (  
        <>
          <div className="page-content">
            <Container style={{paddingTop: "20px", paddingBottom: "20px"}}>
              <Row>
              <Col md={1}>
                <h5>Professor</h5>
              </Col>
              <Col md={3}>
                <Input
                  onChange={e => {
                    if(e.target.value == "") {
                      setListaDeEnvtos(listaDeAulas)
                    } else {
                      var listaAux = []
                    listaAux = listaDeAulas.filter((elem) => elem.professor == e.target.value)
                    setListaDeEnvtos(listaAux)
                  }
                    setTreinador(e.target.value)
                  }}
                  value={trienador}
                  type="select"
                  name="select"
                  id="professorInput"
                >
                  {professores.map((elem, index) => {
                    return <option key={index}>{elem.nome}</option>
                  })}
                </Input>
              </Col>
              </Row>
            </Container>
             

              <div>
                  <Calendar 
                    defaultView='week'
                    step={15} // Meia em Meia hora
                    events={listaDeEventos} // Lista de eventos
                    
                    startAccessor="start"
                    endAccessor="end"
                    components={{
                        event: EnventComponent,
                    }}
                    views={['week','day','agenda']}

                    localizer={localizer}
                    messages= {mensagensPT}
                    eventPropGetter={(event) => {
                      const backgroundColor = event.color ? event.color : null;
                      return { style: { backgroundColor } }
                    }}
                    
                  />
              </div>

          </div>
        </>
    );
}


export default VisaoCalendario;
