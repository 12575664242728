import EditarFeriadosModal from 'components/modals/editar_feriado_modal';
import React from 'react';
import { Pencil, Trash2Fill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { selectferiados } from 'store/descontos/feriados';

function Feriados() {
    const { t, i18n } = useTranslation()
    const feriados = useSelector(selectferiados);
    const jsonFeriados = JSON.parse(feriados);
    const mapFeriados = Object.entries(jsonFeriados)

    const sortedList = mapFeriados.sort((a,b) => {
       if(a > b) {
        return 1
       } else 
       return -1
    })

    console.log(mapFeriados)
    function convertDias(mes,dia) {
      var year = new Date().getFullYear();
      var date = new Date( year, (parseInt(mes) - 1), dia);
      return date;
    }

    const stylesTable = {
      "--bs-table-bg": "#ececf1",
    }

    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Great Padel - Feriados</title>
            </MetaTags>
            <Container fluid>
              <div className="page-title-box">
                
                <Row className="align-items-center">
                  <Col md={10}>
                    <h6 className="page-title">{t("Feriados")}</h6>
                  </Col>
                  <Col md={2}>
                    <Button color='primary'>
                        Adicionar Feriado
                    </Button>
                  </Col>
                </Row>
                
              </div>

            <Row>
              <Table bordered striped responsive className="myList" style={stylesTable}>
                <thead>
                  <tr>
                    <th>Dia</th>
                    <th>Nome</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedList.map((elem,index) => {
                  
                  return (
                    <tr key={index}>
                        <td>
                            {convertDias(elem[0].substring(0,2), elem[0].substring(3,5)).toLocaleDateString()  } 
                        </td>
                        <td> {elem[1]} </td>
                        <td>
                          <div className="d-flex gap-2 ">
                            <EditarFeriadosModal feriado={elem} key={ elem[1] + "edit" + index}> </EditarFeriadosModal>
                            <Button className='btn btn-danger' key={ elem[1] + "trash" + index}><Trash2Fill /></Button>
                          </div>
                        </td>
                      </tr>
                    )
                }) }
                </tbody>
              </Table>
            </Row>

            </Container>
          </div>
        </React.Fragment>
      )
}

export default Feriados;