import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Container, Input, Row } from "reactstrap"
import firebase from "firebase/app"
import "firebase/firestore"
import { ArrowRightCircle } from "react-bootstrap-icons"
import { Link } from "react-router-dom"
import { convertCamps } from "services/consts"
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker"

const PesquisarReservas = () => {
  const { t, i18n } = useTranslation()
  const [listaDeReservas, setListaDeReservas] = useState([])
  const [listaDeReservasBloqueadas, setListaDeReservasBloqueads] = useState([])
  const [listaDeReservasFiltrada, setListaDeReservasFiltrada] = useState([])

  const [value, onChange] = useState(new Date())
  const minDate = new Date()
  const [valueFinal, onChangeFinal] = useState(new Date())
  const minDateFinal = new Date()

  const [isLoading, setIsLoading] = useState(false)

  async function getReservas(localizacao, horaInicial, horaFinal) {
    try {
      firebase
        .firestore()
        .collection("reservas")
        .where("localizacao", "==", localizacao)
        .where(
          "horaIncial",
          ">=",
          firebase.firestore.Timestamp.fromDate(horaInicial)
        ).where(
            "horaIncial",
            "<=",
            firebase.firestore.Timestamp.fromDate(horaFinal)).get().then((docsSnaps )=> {
                var listaAux = []
          var listaBloqAux = []
          setListaDeReservas([])
          setListaDeReservasBloqueads([])
          for (const element of docsSnaps.docs) {
            if (element.data().bloqueada == true) {
              listaBloqAux.push(element)
            } else {
              listaAux.push(element)
            }
          }
          setListaDeReservasBloqueads(listaBloqAux)
          setListaDeReservasFiltrada(listaAux)
          setListaDeReservas(listaAux)
            })
        
      return true
    } catch (error) {
      console.error(error)
      return null
    }
  }

  function filtraReservas(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().estado == filtro)
    setListaDeReservasFiltrada(asd)
  }

  function filtrarPorEmail(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().jogadores.includes(filtro.trim()))
    setListaDeReservasFiltrada(asd)
  }

  function filtrarPorHorario(HInicial, HFianl) {
    var horarioInicial = firebase.firestore.Timestamp.fromDate(HInicial)
    var horarioFinal = firebase.firestore.Timestamp.fromDate(HFianl)

    var asd = listaDeReservas.filter(elem => (elem.data().horaIncial >= horarioInicial && elem.data().horaIncial <= horarioFinal ))
    setListaDeReservasFiltrada(asd)
  }

  function filtraPorNumeroDeTelemovel(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().estado == filtro)
    setListaDeReservasFiltrada(asd)
  }

  function filtraPorEmail(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().estado == filtro)
    setListaDeReservasFiltrada(asd)
  }

  function resetAoFiltro() {
    setListaDeReservasFiltrada(listaDeReservas)
  }

  function reservasBloqueadas() {
    setListaDeReservasFiltrada(listaDeReservasBloqueadas)
  }

  useEffect(() => {
   
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">{t("Reservas")}</h6>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    filtraReservas("Confirmada")
                  }}
                >
                  Confirmadas
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    resetAoFiltro()
                  }}
                >
                  Todas
                </Button>
              </Col>
              {/* <Col md={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    reservasBloqueadas()
                  }}
                >
                  Campos Bloqueados
                </Button>
              </Col> */}
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col md={2}>
                <h5>Hora Inicial</h5>
              </Col>
              <Col md={3}>
                  <DateTimePicker 
                  disableCalendar={true}
                  disableClock={true}
                  onChange={value => {
                    console.log(value)
                    onChange(value)
                  }}
                  value={value}
                  >
                </DateTimePicker>
              </Col>
              <Col md={3}>
                  <DateTimePicker 
                  disableCalendar={true}
                  disableClock={true}
                  onChange={value => {
                    console.log(value)
                    onChangeFinal(value)
                  }}
                  value={valueFinal}
                  >
                </DateTimePicker>
              </Col>
              <Col md={2}>
                <Button color="primary"  onClick={() => {
                  getReservas("Great Padel Vila Verde", value, valueFinal)
                }} >
                  Pesquisar
                </Button>
              </Col>
            </Row>
           {/*  <Row  style={{ paddingTop: "20px" }}>
              <Col md={2}>
                <h5>Email</h5>
              </Col>
              <Col md={6}>
                <Input id="pesquEmail"></Input>
              </Col>
              <Col md={2}>
                <Button color="primary"  onClick={() => {
                  filtrarPorEmail( document.getElementById("pesquEmail").value)
                }} >
                  Pesquisar
                </Button>
              </Col>
            </Row> */}
            <Row style={{ paddingTop: "60px" }}>
              <Col className="list-title" md={3}>
                <h4>Reservada por</h4>
              </Col>
              <Col className="list-title" md={2}>
                <h4> Dia</h4>
              </Col>

              <Col className="list-title" md={2}>
                <h4> Início</h4>
              </Col>
              <Col className="list-title" md={1}>
                <h4> Duração</h4>
              </Col>
              <Col className="list-title" md={1}>
                <h4> Campo</h4>
              </Col>
              <Col className="list-title" md={1}>
                <h4> Estado</h4>
              </Col>
              <Col className="list-title" md={1}>
                <h4> Valor</h4>
              </Col>
            </Row>

            {listaDeReservasFiltrada.map((reservaSnap, index) => {
              const reserva = reservaSnap.data()
              var date = new Date()
              date = reserva.horaIncial.toDate()
              const horas = ("0" + date.getHours().toString()).slice(-2)
              const minutos = ("0" + date.getMinutes().toString()).slice(-2)
              return (
                <Row
                  style={{ alignItems: "start" }}
                  onClick={() => {
                    console.log("asdasd")
                  }}
                  key={index}
                  className={index % 2 == 0 ? "myList-even" : "myList-odd"}
                >
                  <Col md={3}>
                    <p>{reserva.jogador1estado.email}</p>
                  </Col>
                  <Col md={2}>
                    <p>{date.toLocaleDateString()}</p>
                  </Col>

                  <Col md={2}>
                     <td>{date.toISOString().substring(11,16) + "h"}</td>
                  </Col>
                  <Col md={1}>
                    <p>{reserva.duracao + ""}</p>
                  </Col>
                  <Col md={1}>
                    <p>{convertCamps[reserva.campo]}</p>
                  </Col>
                  <Col md={1}>
                    {reserva.estado == "Anulada" ? (
                      <p style={{ color: "red" }}>{reserva.estado}</p>
                    ) : reserva.estado == "Pendente" ? (
                      <p style={{ color: "indigo" }}>{reserva.estado}</p>
                    ) : (
                      <p style={{ color: "green" }}>{reserva.estado}</p>
                    )}
                  </Col>
                  <Col md={1}>
                    <p>{reserva.valorTotal + "€"}</p>
                  </Col>
                  <Col md={1}>
                    <Link to={"/reservas/" + reservaSnap.id}>
                      <ArrowRightCircle></ArrowRightCircle>
                    </Link>
                  </Col>
                </Row>
              )
            })}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PesquisarReservas
