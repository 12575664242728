import firebase from "firebase/app"
import "firebase/firestore"
import { Aula } from "models/aula"
import { Contrato } from "models/contrato"
import {
  aulasCollection,
  contratosCollection,
  historicoDeContratosCollection,
  usersCollection,
} from "services/consts"

export async function createContrato(contratoP) {
  try {
    const id = firebase.firestore().collection(contratosCollection).doc().id
    var contrato = new Contrato()
    contrato = contratoP
    const contratoJson = contrato.toFirebaseJson()
    console.log(contratoJson)

    await firebase
      .firestore()
      .collection(contratosCollection)
      .doc(id)
      .set(contrato.toFirebaseJson())

    return id
  } catch (e) {
    console.error(e)
    console.log("Falha a criar contrato")
    return null
  }
}

export async function adicionarContratoAoUser(contratoD, contratoId, userId) {
  var contratos = new Contrato()
  var contratos = contratoD
  try {
    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(userId)
      .set(
        {
          contrato: firebase.firestore.FieldValue.increment(
            contratos.numeroDeJogos
          ),
        },
        { merge: true }
      )

    return await historicoDeContratos(userId, contratoD, contratoId)
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function adicionarTipoDeContratosAoUser(userId, map) {
  try {
    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(userId)
      .set(
        {
          tipoDeContrato: map
        },
        { merge: true }
      )

      await firebase
      .firestore()
      .collection(usersCollection)
      .doc(userId).collection("historicoDeContratosPagos")
      .add(
       map,
       
      )
      return true
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function removeValoresDosContratosDoUser(
  userId,
  localizacao
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("historicoDeContratosPagos")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        localizacao: localizacao,
        notas: "Removeu o contrato em vigor"
      })
     
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update(
        {
          tipoDeContrato: firebase.firestore.FieldValue.delete(),
        },
        { merge: true }
      )

    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function historicoDeContratos(userID, contratoData, contratoID) {
  console.log("Historico de Contratos")
  console.log(userID)
  console.log(contratoData)
  console.log(contratoID)
  try {
    var contrato = new Contrato()
    var map = {}
    contrato = contratoData
    map = contrato.toFirebaseJson()
    map.contratoID = contratoID
    map.createdAt = firebase.firestore.Timestamp.fromDate(new Date())

    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(userID)
      .set(
        {
          historicoDeContratos: {
            [contratoID]: {
              expiresAt: map.expiresAt,
              diaDaSemana: map.diaDaSemana,
              numeroDeJogos: map.numeroDeJogos,
              periodo: map.periodo,
            },
          },
        },
        { merge: true }
      )

    return true
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * Cria contrato perioódico
 * Como funciona no mesmo formato que aulas serão gravados como aulas
 * do tipo -> Contrato
 * @param {Aula} newAula,
 */
export async function createControPeriodio(newAula) {
  var aula = new Aula()
  aula = newAula

  try {
    return firebase
      .firestore()
      .collection(aulasCollection)
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        weekDay: aula.weekDay,
        localizacao: aula.localizacao,
        horaInicial: aula.horaInicial,
        horaFinal: aula.horaFinal,
        diaInicial: firebase.firestore.Timestamp.froemDate(aula.diaInicial),
        diaFinal: firebase.firestore.Timestamp.fromDate(aula.diaFinal),
        professor: aula.professor,
        alunos: [],
        alunosData: {},
        nivel: aula.nivel,
        estado: aula.estado,
        nome: aula.nome,
        campos: aula.campos,
        tipo: aula.tipo,
        notas: "",
        isAtiva: aula.isAtiva,
      })
      .then(value => {
        return true
      })
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * Edita os valores dos contratos no clube
 * @param {*} clubeId 
 * @param {*} map 
 * @returns 
 */
export async function editaValoresDosContratos(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).set(
      {
        valoresDosContratos: map,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function removeValoresDosContratos(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).update(
      {
        valoresDosContratos: map,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function testeAddRemoveContratos() {
  try {

    await firebase.firestore().collection("users")
      .where("contrato", "!=", 0).get().then((value) => {
        value.docs.forEach(async (elem) => {
          await firebase.firestore().collection("users").doc(elem.id).set({
            contrato: 0,
          }, {merge: true});
        })
      })

    await firebase.firestore().collection("users")
        .orderBy("tipoDeContrato").get().then((value) => {
          value.docs.forEach(async (elem) => {
            const contrato = elem.data().tipoDeContrato;
            if (contrato.expiryAt.toDate() > new Date()) {
              console.log("Adiciona mais contratos a: ", elem.data().email);
              contrato.email = elem.data().email,
              contrato.createdAt =
              firebase.firestore.Timestamp.fromDate( new Date());
              await firebase.firestore().collection("users").doc(elem.id).set({
                contrato: parseInt(contrato.nrDeJogos),
              }, {merge: true});
              await firebase.firestore()
                  .collection("renovacoesDosContratos").add(
                      contrato,
                  );
            } 
          });
          return null;
        });
    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
}


