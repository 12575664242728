import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from "firebase/app"
import "firebase/firestore"
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { convertCamps } from 'services/consts';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom"

function HistoricoDePagamentos(props) {
    let { email,id } = useParams()
    const { t, i18n } = useTranslation()
    const [listaDeMbway, setlistaDeMbway] = useState([])
    const [listaMultibanco, setListaMultibanco] = useState([])

   async function getHistoricoDePagamentos() {
    var listaAUx = []
        try {
            await firebase.firestore().collection("pagamentosMBway")
            .where("email", "==", email)
            .where("estado", "==", "PAGO")
            .get().then((value) => {
            value.docs.forEach((elem) => {
              var json = {}
            json.metodo = "MBway"
            json.classe = elem.data().classe
            json.createdAt = elem.data().createdAt
            json.valor = elem.data().valor
            json.valorAAdicionar = elem.data().valorAAdicionar
            console.log(json)
            listaAUx.push(json)
            })
        }) 
       
          console.log("Pagamento de multibanco")
          console.log(id)

          await firebase.firestore().collection("pagamentosMultibanco")
          .where("userId", "==", id).orderBy("payment_datetime")
          .get().then((value) => {
          value.docs.forEach((elem) => {
            var json = {}
            json.metodo = "Multibanco"
            json.createdAt = elem.data().createdAt
            json.classe = "Carregamento " + elem.data().valor + "€"
            json.valor = elem.data().valor
            json.valorAAdicionar = elem.data().valorAAdicionar
            listaAUx.push(json)
          })
          
      }) 
      listaAUx.sort((a,b) => {
        if(a.createdAt.toDate() > b.createdAt.toDate()) {
            return -1 
        } else {
            return 1
        }
    })
      setlistaDeMbway(listaAUx)
      return
      } catch (error) {
          console.log(error)
          return null
      }
    } 

    async function getHistoricoDePagamentosMultibanco() {
      
      console.log(email)
  }
    

    useEffect(() => {
        getHistoricoDePagamentos() 
    }, [])

    const stylesTable = {
        "--bs-table-bg": "#ececf1",
      }

    return (  
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                  <div className="page-title-box">
                      <Row className="align-items-center">
                        <Col md={8}>
                            <h6 className="page-title">{t("Historico de Pagamentos")}</h6>
                        </Col>
                      </Row>
               { listaDeMbway.length == 0 ? <h3 style={{padding: "40px"}}>Sem Aulas compradas</h3> :
              <Row className="pt-5">
              <Table bordered striped responsive className="myList" style={stylesTable} >
                <thead>
                  <tr>
                    <th></th>
                    <th>Método</th>
                    <th>Tipo</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Valor em Saldo</th>
                  </tr>
                </thead>
            
                { listaDeMbway.map((elem,index) => {
                   let date = elem.createdAt.toDate()
                   return (
                     <tr
                       onClick={() => {console.log("asdasd")}}
                       key={index}
                     >
                       <td>{elem.metodo == "MBway" ? 
                       <img width={"60px"} style={{padding: "10px"}} src={"https://play-lh.googleusercontent.com/nDKhDELMEjag8qJ9aKAjfTSzWZKVg3tY2OZ-eo8Jp8hxYDgifCFQoNOqxDwTaAW-O8o"}></img>: 
                       <img width={"60px"} style={{padding: "10px"}} src={"https://seeklogo.com/images/M/multibanco-logo-A2C9EFA923-seeklogo.com.png"}></img>  }</td>
                       <td>{elem.metodo }</td>
                       <td>{elem.classe}</td>
                       <td>{date.toLocaleString( "pt-PT", { weekday: "long" }).split('-')[0] + ", " + date.toLocaleString().substring(0,10) }</td>
                       <td>{elem.valor + "€"}</td>
                       <td>{elem.valorAAdicionar+ "€"}</td>
                     </tr>
                   )
                })}   
            </Table>
            </Row>
            
               }
                 </div>
            </Container>
            </div>

        </React.Fragment>
    );
}

export default HistoricoDePagamentos;