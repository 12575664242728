import React, {useState } from "react"
import { useSelector } from "react-redux";
import { Button, Container, Row, Table } from "reactstrap"
import { selectclubeid } from "store/localizacao/clube_id_reducer";
import InsertEmail from "components/modals/staff/InsertEmail";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { selectRoles } from "store/localizacao/grupos_staff";
import { selectrotaspermitidas } from "store/utilizadores/rotas_permitidas_reducer";
import { selectsetuserroles } from "store/clube/user_roles_reducer";



function GruposStaff() {

    // Grupos vindo do REDUX
    const useRoles = useSelector(selectsetuserroles)
    const userRoles = JSON.parse(useRoles)
    const mapAualsV = Object.entries(userRoles)
    

    const [isOpen, setIsOpen] = useState(false);
    const [roleClicked, setRoleClicked] = useState(null);

    
    function toggle(role) {
        setRoleClicked(role);
        setIsOpen(!isOpen);
    }


    // Gestão da UI da Tabela
    const colSpanNome = 10;
    const colSpanAcoes = 2;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="page-title">Grupos de Staff</h6>
              </div>
              <div>
                <button hidden={true}
                  className="btn btn-primary dropdown-toggle waves-effect waves-light"
                  type="button"
                  disabled
                >
                  Adicionar novo Role
                </button>
              </div>
            </div>
          </div>
          <Row>
            <Table striped bordered responsive>
                <thead>
                    <tr>
                        <th colSpan={colSpanNome}>Nome</th>
                        <th colSpan={colSpanAcoes}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                {
                   mapAualsV.map((role, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={colSpanNome}>{role[0]}</td>
                        <td colSpan={colSpanAcoes} style={{display:"flex",gap:"5px"}}>
                          
                          <div className="">
                            <Link className="btn btn-primary" to={'/grupos_staff/' + role[0]}>Ver</Link>
                          </div>
                          <div className="">
                            <button
                              className="btn btn-secondary"
                              onClick={() => toggle(role[0])}
                            >
                              Adicionar
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }) 
                }


                </tbody>

            </Table>
           
          </Row>
        
        </Container>
      </div>

      {/* Modal */}
      {isOpen && (
        <InsertEmail 
          isOpen={isOpen} 
          role={roleClicked} 
          setIsOpen={setIsOpen}
        /> 
      )}
    </>
  )
}

export default GruposStaff
