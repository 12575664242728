import AdicionarCarregamentos from "components/modals/adicionar_carregamento"
import { PagamentoNumerario } from "models/pagamentos_numerario"
import React, { useState } from "react"
import { Check, Pencil } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
import { editaValoresDosCarregamentos } from "services/clube/clube_services"
import { checkIfUserExists } from "services/reservas/reservas_services"
import { adicionarValorAoBancoDeHoras } from "services/useres/jogadores_services"
import { selectcarregamentos } from "store/clube/carregamentos_reducer_services"
import { selectclubeid } from "store/localizacao/clube_id_reducer"
import { selectlocalizacao } from "store/localizacao/localizacao_reducer"

function AdicioanarBancoDeHoras() {
  const { t, i18n } = useTranslation()
  const clubId = useSelector(selectclubeid);
  const carregamentoVals = useSelector(selectcarregamentos)
  const carregamentoValsJson = JSON.parse(carregamentoVals)
  const mapCarregam = Object.entries(carregamentoValsJson)

  var localizacao = useSelector(selectlocalizacao)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={9}>
                <h6 className="page-title">{t("Carregamentos")}</h6> 
              </Col>
              <Col md={3}>
                <AdicionarCarregamentos></AdicionarCarregamentos>
              </Col>
            </Row>
          </div>
        </Container>
        <Row>
        {mapCarregam.map((elem,index) => {
          return <Col key={"key " + index} md={3} >
            <Card style={{ textAlign: "center" }}>
            <CardBody>
            <h3 >{elem[0]}</h3>
                <h5>{"Valor " + elem[1].preco + "€"}</h5>
                <p>{"Valor " + elem[1].desconto + "€"} </p>
                <h4 style={{ paddingBottom: "40px" }}>{"Recebe " + elem[1].precoFinal + "€"}</h4>
                <Row>
                  <Col md={6}>
                  <Button onClick={() => {
                    
                  }} color="primary">
                    Editar
                  </Button>
                </Col>
                <Col md={6}>
                  <Button  onClick={async () => {
                    delete carregamentoValsJson[elem[0]]

                    var res = await editaValoresDosCarregamentos(clubId,carregamentoValsJson );
                    if(res) {
                      alert("Valor Removido com suceesso")
                    } else {
                      alert("Erro a remover ")
                    }
                  }} color="danger">
                    Remover
                  </Button>
                </Col>
                </Row>
            </CardBody>
            </Card>
          </Col>
        })}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AdicioanarBancoDeHoras
