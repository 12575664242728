import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Container, Row, Table } from 'reactstrap';
import { selecthorarioDeFuncionamento } from 'store/clube/horario_de_funcionamento_reducer';

function HorarioDeFuncionamento() {
    const horarioDeFuncionamento = useSelector(selecthorarioDeFuncionamento)
    const horarioDeFuncionamentoJson = JSON.parse(horarioDeFuncionamento)
    const mapHorario = Object.entries(horarioDeFuncionamentoJson)

    const diasDaSemana = ["Domingo","Segunda","Terça","Quarta","Quinta","Sexta","Sábado",]

    const { t, i18n } = useTranslation()


    const stylesTable = {
      "--bs-table-bg": "#ececf1",
    }


    return (  
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <div className="page-title-box">

                  {/* Título */}
                  <Row className="align-items-center">
                    <Col md={10}>
                      <h6 className="page-title">{t("Horário de funcionamento ")}</h6>
                    </Col>
                  </Row>


                  {/* Tabela */}
                  <Row className="pt-2">
                    <Table bordered striped responsive className="myList" style={stylesTable}>
                      <thead>
                        <tr>
                          <th>Dia</th>
                          <th>Horas Bloqueadas</th>
                        </tr>
                      </thead>
                      <tbody>
                      {mapHorario.map((elem, index) => {
                        return (
                          <tr key={elem[1] + index}>
                            <td>{diasDaSemana[ parseInt( elem[0])]}</td>
                            <td>
                              {
                                elem[1].map((elem, index) => {
                                  return <strong>{elem}h<br/></strong>
                                })
                              }
                            </td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </Table>
                  </Row>
                  
                </div>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default HorarioDeFuncionamento;