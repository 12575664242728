import React, { useEffect, useState } from "react"
import { Pencil } from "react-bootstrap-icons"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

function EditarBancoDeHorasMOdal(props) {
  const [isOpen, setIsOpen] = useState(false)

  const [bancoDeHoras, setBancoDeHoras] = useState(props.bancoDeHoras)
  const [notas, setNotas] = useState("")
  const [nome, setNome] = useState("")

  function toggle() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setBancoDeHoras(props.bancoDeHoras)
  }, [])

  return (
    <React.Fragment>
      <Button color="transparent" onClick={() => toggle()}>
        <Pencil></Pencil>
      </Button>
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader
          toggle={() => {
            toggle()
          }}
        >
          Editar Banco de horas
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup style={{ paddingBottom: "40px" }}>
              <Row>
                <Col md={3}>
                  <Label>Valor Inicial: </Label>
                </Col>
                <Col md={9}>
                  <h4>{props.bancoDeHoras}</h4>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={{ paddingBottom: "20px" }}>
              <Row>
                <Col md={3}>
                  <Label>Novo valor</Label>
                </Col>
                <Col md={9}>
                  <Input
                    type="number"
                    value={bancoDeHoras}
                    id="bancoHorasID"
                    onChange={e => {
                      setBancoDeHoras(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <Label>Alterado Por</Label>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome}
                    onChange={e => {
                      setNome(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <Label style={{ paddingTop: "20px" }} for="exampleText">
              Razão da Alteração
            </Label>
            <Input
              type="textarea"
              name="text"
              value={notas}
              id="notasID"
              onChange={e => {
                setNotas(e.target.value)
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              console.log(notas)
              console.log(bancoDeHoras)
              console.log(document.getElementById("bancoHorasID").value)
              console.log(nome)
            }}
          >
            {" "}
            Alterar Valor{" "}
          </Button>
          <Button
            onClick={() => {
              toggle()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default EditarBancoDeHorasMOdal
