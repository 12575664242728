import { createSlice } from "@reduxjs/toolkit"

export const horarioDeFuncionamentoSlice = createSlice({
  name: "horarioDeFuncionamento",
  initialState: {
    value: "",
  },
  reducers: {
    sethorarioDeFuncionamento: (state, action) => {
      state.value = JSON.stringify(action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { sethorarioDeFuncionamento } = horarioDeFuncionamentoSlice.actions

export const selecthorarioDeFuncionamento = state => state.horarioDeFuncionamento.value

export default horarioDeFuncionamentoSlice.reducer
