
import React from 'react';
import { Container, Row,Button } from 'reactstrap';
import { convertCamps } from 'services/consts';
import { Link } from "react-router-dom"

function EnventComponent(props) {
    var style = { 
        fontSize: "10px", 
        padding: "1px",
        width: "100%",
        color:"#fff"
    }
    console.log(props.event)
    return (  
        <>
        <Link  key={props.event.title + props.event.professor + props.event.campo + "Link"} to={"/aulas/" + props.event.id}>
            <Container style={{background:props.event.color}}>
                <Row style={style}>
                    {props.event.title}
                </Row>
                <Row style={style}>
                    {props.event.professor} 
                </Row>
                <Row style={style}>
                    {props.event.campo.length == 0 ? null : convertCamps[props.event.campo[0]]}
                </Row>
            </Container>
        </Link>
        </>
    );
}

export default EnventComponent;