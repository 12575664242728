import firebase from "firebase/app"
import "firebase/firestore"
import { Treinador } from "models/treinador"
import { localizacaoCollection, treinadoresCollection } from "services/consts"

export async function getTreinadores(localizacao) {
  try {
    firebase
      .firestore()
      .collection(treinadoresCollection)
      .where("localizacao", "==", localizacao)
      .onSnapshot(docsSnaps => {
        docsSnaps.docs()
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function adicionarTreinarALocalizacao(treinador, localID) {
  var newTreinador = new Treinador()
  var newTreinador = treinador
  try {
    firebase
      .firestore()
      .collection(localizacaoCollection)
      .doc(localID)
      .set(
        {
          treinadores: firebase.firestore.FieldValue.arrayUnion({
            email: newTreinador.email,
            nome: newTreinador.nome,
          }),
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.error(error)
  }
}

export async function criarTreinadorNosTreinadores(treinador) {
  var newTreinador = new Treinador()
  var newTreinador = treinador
  var fbData = newTreinador.toFirebaseFormat()

  try {
    await firebase
      .firestore()
      .collection(treinadoresCollection)
      .doc()
      .set(fbData)
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function criarTreinador(treinador, localID) {
  var local = await adicionarTreinarALocalizacao(treinador, localID)
  console.log("treinador")
  console.log(treinador)
  var treina = await criarTreinadorNosTreinadores(treinador)
  if (local == true && treina == true) {
    return true
  } else {
    return null
  }
}

/**
 * Calcular o dinheiro que um treinador tem que receber durante x tempo
 * @param {*} localizacao 
 * @param {*} treinador 
 * @param {*} horaInicial 
 * @param {*} horaFinal 
 */
export async function faturacaoDosTreinadores(localizacao, professor, horaInicial, horaFinal) {
  try {
    let valorDasAulas = 0
    console.log("Patig 1")
    await firebase.firestore()
    .collection("historicoDeAulas")
    .where("aula.localizacao", "==", localizacao)
    .where("aula.professor", "==", professor)
    .where("createdAt", ">", horaInicial)
    .where("createdAt", "<", horaFinal).get().then((value) => {
      console.log("Patig 2")
      for (let index = 0; index < value.docs.length; index++) {
        const elem = value.docs[index]
        if(elem.data().valorDaAula != undefined){
          console.log(elem.data().valorDaAula)
        valorDasAulas = valorDasAulas + elem.data().valorDaAula
      }     
      }
    })
    return valorDasAulas
  } catch (error) {
    console.log(error)
    return null;
  }
}

export async function faturacaoDeTodosTreinadores(localizacao, horaInicial, horaFinal) {
  try {
    
    console.log("Patig 1")
    var res = await firebase.firestore()
    .collection("historicoDeAulas")
    .where("aula.localizacao", "==", localizacao)
    .where("createdAt", ">", horaInicial)
    .where("createdAt", "<", horaFinal).get().then((value) => {
        return value.docs;
    })
    
    return res
  } catch (error) {
    console.log(error)
    return null;
  }
}
