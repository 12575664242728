import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { Carregamento } from "models/carregamento"

export async function editaValoresDasAulas(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).set(
      {
        valoresDasAulas: map,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function removeValoresDasAulas(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).update(
      {
        valoresDasAulas: map,
      },
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function editarFaturaRefs(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).update(
      {
        faturasRef: map,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function backUp(mainDoc) {
  firebase
    .firestore()
    .collection("localizacao")
    .doc(mainDoc)
    .get()
    .then(value => {
      firebase.firestore().collection("localizacaoBck")
      .add({data: value.data(), createdAt: firebase.firestore.Timestamp.fromDate(new Date()) })
    })
}

export async function editaValoresDosCarregamentos(clubeId, map) {
  try {
     await firebase.firestore().collection("localizacao").doc(clubeId).update(
      {
        valoresDosCarregamentos: map,
      },
      { merge: true }
    ) 
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function adicionaClassEspecial(clubeId, nome,valor) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).set(
     {
      classesEspeciais: {[nome]: valor},
     },
     { merge: true }
   ) 
   return true
 } catch (error) {
   console.log(error)
   return null
 }
}

export async function removeClasseEspecial(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).update(
     {
      classesEspeciais: map,
     },
     { merge: true }
   ) 
   return true
 } catch (error) {
   console.log(error)
   return null
 }
}

export async function editaValoresDosContratos(clubeId, map) {
  try {
    await firebase.firestore().collection("localizacao").doc(clubeId).set(
      {
        valoresDosContratos: map,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function getVisoTourniqeuetes() {
  try {
   return await firebase.firestore().collection("viso").doc("reservas").get().then(value => {
      return value.data()
    })
  } catch (error) {
    console.log("error")
    return null;
  }
}

export async function registaUser(email,passwr,role, localizacao) {
  try {
    await firebase
    .auth()
    .createUserWithEmailAndPassword(email, passwr).then(async (value) => {
      if(value.user.uid != null) {
        await firebase.firestore().collection("users").doc(value.user.uid).set({
          "email" : email,
          "localizacao": localizacao,
          "user_role": role,
        })
      }
    })
    return true
  } catch (error) {
    console.log(error)
    return false;
  }
}

export async function addImagemToStorage(clubeId, fileName,file) {
  try {
    console.log(clubeId);
    const storage = firebase.storage();
    var storageRef = storage.ref();
    var path = clubeId + "/primeiraImagem/" + fileName
    var spaceRef = storageRef.child(path);
    return await spaceRef.put(file).then(async (snapshot) => {
      console.log('Uploaded a blob or file!');
      //"https://storage.googleapis.com/BUCKET_NAME/OBJECT_NAME"
      console.log(snapshot.ref.fullPath);
      console.log(snapshot.ref.bucket);
      console.log(snapshot.ref.getMetadata());
      return snapshot.ref.getDownloadURL();
    })
  } catch (error) {
      console.log(error)
      return null
  }
}

/**
 * Da fetch ao url de downlaod do backUp
 * @param {*} clubeId 
 * @param {*} fileName 
 * @param {*} file 
 * @returns 
 */
export async function getBackUpURL(clubeId, fileName,file) {
  try {
    console.log(clubeId);
    const storage = firebase.storage();
    var storageRef = storage.ref();
    var path = "/backups/100/teste"
    var spaceRef = storageRef.child(path);
    storageRef.child(path).getDownloadURL().then((value) => {
      console.log(value);
    })
  } catch (error) {
      console.log(error)
      return null
  }
}

export async function imagemDaApp(localId, imagemUrl, isAtiva) {

  await firebase.firestore().collection("localizacao").doc(localId).set({
      "imagemDaApp": {
        imagem: imagemUrl,
        isAtiva: isAtiva,
      }
  }, {merge: true})

}

export async function imagemDaAppEstado(localId, isAtiva) {

  try {
    await firebase.firestore().collection("localizacao").doc(localId).set({
      "imagemDaApp": {
        isAtiva: isAtiva,
      }
  }, {merge: true})
  return true
  } catch (error) {
    console.log(error)
    return null
  }
  
  

}

export async function logOut() {
  try {
    await firebase
    .auth().signOut()
    
    return true
  } catch (error) {
    console.log(error)
    return false;
  }
}

export async function addEmailImagemToStorage(clubeId, fileName,file) {
  try {
    console.log(clubeId);
    const storage = firebase.storage();
    var storageRef = storage.ref();
    var path = clubeId + "/imagemParaEmail/" + fileName
    var spaceRef = storageRef.child(path);
    return await spaceRef.put(file).then(async (snapshot) => {
      console.log('Uploaded a blob or file!');
      //"https://storage.googleapis.com/BUCKET_NAME/OBJECT_NAME"
      console.log(snapshot.ref.fullPath);
      console.log(snapshot.ref.bucket);
      console.log(snapshot.ref.getMetadata());
      return snapshot.ref.getDownloadURL();
    })
  } catch (error) {
      console.log(error)
      return null
  }
}

//------------------ Dashboar services ----------------

export async function calculaReservasEbloqueiosDiarios(docID, localizacao, horaInicial, horaFinal, dashDocId ) {
  try {
    const listaDeReservas = []
    const listaDeContratos = []
    const listaDeDuracaoDasReservas = []
    const listaDeDuracaoDosContratos = []
    const listaDeAulas = []
    let duracaoDaReservas = 0;
    let valorDasReservas = 0;
    let numeroDeAulas = 0;
    let duracaoDeContratos = 0;
    let duracaoDasAulas = 0;

    await firebase.firestore().collection("reservas")
          .where("localizacao", "==", localizacao)
          .where("horaIncial", ">", horaInicial)
          .where("horaIncial", "<", horaFinal).get().then((value) => {
            value.forEach((elem) => {
              if (elem.data().bloqueada == true) {
                 
              } else {
                if (elem.data().estado == "Confirmada" &&  elem.data().jogador1estado.tipo != "contrato") {
                  listaDeReservas.push(elem.data())
                  listaDeDuracaoDasReservas.push(elem.data().duracao)
                  duracaoDaReservas = duracaoDaReservas + parseFloat( elem.data().duracao)
                  valorDasReservas = valorDasReservas + parseFloat(elem.data().valorTotalPago)
                } else if (elem.data().estado == "Confirmada") {
                  listaDeContratos.push(elem.data())
                  listaDeDuracaoDosContratos.push(elem.data().duracao)
                  duracaoDeContratos = duracaoDeContratos + parseFloat( elem.data().duracao)
                }
              }
            }) 
          })
      await firebase.firestore().collection("historicoDeAulas")
          .where("aula.localizacao", "==", localizacao)
          .where("createdAt", ">", horaInicial)
          .where("createdAt", "<", horaFinal)
          .get().then((value) => {
            value.forEach((elem) => {
              if(elem.data().aula.isAtiva) {
                listaDeAulas.push(elem)
              var duracaoAux = 0
              var horasInicais = parseFloat(elem.data().aula.horaInicial.substring(0, 2));
              var minutosInicais = parseFloat(elem.data().aula.horaInicial.substring(3, 5));
              var horasFinais = parseFloat(elem.data().aula.horaFinal.substring(0, 2));
              var minutosFinais =  parseFloat(elem.data().aula.horaFinal.substring(3, 5));
              duracaoAux = (horasFinais - horasInicais) * 60 + (minutosFinais - minutosInicais)
              duracaoDasAulas = duracaoDasAulas + duracaoAux;
            }
            }) 
            numeroDeAulas = listaDeAulas.length;
          })
          
    const map = {}
    map.listaDeDuracaoDasReservas = listaDeDuracaoDasReservas
    map.listaDeDuracaoDosContratos = listaDeDuracaoDosContratos
    /* map.dia = firebase.firestore.Timestamp.fromDate(horaInicial)
    map.numeroDeAulas = numeroDeAulas;
    map.duracaoDeContratos = duracaoDeContratos;
    map.numeroDeContratos = listaDeContratos.length;
    map.numeroDeReservas = listaDeReservas.length;
    map.duracaoDaReservas = duracaoDaReservas;
    map.duracaoDasAulas = duracaoDasAulas;
    map.valorDasReservas = valorDasReservas;
    map.duracaoTotal = duracaoDaReservas + duracaoDeContratos + duracaoDasAulas; */

    await firebase.firestore()
            .collection("localizacao")
            .doc(docID)
            .collection("dashboard")
            .doc(dashDocId).set(map, {merge: true})
    return map
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function aulasValor( dataInical, dataFinal,localizacao) {
  try {
    var respo = []
    var respoAux = []
    var respoPax = []
    console.log(dataInical)
    console.log(dataFinal)
    await firebase.firestore()
      .collection("users")
      .where("aulasAcamida.localizacao", "==", localizacao)
      .where("aulasAcamida.createdAt", ">", dataInical)
      .where("aulasAcamida.createdAt", "<", dataFinal)
      .get().then((value) => {
        respoAux = value.docs
      })
      console.log("Valores")
      respoAux.forEach((elem) => {
        var mapAux = {}
        mapAux.refPath = elem.ref.path
        mapAux.valor = elem.data().aulasAcamida.valor
        mapAux.numeroDeAulas = elem.data().aulasAcamida.numeroDeAulas
        mapAux.tipo = elem.data().aulasAcamida.tipo
        mapAux.classe = "Academia"
        mapAux.fidelizacao = elem.data().aulasAcamida.fidelizacao
        respo.push(mapAux)
      }) 
      await firebase.firestore()
      .collectionGroup("historicoDeAulasPax")
      .where("localizacao", "==", localizacao)
      .where("createdAt", ">", dataInical)
      .where("createdAt", "<", dataFinal)
      .get().then((value) => {
        respoPax = value.docs
      })
      
      respoPax.forEach((elem) => {
        var mapAux = {}
        mapAux.refPath = elem.ref.path
        mapAux.valor = elem.data().valor
        mapAux.tipo = elem.data().tipo
        mapAux.numeroDeAulasAAdicionar = elem.data().numeroDeAulasAAdicionar
        mapAux.classe = "Pax"
        respo.push(mapAux)
      })
      console.log(respo)
    return respo;
  } catch (error) {
    console.log(error)
    return null;
  }
}


export async function gravaPrecoDeAulas( clubeID, docDashID, map) {
  try {
    await firebase.firestore()
      .collection("localizacao")
      .doc(clubeID)
      .collection("dashboard").doc(docDashID).set(
        map
      ,{merge: true})
  } catch (error) {
    return null;
  }
}




