import React, { useEffect, useState } from "react"
import { Check, Trash2 } from "react-bootstrap-icons"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import firebase from "firebase/app"
import "firebase/firestore"
import {
  adicionarAlunoEData,
  adicionarAlunoEDataNovoPagamento,
  removerAlunoDaAula,
} from "services/aulas/aulas_services"
import QRCode from "qrcode"
import { useSelector } from "react-redux"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"
import NovoPagamentoAulas from "./novo_pagamento_aulas"
import DatePicker from "react-date-picker"
import { checkIfUserExists } from "services/reservas/reservas_services"

function InfoAlunosModal(props) {
  console.log(props.alunoData)

  function getRandomQrcode(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const [experimental, setExperimental] = useState(false)
  const [tipoGeral, setTipoGeral] = useState(null)
  const [loadingNP, setLoadingNP] = useState(false)
  const [email, setEmail] = useState(props.email)
  console.log(email)
  const [tel, setTel] = useState(null)
  const [cc, setCC] = useState(null)
  const [nome, setNome] = useState(null)
  const [qrCode, setQrCode] = useState(null)
  const [qrCodeValue, setQrCodeValue] = useState(null)
  const [fidelizacao, setFidelizacao] = useState("Não")
  const [academia, setAcademia] = useState("Não")
  const [tipoDeAula, setTipoDeAula] = useState("")
  const [valor, setValor] = useState(0)
  const [aulasPax, setAulasPax] = useState(0)

  const [colorBTN1, setColorBTN1] = useState("secondary")

  const tipoDeAulasStringfied = useSelector(selectaulasvalores)
  const tipoDeAulasJson = JSON.parse(tipoDeAulasStringfied)
  const mapAualsV = [" "].concat(Object.entries(tipoDeAulasJson))

  const sortedList = mapAualsV.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })

  function addDays(date, days) {
    return new Date(date.getTime() + days * 60000 * 60 * 24)
  }

  const [nextDate, setnextDate] = useState(null)

  /* var alunoData = props.alunoData[email] */

  async function emailCheck(email, nomeId, ccId) {
    var primeiroNome= ""
    var ultimoNome= ""
    var isEmail1 = await checkIfUserExists(email)

    if (Object.keys(isEmail1).length == 0) {
      alert("O email não está registado")
      return false
    }
    if(typeof isEmail1.numeroTele != "undefined") {
      setTel(isEmail1.numeroTele)
    }

    if (typeof isEmail1.primeiroNome != "undefined") {
      primeiroNome = isEmail1.primeiroNome + " "
    }
    if (typeof isEmail1.ultimoNome != "undefined") {
      ultimoNome = isEmail1.ultimoNome
    }
    if (typeof isEmail1.numeroDoCC != "undefined") {
      setCC(isEmail1.numeroDoCC)
    } else {
      document.getElementById(ccId).value = null
    }
    setNome(primeiroNome + ultimoNome)

    if (typeof isEmail1.aulasPax != "undefined") {
      setAulasPax(isEmail1.aulasPax)
    }

    if (
      typeof isEmail1.primeiroNome != "undefined" &&
      typeof isEmail1.ultimoNome != "undefined" &&
      typeof isEmail1.numeroDoCC != "undefined"
    ) {
      return true
    } else {
      return false
    }
  }

  const [temp1, setTemp1] = useState("")
  const [temp2, setTemp2] = useState("")
  const [temp3, setTemp3] = useState("")

  function gravaValoresTemp() {
    setTemp1(tipoDeAula)
    setTemp3(nextDate)
  }

  function resetValoresQUandoCancela() {
    setTipoDeAula(temp1)
    setnextDate(temp3)
  }

  function gerarQRCode(qrCode) {
    QRCode.toDataURL(qrCode)
      .then(url => {
        console.log(url)
        setQrCode(url)
      })
      .catch(err => {
        console.error(err)
      })
  }

  async function patig() {
    setTel(props.alunoData.tel)
    setCC(props.alunoData.cc)
    setNome(props.alunoData.nome)

    if (typeof props.alunoData.fidelizacao != "undefined") {
      setFidelizacao(props.alunoData.fidelizacao)
    }

    if (typeof props.alunoData.academia != "undefined") {
      setAcademia(props.alunoData.academia)
    }

    if (typeof props.alunoData.tipoDeAula != "undefined") {
      setTipoDeAula(props.alunoData.tipoDeAula)
    }

    if (typeof props.alunoData.valor != "undefined") {
      setValor(props.alunoData.valor)
    }

    if (typeof props.alunoData.qrCode != "undefined") {
      gerarQRCode(props.alunoData.qrCode.toString())
      setQrCodeValue(props.alunoData.qrCode)
    }
    if (typeof props.alunoData.proxPag != "undefined") {
      setnextDate(props.alunoData.proxPag.toDate())
    }
    await checkIfUserExists(props.email).then(value => {
      if (typeof value.aulasPax != "undefined") {
        setAulasPax(value.aulasPax)
      }
    })
  }

  useEffect(() => {
    patig()
    checkIfUserExists(props.email).then(value => {
      if (typeof value.aulasPax != "undefined") {
        setAulasPax(value.aulasPax)
      }
    })
  }, [])

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenNovoPag, setIsOpenNovoPag] = useState(false)
  console.log("asdasdadsdsa")
  console.log(tipoDeAula)
  return (
    <React.Fragment>
      <Row>
        <Col hidden={props.tipoDeAula == "PAX" ? false : true} md={3}>
          {aulasPax}
        </Col>
        <Col md={9}>
          <Button
            color="primary"
            onClick={async () => {
              await patig()
              if (props.aula != null) {
                if (typeof props.aula.tipo != "undefined") {
                  if (props.aula.tipo == "Experimental") {
                    setExperimental(true)
                  }
                }
              }
              setIsOpen(!isOpen)
            }}
          >
            Info
          </Button>
        </Col>
      </Row>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          {props.titulo}
        </ModalHeader>
        <ModalBody>
          <Form style={{ paddingBottom: "20px" }}>
            
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={9}>
                 {email}
                    
                </Col>
                {/* <Col md={2}>
                  <Button
                    id="ckeckButton1"
                    color={colorBTN1}
                    onClick={async () => {
                      var check = await emailCheck(
                        document.getElementById("emailId").value,
                        "nomeId",
                        "ccId"
                      )
                      if (check) {
                        setColorBTN1("primary")
                      } else {
                        setColorBTN1("secondary")
                      }
                    }}
                  >
                    <Check></Check>
                  </Button>
                </Col> */}
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome}
                    onChange={e => setNome(e.target.value)}
                    type="text"
                    name="nome"
                    id="nomeId"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Tel</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={tel}
                    onChange={e => setTel(e.target.value)}
                    type="number"
                    name="tel"
                    id="telId"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc}
                    onChange={e => setCC(e.target.value)}
                    type="cc"
                    name="cc"
                    id="ccId"
                  />
                </Col>
              </Row>
            </FormGroup>
            {/* <FormGroup hidden={academia == "Não" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Fidelização</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={fidelizacao}
                    onChange={e => setFidelizacao(e.target.value)}
                    type="select"
                    style={
                      fidelizacao == "Não"
                        ? { color: "red" }
                        : { color: "green" }
                    }
                  >
                    <option>Não</option>
                    <option>Sim</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup> */}     
            <FormGroup style={{ paddingBottom: "0px", paddingTop: "10px" }}>
              <Row>
                <Col md={3}>
                  <p>QrCode Nr</p>
                </Col>
                <Col md={9}>
                  <p>
                  {qrCodeValue}
                   
                  </p>
                </Col>
              </Row>
            </FormGroup>      
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>QrCode</p>
                </Col>
                <Col md={9}>
                  <p>
                    {qrCode != null ? (
                      <img src={qrCode} alt="qrCode"></img>
                    ) : null}
                  </p>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        <Button
            onClick={async () => {
              var listaAlunosAux = [].concat(props.aula.alunos)
              var jsonAlunosDataAux = props.aula.alunoData

              const index = listaAlunosAux.indexOf(email)
              const x = listaAlunosAux.splice(index, 1)

              delete jsonAlunosDataAux[email]

              console.log(`myArray values: ${listaAlunosAux}`)
              console.log(jsonAlunosDataAux)

              await removerAlunoDaAula(
                props.aulaId,
                listaAlunosAux,
                jsonAlunosDataAux,
                email
              )

              var data = {}
              data.email = email
              data.cc = cc
              data.nome = nome
              data.tel = tel
              data.qrCode = qrCodeValue
              /* data.fidelizacao = academia == "Não" ? null : fidelizacao
              data.academia = academia
              data.tipoDeAula = academia == "Não" ? null : tipoDeAula
              data.valor =
                academia == "Não" ? null : tipoDeAulasJson[tipoDeAula]
              data.proxPag =
                academia == "Não"
                  ? firebase.firestore.Timestamp.fromDate(new Date())
                  : firebase.firestore.Timestamp.fromDate(nextDate) */

              await adicionarAlunoEData(email, data, props.aulaId).then(
                value => {
                  if (value) {
                    alert("Dados atualizados com sucesso")
                    setIsOpen(!isOpen)
                  } else {
                    alert("Erro ao atualizar dados")
                    setIsOpen(!isOpen)
                  }
                }
              )
            }}
            color="primary"
          >
            Editar Dados
          </Button>
          {/*  <Button color="primary" onClick={async () => {

          }}>
            Enviar QRcode
          </Button> */}
          <Button
            color="danger"
            onClick={async () => {
              var listaAlunosAux = [].concat(props.aula.alunos)
              var jsonAlunosDataAux = props.aula.alunoData

              const index = listaAlunosAux.indexOf(email)
              const x = listaAlunosAux.splice(index, 1)

              delete jsonAlunosDataAux[email]

              console.log(props.aula)
              console.log(`myArray values: ${listaAlunosAux}`)
              console.log(jsonAlunosDataAux)

              var res = await removerAlunoDaAula(
                props.aulaId,
                listaAlunosAux,
                jsonAlunosDataAux,
                email
              )
              if (res) {
                alert("Aluno Removido com sucesso")
                setIsOpen(!isOpen)
              } else {
                alert("Erro a remover aluno")
                setIsOpen(!isOpen)
              }
            }}
          >
            Remover Aluno
          </Button>

          <Button onClick={() => setIsOpen(!isOpen)}>Voltar</Button>
        </ModalFooter>
        
      </Modal>
    </React.Fragment>
  )
}

export default InfoAlunosModal
