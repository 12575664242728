import firebase from "firebase/app"
import "firebase/firestore"
import { map, merge } from "lodash"
import { Aula } from "models/aula"
import {
  aulasCollection,
  localizacaoCollection,
  reservasCollection,
} from "services/consts"

export async function verListaDeAulas(localizacao) {
  try {
    return firebase
      .firestore()
      .collection(aulasCollection)
      .where("localizacao", "==", localizacao)
      .onSnapshot(docsSnap => {
        return docsSnap.docs
      })
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function getAulaById(docId) {
  try {
    return firebase
      .firestore()
      .collection(aulasCollection)
      .doc(docId)
      .get()
      .then(value => {
        return value.data()
      })
  } catch (error) {
    console.error(error)
    return null
  }
}



export async function createAula(newAula) {
  var aula = new Aula()
  aula = newAula

  try {
    return await firebase
      .firestore()
      .collection(aulasCollection)
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        weekDay: aula.weekDay,
        localizacao: aula.localizacao,
        horaInicial: aula.horaInicial,
        horaFinal: aula.horaFinal,
        diaInicial: firebase.firestore.Timestamp.fromDate(aula.diaInicial),
        diaFinal: firebase.firestore.Timestamp.fromDate(aula.diaFinal),
        professor: aula.professor,
        alunos: typeof aula.alunos == "undefined" ? [] : aula.alunos,
        alunosData: typeof aula.alunos == "undefined" ? [] : aula.alunosData,
        alunoData: {},
        expiryAt: typeof aula.expiryAt == "undefined" ? null : aula.expiryAt,
        nivel: aula.nivel,
        estado: aula.estado,
        nome: aula.nome,
        campos: aula.campos,
        tipo: aula.tipo,
        notas: typeof aula.notas == "undefined" ? "" : aula.notas == null ? "" : aula.notas,
        isAtiva: aula.isAtiva,
        valor: typeof aula.valor == "undefined" ? null : aula.valor,
        criadaPor: typeof aula.criadaPor == "undefined" ? null: aula.criadaPor,
        editadaPor: typeof aula.editadaPor == "undefined" ? [] : aula.editadaPor,
      })
      .then(value => {
        return true
      })
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function updateAula(newAula, aulaId) {
  var aula = new Aula()
  aula = newAula

  try {
    return firebase
      .firestore()
      .collection(aulasCollection)
      .doc(aulaId)
      .set(
        {
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          weekDay: aula.weekDay,
          localizacao: aula.localizacao,
          horaInicial: aula.horaInicial,
          horaFinal: aula.horaFinal,
          diaInicial:
            aula.diaInicial == null
              ? null
              : firebase.firestore.Timestamp.fromDate(aula.diaInicial),
          diaFinal:
            aula.diaInicial == null
              ? null
              : firebase.firestore.Timestamp.fromDate(aula.diaFinal),
          professor: aula.professor,
          nivel: aula.nivel,
          estado: aula.estado,
          nome: aula.nome,
          campos: aula.campos,
          notas: aula.notas,
          isAtiva: aula.isAtiva,
          editadaPor: firebase.firestore.FieldValue.arrayUnion(aula.editadaPor)
        },
        { merge: true }
      )
      .then(value => {
        return true
      })
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function updateContratoPeriodico(contratoID, data) {
  console.log("Dentro do Update Contrato Periodio")
  console.log(contratoID)
  console.log(data)
   try {
    firebase.firestore().collection(aulasCollection).doc(contratoID).update({
        alunos: data.alunos,
        alunosData: data.alunosData,
        notas: data.notas,
        campos: data.campos,
        expiryAt: firebase.firestore.Timestamp.fromDate(data.expiryAt),
        isAtiva: data.isAtiva,
        horaFinal: data.horaFinal,
        horaInicial: data.horaInicial,
        weekDay: data.weekDay,
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  } 

}

export async function removerAlunoDaAula(aulaID, alunos, alunosData,email) {
  try {
    await firebase.firestore().collection(aulasCollection).doc(aulaID).update({
      editadaPor: firebase.firestore.FieldValue.arrayUnion({email: JSON.parse(localStorage.getItem("authUser")).email, date: firebase.firestore.Timestamp.fromDate(new Date()), edicao: "Removeu o jogador " + email}),
      alunos: alunos,
      alunoData: alunosData,

    })
    return true
  } catch (error) {
    print(error)
    return false
  }
}

export async function adicinarAlunosAAula(usersID, aulaID) {
  console.log(usersID)
  console.log(aulaID)
  try {
    await firebase
      .firestore()
      .collection(aulasCollection)
      .doc(aulaID)
      .set(
        {
          editadaPor: firebase.firestore.FieldValue.arrayUnion({email: JSON.parse(localStorage.getItem("authUser")).email, date: firebase.firestore.Timestamp.fromDate(new Date())}),
          alunos: firebase.firestore.FieldValue.arrayUnion(...usersID),
        },
        { merge: true }
      )
    
    return true
  } catch (e) {
    console.error(e)
    return null
  }
}

function getRandomQrcode(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

export async function adicionarAlunoEData(email, alunoData, aulaID) {
  console.log(email)
  console.log(aulaID)
  var qrCode = getRandomQrcode(1, 16777215)

  try {
    await firebase
      .firestore()
      .collection(aulasCollection)
      .doc(aulaID)
      .set(
        {
          editadaPor: firebase.firestore.FieldValue.arrayUnion({email: JSON.parse(localStorage.getItem("authUser")).email, date: firebase.firestore.Timestamp.fromDate(new Date()), edicao: "Adicionou o jogador " + email  }),
          alunos: firebase.firestore.FieldValue.arrayUnion(email),
          alunoData: {
            [email]: {
              nome: alunoData.nome,
              cc: alunoData.cc,
              tel: alunoData.tel,
              qrCode: alunoData.qrCode,
            },
          },
        },
        { merge: true }
      )
    return true
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function adicionarAlunoEDataNovoPagamento(
  email,
  alunoData,
  aulaID
) {
  try {
    await firebase
      .firestore()
      .collection(aulasCollection)
      .doc(aulaID)
      .set(
        {
          editadaPor: firebase.firestore.FieldValue.arrayUnion({email: JSON.parse(localStorage.getItem("authUser")).email, date: firebase.firestore.Timestamp.fromDate(new Date())}),
          alunos: firebase.firestore.FieldValue.arrayUnion(email),
          alunoData: {
            [email]: {
              tipoDeAula: alunoData.tipoDeAula,
              valor: alunoData.valor,
              proxPag: alunoData.proxPag,
            },
          },
        },
        { merge: true }
      )
    return true
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function adicinarAlunosPAXAAula(usersID, aulaID) {
  console.log(usersID)
  console.log(aulaID)
  try {
    await firebase
      .firestore()
      .collection(aulasCollection)
      .doc(aulaID)
      .set(
        {
          editadaPor: firebase.firestore.FieldValue.arrayUnion({email: JSON.parse(localStorage.getItem("authUser")).email, date: firebase.firestore.Timestamp.fromDate(new Date())}),
          alunosPAX: firebase.firestore.FieldValue.arrayUnion(...usersID),
        },
        { merge: true }
      )
    return true
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function removeAula(aulaID) {
  try {
    await firebase.firestore().collection(aulasCollection).doc(aulaID).delete()
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function gravaAulaNoHistoricoERemove(id) {

  var elem = await firebase.firestore().collection("aulas").doc(id).get();
 
  await firebase.firestore()
  .collection("historicoDeAulas").add({
    classe: "Experimental",
    createdAt: firebase.firestore
        .Timestamp.fromDate(new Date()),
    aula: elem.data(),
  });
  if (typeof elem.data().alunoData != "undefined") {
  for (const elemnt in elem.data().alunoData) {
    if (elemnt != null ) {
      await firebase.firestore().collection("users")
          .where("email", "==", elemnt)
          .get().then(async (userVls) => {
            await firebase.firestore().collection("users")
                .doc(userVls.docs.at(0).id).set({
                  // aulaExperimental
                  aulaExperimental: true,
                }, {merge: true});
          });
    }
  }
  }
  await firebase.firestore()
    .collection("aulas")
    .doc(elem.id).delete();
}

export async function recuperaAula(id) {
  /* 
LqEj8PwfEHMdxJie4FVK
 */

  var elem = await firebase.firestore()
  .collection("historicoDeAulas").doc("fd1USl0NNSwcARvdHmN6").get();

  console.log(elem.data())
  try {
    await firebase.firestore().collection("aulas").doc("846HxD232PdEasa0bbDa").set(
      {
        alunoData: elem.data().aula.alunoData,
        alunos: elem.data().aula.alunos
      }
    ) 
  } catch (error) {
    console.log(error)
  }
 
  
}

export async function checkaNrAulasAcademia(email,localizacao) {
  try {
    var elem = await firebase.firestore()
    .collection("aulas")
    .where("localizacao", "==", localizacao)
    .where("tipo", "==", "Academia")
    .where("alunos", "array-contains",email).get()

    return elem.docs.length
  } catch (error) {
    console.log(error)
    return null;
    
  }

}

export async function checkaAsAulasAcademia(email,localizacao) {
  try {
    var elem = await firebase.firestore()
    .collection("aulas")
    .where("localizacao", "==", localizacao)
    .where("tipo", "==", "Academia")
    .where("alunos", "array-contains",email).get()
    var listaAux = []

    for (let index = 0; index < elem.docs.length; index++) {
       listaAux.push(elem.docs[index].data().nome )       
    }

    return listaAux
  } catch (error) {
    console.log(error)
    return null;
    
  }

}

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes*60000);
}

export async function testeDOEmailDasExpire() {
  var date = new Date() 
  var date1 = addMinutes(new Date(), 1440);
  try {
    firebase.firestore().collection("users")
    .where("aulasAcamida.expiryAt", ">=", date )
    .where("aulasAcamida.expiryAt", "<=", addMinutes(new Date(), 10440) ).get().then((value) => {
      console.log("patig")
      for (let index = 0; index < value.docs.length; index++) {
       console.log(value.docs[index].data()  )
        
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export async function testeDOEmailDasExpire2() {
  const date = addMinutes(new Date(), 1440*1);
  const date1 = addMinutes(new Date(), 1440*3);
  console.log("Data Inical: " + date);
  console.log("Data Final: " + date1);
  try {
    let mensage = "Jogadores com fidelização | contrato a renovar em 3 dias";
    let mensageSemFid = "Jogadores sem fidelização | contrato a renovar hoje";
    console.log("BP1");
    await firebase.firestore().collection("users")
        .where("aulasAcamida.expiryAt", ">=", date )
        .where("aulasAcamida.expiryAt", "<=", date1 ).get().then((value) => {
          console.log("BP2");
          console.log("patig");
          const listaAuxFidel = [];
          
          for (let index = 0; index < value.docs.length; index++) {
            console.log(value.docs.at(index).data().email);
            if(value.docs.at(index).data().aulasAcamida.fidelizacao == "Sim"){
              listaAuxFidel.push(value.docs.at(index).data().email);
            } 
          }
          
          for (let index = 0; index < listaAuxFidel.length; index++) {
            mensage = mensage + "<p>" + listaAuxFidel.at(index) + "</p>";
          }
          console.log(mensage)
         /*  enviarEmailWithoutAttach("manuelbarros@macrobiiz.com",
              mensage, "Contratos Academia com fidelização" ); */
        });
      await firebase.firestore().collection("users")
        .where("aulasAcamida.expiryAt", ">=", new Date() )
        .where("aulasAcamida.expiryAt", "<=", date ).get().then((value) => {
          console.log("BP2");
          console.log("patig");
          
          const listaAuxSemFidel = [];
          for (let index = 0; index < value.docs.length; index++) {
            console.log(value.docs.at(index).data().email);
            if(value.docs.at(index).data().aulasAcamida.fidelizacao == "Não"){
              listaAuxSemFidel.push(value.docs.at(index).data().email);
            } 
          }

          for (let index = 0; index < listaAuxSemFidel.length; index++) {
            mensageSemFid = mensageSemFid + "<p>" + listaAuxSemFidel.at(index) + "</p>";
          }
          console.log(mensageSemFid)
         /*  enviarEmailWithoutAttach("manuelbarros@macrobiiz.com",
              mensage, "Contratos Academia com fidelização" ); */
        });
  } catch (error) {
    console.log(error);
  }
}

/**
 * email das Expire
 */
async function enviarEmailWithoutAttach(to, message, assunto) {
  try {
    await firebase.firestore().collection("mail").add({
      to: to,
      message: {
        subject: assunto,
        html: message,
      },
    });
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function enviaEMailDeAniversario() {
  const date = new Date()
  const dia = ("0" + date.getDate()).slice(-2);
  const mes = ("0" + (date.getMonth() )).slice(-2);
  const queryDate = mes + dia;
  try {
    await firebase.firestore().collection("users")
        .where("diaMesNasceu", "==", queryDate )
        .get().then(async (value) => {
          for (let index = 0; index < value.docs.length; index++) {
            console.log(value.docs.at(index).data().primeiroNome);
            let primeiroNome = null;
            let ultimoNome = null;
            if (typeof value.docs.at(index).data().primeiroNome != "undefined") {
              primeiroNome = value.docs.at(index).data().primeiroNome;
            }
            if (typeof value.docs.at(index).data().ultimoNome != "undefined" ) {
              ultimoNome = value.docs.at(index).data().ultimoNome;
            }
            const nome = (primeiroNome == null ||
              ultimoNome == null ? "" : ( primeiroNome + " " + ultimoNome));

            const mensage = "<p>Olá " + nome + "</p>" +
              "<p>A Great Padel deseja-te um feliz aniversário e tem uma"+
              " surpresa para ti!<br> " +
              "Durante os próximos 8 dias, vem jogar ao Great Padel" +
              " e apresenta " +
              "este e-mail na receção e vais receber no teu banco de horas " +
              "10% da tua reserva para gastares numa próxima vez. </p><br>" +
              "Vemo-nos em breve e bons jogos!<br><br><br>" + 
              "Nota: pedimos que se faça acompanhar do seu " + 
              "cartão de cidadão quando apresentar o e-mail na receção.";

            await enviarEmailWithoutAttach( value.docs.at(index).data().email.trim(),
                mensage, (nome == "" ? "Atleta" : nome) +", parabéns pelo teu aniversário!" );
            console.log("Email de parabens enviado para:", value.docs.at(index).data().email.trim())
          }
        });
  } catch (error) {
    console.log(error);
  }
} 


export async function historicoDeAulasFunc(localizacao) {

  try {
    return firebase.firestore().collection("historicoDeAulas").where("aula.localizacao", "==", localizacao).get().then((value) => {
      return value.docs
    })
  } catch (error) {
    console.log(error)
    return null;
  }

}

export async function historicoDeAulasDate(localizacao, dataInicial, dataFInal) {

  try {
    return firebase.firestore().collection("historicoDeAulas")
    .where("aula.localizacao", "==", localizacao)
    .where("createdAt", "<=", dataFInal )
    .where("createdAt", ">=", dataInicial)
    .get().then((value) => {
      return value.docs
    })
  } catch (error) {
    console.log(error)
    return null;
  }

}

export async function deleteAulaDoHistorico(id) {

  try {
    await firebase.firestore().collection("historicoDeAulas").doc(id).delete()
    return true
  } catch (error) {
    console.log(error)
    return null
  }


}

export async function removeAulasExperimentais(id) {

  try {
    const date = new Date();
        const day = date.getDay();
        const hours = ("0" + (date.getHours()+ 1)).slice(-2);
        const hoursExper = ("0" + (date.getHours()+ (1 - 2))).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const horaInicial = hours + ":" + minutes;
        const horaInicialExpe = hoursExper + ":" + minutes;
    await firebase.firestore().collection("aulas")
            .doc(id).get()
            .then(async (elem) => {
              console.log("Estou dentro das aulas Experimentais");
              
                const dataFin = addMinutes(
                    elem.data().diaFinal.toDate(), 60 );
                if (typeof elem.data().alunos != "undefined" ) {
                    const hin = elem.data().horaInicial;
                    const diaDaAula = new Date(date.getFullYear(),
                        date.getMonth(), date.getDate(), hin.substring(0, 2),
                        hin.substring(3, 5),
                    );
                    console.log("Data Final: " + dataFin);
                    console.log("Dia da Aula: " + diaDaAula);
                    console.log(diaDaAula);
                    
                        await firebase.firestore()
                            .collection("historicoDeAulas").add({
                              classe: "Experimental",
                              createdAt: firebase.firestore
                                  .Timestamp.fromDate(new Date()),
                              dia: firebase.firestore
                                  .Timestamp.fromDate(diaDaAula),
                              aula: elem.data(),
                            });
                        if (typeof elem.data().alunoData != "undefined") {
                          for (const elemnt in elem.data().alunoData) {
                            if (elemnt != null ) {
                              await firebase.firestore().collection("users")
                                  .where("email", "==", elemnt)
                                  .get().then(async (userVls) => {
                                    await firebase.firestore().collection("users")
                                        .doc(userVls.docs.at(0).id).set({
                                          // aulaExperimental
                                          aulaExperimental: true,
                                        }, {merge: true});
                                  });
                            }
                          }
                        }
                        await firebase.firestore()
                            .collection("aulas")
                            .doc(elem.id).delete();
                      
                    }
                  
                
              
            });
            return true;
  } catch (error) {
    console.log(error)
    return null
  }

}

export async function historicoDeAulasComContratos(horaInicial ) {
 
  await firebase.firestore().collection("aulas")
            .where("weekDay", "==", (new Date().getDay()))
            .where("isAtiva", "==", false)
            .where("horaInicial", "==", horaInicial).get()
            .then(async (value) => {
              value.docs.forEach(async (elemn) => {
                  var map = {}
                  var listaAux = []
                  listaAux = elemn.data().alunos
                  listaAux.forEach(async (elem) => {
                    map[elem] = {}
                     await firebase.firestore()
                        .collection("users")
                        .where("email", "==", elem)
                        .get().then(async (valores) => {
                          await firebase
                                .firestore()
                                .collection("users")
                                .doc(valores.docs.at(0).id)
                                .collection("historicoDeAulasPax")
                                .orderBy("valor")
                                .orderBy("createdAt", "desc").limit(1).get().then((haPaxV) => {
                                  console.log(haPaxV.docs.at(0).data())
                                  map[elem] = haPaxV.docs.at(0).data()
                                })
                          
                        }) 
                        console.log(map)
                  })
                  
              })
            })
          
}





