import firebase from "firebase/app"

import AdicionarFaturaRefModal from "components/modals/Adicionar_faturaRef_modal"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Button, Col, Container, Row } from "reactstrap"
import { aulasValor, calculaReservasEbloqueiosDiarios, getVisoTourniqeuetes, gravaPrecoDeAulas } from "services/clube/clube_services"
import { selectfaturasref } from "store/clube/faturas_referencias_reducer"
import { selectclubeid } from "store/localizacao/clube_id_reducer"
import { historicoDeAulasComContratos } from "services/aulas/aulas_services"
import { faturacaoDosTreinadores } from "services/treinadores/treinadores_Services"
import { paraRemover } from "services/reservas/reservas_services"

function FaturacaoScreen() {
  function addDays(date, days) {
    return new Date(date.getTime() + days * 60000 * 60 * 24);
  }

  const { t, i18n } = useTranslation()
  const clubeID = useSelector(selectclubeid)
  const faturasVals = useSelector(selectfaturasref)
  const faturasValsJson = JSON.parse(faturasVals)
  const mapFaturas = Object.entries(faturasValsJson)

  console.log(mapFaturas)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">{t("Valores de faturação")}</h6>
              </Col>
              <Col md={4}>
                <AdicionarFaturaRefModal></AdicionarFaturaRefModal>
              </Col>
            </Row>
            
            <Row>
              <Col md={6}>Nome</Col>
              <Col md={6}>Referências</Col>
            </Row>
            <Row>
              {mapFaturas.map((elem, index) => {
                return (
                  <Row
                    key={elem[1] + index}
                    className={index % 2 == 0 ? "myList-even" : "myList-odd"}
                  >
                    <Col md={6}>{elem[0]}</Col>
                    <Col md={6}>
                      {elem[1].map((elem, index) => {
                        return <Row>{elem}</Row>
                      })}
                    </Col>
                  </Row>
                )
              })}
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FaturacaoScreen

/* 

for (let index = 0; index < 15; index++) { 
           
            var date1 = addDays(new Date(), -(index + 1) )
            var date2 = addDays(new Date(), -(index) ) 
             var date1 = addDays(new Date(), -1 )
            var date2 = addDays(new Date(), 0 ) 
            const dataInicial = new Date(
              date1.getFullYear(), date1.getMonth(), date1.getDate() , 0, 0);
            const dataFinal = new Date(
              date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0);

            const docDashId = dataInicial.getFullYear().toString() +
            ("0" + dataInicial.getMonth().toString()).slice(-2) +
            ("0" + dataInicial.getDate().toString()).slice(-2);

            var array = []
            var valorTotal = 0
            var nrAulasAcademia = 0 
            var nrAulasPax = 0
            await aulasValor(dataInicial, dataFinal, "Great Padel Vila Verde").then((value) => {
              array = value
            })
            
           var listaDeAcademias = []
           var listaDePaxs = []
           for( let index = 0; index < array.length; index ++) {
              valorTotal = valorTotal + parseFloat(array[index].valor)
              if ( array[index].classe == "Academia" ) {
                listaDeAcademias.push(array[index].tipo)
                nrAulasAcademia++
              } else if (array[index].classe == "Pax" ) {
                listaDePaxs.push(array[index].tipo)
                nrAulasPax++
              }
            }
            var mapAGravar = {}
            mapAGravar.valorCompradoAulas = valorTotal
            mapAGravar.numeroAcademia = nrAulasAcademia
            mapAGravar.listaDeAcademias = listaDeAcademias
            mapAGravar.numeroPax = nrAulasPax
            mapAGravar.listaDePaxs = listaDePaxs
            console.log(array)
            console.log({valorCompradoAulas: valorTotal})
            console.log(docDashId) 
            await gravaPrecoDeAulas(clubeID, docDashId, mapAGravar)  
           } 

*/