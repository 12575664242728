import React, { useState } from "react"
import { Trash2 } from "react-bootstrap-icons"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import firebase from "firebase/app"
import "firebase/firestore"
import { selectbloquearReservas } from "store/bloquear_reservas/bloquear_reservas_reducer"
import { useSelector } from "react-redux"
import { selectlocalizacao } from "store/localizacao/localizacao_reducer"
import { selectclubeid } from "store/localizacao/clube_id_reducer"
import { deleteBloqueio } from "services/reservas/reservas_services"

function RemoveBloqueioHorarioModal(props) {

    const [isLoadingBloq, setIsLoadingBloq] = useState(false)
    const localizacao = useSelector(selectlocalizacao)
    const docId = useSelector(selectclubeid);

    const horariosBloqueadosList = useSelector(selectbloquearReservas)
  

  const [isOpen, setIsOpen] = useState(false)
  return (
    <React.Fragment>
      <Button
        color="danger"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <Trash2></Trash2>
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          Tem a certeza que deseja remover este bloqueio de horário?
        </ModalHeader>

        <ModalFooter>
            {/* docId ,horariosBloqueadosList[elem].horaInicial.toDate(),horariosBloqueadosList[elem].horaFinal.toDate(),localizacao,newMAp,nome */}
            {isLoadingBloq ? <Spinner color='primary' ></Spinner> :
          <Button color="primary"
            onClick={async () => {
                setIsLoadingBloq(true)
                var newMAp = {}
                var nome = "";
                    console.log(horariosBloqueadosList)
                for(const element in horariosBloqueadosList) {
                    if(element != props.elem ) {
                        newMAp[element] = horariosBloqueadosList[element]
                    } else {
                        nome = element
                    }
                }
                console.log(newMAp)
                var res = await deleteBloqueio(docId ,horariosBloqueadosList[props.elem].horaInicial.toDate(),horariosBloqueadosList[props.elem].horaFinal.toDate(),localizacao,newMAp,nome );
                if(res) {
                    alert("Bloqueio de horário removido com sucesso")
                } else {
                    alert("Erro")
                }
                setIsLoadingBloq(false)
            }}
          >
            Remover
          </Button>}
          <Button onClick={() => setIsOpen(!isOpen)}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default RemoveBloqueioHorarioModal
