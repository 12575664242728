export class Aula {
  constructor(
    localizacao,
    horaInicial,
    horaFinal,
    diaInicial,
    diaFinal,
    professor,
    alunos,
    alunosPAX,
    alunosData,
    nivel,
    estado,
    nome,
    campos,
    weekDay,
    tipo,
    notas,
    isAtiva,
    expiryAt,
  ) {
    this.createdAt = new Date()
    this.weekDay = weekDay
    this.localizacao = localizacao
    this.horaInicial = horaInicial
    this.horaFinal = horaFinal
    this.diaInicial = diaInicial
    this.diaFinal = diaFinal
    this.professor = professor
    this.alunos = alunos
    this.alunosData= alunosData
    this.alunosPAX = alunosPAX
    this.nivel = nivel
    this.estado = estado
    this.nome = nome
    this.campos = campos
    this.tipo = tipo
    this.notas = notas
    this.isAtiva = isAtiva
    this.expiryAt = expiryAt
  }

  toJSON() {
    return {
      expiryAt : this.expiryAt,
      createdAt: this.createdAt,
      weekDay: this.weekDay,
      localizacao: this.localizacao,
      horaInicial: this.horaInicial,
      horaFinal: this.horaFinal,
      diaInicial: this.diaInicial,
      diaFinal: this.diaFinal,
      professor: this.professor,
      alunos: this.alunos,
      alunosData: this.alunosData,
      alunosPAX: this.alunosPAX,
      nivel: this.nivel,
      estado: this.estado,
      nome: this.nome,
      campos: this.campos,
      tip: this.tipo,
      notas: this.notas,
      isAtiva: this.isAtiva
     
    }
  }

  static fromJSON(json) {
    const aula = new Aula(
      json.localizacao,
      json.horaInicial,
      json.horaFinal,
      new Date(json.diaInicial),
      new Date(json.diaFinal),
      json.professor,
      json.alunos,
      json.alunosData,
      json.alunosPAX,
      json.nivel,
      json.estado,
      json.nome,
      json.campos,
      json.tipo,
      json.notas,
      json.isAtiva,
    )
    aula.createdAt = new Date(json.createdAt)
    aula.weekDay = json.weekDay
    return aula
  }
}

export class AlunoData {
  constructor(academia, cc, fidelizacao, nome, qrCode, tel, tipoDeAula, valor) {
    this.academia = academia;
    this.cc = cc;
    this.fidelizacao = fidelizacao;
    this.nome = nome;
    this.qrCode = qrCode;
    this.tel = tel;
    this.tipoDeAula = tipoDeAula;
    this.valor = valor;
  }

  toJSON() {
    return {
      academia: this.academia,
      cc: this.cc,
      fidelizacao: this.fidelizacao,
      nome: this.nome,
      tel: this.tel,
      tipoDeAula: this.tipoDeAula,
      valor: this.valor,
    }
  }
}
