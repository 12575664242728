import React, { useEffect, useState } from "react"
import { Pencil } from "react-bootstrap-icons"
import { useSelector } from "react-redux"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { editaValoresDosContratos } from "services/contratos/contratos_services"
import { selectcontratosvalores } from "store/clube/valores_dos_contratos_reducer"
import { selectclubeid } from "store/localizacao/clube_id_reducer"

function AdicionarValorDeContratos(props) {
  const [isOpen, setIsOpen] = useState(false)
  const contratosValores = useSelector(selectcontratosvalores)
  const clubeId = useSelector(selectclubeid)
  const mapCV = JSON.parse(contratosValores)

  const numeroDeJogos = ["",1,2,3,4,7]

  const [preco, setPreco] = useState(0)
  const [nome, setNome] = useState("")
  const [nrJogosPSemana, setNrJogosPSemana] = useState(0)
  const [periodo, setPeriodo] = useState("")

  function resetDados() {
    setNrJogosPSemana(0)
    setNome("")
    setPreco(0)
    setPeriodo("")
  }

  function toggle() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <Button color="primary" onClick={() => {
        toggle()
        resetDados()
        }}>
        Adicionar preço de contrato
      </Button>
      <Modal isOpen={isOpen} toggle={() => {
        toggle()
        resetDados()
        }}>
        <ModalHeader
          toggle={() => {
            toggle()
            resetDados()
            }}
        >
          Adicionar preço do contrato
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <Label>Descrição</Label>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome}
                    onChange={e => {
                      setNome(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={{ paddingTop: "20px" }}>
              <Row>
                <Col md={3}>
                  <Label>Nº de jogos por semana</Label>
                </Col>
                <Col md={8}>
                  <Input
                    type="select"
                    value={nrJogosPSemana}
                    id="precoId"
                    onChange={e => {
                      setNrJogosPSemana(e.target.value)
                    }}
                  >  {numeroDeJogos.map((elem,index) => {
                      return <option>{elem}</option>
                  })} </Input>
                </Col>
                <Col md={1}>€</Col>
              </Row>
            </FormGroup>
            <FormGroup style={{  paddingTop: "20px" }}>
              <Row>
                <Col md={3}>
                  <Label>Periodo</Label>
                </Col>
                <Col md={8}>
                <Input
                    type="select"
                    value={periodo}
                    id="precoId"
                    onChange={e => {
                      setPeriodo(e.target.value)
                    }}
                  >   <option>Semestral</option>
                      <option>Anual</option>
                   </Input>
                </Col>
                <Col md={1}>€</Col>
              </Row>
            </FormGroup>
            <FormGroup style={{ paddingBottom: "20px", paddingTop: "20px" }}>
              <Row>
                <Col md={3}>
                  <Label>Valor</Label>
                </Col>
                <Col md={8}>
                  <Input
                    type="number"
                    value={preco}
                    id="precoId"
                    onChange={e => {
                      setPreco(e.target.value)
                    }}
                  ></Input>
                </Col>
                <Col md={1}>€</Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
            
          <Button
            color="primary"
            onClick={async () => {
                var map = {}
                  map[nome] = {}
                  map[nome].nrJogosPSemana = nrJogosPSemana
                  map[nome].periodo = periodo
                  map[nome].valor = preco

                /*  var res = await editaValoresDasAulas(clubeId, map)
                  if (res) {
                    alert("Valores adicionados com sucesso")
                    toggle()
                  } else {
                    alert("Erro a adicionar valores")
                    toggle()
                  }  */
               if (nome.trim() != "" && preco.trim() != "") {
                mapCV[nome] = parseFloat(preco)
                var res = editaValoresDosContratos(clubeId, map)
                if (res) {
                  alert("Valores adicionados com sucesso")
                  toggle()
                } else {
                  alert("Erro a adicionar valores")
                  toggle()
                }
              } else {
                alert("Todos os valores tem que estar preenchidos")
              } 
            }}
          >
            {" "}
            Adicionar{" "}
          </Button>
          <Button
            onClick={() => {
              toggle()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default AdicionarValorDeContratos
