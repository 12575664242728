import AdicionarContratosModal from "components/modals/adicionar_contratos_modal"
import AdicionarContratroEntidadesModal from "components/modals/adicionar_contrato_entidades"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Container, Row, Table } from "reactstrap"
import firebase from "firebase/app"
import "firebase/firestore"
import { contratosCollection } from "services/consts"
import { element } from "prop-types"
import { map } from "lodash"
import { Pencil } from "react-bootstrap-icons"

function ContratosScree() {
  const { t, i18n } = useTranslation()
  const [contratos, setContratos] = useState([])

  async function getContratos(localizacao) {
    try {
      var listaAux = []
      firebase
        .firestore()
        .collection(contratosCollection)
        .where("localizacao", "==", localizacao)
        .onSnapshot(docsSnap => {
          docsSnap.docs.forEach(element => {
            console.log(element.data())
            listaAux.push(element)
          })
          setContratos(listaAux)
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getContratos("Great Padel Vila Verde")
  }, [])

  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">

            <Row className="align-items-center justify-content-between">
              <Col md={8}>
                <h6 className="page-title">{t("Contratos")}</h6>
              </Col>
              <Col color="primary" md={4}>
                <div className="d-flex gap-2 align-items-center">
                  <AdicionarContratosModal />
                  <AdicionarContratroEntidadesModal />
                </div>
              </Col>
            </Row>

            <Row className='pt-4'>
              <Table bordered striped responsive className="myList" style={stylesTable} >
                  <thead>
                      <tr>
                          <th>Nome</th>
                          <th>Data Expiração</th>
                          <th>Período</th>
                          <th>N° Jogos</th>
                          <th>Valor</th>
                          <th>Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                    {contratos.map((element, index) => {
                      const data = element.data()
                      if(data.tipo == "periodico") {
                        return <div></div>
                      } else 
                      return (
                        <tr key={index}>
                          <td>{data.entidade == null ? data.userEmail : data.entidade}</td>
                          <td>{data.expiresAt.toDate().toDateString()}</td>
                          <td>{data.periodo}</td>
                          <td>{data.numeroDeJogos}</td>
                          <td>{data.valor + "€"}</td>
                          <td>
                            <div style={{ marginBottom: "5px" }}>
                              <Button
                                color="warning"
                              >
                                <Pencil size={15}></Pencil>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
              </Table>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContratosScree
