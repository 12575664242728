export class Carregamento {
    constructor(nome, preco, precoFinal, desconto) {
        this.nome = nome;
        this.preco = preco;
        this.precoFinal = precoFinal;
        this.desconto = desconto;
      }

    toJson()  {
        var map = {}
        map[this.nome] = {}
        map[this.nome].preco = this.preco
        map[this.nome].precoFinal = this.precoFinal
        map[this.nome].desconto = this.desconto

        return map
    }
}