import { createSlice } from "@reduxjs/toolkit"

export const rotaspermitidasSlice = createSlice({
  name: "rotaspermitidas",
  initialState: {
    value: "",
  },
  reducers: {
    setrotaspermitidas: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setrotaspermitidas } = rotaspermitidasSlice.actions

export const selectrotaspermitidas = state => state.rotaspermitidas.value

export default rotaspermitidasSlice.reducer
