import "firebase/storage";
import firebase from "firebase/app"


export async function uploadPlanoDeNutricao(userID, file) {
    try {
        const storage = firebase.storage();
    
        var nutriRef = storage.ref(userID + "/nutricao/nutricao.pdf" )
        await nutriRef.put(file).then((snapshot) => {
            console.log("File uplaodade") 
        })
        return true;
    } catch (error) {
        console.log(error)
        return false
    }

}

export async function getPlanoDeNutricao(userID) {
    try {
        const storage = firebase.storage();
    
        var nutriRef = storage.ref(userID + "/nutricao/nutricao.pdf")
        const ficheiro =  await nutriRef.getDownloadURL((value) => {
            console.log(value)
        })
        return ficheiro;
    } catch (error) {
        console.log(error)
        return false
    }

}