import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from "firebase/app"
import "firebase/firestore"
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { convertCamps } from 'services/consts';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom"

function HistoricoDeJogos(props) {
    let { email } = useParams()
    const { t, i18n } = useTranslation()

    const [listaDeAulas, setListaDeAUlas] = useState([])

    async function getHistoricoDeJogos() {
        try {
            firebase.firestore().collection("reservas")
            .where("jogadores", "array-contains", email)
            .where("estado", "==", "Confirmada")
            .get().then((value) => {
            var listaAUx = []
            value.docs.forEach((elem) => {
                listaAUx.push(elem)
            })
            listaAUx.sort((a,b) => {
                if(a.data().createdAt.toDate() > b.data().createdAt.toDate()) {
                    return -1 
                } else {
                    return 1
                }
            })
            setListaDeAUlas(listaAUx)
        }) 
        } catch (error) {
            console.log(error)
            return null
        }
        console.log(email)
        
    }
    

    useEffect(() => {
        getHistoricoDeJogos()
    }, [])

    const stylesTable = {
        "--bs-table-bg": "#ececf1",
      }

    return (  
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <div className="page-title-box">
                    <Row className="align-items-center">
                    <Col md={8}>
                        <h6 className="page-title">{t("Historico de Jogos")}</h6>
                    </Col>
                    </Row>
                  
               
               { listaDeAulas.length == 0 ? <h3 style={{padding: "40px"}}>Sem Jogos</h3> :
              <Row className="pt-5">
              <Table bordered striped responsive className="myList" style={stylesTable} >
                <thead>
                  <tr>
                    <th>Dia</th>
                    <th>Início</th>
                    <th>Duração</th>
                    <th>Campo</th>
                    <th>Valor</th>
                    <th>Ações</th>
                  </tr>
                </thead>
            
                { listaDeAulas.map((elem,index) => {
                   const reserva = elem.data()
                   var date = new Date()
                   date = reserva.horaIncial.toDate()
                   const horas = ("0" + date.getHours().toString()).slice(-2)
                   const minutos = ("0" + date.getMinutes().toString()).slice(-2)
                   return (
                     <tr
                       onClick={() => {console.log("asdasd")}}
                       key={index}
                     >
                        {/* dateUTC.toLocaleString( "pt-PT", { weekday: "long" }).split('-')[0] + ", " + dateUTC.toLocaleString().substring(0,10) */}
                       <td>{date.toLocaleString( "pt-PT", { weekday: "long" }).split('-')[0] + ", " + date.toLocaleString().substring(0,10) }</td>
                       {/* <td>{date.toLocaleString( "pt-PT", { weekday: "long" })}   </td> */}
                       <td>{date.toISOString().substring(11,16) + "h" }</td>
                       <td>{reserva.duracao + "m"}</td>
                       <td>{convertCamps[reserva.campo]}</td>
                       <td>{reserva.valorTotal + "€"}</td>
                       <td>
                         <Link className="btn btn-primary" to={"/reservas/" + elem.id}>
                           <ArrowRightCircle></ArrowRightCircle>
                         </Link>
                       </td>
                     </tr>
                   )
                   
                  
                 
                })}
            
            </Table>
            </Row>
            
               }
                 </div>
            </Container>
            </div>

        </React.Fragment>
    );
}

export default HistoricoDeJogos;