import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage";
import { PagamentoNumerario } from "models/pagamentos_numerario"
import { historicoDePagamentos, usersCollection } from "services/consts"
/* import "firebase/compat/storage"; */

function capitalized(word) {
  var capitalized
  if (word.charAt(0) == word.charAt(0).toUpperCase()) {
    capitalized = word.charAt(0).toLowerCase() + word.slice(1)
  }
  if (word.charAt(0) == word.charAt(0).toLowerCase()) {
    capitalized = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return capitalized
}

export async function pesquisarJogadoresByEmail(email) {
  try {
    return firebase
      .firestore()
      .collection(usersCollection)
      .where("email", "==", email)
      .limit(1)
      .get()
      .then(value => {
        console.log(value)
        console.log(value.docs.at(0))
        return value.docs.at(0)
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function pesquisarJogadoresByPrimeiroNome(nome) {
  try {
    const space = nome + " "
    const capital = capitalized(nome)
    const capitalSpace = capitalized(nome) + " "

    var listaNome = []
    var listaCapital = []
    var listaSpace = []
    var listaCapitalSpace = []
    listaNome = await firebase
      .firestore()
      .collection(usersCollection)
      .where("primeiroNome", "==", nome)
      .get()
      .then(value => {
        return value.docs
      })

    listaCapital = await firebase
      .firestore()
      .collection(usersCollection)
      .where("primeiroNome", "==", capital)
      .get()
      .then(value => {
        return value.docs
      })

    listaSpace = await firebase
      .firestore()
      .collection(usersCollection)
      .where("primeiroNome", "==", space)
      .get()
      .then(value => {
        return value.docs
      })

    listaCapitalSpace = await firebase
      .firestore()
      .collection(usersCollection)
      .where("primeiroNome", "==", capitalSpace)
      .get()
      .then(value => {
        return value.docs
      })

    return listaNome
      .concat(listaCapital)
      .concat(listaSpace)
      .concat(listaCapitalSpace)
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function pesquisarJogadoresByUltimoNome(nome) {
  try {
    const space = nome + " "
    const capital = capitalized(nome)
    const capitalSpace = capitalized(nome) + " "

    var listaNome = []
    var listaCapital = []
    var listaSpace = []
    var listaCapitalSpace = []
    listaNome = await firebase
      .firestore()
      .collection(usersCollection)
      .where("ultimoNome", "==", nome)
      .get()
      .then(value => {
        return value.docs
      })

    listaCapital = await firebase
      .firestore()
      .collection(usersCollection)
      .where("ultimoNome", "==", capital)
      .get()
      .then(value => {
        return value.docs
      })

    listaSpace = await firebase
      .firestore()
      .collection(usersCollection)
      .where("ultimoNome", "==", space)
      .get()
      .then(value => {
        return value.docs
      })

    listaCapitalSpace = await firebase
      .firestore()
      .collection(usersCollection)
      .where("ultimoNome", "==", capitalSpace)
      .get()
      .then(value => {
        return value.docs
      })

    return listaNome
      .concat(listaCapital)
      .concat(listaSpace)
      .concat(listaCapitalSpace)
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function pesquisarJogadoresByNumeroDeTele(tele) {
  try {
   

    var listaTele = []
    var listaTele351 = []
   
    listaTele = await firebase
      .firestore()
      .collection(usersCollection)
      .where("numeroTele", "==", tele)
      .get()
      .then(value => {
        return value.docs
      })

    listaTele351 = await firebase
      .firestore()
      .collection(usersCollection)
      .where("numeroTele", "==", "351" + tele)
      .get()
      .then(value => {
        return value.docs
      })

    return listaTele
      .concat(listaTele351)
     
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function pesquisarJogadoresByEmailParaEntidades(emails) {
  var map = {}
  for (const element of emails) {
    try {
      await firebase
        .firestore()
        .collection(usersCollection)
        .where("email", "==", element)
        .limit(1)
        .get()
        .then(value => {
          if (typeof value.docs.at(0) == "undefined") {
            map[element] = null
          } else {
            map[element] = value.docs.at(0).id
          }
        })
    } catch (error) {
      map[element] = null
      console.error(error)
    }
  }
  return map
}

export async function criarUtilizador(email, password, nome, dataDN, cc) {
  try {
    var user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
  } catch (error) {}
}

//Deixa de enviar emails para todos os users na lista de bloqueados
export async function getAllUsersBloqd() {
  try {
    await firebase
    .firestore()
    .collection("localizacao")
    .doc("emailsBloqueados").get().then(async (value) => {
      const emails = value.data().emails;
       for (let index = 0; index < emails.length; index++) { 
        await firebase
        .firestore()
        .collection("users")
        .where("email", "==", emails[index]).get().then(async (value) => {
          if( value.docs.length != 0){
          console.log("Estou no user " + value.docs.at(0).data().email )
          await firebase.firestore().collection("users").doc(value.docs.at(0).id).set({
            pubSub: false,
          }, {merge: true})
        }
        });
      } 
    })
  } catch (error) {
    console.log(error)
  }
}

export async function enviarEMailsTesteS() {
  try {
    await firebase
    .firestore()
    .collection("enviaEmail")
    .where("teste", "==", false)
    .get().then(async (value) => {
      const email = value.docs.at(0);
      if(typeof email.data().aEnviar == "undefined") {
        const listaAux = []
        const allUsers = await firebase.firestore().collection("users").get()
        for (let index = 0; index < allUsers.docs.length; index++) {
          listaAux.push(allUsers.docs.at(index).data().email) 
        }
        await firebase.firestore().collection("enviaEmail").doc(email.id).set({
          aEnviar: true,
          enviados: 0,
          listaDeUsers: listaAux
        })
      } else {
        const listaDeUsers = email.data().listaDeUsers
        const enviados = email.data().enviados
        let enviarAte = enviados + 100;
        let aEvniar = true

        if(listaDeUsers.length < enviarAte) {
          enviarAte = listaDeUsers.length;
          aEvniar = false;
        }
        
        for (let index = enviados; index < enviados + 100; index++) {
         
        }
      }

    })
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function pesquisarComprasByRequestId(tipo, requestID) {
  var tipoA = "pagamentosMultibanco"
  var tipoB = "pagamentosMBway"
  var email
  var json = {}

  console.log(requestID)

  try {
    if (tipo == "multibanco") {
      await firebase
        .firestore()
        .collection(tipoA)
        .where("reference", "==", requestID)
        .limit(1)
        .get()
        .then(async value => {
          var data = value.docs.at(0).data()
          json.email = data.email
          json.userID = data.userId
          json.valor = data.valor
          json.tipo = data.tipo

          await firebase
            .firestore()
            .collection("users")
            .where("email", "==", json.email)
            .limit(1)
            .get()
            .then(valueUser => {
              var dataUser = valueUser.docs.at(0).data()
              json.nome = dataUser.primeiroNome + " " + dataUser.ultimoNome
              json.nif = dataUser.nif
              json.codigoMoloni = dataUser.codigoMoloni
              json.morada = dataUser.morada
              json.localidade = dataUser.localidade
              json.country = dataUser.country
              json.codigoPostal = dataUser.codigoPostal
            })
        })
    } else {
      await firebase
        .firestore()
        .collection(tipoB)
        .where("referencia", "==", requestID)
        .limit(1)
        .get()
        .then(async value => {
          var data = value.docs.at(0).data()
          json.email = data.email
          json.userID = data.userId
          json.valor = data.valor
          json.tipo = data.tipo

          await firebase
            .firestore()
            .collection("users")
            .where("email", "==", json.email)
            .limit(1)
            .get()
            .then(valueUser => {
              var dataUser = valueUser.docs.at(0).data()
              json.nome = dataUser.primeiroNome + " " + dataUser.ultimoNome
              json.nif = dataUser.nif
              json.codigoMoloni = dataUser.codigoMoloni
              json.morada = dataUser.morada
              json.localidade = dataUser.localidade
              json.country = dataUser.country
              json.codigoPostal = dataUser.codigoPostal

              console.log(json)
            })
        })
    }
    return json
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function adicionarValorAoBancoDeHoras(pagamentoNumerario) {
  try {
    var pagamentoNum = new PagamentoNumerario()
    pagamentoNum = pagamentoNumerario
    var firebaseData = pagamentoNum.firebaseFormat()

    var snapData = await firebase
      .firestore()
      .collection("pagamentosNumerario")
      .add(firebaseData)
    var docID = snapData.id
    var userData = await firebase
      .firestore()
      .collection(usersCollection)
      .doc(pagamentoNum.userID)
      .get()
    var valorAntesDoPAgamento = userData.data().bancoDeHoras
    var valorDepoisDoPagamento =
      userData.data().bancoDeHoras + pagamentoNum.valorAAdicionar

    firebaseData["saldoAntesDoPagamento"] = valorAntesDoPAgamento
    firebaseData["saldoDepoisDoPagamento"] = valorDepoisDoPagamento

    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(pagamentoNum.userID)
      .set(
        {
          historicoDePagamentos: { [docID]: firebaseData },
          bancoDeHoras: firebase.firestore.FieldValue.increment(
            pagamentoNum.valorAAdicionar
          ),
        },
        { merge: true }
      )

    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(pagamentoNum.userID)
      .collection("historicoDePagamentos")
      .add(firebaseData)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function alteraBancoDeHoras(
  userId,
  valor,
  valorAnterior,
  alteradoPor,
  notas
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("alteracoesDeBancoDeHoras")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        valorAntesDaAltercao: parseFloat(valorAnterior),
        novoValor: parseFloat(valor),
        alteradoPor: alteradoPor,
        notas: notas,
      })
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          bancoDeHoras: parseFloat(valor),
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function alteraCC(
  userId,
  valor,
 
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          numeroDoCC: valor,
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function alteraNIF(
  userId,
  valor,
 
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          nif: valor,
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function alteraTEL(
  userId,
  valor,
 
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          numeroTele: valor,
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function adicionarAulasPax(
  userId,
  tipo,
  valor,
  numeroDeAulas,
  numeroDeAulasAnteriores,
  localizacao,
  email
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("historicoDeAulasPax")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        numeroDeAulasAnteriores: parseInt(numeroDeAulasAnteriores),
        novoValor: parseInt(numeroDeAulas) + parseInt(numeroDeAulasAnteriores),
        numeroDeAulasAAdicionar: parseInt(numeroDeAulas),
        valor: valor,
        tipo: tipo,
        localizacao:localizacao
      }),
      await firebase
      .firestore()
      .collection("pagamentosAulas")
      .add({
        userID: userId,
        email: email,
        classe: "PAX",
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        numeroDeAulasAnteriores: parseInt(numeroDeAulasAnteriores),
        novoValor: parseInt(numeroDeAulas) + parseInt(numeroDeAulasAnteriores),
        numeroDeAulasAAdicionar: parseInt(numeroDeAulas),
        valor: valor,
        tipo: tipo,
        localizacao:localizacao
      })
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          aulasPax: parseInt(numeroDeAulasAnteriores) + parseInt(numeroDeAulas),
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function adicionarAulasAcademia(
  userId,
  map,
  email,
  map2
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("historicoDeAulasAcademia")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        localizacao: map.localizacao,
        valor: map.valores.valor        ,
        tipo: map.tipo,
        duracao: map.valores.duracao,
        fidelizacao:map.valores.fidelizacao,
        horario: map.valores.horario,
        idade: map.valores.idade,
        numeroDeAulas: map.valores.numeroDeAulas,
        expiryAt: firebase.firestore.Timestamp.fromDate(map.expiryAt),
      })
      await firebase
      .firestore()
      .collection("pagamentosAulas")
      .add({
        userID: userId,
        email: email,
        classe: "Academia",
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        localizacao: map.localizacao,
        valor: map.valores.valor        ,
        tipo: map.tipo,
        duracao: map.valores.duracao,
        fidelizacao:map.valores.fidelizacao,
        horario: map.valores.horario,
        idade: map.valores.idade,
        numeroDeAulas: map.valores.numeroDeAulas,
        expiryAt: firebase.firestore.Timestamp.fromDate(map.expiryAt),
      })
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          aulasAcamida: {
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            valor: map.valores.valor , 
            localizacao: map.localizacao,      
            tipo: map.tipo,
            duracao: map.valores.duracao,
            fidelizacao:map.valores.fidelizacao,
            horario: map.valores.horario,
            idade: map.valores.idade,
            numeroDeAulas: map.valores.numeroDeAulas,
            expiryAt: firebase.firestore.Timestamp.fromDate(map.expiryAt),
          },
        },
        { merge: true }
      )

    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function removeAulasAcademia(
  userId,
  localizacao
) {
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .collection("historicoDeAulasAcademia")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        localizacao: localizacao,
        valor: null ,
        tipo: "Academia",
        duracao: null,
        fidelizacao: null,
        horario: null,
        idade: null,
        numeroDeAulas: null,
        expiryAt: null,
        notas: "Removeu o contrato em vigor"
      })
     
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update(
        {
          aulasAcamida: firebase.firestore.FieldValue.delete(),
          aulasAcademiaFerias: firebase.firestore.FieldValue.delete()
        },
        { merge: true }
      )

    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function adicionarFeriasAcademia(
  userId,
  map,
) {
  try {
    
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          aulasAcademiaFerias: {
            ferias1: map.ferias1 != null ? firebase.firestore.Timestamp.fromDate(map.ferias1) : null,
            ferias2: map.ferias2 != null ? firebase.firestore.Timestamp.fromDate(map.ferias2) : null,
            ferias3: map.ferias3 != null ? firebase.firestore.Timestamp.fromDate(map.ferias3) : null,
            ferias4: map.ferias4 != null ? firebase.firestore.Timestamp.fromDate(map.ferias4) : null,
          },
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function adicionarFeriasContratos(
  userId,
  map,
) {
  try {
    
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          tipoContratoFerias: {
            ferias1: map.ferias1 != null ? firebase.firestore.Timestamp.fromDate(map.ferias1) : null,
            ferias2: map.ferias2 != null ? firebase.firestore.Timestamp.fromDate(map.ferias2) : null,
            ferias3: map.ferias3 != null ? firebase.firestore.Timestamp.fromDate(map.ferias3) : null,
            ferias4: map.ferias4 != null ? firebase.firestore.Timestamp.fromDate(map.ferias4) : null,
          },
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000)
}

export async function addAllUsersWithDataDeNascimento() {
  try {
    await firebase.firestore().collection("users")
        .orderBy("dataDeNascimento").get().then(async (value) => {
          for (const elem of value.docs) {
            console.log(elem.data().email);
            const data = addMinutes(elem.data().dataDeNascimento.toDate(), 121);
            const diaNasceu = ("0" + data.getDate()).slice(-2);
            const mesNasceu = ("0" + data.getMonth()).slice(-2);
            console.log("Data de nascimento: " + data);
            console.log("Dia: " + diaNasceu);
            console.log("Mês: " + mesNasceu);
            const diaMesNasceu = mesNasceu + diaNasceu;
            await firebase.firestore().collection("users").doc(elem.id).set({
              diaMesNasceu: diaMesNasceu,
            }, {merge: true});
          }
        });
  } catch (error) {
    console.log(error);
  }
}

async function testeDOEmailDasExpire() {
  const date = addMinutes(new Date(), 1440*3);
  const date1 = addMinutes(new Date(), 1440*4);
  try {
    console.log("BP1");
    firebase.firestore().collection("users")
        .where("aulasAcamida.expiryAt", ">=", date )
        .where("aulasAcamida.expiryAt", "<=", date1 ).get().then((value) => {
          console.log("BP2");
          console.log("patig");
          const listaAux = [];
          for (let index = 0; index < value.docs.length; index++) {
            console.log(value.docs.at(index).data().email);
            listaAux.push(value.docs.at(index).data().email);
          }
          let mensage = "Jogadores com contrato a renovar em 3 dias";
          for (let index = 0; index < listaAux.length; index++) {
            mensage = mensage + "<p>" + listaAux.at(index) + "</p>";
          }
          enviarEmailWithoutAttach("manuelbarros@macrobiiz.com",
              mensage, "Contratos Academia com fidelização" );
        });
  } catch (error) {
    console.log(error);
  }
}


export async function vaiBuscarAsAulasAosJogadores() {
    try {
      return firebase.firestore().collection("users").orderBy("historicoDeJogos").get().then((value) => {
        return value.docs;
      })
    } catch (error) {
      
    }
}


export async function addContratoPDF(userID, fileName,file) {
  try {
    console.log(userID);
    const storage = firebase.storage();
    var storageRef = storage.ref();
    var path = userID + "/contratos/" + fileName
    var spaceRef = storageRef.child(path);

    await spaceRef.put(file).then((snapshot) => {
      console.log('Uploaded a blob or file!');
    })
    return true

  } catch (error) {
      console.log(error)
      return false
  }
}

export async function testeAddRemoveContratos() {
  try {
    firebase.firestore().collection("users").orderBy("tipoDeContrato").get().then((value) => {
      value.docs.forEach(async (elem) => {
        const contrato = elem.data().tipoDeContrato
        if(contrato.expiryAt.toDate() > new Date()) {
          console.log("Adiciona mais contratos a: ", elem.data().email)
          contrato.email = elem.data().email
          contrato.createdAt = firebase.firestore.Timestamp.fromDate( new Date())
          await firebase.firestore().collection("users").doc(elem.id).set({
            contrato: contrato.nrDeJogos
          }, {merge: true})
          
          await firebase.firestore().collection("renovacoesDosContratos").add(
            contrato
          )
        } else {
          console.log("Não adiciona contratos a: ", elem.data().email)
        }
      })
    })
    return true
  } catch (error) {
      console.log(error)
      return false
  }
}

export const listAllUsers = (nextPageToken) => {
  try {
    const listaUser = [];
    firebase.auth()
        .listUsers(1000, nextPageToken)
        .then((listUsersResult) => {
          listUsersResult.users.forEach((userRecord) => {
            
            listaUser.push(userRecord.toJSON());
          });
          if (listUsersResult.pageToken) {
            listAllUsers(listUsersResult.pageToken);
          }
        })
        .catch((error) => {
          console.log("Error listing users:", error);
        });
    console.log(listaUser.length);
  } catch (error) {
    console.log(error);
    return null;
  }
};