import firebase from "firebase/app"
import "firebase/firestore"
import { ProcuraReserva, Reserva } from "models/reserva"
import {
  aulasCollection,
  dateToStringCF,
  listaDeFeriados2022,
  localizacaoCollection,
  reservasCollection,
  usersCollection,
} from "services/consts"
import "firebase/auth"
import Reservas from "pages/Reservas/reservas"
import axios from "axios"
import { element, func } from "prop-types"

const pagamentosMBwayCollection = "pagamentosMBway"

function subtractHours(date, hours) {
  var copiedDate = new Date(date.getTime())
  copiedDate.setHours(copiedDate.getHours() - hours)
  return copiedDate
}
/* 
Se nao for usado remover
function addHours(date, minutes) {
  var copiedDate = new Date(date.getTime())
  var hours = minutes / 60
  copiedDate.setHours(copiedDate.getHours() + hours)
  console.log(copiedDate)
  return copiedDate
} */

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000)
}

export async function retornaCamposIndisponíveisNaHora(procuraReserv) {
  var listaDeCamposIndisponiveis = []
  var procuraReserva = new ProcuraReserva()
  procuraReserva = procuraReserv
  console.log("bp1")
  var horaInicial = procuraReserva.horaDaReserva
  var horaInicialParaProcurarReserva = addMinutes(
    procuraReserva.horaDaReserva,
    -120
  )
  var horaFinal = addMinutes(
    procuraReserva.horaDaReserva,
    procuraReserva.duracaoDaReserva
  )
  
  try {
    await firebase
      .firestore()
      .collection(reservasCollection)
      .where("localizacao", "==", "Great Padel Vila Verde")
      .where(
        "horaIncial",
        ">=",
        firebase.firestore.Timestamp.fromDate(horaInicialParaProcurarReserva)
      )
      .where(
        "horaIncial",
        "<=",
        firebase.firestore.Timestamp.fromDate(horaFinal)
      )
      .get()
      .then(value => {
        console.log("Resultados das reservas")
        console.log(value.docs.length)
        for (var element of value.docs) {
          var hi = element.data().horaIncial
          var hf = element.data().horaFinal
          horaInicial.setMilliseconds(0)
          horaInicial.setSeconds(0)
          horaFinal.setMilliseconds(0)
          horaFinal.setSeconds(0)
          var rhi = firebase.firestore.Timestamp.fromDate(horaInicial)
          var rhf = firebase.firestore.Timestamp.fromDate(horaFinal)

          var hidate = element.data().horaIncial.toDate()
          var hfdate = element.data().horaFinal.toDate()
          hidate.setMilliseconds(0)
          hidate.setSeconds(0)
          hfdate.setMilliseconds(0)
          hfdate.setSeconds(0)

          hi = firebase.firestore.Timestamp.fromDate(hidate)
          hf =  firebase.firestore.Timestamp.fromDate(hfdate)

          if (element.data().estado != "Anulada") {
            if (hi >= rhi && hf > rhf && rhf > hi) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hi <= rhi && hf <= rhf && rhi < hf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hi >= rhi && hi < rhf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hf > rhi && hf <= rhf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hi <= rhi && hf >= rhf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
          }
        }
      })

    //Coverter o horario da Reserva para um tempo compativel com o das aulas. Formato-> 00:00
    var horaIncialComp = ("0" + horaInicial.getHours()).slice(-2)
    horaIncialComp = ("0" + (parseInt(horaIncialComp) - 2).toString()).slice(-2)
    var minutosInicialComp = ("0" + horaInicial.getMinutes()).slice(-2)
    var horaFinalComp = ("0" + horaFinal.getHours()).slice(-2)
    var minutosFinalComp = ("0" + horaFinal.getMinutes()).slice(-2)
    var horaInicialFormat1 = horaIncialComp + ":" + minutosInicialComp
    var horaInicialFormat2 = horaFinalComp + ":" + minutosFinalComp

    /* console.log("-------AULAS------")
    console.log("Hora incial Formatada: " + horaInicialFormat1)
    console.log("Hora final Formatada: " + horaInicialFormat2) */

    if(horaInicialFormat2 == "00:00"  ){
      horaInicialFormat2 = "24:00"
    }
    if(horaInicialFormat2 == "00:30") {
      horaInicialFormat2 = "24:30"
    }

    await firebase
      .firestore()
      .collection(aulasCollection)
      .where("localizacao", "==", procuraReserva.localizacao)
      .where("weekDay", "==", procuraReserva.horaDaReserva.getDay())
      .where("horaInicial", ">=", horaInicialFormat1)
      .where("horaInicial", "<=", horaInicialFormat2)
      .where("isAtiva", "==" , true)
      .get()
      .then(value => {
        console.log("Resultado das aulas")
        if (value.docs.length != 0) {
          for (var elementa of value.docs) {
            
            if (elementa.data().estado != "Anulada") {
              var aulasHi = elementa.data().horaInicial
              var aulasHf = elementa.data().horaFinal
              let controaaa = true;
               if( elementa.data().tipo == "Experimental") {
                /* console.log("Estou a chekar uma uala experimental")
                console.log(elementa.data().diaFinal.toDate());
                console.log(elementa.data().diaFinal.toDate());
                console.log(elementa.data().horaInicial); */
                const dateAulaaaa = elementa.data().diaFinal.toDate();
                const dateReservaaaa = horaInicial;
                if( dateAulaaaa.getMonth() != dateReservaaaa.getMonth() || 
                dateAulaaaa.getFullYear() != dateReservaaaa.getFullYear() ||
                dateAulaaaa.getDate() != dateReservaaaa.getDate() ) {
                  controaaa = false
                }
              }  
              
              if(aulasHf == "00:00"){
                aulasHf = "24:00"
              }
              if(aulasHf == "00:30"){
                aulasHf = "24:30"
              }

              var horaIncialAula = ("0" + horaInicial.getHours()).slice(-2)
              var minutosInicialAula = ("0" + horaInicial.getMinutes()).slice(
                -2
              )
              var horaFinalAula = ("0" + horaFinal.getHours()).slice(-2)
              var minutosFinalAula = ("0" + horaFinal.getMinutes()).slice(-2)

              

              var aulasRhi = horaIncialAula + ":" + minutosInicialAula
              var aulasRhf = horaFinalAula + ":" + minutosFinalAula

              console.log(aulasRhf)
              console.log(aulasRhf == "00:00")

              if(aulasRhf == "00:00"){
                aulasRhf = "24:00"
              }
              if(aulasRhf == "00:30"){
                aulasRhf = "24:30"
              }

              /* console.log("VALORES A COMPRAR");
              console.log("Aulas Hora Inicial: ",aulasHi )
              console.log("Reserva Hora Inicial: ",aulasRhi )
              console.log("Aulas Hora Final: ",aulasHf )
              console.log("Reserva Hora Final: ",aulasRhf )

             

              console.log("casos de teste: ")
              console.log(
                aulasHi >= aulasRhi && aulasHf > aulasRhf && aulasRhf > aulasHi
              )
              console.log(
                aulasHi <= aulasRhi && aulasHf <= aulasRhf && aulasRhi < aulasHf
              )
              console.log(aulasHi >= aulasRhi && aulasHi < aulasRhf)
              console.log(aulasHf > aulasRhi && aulasHf <= aulasRhf)
              console.log(aulasHi <= aulasRhi && aulasHf >= aulasRhf) */
              if(controaaa){
              if (
                aulasHi >= aulasRhi &&
                aulasHf > aulasRhf &&
                aulasRhf > aulasHi
              ) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (
                aulasHi <= aulasRhi &&
                aulasHf <= aulasRhf &&
                aulasRhi < aulasHf
              ) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHi >= aulasRhi && aulasHi < aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHf > aulasRhi && aulasHf <= aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHi <= aulasRhi && aulasHf >= aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }}
              console.log(listaDeCamposIndisponiveis)
            }}
          }
        
      })

    return listaDeCamposIndisponiveis
  } catch (error) {
    console.log(error)
    return listaDeCamposIndisponiveis
  }
}
export async function retornaCamposIndisponíveisNaHoraENDP(procuraReserv) {
  var procuraReserva = new ProcuraReserva()
  procuraReserva = procuraReserv
  try {
    const respo = await axios.get("https://us-central1-napadle.cloudfunctions.net/camposIndisponiveisBackOffice", {
      params: {
        localizacao: procuraReserva.localizacao,
        horario: dateToStringCF(procuraReserva.horaDaReserva),
        duracao: procuraReserva.duracaoDaReserva,
      }});
    return respo.data;
  } catch (error) {
    console.log(error)
    return null
  } 
}

export async function retornaCamposIndisponíveisNaHoraEditarENDP(procuraReserv,nome) {
  var procuraReserva = new ProcuraReserva()
  procuraReserva = procuraReserv
  try {
    const respo = await axios.get("https://us-central1-napadle.cloudfunctions.net/camposIndisponiveisBackOfficeBDH", {
      params: {
        nome: nome,
        localizacao: procuraReserva.localizacao,
        horario: dateToStringCF(procuraReserva.horaDaReserva),
        duracao: procuraReserva.duracaoDaReserva,
      }});
    return respo.data;
  } catch (error) {
    console.log(error)
    return null
  } 
}
/* 
var url = Uri.https('us-central1-napadle.cloudfunctions.net',
          'camposIndisponiveis', qParams);
           */
export async function retornaCamposIndisponíveisNaHoraAulas(procuraReserv) {
  var listaDeCamposIndisponiveis = []
  var procuraReserva = new ProcuraReserva()
  procuraReserva = procuraReserv
  console.log("bp1")
  var horaInicial = procuraReserva.horaDaReserva
  var horaInicialParaProcurarReserva = addMinutes(
    procuraReserva.horaDaReserva,
    -120
  )
  var horaFinal = addMinutes(
    procuraReserva.horaDaReserva,
    procuraReserva.duracaoDaReserva
  )
  /* console.log("PATIG0")
  console.log(procuraReserva.localizacao)
  console.log("Hora incial da Reserva: " + horaInicial),
  console.log("Hora final da Reserva: " + horaFinal) */
  
  try {
    await firebase
      .firestore()
      .collection(reservasCollection)
      .where("localizacao", "==", "Great Padel Vila Verde")
      .where(
        "horaIncial",
        ">=",
        firebase.firestore.Timestamp.fromDate(horaInicialParaProcurarReserva)
      )
      .where(
        "horaIncial",
        "<=",
        firebase.firestore.Timestamp.fromDate(horaFinal)
      )
      .get()
      .then(value => {
        console.log("Resultados das reservas")
        console.log(value.docs.length)
        for (var element of value.docs) {
          var hi = element.data().horaIncial
          var hf = element.data().horaFinal
          horaInicial.setMilliseconds(0)
          horaInicial.setSeconds(0)
          horaFinal.setMilliseconds(0)
          horaFinal.setSeconds(0)
          var rhi = firebase.firestore.Timestamp.fromDate(horaInicial)
          var rhf = firebase.firestore.Timestamp.fromDate(horaFinal)

          var hidate = element.data().horaIncial.toDate()
          var hfdate = element.data().horaFinal.toDate()
          hidate.setMilliseconds(0)
          hidate.setSeconds(0)
          hfdate.setMilliseconds(0)
          hfdate.setSeconds(0)

          hi = firebase.firestore.Timestamp.fromDate(hidate)
          hf =  firebase.firestore.Timestamp.fromDate(hfdate)

          /* console.log("patig")
          console.log("Hora Inicial :",  hi.toDate())
          console.log("Hora Final :",  hf.toDate())
          console.log("Reserva hora Inicial :", rhi.toDate())
          console.log("Reserva hora Final :",  rhf.toDate())
          console.log("Hora Inicial :", + hi.toDate())
          console.log("Hora Final :", + hf.toDate())
          console.log("Reserva hora Inicial :", + rhi.toDate())
          console.log("Reserva hora Final :",  + rhf.toDate()) */
         /*  console.log("hi <= rhi", hi < rhi)
          console.log("hf <= rhf", hf <= rhf)
          console.log("rhi < hf", rhi < hf) */
         /*  console.log(hi >= rhi && hf > rhf && rhf > hi)
          console.log(hi <= rhi && hf <= rhf && rhi < hf)
          console.log(hi >= rhi && hi < rhf)
          console.log(hf > rhi && hf <= rhf)
          console.log(hi <= rhi && hf >= rhf) */

          if (element.data().estado != "Anulada") {
            if (hi >= rhi && hf > rhf && rhf > hi) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hi <= rhi && hf <= rhf && rhi < hf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hi >= rhi && hi < rhf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hf > rhi && hf <= rhf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if (hi <= rhi && hf >= rhf) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
          }

          /* if (rhi <= hi && hi < rhf) {
            listaDeCamposIndisponiveis.push(element.data().campo)
          }
          if (hi <= rhi && rhi < hf) {
            listaDeCamposIndisponiveis.push(element.data().campo)
          }
          if (hi <= rhi && rhf <= hf) {
            listaDeCamposIndisponiveis.push(element.data().campo)
          } */
        }
      })

    //Coverter o horario da Reserva para um tempo compativel com o das aulas. Formato-> 00:00
    var horaIncialComp = ("0" + horaInicial.getHours()).slice(-2)
    horaIncialComp = ("0" + (parseInt(horaIncialComp) - 2).toString()).slice(-2)
    var minutosInicialComp = ("0" + horaInicial.getMinutes()).slice(-2)
    var horaFinalComp = ("0" + horaFinal.getHours()).slice(-2)
    var minutosFinalComp = ("0" + horaFinal.getMinutes()).slice(-2)
    var horaInicialFormat1 = horaIncialComp + ":" + minutosInicialComp
    var horaInicialFormat2 = horaFinalComp + ":" + minutosFinalComp

    console.log("-------AULAS------")
    console.log("Hora incial Formatada: " + horaInicialFormat1)
    console.log("Hora final Formatada: " + horaInicialFormat2)

    if(horaInicialFormat2 == "00:00"  ){
      horaInicialFormat2 = "24:00"
    }
    if(horaInicialFormat2 == "00:30") {
      horaInicialFormat2 = "24:30"
    }

    await firebase
      .firestore()
      .collection(aulasCollection)
      .where("localizacao", "==", procuraReserva.localizacao)
      .where("weekDay", "==", procuraReserva.horaDaReserva.getDay())
      .where("horaInicial", ">=", horaInicialFormat1)
      .where("horaInicial", "<=", horaInicialFormat2)
      .get()
      .then(value => {
        console.log("Resultado das aulas")
        if (value.docs.length != 0) {
          for (var elementa of value.docs) {
            
            if (elementa.data().estado != "Anulada") {
              var aulasHi = elementa.data().horaInicial
              var aulasHf = elementa.data().horaFinal
              let controaaa = true;
               if( elementa.data().tipo == "Experimental") {
                console.log("Estou a chekar uma uala experimental")
                console.log(elementa.data().diaFinal.toDate());
                console.log(elementa.data().diaFinal.toDate());
                console.log(elementa.data().horaInicial);
                const dateAulaaaa = elementa.data().diaFinal.toDate();
                const dateReservaaaa = horaInicial;
                if( dateAulaaaa.getMonth() != dateReservaaaa.getMonth() || 
                dateAulaaaa.getFullYear() != dateReservaaaa.getFullYear() ||
                dateAulaaaa.getDate() != dateReservaaaa.getDate() ) {
                  controaaa = false
                }
              }  
              
              if(aulasHf == "00:00"){
                aulasHf = "24:00"
              }
              if(aulasHf == "00:30"){
                aulasHf = "24:30"
              }

              var horaIncialAula = ("0" + horaInicial.getHours()).slice(-2)
              var minutosInicialAula = ("0" + horaInicial.getMinutes()).slice(
                -2
              )
              var horaFinalAula = ("0" + horaFinal.getHours()).slice(-2)
              var minutosFinalAula = ("0" + horaFinal.getMinutes()).slice(-2)

              

              var aulasRhi = horaIncialAula + ":" + minutosInicialAula
              var aulasRhf = horaFinalAula + ":" + minutosFinalAula

              console.log(aulasRhf)
              console.log(aulasRhf == "00:00")

              if(aulasRhf == "00:00"){
                aulasRhf = "24:00"
              }
              if(aulasRhf == "00:30"){
                aulasRhf = "24:30"
              }

              console.log("VALORES A COMPRAR");
              console.log("Aulas Hora Inicial: ",aulasHi )
              console.log("Reserva Hora Inicial: ",aulasRhi )
              console.log("Aulas Hora Final: ",aulasHf )
              console.log("Reserva Hora Final: ",aulasRhf )

             

              console.log("casos de teste: ")
              console.log(
                aulasHi >= aulasRhi && aulasHf > aulasRhf && aulasRhf > aulasHi
              )
              console.log(
                aulasHi <= aulasRhi && aulasHf <= aulasRhf && aulasRhi < aulasHf
              )
              console.log(aulasHi >= aulasRhi && aulasHi < aulasRhf)
              console.log(aulasHf > aulasRhi && aulasHf <= aulasRhf)
              console.log(aulasHi <= aulasRhi && aulasHf >= aulasRhf)
              if(controaaa){
              if (
                aulasHi >= aulasRhi &&
                aulasHf > aulasRhf &&
                aulasRhf > aulasHi
              ) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (
                aulasHi <= aulasRhi &&
                aulasHf <= aulasRhf &&
                aulasRhi < aulasHf
              ) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHi >= aulasRhi && aulasHi < aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHf > aulasRhi && aulasHf <= aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHi <= aulasRhi && aulasHf >= aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }}
              console.log(listaDeCamposIndisponiveis)
            }}
          }
        
      })

    return listaDeCamposIndisponiveis
  } catch (error) {
    console.log(error)
    return listaDeCamposIndisponiveis
  }
}

export async function retornaCamposIndisponíveisNaHoraEditarBloqueioDeHoras(procuraReserv,nome) {
  var listaDeCamposIndisponiveis = []
  var procuraReserva = new ProcuraReserva()
  procuraReserva = procuraReserv
  console.log("bp1")
  var horaInicial = procuraReserva.horaDaReserva
  var horaInicialParaProcurarReserva = addMinutes(
    procuraReserva.horaDaReserva,
    -120
  )
  var horaFinal = addMinutes(
    procuraReserva.horaDaReserva,
    procuraReserva.duracaoDaReserva
  )
  /* console.log("PATIG0")
  console.log(procuraReserva.localizacao)
  console.log("Hora incial da Reserva: " + horaInicial),
  console.log("Hora final da Reserva: " + horaFinal) */
  
  try {
    await firebase
      .firestore()
      .collection(reservasCollection)
      .where("localizacao", "==", "Great Padel Vila Verde")
      .where(
        "horaIncial",
        ">=",
        firebase.firestore.Timestamp.fromDate(horaInicialParaProcurarReserva)
      )
      .where(
        "horaIncial",
        "<=",
        firebase.firestore.Timestamp.fromDate(horaFinal)
      )
      .get()
      .then(value => {
        console.log("Resultados das reservas")
        console.log(value.docs.length)
        for (var element of value.docs) {
          var hi = element.data().horaIncial
          var hf = element.data().horaFinal
          horaInicial.setMilliseconds(0)
          horaInicial.setSeconds(0)
          horaFinal.setMilliseconds(0)
          horaFinal.setSeconds(0)
          var rhi = firebase.firestore.Timestamp.fromDate(horaInicial)
          var rhf = firebase.firestore.Timestamp.fromDate(horaFinal)

          var hidate = element.data().horaIncial.toDate()
          var hfdate = element.data().horaFinal.toDate()
          hidate.setMilliseconds(0)
          hidate.setSeconds(0)
          hfdate.setMilliseconds(0)
          hfdate.setSeconds(0)

          hi = firebase.firestore.Timestamp.fromDate(hidate)
          hf =  firebase.firestore.Timestamp.fromDate(hfdate)

          /* console.log("patig")
          console.log("Hora Inicial :",  hi.toDate())
          console.log("Hora Final :",  hf.toDate())
          console.log("Reserva hora Inicial :", rhi.toDate())
          console.log("Reserva hora Final :",  rhf.toDate())
          console.log("Hora Inicial :", + hi.toDate())
          console.log("Hora Final :", + hf.toDate())
          console.log("Reserva hora Inicial :", + rhi.toDate())
          console.log("Reserva hora Final :",  + rhf.toDate()) */
         /*  console.log("hi <= rhi", hi < rhi)
          console.log("hf <= rhf", hf <= rhf)
          console.log("rhi < hf", rhi < hf) */
         /*  console.log(hi >= rhi && hf > rhf && rhf > hi)
          console.log(hi <= rhi && hf <= rhf && rhi < hf)
          console.log(hi >= rhi && hi < rhf)
          console.log(hf > rhi && hf <= rhf)
          console.log(hi <= rhi && hf >= rhf) */
          console.log("Nomes")
          console.log(element.data().nome)
          console.log(nome)

          if (element.data().estado != "Anulada") {
            if ((hi >= rhi && hf > rhf && rhf > hi) && element.data().nome != nome  ) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if ((hi <= rhi && hf <= rhf && rhi < hf) && element.data().nome != nome ) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if ((hi >= rhi && hi < rhf) && element.data().nome != nome ) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if ((hf > rhi && hf <= rhf) && element.data().nome != nome) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
            if ((hi <= rhi && hf >= rhf) && element.data().nome != nome) {
              listaDeCamposIndisponiveis.push(element.data().campo)
            }
          }

          /* if (rhi <= hi && hi < rhf) {
            listaDeCamposIndisponiveis.push(element.data().campo)
          }
          if (hi <= rhi && rhi < hf) {
            listaDeCamposIndisponiveis.push(element.data().campo)
          }
          if (hi <= rhi && rhf <= hf) {
            listaDeCamposIndisponiveis.push(element.data().campo)
          } */
        }
      })

    //Coverter o horario da Reserva para um tempo compativel com o das aulas. Formato-> 00:00
    var horaIncialComp = ("0" + horaInicial.getHours()).slice(-2)
    horaIncialComp = ("0" + (parseInt(horaIncialComp) - 2).toString()).slice(-2)
    var minutosInicialComp = ("0" + horaInicial.getMinutes()).slice(-2)
    var horaFinalComp = ("0" + horaFinal.getHours()).slice(-2)
    var minutosFinalComp = ("0" + horaFinal.getMinutes()).slice(-2)
    var horaInicialFormat1 = horaIncialComp + ":" + minutosInicialComp
    var horaInicialFormat2 = horaFinalComp + ":" + minutosFinalComp

    console.log("-------AULAS------")
    console.log("Hora incial Formatada: " + horaInicialFormat1)
    console.log("Hora final Formatada: " + horaInicialFormat2)

    if(horaInicialFormat2 == "00:00"  ){
      horaInicialFormat2 = "24:00"
    }
    if(horaInicialFormat2 == "00:30") {
      horaInicialFormat2 = "24:30"
    }

    await firebase
      .firestore()
      .collection(aulasCollection)
      .where("localizacao", "==", procuraReserva.localizacao)
      .where("weekDay", "==", procuraReserva.horaDaReserva.getDay())
      .where("horaInicial", ">=", horaInicialFormat1)
      .where("horaInicial", "<=", horaInicialFormat2)
      .get()
      .then(value => {
        console.log("Resultado das aulas")
        if (value.docs.length != 0) {
          for (var elementa of value.docs) {
            
            if (elementa.data().estado != "Anulada") {
              var aulasHi = elementa.data().horaInicial
              var aulasHf = elementa.data().horaFinal
              let controaaa = true;
               if( elementa.data().tipo == "Experimental") {
                console.log("Estou a chekar uma uala experimental")
                console.log(elementa.data().diaFinal.toDate());
                console.log(elementa.data().diaFinal.toDate());
                console.log(elementa.data().horaInicial);
                const dateAulaaaa = elementa.data().diaFinal.toDate();
                const dateReservaaaa = horaInicial;
                if( dateAulaaaa.getMonth() != dateReservaaaa.getMonth() || 
                dateAulaaaa.getFullYear() != dateReservaaaa.getFullYear() ||
                dateAulaaaa.getDate() != dateReservaaaa.getDate() ) {
                  controaaa = false
                }
              }  
              
              if(aulasHf == "00:00"){
                aulasHf = "24:00"
              }
              if(aulasHf == "00:30"){
                aulasHf = "24:30"
              }

              var horaIncialAula = ("0" + horaInicial.getHours()).slice(-2)
              var minutosInicialAula = ("0" + horaInicial.getMinutes()).slice(
                -2
              )
              var horaFinalAula = ("0" + horaFinal.getHours()).slice(-2)
              var minutosFinalAula = ("0" + horaFinal.getMinutes()).slice(-2)

              

              var aulasRhi = horaIncialAula + ":" + minutosInicialAula
              var aulasRhf = horaFinalAula + ":" + minutosFinalAula

              console.log(aulasRhf)
              console.log(aulasRhf == "00:00")

              if(aulasRhf == "00:00"){
                aulasRhf = "24:00"
              }
              if(aulasRhf == "00:30"){
                aulasRhf = "24:30"
              }

              console.log("VALORES A COMPRAR");
              console.log("Aulas Hora Inicial: ",aulasHi )
              console.log("Reserva Hora Inicial: ",aulasRhi )
              console.log("Aulas Hora Final: ",aulasHf )
              console.log("Reserva Hora Final: ",aulasRhf )

             

              console.log("casos de teste: ")
              console.log(
                aulasHi >= aulasRhi && aulasHf > aulasRhf && aulasRhf > aulasHi
              )
              console.log(
                aulasHi <= aulasRhi && aulasHf <= aulasRhf && aulasRhi < aulasHf
              )
              console.log(aulasHi >= aulasRhi && aulasHi < aulasRhf)
              console.log(aulasHf > aulasRhi && aulasHf <= aulasRhf)
              console.log(aulasHi <= aulasRhi && aulasHf >= aulasRhf)
              if(controaaa){
              if (
                aulasHi >= aulasRhi &&
                aulasHf > aulasRhf &&
                aulasRhf > aulasHi
              ) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (
                aulasHi <= aulasRhi &&
                aulasHf <= aulasRhf &&
                aulasRhi < aulasHf
              ) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHi >= aulasRhi && aulasHi < aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHf > aulasRhi && aulasHf <= aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }
              if (aulasHi <= aulasRhi && aulasHf >= aulasRhf) {
                for (
                  let index = 0;
                  index < elementa.data().campos.length;
                  index++
                ) {
                  listaDeCamposIndisponiveis.push(elementa.data().campos[index])
                }
              }}
              console.log(listaDeCamposIndisponiveis)
            }}
          }
        
      })

    return listaDeCamposIndisponiveis
  } catch (error) {
    console.log(error)
    return listaDeCamposIndisponiveis
  }
}

export async function editarDadosReserva(
  reservaId,
  jogador1estado,
  jogador2estado,
  jogador3estado,
  jogador4estado,
  campo,
  novaListaDeJogadores,
  editadaPor
) {
  try {
    console.log(reservaId)
    console.log(editadaPor)
    console.log("^^^^^^^")
    await firebase
      .firestore()
      .collection(reservasCollection)
      .doc(reservaId)
      .set(
        {
          campo: campo,
          jogadores: novaListaDeJogadores,
          jogador1estado,
          jogador2estado,
          jogador3estado,
          jogador4estado,
          editadaPor: editadaPor.jaEditou ?  firebase.firestore.FieldValue.arrayUnion(editadaPor) : [editadaPor]
        },
        { merge: true }
      )
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

//MUdar esta função para ir buscar a classe de cada jogador
export async function checkIfUserExists(email) {
  try {
    return firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .limit(1)
      .get()
      .then(value => {
        if (value.docs.length == 0) {
          return {}
        } else {
          var user = value.docs.at(0).data()
          user.userID = value.docs.at(0).id
          return user
        }
      })
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * ve se o email ou numero de telemovel existem
 * @param {String} emailOrNum 
 * @returns 
 */
export async function checkIfUserExistsEmaiLOrNum(emailOrNum) {
  try {
    var email = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", emailOrNum)
      .get()
      .then(value => {
        
        if (value.docs.length == 0) {
          return []
        } else {
          var user = value.docs.at(0).data()
          user.userID = value.docs.at(0).id
          return [user]
        }
      })
    
    var numeroDeTele = await firebase
      .firestore()
      .collection("users")
      .where("numeroTele", "==", emailOrNum)
      .get()
      .then(value => {
        
        var listaAux = []
        value.docs.forEach((element) => {
          var user = element.data()
          user.userID = element.id
          listaAux.push(user)
        })
        
        return listaAux;
      })
    
      
      var concataneda = email.concat(numeroDeTele) ;
      return concataneda;
  } catch (error) {
    console.log(error)
    return null
  }
}

/* export async function checkIfAllUsersExist({
  email1,
  email2,
  email3,
  email4,
  pagamento1,
  pagamento2,
  pagamento3,
  pagamento4,
}) {
  var listaDeEmails = []
  var listaDeEmailsInvalidos = []
  var mapEmailsIds = {}

  await checkIfUserExists(email1).then(userSnap => {
    mapEmailsIds[email1] = null
    if (userSnap == null) {
      if (pagamento1 == "saldo") {
        listaDeEmailsInvalidos.add(email1)
      } else {
        listaDeEmails.add(email1)
      }
    } else {
      mapEmailsIds[email1] = userSnap.id
    }
  })

  await checkIfUserExists(email2).then(userSnap => {
    mapEmailsIds[email2] = null
    if (userSnap == null) {
      if (pagamento2 == "saldo") {
        listaDeEmailsInvalidos.add(email2)
      } else {
        listaDeEmails.add(email2)
      }
    } else {
      mapEmailsIds[email2] = userSnap.id
    }
  })

  await checkIfUserExists(email3).then(userSnap => {
    mapEmailsIds[email3] = null
    if (userSnap == null) {
      if (pagamento3 == "saldo") {
        listaDeEmailsInvalidos.add(email3)
      } else {
        listaDeEmails.add(email3)
      }
    } else {
      mapEmailsIds[email3] = userSnap.id
    }
  })

  await checkIfUserExists(email4).then(userSnap => {
    mapEmailsIds[email4] = null
    if (userSnap == null) {
      if (pagamento4 == "saldo") {
        listaDeEmailsInvalidos.add(email4)
      } else {
        listaDeEmails.add(email4)
      }
    } else {
      mapEmailsIds[email4] = userSnap.id
    }
  })

  if (listaDeEmailsInvalidos.length == 0) {
    return [listaDeEmails, mapEmailsIds]
  } else {
    var strg =
      "É necessário conta para efetuar o pagamento através de saldo. Os emails\n"
    listaDeEmailsInvalidos.forEach(element => {
      strg = strg + element + "\n"
    })
    strg += "não estão registados"
    return strg
  }
} */

function getRandomQrcode(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

/* async function adicionarReservaALocalizacao(localID, reserva, reservaID) {
  try {
    firebase.firestore().collection(localizacaoCollection).doc(localID).set(
      {
        reservas: { reservaID:
          {reservadaPor:  }},
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
} */

export async function criarNovaReserva(
  reservav,
  mapDosValoresDosUsers,
  semRegisto
) {
  console.log("Map dos Valores dos Users: id em criar nova reserva ")
  console.log(mapDosValoresDosUsers)
  var reserva = new Reserva()
  reserva = reservav

  var qrCode1 = getRandomQrcode(1, 4194302)
  var qrCode2 = getRandomQrcode(4194303, 8388608)
  var qrCode3 = getRandomQrcode(8388609, 12582911)
  var qrCode4 = getRandomQrcode(12582912, 16777215)

  var docRef = firebase.firestore().collection("reservas").doc()

  var horaFinal = addMinutes(
    reserva.horaDaReserva,
    parseInt(reserva.duracaoDaReserva)
  )

  var horaFinalUTC = addMinutes(horaFinal, 60)
  var horaReservaUTC = addMinutes(reserva.horaDaReserva, 60)

  var horarioTimeStamp = firebase.firestore.Timestamp.fromDate(
    horaReservaUTC
  )
  var horarioFinalTimeStamp = firebase.firestore.Timestamp.fromDate(horaFinalUTC)
  var createdAt = firebase.firestore.Timestamp.fromDate(new Date())

  var expiresIn = firebase.firestore.Timestamp.fromDate(
    addMinutes(new Date(), 5)
  )

  if (typeof mapDosValoresDosUsers[0].userID == "undefined") {
    mapDosValoresDosUsers[0].userID = null
  }
  if (typeof mapDosValoresDosUsers[1].userID == "undefined") {
    mapDosValoresDosUsers[1].userID = null
  }
  if (typeof mapDosValoresDosUsers[2].userID == "undefined") {
    mapDosValoresDosUsers[2].userID = null
  }
  if (typeof mapDosValoresDosUsers[3].userID == "undefined") {
    mapDosValoresDosUsers[3].userID = null
  }

  var valorTotalPagoAux = 0
  if (reserva.estado1.tipo == "Numerário") {
    valorTotalPagoAux = valorTotalPagoAux + reserva.estado1.valor
  }
  if (reserva.estado2.tipo == "Numerário") {
    valorTotalPagoAux = valorTotalPagoAux + reserva.estado2.valor
  }
  if (reserva.estado3.tipo == "Numerário") {
    valorTotalPagoAux = valorTotalPagoAux + reserva.estado3.valor
  }
  if (reserva.estado4.tipo == "Numerário") {
    valorTotalPagoAux = valorTotalPagoAux + reserva.estado4.valor
  }
  console.log("reservav")
  console.log(reservav)
  /*  await gerarReferenciaMultibanco(reserva, docRef) */
  await gerarReferenciaMbway(reserva, docRef.id)
  try {
    await firebase
      .firestore()
      .collection("reservas")
      .doc(docRef.id)
      .set({
        criadaPor: JSON.parse(localStorage.getItem("authUser")).email,
        isUpdated: reserva.isUpdated,
        jogadores: reserva.jogadores,
        jogador1estado: {
          qrcode: qrCode1,
          valorPago:
            reserva.estado1.tipo == "Numerário" ? reserva.estado1.valor : 0,
          numertl: reserva.estado1.numertl,
          nome: reserva.estado1.nome,
          cc: reserva.estado1.numeroCC,
          email: reserva.estado1.email,
          estado: reserva.estado1.estado,
          tipo: reserva.estado1.tipo,
          valor: reserva.estado1.valor,
          userID: mapDosValoresDosUsers[0].userID,
          contrato: 0,
        },
        jogador2estado: {
          qrcode: qrCode2,
          valorPago:
            reserva.estado2.tipo == "Numerário" ? reserva.estado2.valor : 0,
          numertl: reserva.estado2.numertl,
          nome: reserva.estado2.nome,
          cc: reserva.estado2.numeroCC,
          email: reserva.estado2.email,
          estado: reserva.estado2.estado,
          tipo: reserva.estado2.tipo,
          valor: reserva.estado2.valor,
          userID: mapDosValoresDosUsers[1].userID,
          contrato: 0,
        },
        jogador3estado: {
          qrcode: qrCode3,
          valorPago:
            reserva.estado3.tipo == "Numerário" ? reserva.estado3.valor : 0,
          numertl: reserva.estado3.numertl,
          nome: reserva.estado3.nome,
          cc: reserva.estado3.numeroCC,
          email: reserva.estado3.email,
          estado: reserva.estado3.estado,
          tipo: reserva.estado3.tipo,
          valor: reserva.estado3.valor,
          userID: mapDosValoresDosUsers[2].userID,
          contrato: 0,
        },
        jogador4estado: {
          qrcode: qrCode4,
          valorPago:
            reserva.estado4.tipo == "Numerário" ? reserva.estado4.valor : 0,
          numertl: reserva.estado4.numertl,
          nome: reserva.estado4.nome,
          cc: reserva.estado4.numeroCC,
          email: reserva.estado4.email,
          estado: reserva.estado4.estado,
          tipo: reserva.estado4.tipo,
          valor: reserva.estado4.valor,
          userID: mapDosValoresDosUsers[3].userID,
          contrato: 0,
        },
        createdAt: createdAt,
        expiresIn: expiresIn,
        duracao: reserva.duracaoDaReserva,
        campo: reserva.nomeDoCampo,
        horaIncial: horarioTimeStamp,
        horaFinal: horarioFinalTimeStamp,
        userID: reserva.userID,
        estado:
          valorTotalPagoAux == reserva.valorTotal ? "Confirmada" : "Pendente",
        jogadores: [
          reserva.estado1.email,
          reserva.estado2.email,
          reserva.estado3.email,
          reserva.estado4.email,
        ],
        valorTotal: reserva.valorTotal,
        valorTotalPago: valorTotalPagoAux,
        localizacao: reserva.localizacao,
      })
    console.log("adicionaReservaAosUtilizadores")
    console.log("Reservada por: ", JSON.parse(localStorage.getItem("authUser")).email)
    await adicionaReservaAosUtilizadores(
      reserva,
      mapDosValoresDosUsers,
      docRef.id
    )
    /*  await adicionarReservaALocalizacao(localID, reserva, docRef.id) */
    console.log("Reserva Adicionada Com suceesso")
  } catch (error) {
    console.log(error)
  }
}



export async function criarNovaReservaAPI(
  reservav,
  mapDosValoresDosUsers
) {
  try {
    var reserva = new Reserva()
  reserva = reservav;
  if (typeof mapDosValoresDosUsers[0].userID == "undefined") {
    mapDosValoresDosUsers[0].userID = null
  }
  if (typeof mapDosValoresDosUsers[1].userID == "undefined") {
    mapDosValoresDosUsers[1].userID = null
  }
  if (typeof mapDosValoresDosUsers[2].userID == "undefined") {
    mapDosValoresDosUsers[2].userID = null
  }
  if (typeof mapDosValoresDosUsers[3].userID == "undefined") {
    mapDosValoresDosUsers[3].userID = null
  }

  reserva.estado1.userID = mapDosValoresDosUsers[0].userID
  reserva.estado2.userID = mapDosValoresDosUsers[1].userID
  reserva.estado3.userID = mapDosValoresDosUsers[2].userID
  reserva.estado4.userID = mapDosValoresDosUsers[3].userID
  
  console.log("Hora Da Reserva")
  console.log(reserva.horaDaReserva)


  const dia = ("0" + reserva.horaDaReserva.getDate()).slice(-2);
  const mes = ("0" + (reserva.horaDaReserva.getMonth() + 1)).slice(-2);
  const ano = reserva.horaDaReserva.getYear() + 1900;
  const sec = ("0" + reserva.horaDaReserva.getSeconds() ).slice(-2);
  const min = ("0" + reserva.horaDaReserva.getMinutes() ).slice(-2);
  const hor = ("0" + reserva.horaDaReserva.getHours() ).slice(-2);

  const dateStringInicial = ano + "-" +
  mes + "-" + dia + " " +
  hor + ":" + min + ":" + sec;

  console.log(dateStringInicial)

  var qParams = {
    'duracao': reserva.duracaoDaReserva,
    'estado1': JSON.stringify(reserva.estado1),
    'estado2': JSON.stringify(reserva.estado2),
    'estado3': JSON.stringify(reserva.estado3),
    'estado4': JSON.stringify(reserva.estado4), 
    'horaDaReserva': dateStringInicial,
    'localizacao': reserva.localizacao,
    'nomeDoCampo': reserva.nomeDoCampo,
    'userID': reserva.userID,
    'valorTotal': reserva.valorTotal.toString() ,
  };

     const respo = await axios.get("https://us-central1-napadle.cloudfunctions.net/criarNovaReservaBackoffice",  { 
      params: qParams })
    
    console.log("Response")
    console.log(respo.data); 
        return respo.data;
  } catch (error) {
    console.log(error)
    return null;
  }
  
}

async function adicionaReservaAosUtilizadores(
  reserva,
  mapDosValoresDosUsers,
  reservaID
) {
  console.log("BP0")
  var listaAux = [].concat(mapDosValoresDosUsers)
  var reservaAux = new Reserva()
  reservaAux = reserva
  var firebaseReserva = reservaAux.toHistoricoDeReservasDoUser()
  console.log(listaAux)
  console.log(firebaseReserva)

  try {
    for (var elemnt of listaAux) {
      if (element.userID != null) {
        console.log(elemnt)
        await firebase
          .firestore()
          .collection(usersCollection)
          .doc(elemnt.userID)
          .set(
            {
              historicoDePagamentos: { [reservaID]: firebaseReserva },
            },
            { merge: true }
          )
      }
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

async function createMbwayPayment(
  userId,
  tipo,
  valorAAdicionar,
  reservaId,
  localizacao,
  numeroDeTelemovel,
  valor,
  email
) {
  try {
    console.log("createMbwayPayment")
    var documentID = firebase
      .firestore()
      .collection(pagamentosMBwayCollection)
      .doc().id
    return gerarMBwayPayment(valor, numeroDeTelemovel, email).then(
      async value => {
        if (value != null) {
          var json = value.data
          return gravaPagamentoDeMbway(
            json["IdPedido"],
            email,
            valorAAdicionar,
            reservaId,
            documentID,
            userId,
            localizacao,
            numeroDeTelemovel,
            tipo,
            json["Valor"],
            json["Estado"]
          ).then(value => true)
        } else {
          return false
        }
      }
    )
  } catch (e) {
    console.log(e)
    return false
  }
}

/* async function criaReferenciaMultibancoViaAPI(  valor) {
  try {
    var url =
        Uri.parse('https://ifthenpay.com/api/multibanco/reference/init');
    var response = await http.post(url,
        "body" : jsonEncode({
          "mbKey": "XZZ-289765",
          "orderId": "order1234",
          "amount": valor
        }));
    print(response.body);
    print('Multibanco criado com sucesso');
    return jsonDecode(response.body);
  } catch (e) {
    print(e);
    print('eero');
    return null;
  }
} */

export async function gerarMBwayPayment(valor, nrtlm, email) {
  console.log("A gerar pagamentos de MBWAy")
  console.log(nrtlm)
  try {
    var canal = "03"
    var mbWayKey = "WEJ-596359"
    var documentID = firebase.firestore().collection("pagamentosMBway").doc().id

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    }
    const formData = new URLSearchParams()
    formData.append("MbWayKey", mbWayKey)
    formData.append("canal", canal)
    formData.append("referencia", documentID.substring(0, 14))
    formData.append("valor", valor)
    formData.append("nrtlm", nrtlm)
    formData.append("email", email)
    formData.append("descricao", "[REFERENCIA]")

    return axios
      .post(
        "https://mbway.ifthenpay.com/ifthenpaymbw.asmx/SetPedidoJSON",
        formData,
        { headers }
      )
      .then(function (response) {
        console.log(response.data)
        console.log(response.data.IdPedido)
        console.log(response.data["IdPedido"])
        return response
      })
      .catch(function (error) {
        console.log(error)
        console.log(error.response)
      })
  } catch (e) {
    console.log(e)
    return null
  }
}

async function gravaPagamentoDeMbway(
  idPedido,
  email,
  valorAAdicionar,
  reservaId,
  documentId,
  userId,
  localizacao,
  nrmtl,
  tipo,
  valor,
  estado
) {
  try {
    console.log("A adicionar pagamentos de MBWAy")
    await firebase
      .firestore()
      .collection("pagamentosMBway")
      .doc(documentId)
      .set({
        email: email,
        valorAAdicionar: valorAAdicionar,
        reservaId: reservaId,
        userId: userId,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        valor: valor,
        tipo: tipo,
        idpedido: idPedido,
        estado: estado,
        numeroDeTelemovel: nrmtl,
        datahorapag: "",
        localizacao: localizacao,
      })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

async function gerarReferenciaMbway(reserva, reservaId) {
  console.log("A gerarReferenciaMbway ")
  try {
    if (reserva.estado1.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado1.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado1.numertl,
        reserva.estado1.valor.toString(),
        reserva.estado1.email
      )
    }
    if (reserva.estado2.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado2.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado2.numertl,
        reserva.estado2.valor.toString(),
        reserva.estado2.email
      )
    }
    if (reserva.estado3.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado3.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado3.numertl,
        reserva.estado3.valor.toString(),
        reserva.estado3.email
      )
    }
    if (reserva.estado4.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado4.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado4.numertl,
        reserva.estado4.valor.toString(),
        reserva.estado4.email
      )
    }
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

async function gerarReferenciaMultibanco(reserva, reservaId) {
  try {
    if (reserva.estado1.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado1.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado1.numertl,
        reserva.estado1.valor.toString(),
        reserva.estado1.email
      )
    }
    if (reserva.estado2.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado2.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado2.numertl,
        reserva.estado2.valor.toString(),
        reserva.estado2.email
      )
    }
    if (reserva.estado3.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado3.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado3.numertl,
        reserva.estado3.valor.toString(),
        reserva.estado3.email
      )
    }
    if (reserva.estado4.tipo == "MBway") {
      createMbwayPayment(
        reserva.userID,
        "Pagamento",
        reserva.estado4.valor.toString(),
        reservaId,
        reserva.localizacao,
        reserva.estado4.numertl,
        reserva.estado4.valor.toString(),
        reserva.estado4.email
      )
    }
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

export async function getAllReservas(localizacao) {
  try {
    return firebase
      .firestore()
      .collection(reservasCollection)
      .where("localizacao", "==", localizacao)
      .onSnapshot(docsSnaps => {
        console.log(docsSnaps.docs)
        return docsSnaps.docs
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getReservaById(docId) {
  try {
    return firebase
      .firestore()
      .collection(reservasCollection)
      .doc(docId)
      .get()
      .then(value => {
        return value.data()
      })
  } catch (error) {
    console.error(error)
    return null
  }
}

function escolheMelhoresDescontosParaCadaEmail(
  listaDeEmails,
  dataDaReserva,
  mapEmailClasses,
  mapDescontos
) {
  var mapEmailValorAPAgar = {}
  var mapAUXDES = {}
  var date = new Date()
  date = dataDaReserva
  var diaParaVerSeEFeriado =
    date.getDate() + "-" + (date.getMonth() + 1).toString()

  date.getDate()
  for (var i = 0; i < listaDeEmails.length; i++) {
    if (
      date.getDay() < 6 ||
      date.getDay() == 0 ||
      listaDeFeriados2022[diaParaVerSeEFeriado] != null
    ) {
      if (mapEmailClasses[listaDeEmails[i]] != null) {
        mapEmailClasses[listaDeEmails[i]].forEach(
          (keyClasse, valueExpiraEm) => {
            if (
              mapDescontos[keyClasse]["Semana"] != null ||
              mapDescontos[keyClasse]["Semana"].isNotEmpty
            ) {
            }
          }
        )
      }
    }
  }
}

export async function anularReservaPorUser(docId) {
  try {
    firebase.firestore().collection("reservas").doc(docId).set(
      {
        canceladaPeloUser: true,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function anularReservaBackoffice(docId) {
  try {
    firebase.firestore().collection("reservas").doc(docId).set(
      {
        canceladaPeloBOFC: true,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.log(error)
    return null
  }
}



export async function bloquearHorario(docId, hb, startDate, endDate, nome) {
  var mapAux = []
  mapAux = hb
  const timestampStart = firebase.firestore.Timestamp.fromDate(startDate)
  const timestampEnd = firebase.firestore.Timestamp.fromDate(endDate)

  mapAux[nome] = {}
  mapAux[nome].horaInicial = timestampStart
  mapAux[nome].horaFinal = timestampEnd

  try {
    firebase.firestore().collection("localizacao").doc(docId).set(
      {
        horariosBloqueadosJson: mapAux,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function removerHorarioBloq(docId, hb) {
  try {
    firebase.firestore().collection("localizacao").doc(docId).set(
      {
        horariosBloqueadosJson: hb,
      },
      { merge: true }
    )
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

function diffInHours(date1, date2) {
  var diff = date2.valueOf() - date1.valueOf()
  var diffInHours = diff / 1000 / 60 / 60

  return diffInHours.toFixed(1)
}

export async function bloquearProximoFimDeSemana(localizacao,
  nomes,
  campos) {

  /*var date1 = new Date(2024,1,16,19)
  var date2 = new Date(2024,1,17,0,30 )*/

  var date3 = new Date(2024,1,15,20)
  var date4 = new Date(2024,1,15,21)

  /* var date5 = new Date(2024,1,18,7)
  var date6 = new Date(2024,1,19,0,30)  */

  /* var date7 = new Date(2024,0,1,7)
  var date8 = new Date(2024,0,2,0,30)

  var date9 = new Date(2024,0,7,13,30)
  var date10 = new Date(2024,0,7,16,0)

  var date11 = new Date(2024,0,7,20)
  var date12 = new Date(2024,0,8,0) */

  /*   console.log(date1)
  console.log(date2)

console.log(date3)
  console.log(date4)

  console.log(date5)
  console.log(date6) */

 /*  console.log(date7)
  console.log(date8)
  console.log(date9)
  console.log(date10)
  console.log(date11)
  console.log(date12) */

 /* await bloquearComReservasSemAdicionarALocalizacao(date1,date2,localizacao,nomes,campos)    */
   await bloquearComReservasSemAdicionarALocalizacao(date3,date4,localizacao,nomes,campos)  
  /* await bloquearComReservasSemAdicionarALocalizacao(date5,date6,localizacao,nomes,campos)   */
 /*  await bloquearComReservasSemAdicionarALocalizacao(date7,date8,localizacao,nomes,campos) 
  await bloquearComReservasSemAdicionarALocalizacao(date9,date10,localizacao,nomes,campos) 
  await bloquearComReservasSemAdicionarALocalizacao(date11,date12,localizacao,nomes,campos)       */
   
}

export async function bloquearTotal(localizacao,
  nomes,
  campos) {

  var date1 = new Date(2023,11,8,7)
  var date2 = new Date(2023,11,9,8,)

  var date3 = new Date(2023,11,9,7,)
  var date4 = new Date(2023,11,10,0)

  var date5 = new Date(2023,11,10,7)
  var date6 = new Date(2023,11,11,0)

  console.log(date1)
  console.log(date2)

  console.log(date3)
  console.log(date4)

  console.log(date5)
  console.log(date6)


  //await bloquearComReservasSemAdicionarALocalizacao(date1,date2,localizacao,nomes,campos)   
  await bloquearComReservasSemAdicionarALocalizacao(date3,date4,localizacao,nomes,campos)  
  await bloquearComReservasSemAdicionarALocalizacao(date5,date6,localizacao,nomes,campos)     
   
}

export async function bloquearComReservasSemAdicionarALocalizacao(
  horaInicial,
  horaFinal,
  localizacao,
  nome,
  campos
) {

  const difInHoursOriginal = diffInHours(horaInicial, horaFinal)
  const decimal = (difInHoursOriginal + "").split(".");
  const diffInMinutes = decimal[1]
  const difInHours = decimal[0]
  
  try {
    if(diffInMinutes == 5) {
      for (let hours = 1; hours <= difInHours; hours++) {
        for (let campo = 0; campo < campos.length; campo++) {
          if(hours == difInHours) {
            console.log("A bloquer: ", campos[campo])
            const hi = addMinutes(horaInicial, 60 * (hours - 1))
            const hf = addMinutes(horaInicial, 60 * hours + 30)
            const campoV = campos[campo]
  
          await bloquearReservasComCriacaoDeReservas(hi, hf, localizacao, campoV,90,nome)
          } else {
          console.log("A bloquer: ", campos[campo])
          const hi = addMinutes(horaInicial, 60 * (hours - 1))
          const hf = addMinutes(horaInicial, 60 * hours)
          const campoV = campos[campo]
          await bloquearReservasComCriacaoDeReservas(hi, hf, localizacao, campoV,60,nome)
        }
        }
      }
    }
    else{
    for (let hours = 1; hours <= difInHours; hours++) {
      for (let campo = 0; campo < campos.length; campo++) {
        console.log("A bloquer: ", campos[campo])
        const hi = addMinutes(horaInicial, 60 * (hours - 1))
        const hf = addMinutes(horaInicial, 60 * hours)
        const campoV = campos[campo]

        await bloquearReservasComCriacaoDeReservas(hi, hf, localizacao, campoV,60,nome)
      }
    }}

    return true
  } catch (error) {
    console.log(error)
    return null
  } 
}


export async function bloquearComReservas(
  horaInicial,
  horaFinal,
  localizacao,
  docID,
  horariosBloq,
  nome,
  campos
) {

   var mapAux = {}
  mapAux = horariosBloq
  const timestampStart = firebase.firestore.Timestamp.fromDate(horaInicial)
  const timestampEnd = firebase.firestore.Timestamp.fromDate(horaFinal)

  mapAux[nome] = {}
  mapAux[nome].horaInicial = timestampStart
  mapAux[nome].horaFinal = timestampEnd
  mapAux[nome].campos = campos

  const difInHoursOriginal = diffInHours(horaInicial, horaFinal)
  const decimal = (difInHoursOriginal + "").split(".");
  const diffInMinutes = decimal[1]
  const difInHours = decimal[0]
  
  try {

    if(diffInMinutes == 5) {
      for (let hours = 1; hours <= difInHours; hours++) {
        for (let campo = 0; campo < campos.length; campo++) {
          if(hours == difInHours) {
            console.log("A bloquer: ", campos[campo])
            const hi = addMinutes(horaInicial, 60 * (hours - 1))
            const hf = addMinutes(horaInicial, 60 * hours + 30)
            const campoV = campos[campo]
  
          await bloquearReservasComCriacaoDeReservas(hi, hf, localizacao, campoV,90,nome)
          } else {
          console.log("A bloquer: ", campos[campo])
          const hi = addMinutes(horaInicial, 60 * (hours - 1))
          const hf = addMinutes(horaInicial, 60 * hours)
          const campoV = campos[campo]
          await bloquearReservasComCriacaoDeReservas(hi, hf, localizacao, campoV,60,nome)
        }
        }
      }
    }
    else{
    for (let hours = 1; hours <= difInHours; hours++) {
      for (let campo = 0; campo < campos.length; campo++) {
        console.log("A bloquer: ", campos[campo])
        const hi = addMinutes(horaInicial, 60 * (hours - 1))
        const hf = addMinutes(horaInicial, 60 * hours)
        const campoV = campos[campo]

        await bloquearReservasComCriacaoDeReservas(hi, hf, localizacao, campoV,60,nome)
      }
    }}
    await firebase.firestore().collection("localizacao").doc(docID).set(
      {
        horariosBloqueadosJson: mapAux,
      },
      { merge: true }
    )

    return true
  } catch (error) {
    console.log(error)
    return null
  } 
}

export async function removeBloqueioAnterio(
  docID,
  horaInicial,
  horaFinal,
  localizacao,
  nome
) {
  /* var novoMap = delete horariosBloq[nome] */
  var horaControloInicial = addMinutes(horaInicial, -120)
  var horaControloFinal = addMinutes(horaFinal, 120)

  const timestampStart = firebase.firestore.Timestamp.fromDate(horaInicial)
  const timestampEnd = firebase.firestore.Timestamp.fromDate(horaFinal)
  const timeStampControlStart =
    firebase.firestore.Timestamp.fromDate(horaControloInicial)
  const timeStampControlEnd =
    firebase.firestore.Timestamp.fromDate(horaControloFinal)
  console.log("Horas Bloq")
  console.log(docID)

  try {

    await firebase
      .firestore()
      .collection("reservas")
      .where("horaIncial", "<", timeStampControlEnd)
      .where("horaIncial", ">=", timeStampControlStart)
      .where("localizacao", "==", localizacao)
      .where("bloqueada", "==", true)
      .get()
      .then(async value => {
        console.log("A REMOVER: ", value.docs)
        for (const elem of value.docs) {
          if(typeof elem.data().nome == "undefined") {
            console.log(elem)
          try {
            console.log("A remover: ", elem.id)
            console.log(elem.data())
            await firebase
              .firestore()
              .collection("reservas")
              .doc(elem.id)
              .delete()
          } catch (error) {
            console.log(error)
          }
          } else 
          if(elem.data().nome == nome) {
            try {
              console.log("A remover: ", elem.id)
              console.log(elem.data())
              await firebase
                .firestore()
                .collection("reservas")
                .doc(elem.id)
                .delete()
            } catch (error) {
              console.log(error)
            }
          }
          
          
        }
      })
      return true
  } catch (error) {
    
    console.log(error)
    return false
  }
}


export async function deleteBloqueio(
  docID,
  horaInicial,
  horaFinal,
  localizacao,
  horarBloq,
  nome
) {
  /* var novoMap = delete horariosBloq[nome] */
  var horaControloInicial = addMinutes(horaInicial, -120)

  var horaControloFinal = addMinutes(horaFinal, 120)

  const timestampStart = firebase.firestore.Timestamp.fromDate(horaInicial)
  const timestampEnd = firebase.firestore.Timestamp.fromDate(horaFinal)
  const timeStampControlStart =
    firebase.firestore.Timestamp.fromDate(horaControloInicial)
  const timeStampControlEnd =
    firebase.firestore.Timestamp.fromDate(horaControloFinal)
  console.log("Horas Bloq")
  console.log(horarBloq)
  console.log(docID)

  try {
    await firebase.firestore().collection("localizacao").doc(docID).update(
      {
        horariosBloqueadosJson: horarBloq,
      },
      { merge: true }
    )

    await firebase
      .firestore()
      .collection("reservas")
      .where("horaIncial", "<", timeStampControlEnd)
      .where("horaIncial", ">=", timeStampControlStart)
      .where("localizacao", "==", localizacao)
      .where("bloqueada", "==", true)
      .get()
      .then(async value => {
        console.log("A REMOVER: ", value.docs)
        for (const elem of value.docs) {
          if(typeof elem.data().nome == "undefined") {
            console.log(elem)
          try {
            console.log("A remover: ", elem.id)
            console.log(elem.data())
            await firebase
              .firestore()
              .collection("reservas")
              .doc(elem.id)
              .delete()
          } catch (error) {
            console.log(error)
          }
          } else 
          if(elem.data().nome == nome) {
            try {
              console.log("A remover: ", elem.id)
              console.log(elem.data())
              await firebase
                .firestore()
                .collection("reservas")
                .doc(elem.id)
                .delete()
            } catch (error) {
              console.log(error)
            }
          }
          
          
        }
      })
      return true;
  } catch (error) {
    
    console.log(error)
    return null
  }
}

/* export async function bloquearReservasEndp(horaInicial,
  horaFinal,
  localizacao,
  campo,
  duracao,
  nome) {
    await axios.get("https://us-central1-napadle.cloudfunctions.net/bloquearReservasComCriacaoDeReservas", {
      params: {
        horaInicial: horaInicial,
        horaFinal: horaFinal,
        localizacao: localizacao,
        campo: campo,
        duracao: duracao,
        nome: nome,
      }
    }).then((value) => {
      console.log(value.data)
    }).catch((e) => {
      console.log(e)
    });
} */

export async function bloquearReservasComCriacaoDeReservas(
  horaInicial,
  horaFinal,
  localizacao,
  campo,
  duracao,
  nome
) {
  try {
    await firebase
      .firestore()
      .collection("reservas")
      .add({
        nome: nome,
        isUpdated: false,
        jogadores: [
          "bloqueia@greatpadel.com",
          "bloqueia@greatpadel.com",
          "bloqueia@greatpadel.com",
          "bloqueia@greatpadel.com",
        ],
        jogador1estado: {
          qrcode: 0,
          valorPago: 0,
          numertl: null,
          nome: null,
          cc: null,
          email: null,
          estado: "Bloqueada",
          tipo: "Bloqueada",
          valor: 0,
          userID: null,
          contrato: 0,
        },
        jogador2estado: {
          qrcode: 0,
          valorPago: 0,
          numertl: null,
          nome: null,
          cc: null,
          email: null,
          estado: "Bloqueada",
          tipo: "Bloqueada",
          valor: 0,
          userID: null,
          contrato: 0,
        },
        jogador3estado: {
          qrcode: 0,
          valorPago: 0,
          numertl: null,
          nome: null,
          cc: null,
          email: null,
          estado: "Bloqueada",
          tipo: "Bloqueada",
          valor: 0,
          userID: null,
          contrato: 0,
        },
        jogador4estado: {
          qrcode: 0,
          valorPago: 0,
          numertl: null,
          nome: null,
          cc: null,
          email: null,
          estado: "Bloqueada",
          tipo: "Bloqueada",
          valor: 0,
          userID: null,
          contrato: 0,
        },
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        expiresIn: null,
        duracao: duracao,
        campo: campo,
        horaIncial: horaInicial,
        horaFinal: horaFinal,
        userID: null,
        estado: "Confirmada",
        bloqueada: true,
        valorTotal: 0,
        valorTotalPago: 0,
        localizacao: localizacao,
      })
    return true
  } catch (e) {
    console.log(e)
    return null
  }
}



export async function criaReservasDosContratos() {
  const qrCode1 = getRandomQrcode(1, 4194302);
  const qrCode2 = getRandomQrcode(4194303, 8388608);
  const qrCode3 = getRandomQrcode(8388609, 12582911);
  const qrCode4 = getRandomQrcode(12582912, 16777215);

  const date = new Date();
  const day = date.getDay();
  try {
    var jogador1Email = "asd@asd.asd"
    var jogador2Email = "jogador2@greatpadel.pt"
    var jogador3Email = "jogador3@greatpadel.pt"
    var jogador4Email = "jogador4@greatpadel.pt"

    await firebase
        .firestore()
        .collection("reservas")
        .add({
          isUpdated: false,
          jogadores: [jogador1Email,jogador2Email,jogador3Email,jogador4Email],
          jogador1estado: {
            qrcode: qrCode1,
            valorPago: 0,
            numertl: "",
            nome: "asd",
            cc: "010101",
            email: jogador1Email,
            estado: "Confirmada",
            tipo: "contrato",
            valor: 0,
            userID: "",
            contrato: 1,
          },
          jogador2estado: {
            qrcode: qrCode2,
            valorPago: 0,
            numertl: "",
            nome: "asd2",
            cc: "0101012",
            email: jogador2Email,
            estado: "Confirmada",
            tipo: "contrato",
            valor: 0,
            userID: "",
            contrato: 1,
          },
          jogador3estado: {
            qrcode: qrCode3,
            valorPago: 0,
            numertl: "",
            nome: "asd3",
            cc: "0101031",
            email: jogador3Email,
            estado: "Confirmada",
            tipo: "contrato",
            valor: 0,
            userID: "",
            contrato: 1,
          },
          jogador4estado: {
            qrcode: qrCode4,
            valorPago: 0,
            numertl: "",
            nome: "asd4",
            cc: "01010341",
            email: jogador4Email,
            estado: "Confirmada",
            tipo: "contrato",
            valor: 0,
            userID: "",
            contrato: 1,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          expiresIn: firebase.firestore.Timestamp.fromDate(new Date()),
          duracao: "60",
          campo: "Campo 1",
          horaIncial: firebase.firestore.Timestamp.fromDate( new Date(2023,10,21, 7)),
          horaFinal: firebase.firestore.Timestamp.fromDate(new Date(2023,10,21, 8)),
          userID: null,
          estado: "Confirmada",
          valorTotal: 0,
          valorTotalPago: 0,
          localizacao: "Great Padel Vila Verde",
        });
    console.log("BP4");
        return true;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function asdasdasd() {
  /* try {
    const newDate= addMinutes(new Date(), 500)
    await firebase
    .firestore()
    .collection("reservas2").doc("yDKuha84dM5qYOxs2Qz5").update({
      horaIncial: firebase.firestore.Timestamp.fromDate( newDate),
      canceladaPeloUser: true, 
      horaDaEdicao: firebase.firestore.Timestamp.fromDate( new Date()),
    })
    return true
  } catch (error) {
    console.log(error)
    return false
  } */
    try {
      /* const newDate= addMinutes(new Date(), 500) */
      await firebase
        .firestore()
        .collection("reservas2").doc("0swxIIA5Llu2R7o1G62I").update({
          canceladaPeloUser: true,
          novaHora: firebase.firestore.Timestamp.fromDate( new Date() )
        })
        await firebase
        .firestore()
        .collection("reservas2").doc("0swxIIA5Llu2R7o1G62I").update({
          patig: firebase.firestore.Timestamp.fromDate( new Date() )
        })
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  
}


/*
 *
 *
 */

/* async function
      veSeHaConflitoDeHorariosNasReservasRetornaNomeDosCamposIndisponiveis(
           procuraReserva)  {
    List<String> listaDeNomesDosCamposIndisponiveis = [];

    try {
      await reservasCollection
          .where('localizacao', isEqualTo: reserva.localizacao)
          .where('horaIncial',
              isGreaterThanOrEqualTo: Timestamp.fromDate(
                  reserva.horaDaReserva.subtract(const Duration(hours: 2))))
          .where('horaIncial',
              isLessThan: Timestamp.fromDate(reserva.horaDaReserva
                  .add(Duration(minutes: int.parse(reserva.duracaoDaReserva)))))
          .get()
          .then((value) {
        print(
            'Resultado de ir buscar reservas duas horas antes da hora inicial:' +
                value.docs.toString());
        List listaDeCampos = value.docs;

        if (listaDeCampos.isNotEmpty && listaDeCampos != []) {
          for (var element in listaDeCampos) {
            if (element['estado'] != 'Anulada') {
              Timestamp hi = element['horaIncial'];
              Timestamp hf = element['horaFinal'];
              Timestamp rhi = Timestamp.fromDate(reserva.horaDaReserva);
              Timestamp rhf = Timestamp.fromDate(reserva.horaDaReserva
                  .add(Duration(minutes: int.parse(reserva.duracaoDaReserva))));

              if ((rhi.compareTo(hi) <= 0) && (hi.compareTo(rhf) < 0)) {
                listaDeNomesDosCamposIndisponiveis.add(element['campo']);
              }
              if ((hi.compareTo(rhi) <= 0) && (rhi.compareTo(hf) < 0)) {
                listaDeNomesDosCamposIndisponiveis.add(element['campo']);
              }
              if ((hi.compareTo(rhi) <= 0) && (rhf.compareTo(hf) <= 0)) {
                listaDeNomesDosCamposIndisponiveis.add(element['campo']);
              }
            }
          }
        }

        print('Resultado da pesquisa de Campos:' +
            listaDeNomesDosCamposIndisponiveis.toString());
      });
      //Coverter o horario da Reserva para um tempo compativel com o das aulas. Formato-> 00:00
      String _horaInicialCompativel = DateFormat('HH:mm')
          .format(reserva.horaDaReserva.subtract(const Duration(hours: 2)));

      String _horaIncialCompativel2 = DateFormat('HH:mm').format(reserva
          .horaDaReserva
          .add(Duration(minutes: int.parse(reserva.duracaoDaReserva))));

      DateTime horaFinal = reserva.horaDaReserva
          .add(Duration(minutes: int.parse(reserva.duracaoDaReserva)));

      String _horaFinalCompativel = DateFormat('HH:mm').format(horaFinal);

      await aulasCollection
          .where('localizacao', isEqualTo: reserva.localizacao)
          .where('weekDay', isEqualTo: reserva.horaDaReserva.weekday)
          .where('horaInicial', isGreaterThanOrEqualTo: _horaInicialCompativel)
          .where('horaInicial', isLessThan: _horaIncialCompativel2)
          .get()
          .then((value) {
        print(
          'AULAS Resultado de ir buscar reservas duas horas antes da hora inicial:' +
              value.docs.toString(),
        );

        List listaDeCampos = value.docs;

        if (listaDeCampos.isNotEmpty && listaDeCampos != []) {
          for (var element in listaDeCampos) {
            if (element['estado'] != 'Anulada') {
              String hi = element['horaInicial'];
              String hf = element['horaFinal'];
              String rhi = DateFormat('HH:mm').format(reserva.horaDaReserva);
              String rhf = DateFormat('HH:mm').format(reserva.horaDaReserva
                  .add(Duration(minutes: int.parse(reserva.duracaoDaReserva))));

              if ((rhi.compareTo(hi) <= 0) && (hi.compareTo(rhf) < 0)) {
                element['campos'].forEach((elem) {
                  listaDeNomesDosCamposIndisponiveis.add(elem);
                });
              }
              if ((hi.compareTo(rhi) <= 0) && (rhi.compareTo(hf) < 0)) {
                element['campos'].forEach((elem) {
                  listaDeNomesDosCamposIndisponiveis.add(elem);
                });
              }
              if ((hi.compareTo(rhi) <= 0) && (rhf.compareTo(hf) <= 0)) {
                element['campos'].forEach((elem) {
                  listaDeNomesDosCamposIndisponiveis.add(elem);
                });
              }
            }
          }
        }

        print('AULAS Resultado da pesquisa de Campos:' +
            listaDeNomesDosCamposIndisponiveis.toString());
      });
      return listaDeNomesDosCamposIndisponiveis;
    } catch (e) {
      print('AULAS' + e.toString());
      return [];
    }
  } */
  
export async function paraRemover() {
  
  await firebase
      .firestore()
      .collection("historicoDeAulas")
      .where("aula.professor", "==", "Contratos").limit(10)
      .get().then((value) => {
        value.forEach((doc) => {
          console.log(doc.data())
        })
      })
}