import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getPlanoDeNutricao, uploadPlanoDeNutricao } from 'services/nutricao/nutricao_services';
import { pesquisarJogadoresByEmail } from 'services/useres/jogadores_services';

function NutricaoScreen() {
    const [email, setEmail] = useState("")
    /* const [ficheiro, setFicheiro] = useState(null) */
    const [isUser, setIsUser] = useState(null)
    const [docId, setDocId] = useState(null)
    const [file,setFile] = useState(null)
    const [isLoading,setIsLoading] = useState(null)
    const [isLoadingAdicioanr,setIsLoadingAdicionar] = useState(null)
    const [downlaodURL, setDonwloadURL] = useState(null)


    const { t, i18n } = useTranslation()
    return (  
        <React.Fragment>
             <div className="page-content">
                <Container fluid>
                    <div className="page-title-box">
                        {/* Título */}
                        <Row className="align-items-center">
                            <Col md={10}>
                                <h6 className="page-title">{t("Nutrição")}</h6>
                            </Col>
                            <Col md={2}>
                               
                            </Col>
                        </Row>
                        <Row>
                            <Row style={{ alignItems: "center", paddingBottom: "20px", paddingTop: "20px" }}>
                                <Col md={1}>
                                    <Label>Email</Label>
                                </Col>
                                <Col md={4}>
                                    <Input
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setIsUser(null)
                                        setDonwloadURL(null)
                                        setFile(null)
                                    }}
                                    id="email" 
                                    type="email" defaultValue={""}></Input>
                                </Col>
                                <Col>
                                    { isLoading ? <Spinner></Spinner> :
                                    <Button  onClick={async() => {
                                        setIsLoading(true)
                                        const doc = await pesquisarJogadoresByEmail(email)
                                        if(doc != null){
                                            const docID = doc.id

                                        setDocId(docID)
                                        const nutriURL = await getPlanoDeNutricao(docID)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                        setIsLoading(false)
                                        } else {
                                            alert("O email não está registado")
                                        }
                                        setIsLoading(false)
                                    }} color='primary'>Pesquisar</Button>}
                                </Col>
                                </Row>
                                <Row>
                               
                                </Row>
                                <Row>
                                <Col hidden={!(isUser==true)}  >
                                 <Row>
                                    <Col md={8}>
                                    <object data={downlaodURL} type="application/pdf" width="100%" height="550px">
                                        
                                    </object>
                                    </Col>
                                    <h3 style={{paddingTop: "40px"}} >Adicionar novo ficheiro</h3>
                                    </Row>
                                    <Row style={{paddingTop: "40px", paddingBottom: "40px"}}>
                                    <Input onChange={(e) => {
                                        setFile(e.target.files[0])
                                    }} type='file' accept="application/pdf"></Input>
                                    </Row>
                                    <Row>
                                        <Col  md={6}>
                                    { isLoadingAdicioanr ? <Spinner></Spinner> :
                                    <Button onClick={async () => {
                                        setIsLoadingAdicionar(true)
                                        console.log("Ficheiro:" , file);
                                        const res = await uploadPlanoDeNutricao(docId,file)
                                       if(res) {
                                        alert("Ficheiro adicionado com sucesso")
                                        const nutriURL = await getPlanoDeNutricao(docId)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                       } else {
                                        alert("Erro")
                                       }
                                       setIsLoadingAdicionar(false)
                                    }} color='primary'>Adicionar</Button>}
                                    </Col>
                                    </Row>
                                </Col> 
                                </Row>
                                <Row>
                                <Col  hidden={!(isUser==false)}  >
                                    <Row>
                                    <h3 style={{paddingTop: "40px"}} > Sem ficheiro de nutrição</h3>
                                    </Row>
                                    <Row style={{paddingTop: "40px", paddingBottom: "40px"}}>
                                    <Input onChange={(e) => {
                                        setFile(e.target.files[0])
                                        
                                    }} type='file' accept="application/pdf"></Input>
                                    </Row>
                                    <Row>
                                        <Col  md={6}>
                                    { isLoadingAdicioanr ? <Spinner></Spinner> :
                                    <Button onClick={async () => {
                                        setIsLoadingAdicionar(true)
                                        console.log("Ficheiro:" , + file);
                                        const res = await uploadPlanoDeNutricao(docId,file)
                                       
                                       if(res) {
                                        alert("Ficheiro adicionado com sucesso")
                                        const nutriURL = await getPlanoDeNutricao(docId)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            setFicheiro(nutriURL)
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                       } else {
                                        alert("Erro")
                                       }
                                       setIsLoadingAdicionar(false)
                                    }} color='primary'>Adicionar</Button>}
                                    </Col>
                                    </Row>
                                </Col>
                                </Row>
                            </Row>
                        
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default NutricaoScreen;