import { createSlice } from "@reduxjs/toolkit"

export const CurrentUserAuth = createSlice({
    name: "CurrentUserAuth",
    initialState: {
        value: "",
    },
    reducers: {
        setCurrentUserAuth: (state, action) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCurrentUserAuth } = CurrentUserAuth.actions

export const selectCurrentUserAuth = state => state.CurrentUserAuth.value

export default CurrentUserAuth.reducer