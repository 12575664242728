import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Container, Input, Row, Table } from "reactstrap"
import firebase from "firebase/app"
import "firebase/firestore"
import { ArrowRightCircle } from "react-bootstrap-icons"
import { Link } from "react-router-dom"
import { convertCamps, dateToUTC } from "services/consts"
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker"

const Reservas = () => {

  const { t, i18n } = useTranslation()
  const [listaDeReservas, setListaDeReservas] = useState([])
  const [listaDeReservasBloqueadas, setListaDeReservasBloqueads] = useState([])
  const [listaDeReservasFiltrada, setListaDeReservasFiltrada] = useState([])

  const [value, onChange] = useState(new Date())
  const minDate = new Date()
  const [valueFinal, onChangeFinal] = useState(new Date())
  const minDateFinal = new Date()

  const [isLoading, setIsLoading] = useState(false)

  async function getReservas(localizacao) {
    var horaIncial = new Date()
    horaIncial.setHours(1, 0, 0, )
    try {
      firebase
        .firestore()
        .collection("reservas")
        .where("localizacao", "==", localizacao)
        .where(
          "horaIncial",
          ">=",
          firebase.firestore.Timestamp.fromDate(horaIncial)
        )
        .onSnapshot(docsSnaps => {
          var listaAux = []
          var listaBloqAux = []
          setListaDeReservas([])
          setListaDeReservasBloqueads([])
          for (const element of docsSnaps.docs) {
            if (element.data().bloqueada == true) {
              listaBloqAux.push(element)
            } else {
              listaAux.push(element)
            }
          }
          setListaDeReservasBloqueads(listaBloqAux)
          setListaDeReservasFiltrada(listaAux)
          setListaDeReservas(listaAux)
        })
      return true
    } catch (error) {
      console.error(error)
      return null
    }
  }

  function filtraReservas(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().estado == filtro)
    setListaDeReservasFiltrada(asd)
  }

  function filtrarPorEmail(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().jogadores.includes(filtro.trim()))
    setListaDeReservasFiltrada(asd)
  }

  function filtrarPorHorario(HInicial, HFianl) {
    var horarioInicial = firebase.firestore.Timestamp.fromDate(HInicial)
    var horarioFinal = firebase.firestore.Timestamp.fromDate(HFianl)

    var asd = listaDeReservas.filter(elem => (elem.data().horaIncial >= horarioInicial && elem.data().horaIncial <= horarioFinal ))
    setListaDeReservasFiltrada(asd)
  }

  function filtraPorNumeroDeTelemovel(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().estado == filtro)
    setListaDeReservasFiltrada(asd)
  }

  function filtraPorEmail(filtro) {
    var asd = listaDeReservas.filter(elem => elem.data().estado == filtro)
    setListaDeReservasFiltrada(asd)
  }

  function resetAoFiltro() {
    setListaDeReservasFiltrada(listaDeReservas)
  }

  function reservasBloqueadas() {
    setListaDeReservasFiltrada(listaDeReservasBloqueadas)
  }

  useEffect(() => {
    getReservas("Great Padel Vila Verde")
  }, [])

  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">{t("Reservas")}</h6>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    filtraReservas("Confirmada")
                  }}
                >
                  Confirmadas
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    resetAoFiltro()
                  }}
                >
                  Todas
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    reservasBloqueadas()
                  }}
                >
                  Campos Bloqueados
                </Button>
              </Col>
            </Row>
            <Row style={{ paddingTop: "40px" }}>
              <Col md={2}>
                <h5>Hora Inicial</h5>
              </Col>
              <Col md={3}>
                  <DateTimePicker 
                  minDate={minDate}
                  disableClock={true}
                  onChange={value => {
                    console.log(value)
                    onChange(value)
                  }}
                  value={value}
                  >
                </DateTimePicker>
              </Col>
              <Col md={3}>
                  <DateTimePicker 
                  minDate={minDateFinal}
                  disableClock={true}
                  onChange={value => {
                    console.log(value)
                    onChangeFinal(value)
                  }}
                  value={valueFinal}
                  >
                </DateTimePicker>
              </Col>
              <Col md={2}>
                <Button color="primary"  onClick={() => {
                  filtrarPorHorario( value, valueFinal)
                }} >
                  Pesquisar
                </Button>
              </Col>
            </Row>
            <Row  style={{ paddingTop: "20px" }}>
              <Col md={2}>
                <h5>Email</h5>
              </Col>
              <Col md={6}>
                <Input id="pesquEmail"></Input>
              </Col>
              <Col md={2}>
                <Button color="primary"  onClick={() => {
                  filtrarPorEmail( document.getElementById("pesquEmail").value)
                }} >
                  Pesquisar
                </Button>
              </Col>
            </Row>
            <Row className="pt-5">
              <Table bordered striped responsive className="myList" style={stylesTable} >
                <thead>
                  <tr>  
                    <th>Reservada por</th>
                    <th>Dia</th>
                    <th>Início</th>
                    <th>Duração</th>
                    <th>Campo</th>
                    <th>Estado</th>
                    <th>Valor</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  listaDeReservasFiltrada.map((reservaSnap, index) => {
                  const reserva = reservaSnap.data()
                  var date = new Date()
                  date =  reserva.horaIncial.toDate()
                  var dateUTC = new Date(date.toISOString().substring(0,10))
                    console.log(dateUTC)

                  const horas = ("0" + date.getHours().toString()).slice(-2)
                  const minutos = ("0" + date.getMinutes().toString()).slice(-2)
                  return (
                    <tr
                      onClick={() => {console.log("asdasd")}}
                      key={index}
                    >
                      
                      <td style={typeof reservaSnap.data().criadaPor == "undefined" ? {} : {color: "green"}   } >{reservaSnap.data().criadaPor == "Backoffice" ? reserva.nome : reserva.jogador1estado.email}</td>
                      <td>{dateUTC.toLocaleString( "pt-PT", { weekday: "long" }).split('-')[0] + ", " + dateUTC.toLocaleString().substring(0,10) }</td>
                      {/* <td>{date.toLocaleString( "pt-PT", { weekday: "long" }).split('-')[0] + ", " + date.toLocaleString().substring(0,10) }</td> */}
                      {/*<td>{date.toLocaleString( "pt-PT", { weekday: "long" })} </td> */}
                      {/* <td>{horas + ":" + minutos + "h"}</td> */}
                      <td>{date.toISOString().substring(11,16) + "h"}</td>
                      <td>{reserva.duracao + "m"}</td>
                      <td>{convertCamps[reserva.campo]}</td>
                      <td>
                        {
                        reserva.estado == "Anulada" ? (
                          <span className="badge bg-danger fs-6 px-3">{reserva.estado}</span>
                        ) : reserva.estado == "Pendente" ? (
                          <span className="badge bg-warning fs-6 px-3">{reserva.estado}</span>
                        ) : (
                          <span className="badge bg-success fs-6 px-3">{reserva.estado}</span>
                        )}
                      </td>
                      <td>{reserva.valorTotal + "€"}</td>
                      <td>
                        <Link className="btn btn-primary" to={"/reservas/" + reservaSnap.id}>
                          <ArrowRightCircle></ArrowRightCircle>
                        </Link>
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            </Row>

       
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reservas
