import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function SemPermissoes() {
  return (
    <div className="d-flex justify-content-center flex-column text-center " style={{height:"100vh"}}>
        <h1>Não tem permissões para aceder a esta página.</h1>
        <Link 
            style={{width:"fit-content"}}
            className="btn btn-primary p-2 mx-auto mt-3"
            to="/dashboard"
        >Voltar à página inicial</Link>
    </div>
  )
}

export default SemPermissoes