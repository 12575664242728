import { createSlice } from "@reduxjs/toolkit"

export const setuserrolesSlice = createSlice({
  name: "setuserroles",
  initialState: {
    value: "",
  },
  reducers: {
    
    setsetuserroles: (state, action) => {
    console.log("action.payload Stringfied");
    console.log(JSON.stringify(action.payload) );
   
      state.value = JSON.stringify(action.payload)  
    },
  },
})

// Action creators are generated for each case reducer function
export const { setsetuserroles } = setuserrolesSlice.actions

export const selectsetuserroles = state => state.setuserroles.value

export default setuserrolesSlice.reducer
