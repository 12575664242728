import BloquearHorario from 'components/modals/bloquear_horario';
import React, { useEffect, useState } from 'react';
import { Pencil, Trash, Trash2, Trash2Fill, Trash3, Trash3Fill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertCamps } from 'services/consts';
import { Button, Col, Container, Row, Spinner, Table } from 'reactstrap';
import {  bloquearReservasComCriacaoDeReservas, deleteBloqueio, removerHorarioBloq } from 'services/reservas/reservas_services';
import { selectbloquearReservas } from 'store/bloquear_reservas/bloquear_reservas_reducer';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';
import { selectlocalizacao } from 'store/localizacao/localizacao_reducer';
import EditarBloqueioHorario from 'components/modals/editar_bloqueio_horario_modal';
import RemoveBloqueioHorarioModal from 'components/modals/remove_bloquei_horario_modal';

function DesativarHoras() {
    const { t, i18n } = useTranslation()
    const [isLoadingBloq, setIsLoadingBloq] = useState(false)

    const horariosBloqueadosList = useSelector(selectbloquearReservas)
    /* const hbJson = JSON.parse( horariosBloqueadosList) */
    const localizacao = useSelector(selectlocalizacao)
    const docId = useSelector(selectclubeid);
    const datee = new Date()

    const clubeID = useSelector(selectclubeid)
    var list = []
    for(const elem in horariosBloqueadosList) {
        if( horariosBloqueadosList[elem].horaInicial.toDate() >= datee){
            list.push(elem)
           }
       
    } 
    list.sort((a,b) => {  
        if(horariosBloqueadosList[a].horaInicial.toDate() > horariosBloqueadosList[b].horaInicial.toDate()) {
            return 1
           } else 
           return -1
    })


    const stylesTable = {
        "--bs-table-bg": "#ececf1",
      }

    return (  
    
        <React.Fragment>
            
            <div className="page-content">
                <Container fluid>

                    <div className="page-title-box">
                        {/* Título */}
                        <Row className="align-items-center">
                            <Col md={10}>
                                <h6 className="page-title">{t("Desativar Reservas")}</h6>
                            </Col>
                            <Col md={2}>
                                <BloquearHorario />
                            </Col>
                        </Row>
                    </div>


                {/* Tabela */}
                <Row className="pt-2">
                    
                    <div>
                        <h4>Horários bloqueados</h4>
                    </div>

                    <Table bordered striped responsive className="myList" style={stylesTable}>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Horário Inicial</th>
                                <th>Horário Final</th>
                                <th>N° de campos</th>
                                <th>Campos</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((elem,index) => {
                            console.log("patig")
                            console.log(elem)
                        
                            return(
                                <tr key={elem + "row" + index }>
                                    <td>{elem}</td>
                                    <td >
                                        {/*horariosBloqueadosList[elem].horaInicial.toDate().toLocaleString("pt-PT") + " " + */horariosBloqueadosList[elem].horaInicial.toDate().toLocaleString().substring(0,10)  + " " + horariosBloqueadosList[elem].horaInicial.toDate().toISOString().substring(11,16) + "h"   }
                                    </td>
                                    <td >
                                        {/*horariosBloqueadosList[elem].horaFinal.toDate().toLocaleString("pt-PT") + " " + */horariosBloqueadosList[elem].horaFinal.toDate().toLocaleString().substring(0,10) + " " + horariosBloqueadosList[elem].horaFinal.toDate().toISOString().substring(11,16) + "h"   }
                                    </td> 
                                    <td >
                                        {typeof horariosBloqueadosList[elem].campos != "undefined" ? horariosBloqueadosList[elem].campos.length  : null    }
                                    </td>
                                    <td >
                                        {typeof horariosBloqueadosList[elem].campos != "undefined" ? horariosBloqueadosList[elem].campos.map((elem) => {return convertCamps[elem]+ ", "}) : null    }
                                    </td>
                                    <td>
                                        <EditarBloqueioHorario name={elem} vals={horariosBloqueadosList[elem]}  ></EditarBloqueioHorario>
                                   {/*  <Button style={{"marginLeft": "10px"}} className='btn btn-warning' onClick={async () => {
                                            var newMAp = {}
                                            var nome = "";
                                                console.log(horariosBloqueadosList)
                                            for(const element in horariosBloqueadosList) {
                                                if(element != elem ) {
                                                    newMAp[element] = horariosBloqueadosList[element]
                                                } else {
                                                    nome = element
                                                }
                                                }
                                            console.log(newMAp)
                                        }}>
                                            <Pencil></Pencil>
                                            </Button> */}
                                        <RemoveBloqueioHorarioModal elem={elem} ></RemoveBloqueioHorarioModal>
                                           {/* {isLoadingBloq ? <Spinner color='primary' ></Spinner> :
                                        <Button className='btn btn-danger' onClick={async () => {
                                            setIsLoadingBloq(true)
                                            var newMAp = {}
                                            var nome = "";
                                                console.log(horariosBloqueadosList)
                                            for(const element in horariosBloqueadosList) {
                                                if(element != elem ) {
                                                    newMAp[element] = horariosBloqueadosList[element]
                                                } else {
                                                    nome = element
                                                }
                                            }
                                            console.log(newMAp)
                                            var res = await deleteBloqueio(docId ,horariosBloqueadosList[elem].horaInicial.toDate(),horariosBloqueadosList[elem].horaFinal.toDate(),localizacao,newMAp,nome );
                                            if(res) {
                                                alert("Bloqueio de horário removido com sucesso")
                                            } else {
                                                alert("Erro")
                                            }
                                            setIsLoadingBloq(false)
                                        }}>
                                            <Trash2></Trash2>
                                            </Button>} */}
                                            
                                    </td>
                            </tr>
                            )}) }
                        </tbody>
                    </Table>
                </Row>

                {/* <Row>
                    <h4>Horários bloqueados</h4>
                </Row> */}


                {/* Sub-Título */}
                {/* <Row className='pt-5'>
                    <h4>Horários bloqueados</h4>
                </Row> */}

                
                
                </Container>
            </div>
        </React.Fragment>
    
    );
}

export default DesativarHoras;