import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"
import descontos_reducer from "./descontos/descontos_reducer"
import classes_reducer from "./localizacao/classes_reducer"
import clube_id_reducer from "./localizacao/clube_id_reducer"
import localizacao_reducer from "./localizacao/localizacao_reducer"
import treinadores_reducer from "./treinadores/treinadores_reducer"
import feriados_reducer from "./descontos/feriados"
import bloquear_reservas_reducer from "./bloquear_reservas/bloquear_reservas_reducer"
import valores_aulas_reducer from "./clube/valores_aulas_reducer"
import classes_especiais from "./localizacao/classes_especiais"
import faturas_referencias_reducer from "./clube/faturas_referencias_reducer"
import valores_dos_contratos_reducer from "./clube/valores_dos_contratos_reducer"
import carregamentos_reducer_services from "./clube/carregamentos_reducer_services"
import horario_de_funcionamento_reducer from "./clube/horario_de_funcionamento_reducer"
import grupos_staff from "./localizacao/grupos_staff"
import CurrentUserAuth from "./middlewarerotas/CurrentUserAuth"
import rotas_permitidas_reducer from "./utilizadores/rotas_permitidas_reducer"
import user_roles_reducer from "./clube/user_roles_reducer"
import imagem_da_app_reducer from "./clube/imagem_da_app_reducer"
import user_localstorage from "./localStorage/user_localstorage"


const rootReducer = combineReducers({
  userlocalstorage: user_localstorage,
  imagemDaApp: imagem_da_app_reducer,
  rotaspermitidas: rotas_permitidas_reducer,
  setuserroles : user_roles_reducer,
  horarioDeFuncionamento: horario_de_funcionamento_reducer,
  carregamentos: carregamentos_reducer_services,
  contratosvalores: valores_dos_contratos_reducer,
  faturasref: faturas_referencias_reducer,
  classesespeciais: classes_especiais,
  aulasvalores: valores_aulas_reducer,
  bloquearReservas: bloquear_reservas_reducer,
  feriados: feriados_reducer,
  treinadores: treinadores_reducer,
  descontos: descontos_reducer,
  classes: classes_reducer,
  clubeid: clube_id_reducer,
  localizacao: localizacao_reducer,
  CurrentUserAuth: CurrentUserAuth,

  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
})

export default rootReducer
