import { createSlice } from "@reduxjs/toolkit"

export const faturasrefSlice = createSlice({
  name: "faturasref",
  initialState: {
    value: "",
  },
  reducers: {
    setfaturasref: (state, action) => {
      state.value = JSON.stringify(action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setfaturasref } = faturasrefSlice.actions

export const selectfaturasref = state => state.faturasref.value

export default faturasrefSlice.reducer
