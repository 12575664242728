import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import { Button, Col, Container, Row, Spinner } from "reactstrap"

import firebase from "firebase/app"
import "firebase/firestore"
import { setlocalizacao } from "store/localizacao/localizacao_reducer"
import { setbloquearReservas } from "store/bloquear_reservas/bloquear_reservas_reducer"
import { setdescontos } from "store/descontos/descontos_reducer"
import { setclasses } from "store/localizacao/classes_reducer"
import { selectclubeid, setclubeid } from "store/localizacao/clube_id_reducer"
import { settreinadores } from "store/treinadores/treinadores_reducer"
import { setferiados } from "store/descontos/feriados"
import { getLocalizacaoFromEmail } from "services/localizacao/localizacao_services"
import { useDispatch, useSelector } from "react-redux"
import { setaulasvalores } from "store/clube/valores_aulas_reducer"
import { setclassesespeciais } from "store/localizacao/classes_especiais"
import { setfaturasref } from "store/clube/faturas_referencias_reducer"
import { setcarregamentos } from "store/clube/carregamentos_reducer_services"
import { setcontratosvalores } from "store/clube/valores_dos_contratos_reducer"
import { asdas, bloquearComReservas, criaReservasDosContratos } from "services/reservas/reservas_services"
import { addAllUsersWithDataDeNascimento, enviarEMailsTesteS, getAllUsersBloqd, getAlllUsersAndChangePubSub, pesquisarJogadoresByEmail } from "services/useres/jogadores_services"
import { enviaEMailDeAniversario, testeDOEmailDasExpire, testeDOEmailDasExpire2 } from "services/aulas/aulas_services"
import { sethorarioDeFuncionamento } from "store/clube/horario_de_funcionamento_reducer"
import { backUp, calculaReservasEbloqueiosDiarios, logOut } from "services/clube/clube_services"
import { selectCurrentUserAuth, setCurrentUserAuth } from "store/middlewarerotas/CurrentUserAuth"
import { setsetuserroles } from "store/clube/user_roles_reducer"
import { testeAddRemoveContratos } from "services/contratos/contratos_services"
import { setimagemDaApp } from "store/clube/imagem_da_app_reducer"
import { adminRole, dateToUTC } from "services/consts"
import { duplicaEmail, getAllPendingEmails, getEmailPostion } from "services/email/email_services"
import ReactApexChart from "react-apexcharts"
import { forEach } from "lodash"

function Dashboard() {
  
  const [listaDeAcademia, setListaDeAcademia] = useState([])
  const [listaDePax, setListaDePax] = useState([])
  const [listaDias, setListaDias] = useState([])
  const userAuthRole = useSelector(selectCurrentUserAuth)
  let aulasMaisCompradasChart = {
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  let aulasMaisCompradasChartPax = {
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  let duracoesMaisReservasB = {
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  // line chart
  let lineChart = {
          
    series: [{
        name: "Desktops",
        data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      /* title: {
        text: 'Product Trends by Month',
        align: 'left'
      }, */
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
      }
    }
  }
   
    let chartValuesTempoReservado = {
      series: [{}],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Horas'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  Math.floor(val) + "h e " + ("0" + ((val%1) * 60 ) ).slice(-2) + "m"
            }
          }
        }
      },
    };

    let chartValuesValor = {
      series: [{}],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: '€ Euros'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return  val + "€"
            }
          }
        }
      },
    };

    let chartAulasValor = {
      series: [{}],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: '€ Euros'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          
          enabled: true,
          y: {
            formatter: function (val) {
              return val + "€"
            }
          },
          x: {
            formatter: function (val) {
              var values = listaDias.indexOf(val) 
              console.log("PATIG PARIG ")
              console.log(values)
              console.log(listaDias)
              return val
            }
          }
        }
      },
    };

    let chartNumeros = {
      series: [{}],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Total'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return  val 
            }
          }
        }
      },
    };
    
  const [duracoesMaisReservadas, setDuracoesMaisReservdasB] = useState(duracoesMaisReservasB)
  const [aulasMaisComprPax, setAulasMaisComprPax] = useState(aulasMaisCompradasChartPax)
  const [aulasMaisCompr, setAulasMaisCompr] = useState(aulasMaisCompradasChart)
  const [charsTempoReservado, setChartsTempoReservado] = useState(chartValuesTempoReservado)
  const [charsvalor, setCharsValor] = useState(chartValuesValor)
  const [charsvalorAulas, setCharsValorAulas] = useState(chartAulasValor)
  const [charsNumeros, setCharsNumeros] = useState(chartNumeros)
  const [numeroDeJogadores, setNumeroDeJogadores] = useState("")
  const mapDeAcademias = {}
  const mapDePaxes = {}
  const [listaDeAcademias, setListaDeAcademias] = useState([])
  const [listaDePauxes, setListaDePauxes] = useState([])
  const mapDeReservasDuracoes = {}
  const mapDeContratosDuracoes = {}
  const [listaDeDuracoesReservas, setListaDeDuracoesReservas] = useState([])
  const [listaDeDuracoesContratos, setListaDeDuracoesContratos] = useState([])
  const [lineChartTempo, setLineChartTempo] = useState(lineChart)
  
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
  }

  /**
   * 
   * @param {*} date 
   * @param {*} days 
   * @return {Date}
   */
  function addDays(date, days) {
    return new Date(date.getTime() + days*60000 * 60 * 24);
  }

  const { t, i18n } = useTranslation()
  const [authRole, setauthRole] = useState("")
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  //DA para otimizar isto para so dar fetch a primeira vez que abre a dashboard
  async function getValues() {
    setIsLoading(true);
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      try {
        await getLocalizacaoFromEmail(obj.email).then(async value => {
          var user_role = value.user_role
          console.warn("ROLE DO UTILIZADOR: ", user_role)
          if(typeof user_role != "undefined"){
            setauthRole(user_role)
            dispatch(setCurrentUserAuth(user_role))
          } else {
            await logOut()
          }
          try {
            await firebase
              .firestore()
              .collection("localizacao")
              .where("localizacao", "==", value.localizacao)
              .limit(1)
              .get()
              .then(async values => {
                var imagemDaApp = values.docs.at(0).data().imagemDaApp
                var userRoles = values.docs.at(0).data().userRoles
                var horarioDeFuncionamento = values.docs.at(0).data().horarioDeFuncionamento
                var faturasRef = values.docs.at(0).data().faturasRef
                var carregamentos = values.docs.at(0).data().valoresDosCarregamentos
                var valoresDosContratosScreen = values.docs.at(0).data().valoresDosContratos
                var classesEspeciais = values.docs.at(0).data().classesEspeciais
                var valoresDasAulas = values.docs.at(0).data().valoresDasAulas
                var bloquear = values.docs.at(0).data().horariosBloqueadosJson
                var descontos = values.docs.at(0).data().descontos
                var classes = values.docs.at(0).data().classes
                var clubeid = values.docs.at(0).id
                var treinadores = values.docs.at(0).data().treinadores
                var localizacao = values.docs.at(0).data().localizacao
                var feriados = values.docs.at(0).data().feriados
                
                dispatch(setimagemDaApp(imagemDaApp))
                dispatch(setsetuserroles(userRoles))
                dispatch(sethorarioDeFuncionamento(horarioDeFuncionamento))
                dispatch(setcarregamentos(carregamentos))
                dispatch(setcontratosvalores(valoresDosContratosScreen))
                dispatch(setfaturasref(faturasRef))
                dispatch(setclassesespeciais(classesEspeciais))
                dispatch(setaulasvalores(valoresDasAulas))
                dispatch(setbloquearReservas(bloquear))
                dispatch(setdescontos(descontos))
                dispatch(setclasses(classes))
                dispatch(setclubeid(clubeid))
                dispatch(settreinadores(treinadores))
                dispatch(setlocalizacao(localizacao))
                dispatch(setferiados(feriados))

                /* const response = await docDeTeste(clubeId)
                    console.log(response.data());
                    const listaDeBLoq = response.data().listaDeBloqueios
                    // Horarios bloqueados
                    for (let index = 0; index < listaDeBLoq.length; index++) {
                      const hinic = listaDeBLoq[index].horaInicial
                      const hfinal = listaDeBLoq[index].horaFinal
                      if (hfinal.toDate() > date2) {
                      const diferEmHoras =(hfinal - hinic) / 3600;
                      let total;
                      if (listaDeBLoq[index].campos != undefined){
                        total = diferEmHoras * listaDeBLoq[index].campos.length 
                      }
                      console.log(total)
                    }
                    } */
                const lookBack = 15
                let date1 = new Date()
                let date2 = addDays(date1, -lookBack);
                const listaAux = []
                const listaDurcReservas = []
                const listaDurcAulas = []
                const listaDurcContratos = []
                const listaDurcBloqueados = []
                const numeroHorariosBloq = []
                const listaDurBloqAux = []
                const listaDurcTotal = []
                const listaDeDias = []
                const listaDeValores = []
                const listaDeValoresAulas = []
                const listaNrAulas = []
                const listaNrReservas = []
                const listaNrContratos = []
                const listaDeAcademiaAux = []
                const listaDePaxAux = []
                const listaDeAcademiasAux = []
                const listaDePaxesAux = []
                const listaDeDuracoesReservassAux = []
                const listaDeDuracoesContratosAux = []

                await firebase.firestore()
                  .collection("localizacao")
                  .doc(values.docs.at(0).id)
                  .collection("horaiosBloqueados")
                  .get().then((value) => {
                    console.log("-.-.-.-.-.-.-.- Horarios BLoqueados -.-.-.-.-.-.-.-.-.-. ")
                   
                    value.docs.forEach((value) => {
                      console.log(value.id);
                    })
                     var ano = date1.getFullYear()
                     var ano2 = date2.getFullYear()
                     
                     for (let index = 1; index < lookBack; index++) {
                      let duracaoHorsBloq = 0
                      let numeroHorBloq = 0
                      const checkDate = addDays(new Date(), -lookBack + index);
                      const diaAux = ("0" + checkDate.getDate()).slice(-2)
                      const monthAux = ("0" + (checkDate.getMonth() + 1)).slice(-2)
                      const yearNow = checkDate.getFullYear()
                      const mainKey = monthAux + diaAux
                      
                      value.docs.forEach((elemnt) => {
                        if(elemnt.id == yearNow) {
                          const data = elemnt.data()[mainKey]
                          console.log(mainKey)
                          console.log(data)
                          if(data != undefined) {
                            for ( const key in data ) {
                              numeroHorBloq = numeroHorBloq + 1
                              const hf = data[key].horaFinal.toDate()
                              const hi = data[key].horaInicial.toDate()
                              duracaoHorsBloq = duracaoHorsBloq +((hf -hi) / 60000 ) * data[key].campos.length
                            }         
                          }
                        }
                      })
                      listaDurcBloqueados.push(duracaoHorsBloq / 60)
                       numeroHorariosBloq.push(numeroHorBloq )
                     }
                  })
                await firebase.firestore()
                  .collection("localizacao")
                  .doc(values.docs.at(0).id)
                  .collection("dashboard")
                  .where("dia", ">", date2)
                  .get().then((value) => {  
                    value.docs.forEach((elem, index) => {
                      if(index +1  == value.docs.length) {
                        setNumeroDeJogadores(elem.data().numeroDeUsers)
                      }
                      var dateAux = elem.data().dia.toDate()
                      var diaAux = ("0" + dateAux.getDate()).slice(-2);
                      var mesAux  =("0" + dateAux.getMonth() +1 ).slice(-2);

                      const total =( elem.data().duracaoTotal / 60 ) + listaDurcBloqueados[index]
                      listaDeAcademiaAux.push(elem.data().numeroAcademia  )
                      listaDePaxAux.push(elem.data().numeroPax  )
                      listaDurcReservas.push(elem.data().duracaoDaReservas / 60)
                      listaDurcAulas.push(elem.data().duracaoDasAulas / 60)
                      listaDurcContratos.push(elem.data().duracaoDeContratos / 60)
                      listaDurcTotal.push(total) 
                      listaDeValoresAulas.push(elem.data().valorCompradoAulas)
                      listaDeValores.push(elem.data().valorDasReservas)
                      listaNrAulas.push(elem.data().numeroDeAulas)
                      listaNrReservas.push(elem.data().numeroDeReservas)
                      listaNrContratos.push(elem.data().numeroDeContratos) 
                      listaDeDias.push(diaAux/* + "/" + mesAux */)
                      listaDeAcademiasAux.push(elem.data().listaDeAcademias)
                      listaDePaxesAux.push(elem.data().listaDePaxs)
                      listaDeDuracoesReservassAux.push(elem.data().listaDeDuracaoDasReservas )
                      listaDeDuracoesContratosAux.push(elem.data().listaDeDuracaoDosContratos )
                    })
                    setListaDias(listaDeDias)
                    setListaDeAcademia(listaDeAcademiaAux)
                    setListaDePax(listaDePaxAux)
                    
                    console.log("listaDeAcademiasAux")
                    console.log(listaDeAcademiasAux)
                    
                    const mapDeAcademiasTemp = []
                    

                    listaDeAcademiasAux.forEach((elem,index) => {
                    if (elem != undefined && elem != "") {
                      elem.forEach((value) => {
                        if (mapDeAcademias[value] == undefined) {
                          mapDeAcademias[value] = 1
                        } else {
                          mapDeAcademias[value] = mapDeAcademias[value] + 1
                        }
                      })
                      }
                    })
                    for (let elem in mapDeAcademias) {
                      mapDeAcademiasTemp.push({"nome" : elem , "value":  mapDeAcademias[elem]})
                    }
                    mapDeAcademiasTemp.sort((a,b) => {
                      if (a.value > b.value) {
                        return -1
                      } else {
                        return 1
                      }
                    })

                    const aulasMCValor = []
                    const aulasMCNome = []
                    let acumula = 0;
                    
                    for (let index = 0; index < mapDeAcademiasTemp.length; index++) {
                      console.log("mapDeAcademiasTemp[index]")
                      console.log(mapDeAcademiasTemp[index])
                      const element = mapDeAcademiasTemp[index];
                       if(index < 5) {
                      console.log(index)
                      aulasMCValor.push(element.value)
                      aulasMCNome.push(element.nome)
                      }  else if ( index == mapDeAcademiasTemp.length -1  ) {
                        aulasMCValor.push(acumula)
                        aulasMCNome.push("Outros")
                      } else {
                        acumula = acumula + element.value 
                      }
                    }   

                    aulasMaisCompradasChart.series = aulasMCValor
                    aulasMaisCompradasChart.options.labels = aulasMCNome
                    setAulasMaisCompr(aulasMaisCompradasChart) 
                    
                    setListaDeAcademias(mapDeAcademiasTemp)

                    const mapDePauxesTemp = []
                    listaDePaxesAux.forEach((elem) => {

                      if (elem != undefined && elem != "") {
                      
                      elem.forEach((elemmm) => {
                          if(mapDePaxes[elemmm] == undefined) {
                            mapDePaxes[elemmm] = 1
                          } else {
                            mapDePaxes[elemmm] = mapDePaxes[elemmm] + 1
                          }
                      })
                    }
                    })
                    
                    
                    for (let elem in mapDePaxes) {
                     
                      mapDePauxesTemp.push({"nome" : elem , "value":  mapDePaxes[elem]})
                    }
                    mapDePauxesTemp.sort((a,b) => {
                      if (a.value > b.value) {
                        return - 1
                      } else {
                        return 1
                      }
                    })

                    const aulasMCValorPax = []
                    const aulasMCNomePax = []
                    let acumulaPax = 0;
                    
                    for (let index = 0; index < mapDePauxesTemp.length; index++) {
                      console.log("mapDePauxesTemp[index]")
                      console.log(mapDePauxesTemp[index])
                      const element = mapDePauxesTemp[index];
                       if(index < 5) {
                      console.log(index)
                      aulasMCValorPax.push(element.value)
                      aulasMCNomePax.push(element.nome)
                      }  else if ( index == mapDePauxesTemp.length -1  ) {
                        aulasMCValorPax.push(acumulaPax)
                        aulasMCNomePax.push("Outros")
                      } else {
                        acumula = acumula + element.value 
                      }
                    }   

                    aulasMaisCompradasChartPax.series = aulasMCValorPax
                    aulasMaisCompradasChartPax.options.labels = aulasMCNomePax
                    setAulasMaisComprPax(aulasMaisCompradasChartPax) 
                    
                    setListaDePauxes(mapDePauxesTemp)

                    const mapDeDuracoesReservasTemp = []
                    listaDeDuracoesReservassAux.forEach((elem,index) => {
                    if (elem != undefined && elem != "") {
                      elem.forEach((value) => {
                        if (mapDeReservasDuracoes[value] == undefined) {
                          mapDeReservasDuracoes[value] = 1
                        } else {
                          mapDeReservasDuracoes[value] = mapDeReservasDuracoes[value] + 1
                        }
                      })
                      }
                    })
                    for (let elem in mapDeReservasDuracoes) {
                      mapDeDuracoesReservasTemp.push({"nome" : elem , "value":  mapDeReservasDuracoes[elem]})
                    }
                    mapDeDuracoesReservasTemp.sort((a,b) => {
                      if (a.value > b.value) {
                        return -1
                      } else {
                        return 1
                      }
                    })

                    setListaDeDuracoesReservas(mapDeDuracoesReservasTemp)
                    const mapDeDuracoesContratosTemp = []

                    const aulasMCValorDurac = []
                    const aulasMCNomeDurac = []
                    
                    listaDeDuracoesContratosAux.forEach((elem,index) => {
                    if (elem != undefined && elem != "") {
                      elem.forEach((value) => {
                        if (mapDeContratosDuracoes[value] == undefined) {
                          mapDeContratosDuracoes[value] = 1
                        } else {
                          mapDeContratosDuracoes[value] = mapDeContratosDuracoes[value] + 1
                        }
                      })
                      }
                    })
                    for (let elem in mapDeContratosDuracoes) {
                      aulasMCValorDurac.push(elem)
                      aulasMCNomeDurac.push(mapDeContratosDuracoes[elem] )
                      mapDeDuracoesContratosTemp.push({"nome" : elem , "value":  mapDeContratosDuracoes[elem]  })
                    }
                    mapDeDuracoesContratosTemp.sort((a,b) => {
                      if (a.value > b.value) {
                        return -1
                      } else {
                        return 1
                      }
                    })

                    duracoesMaisReservasB.series = aulasMCValorDurac
                    duracoesMaisReservasB.options.labels = aulasMCNomeDurac
                    setDuracoesMaisReservdasB(duracoesMaisReservasB)

                    setListaDeDuracoesContratos(mapDeDuracoesContratosTemp)

                    const listPairDurcReservas = []
                    for (let index = 0; index < listaDeDias.length; index++) {
                      listPairDurcReservas.push({x:listaDeDias[index] , y:listaDurcReservas[index] })
                    }

                    var seriesTempoReservado = [{
                      name: 'Reservas',
                      data: listaDurcReservas
                    },{
                      name: 'Aulas',
                      data: listaDurcAulas
                    },{
                      name: 'Contratos',
                      data: listaDurcContratos
                    },{ 
                      name: 'Bloqueados',
                      data: listaDurcBloqueados
                    } ,{
                      name: 'Total',
                      data: listaDurcTotal
                    },]

                    var seriesValor = [{
                      name: 'Reservas',
                      data: listaDeValores
                    }]

                    var seriesAulas = [{
                      name: 'Aulas',
                      data: listaDeValoresAulas
                    }]

                    var seriesNumero = [{
                      name: 'Reservas',
                      data: listaNrReservas
                    },{
                      name: 'Aulas',
                      data: listaNrAulas
                    },{
                      name: 'Bloqueados',
                      data: numeroHorariosBloq,
                    },{
                      name: 'Contratos',
                      data: listaNrContratos
                    }]

                    chartValuesTempoReservado.series = seriesTempoReservado
                    chartValuesTempoReservado.options.xaxis.categories = listaDeDias
                    setChartsTempoReservado(chartValuesTempoReservado)
                    
                    chartValuesValor.series = seriesValor
                    chartValuesValor.options.xaxis.categories = listaDeDias
                    setCharsValor(chartValuesValor)

                    chartAulasValor.series = seriesAulas
                    chartAulasValor.options.xaxis.categories = listaDeDias
                    setCharsNumeros(chartAulasValor)

                    chartNumeros.series = seriesNumero
                    chartNumeros.options.xaxis.categories = listaDeDias
                    setCharsNumeros(chartNumeros)
                  })
              })

              setIsLoading(false)
            
          } catch (error) {
            console.log(error)
            setIsLoading(false)
          }
        })
      } catch (error) {
        console.log("ERRO")
        console.log(error)
        setIsLoading(false)
      }
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getValues()
  }, [])
  return (
    
    isLoading ? 
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Great Padel</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">{t("A Carregar ....")}</h6>
                </Col>
                </Row>
                </div>
                </Container>
                </div>
    </React.Fragment> : 
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Great Padel</title>
        </MetaTags>
        <Container hidden={userAuthRole == adminRole ? false : true} fluid  >
           {/* <Button  onClick={() => {
            backUp("rUwVSY5ZZG10c85M9rEY")
          }}> Back up
          </Button>  */}
           {/* <Button  onClick={() => {
            pesquisarJogadoresByEmail("rui.carmo@fundicaoricarsil.pt").then((value) => {
              var contador = 0
              for( var asd in  value.data().historicoDeJogos  ) {
                contador ++
              }           
              console.log(contador) 
            })
          }}> Back up
          </Button>  */}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Row>
              <Col md={6}>
              
                <p><span style={{ fontWeight: "bold", fontSize: "18px" }} >Contas Registadas: </span><span style={{ fontWeight: "normal", fontSize: "16px" }}>{numeroDeJogadores} </span></p>
            </Col>
              </Row>
            <Col md={6}>
                <h5>Reservas Efetuadas</h5>
              <ReactApexChart options={charsvalor.options} series={charsvalor.series} type="bar" height={300} />
              </Col>
            <Col md={6}>
                <h5>Aulas Compradas</h5>
              <ReactApexChart options={charsvalorAulas.options} series={charsvalorAulas.series} type="bar" height={300} />
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <h5>Tempo Reservado</h5>
              <ReactApexChart options={charsTempoReservado.options} series={charsTempoReservado.series} type="bar" height={300} />
              </Col>
              <Col md={6}>
                <h5>Aulas Academia</h5>
              <ReactApexChart options={aulasMaisCompr.options} series={aulasMaisCompr.series} type="pie" height={300} />
              </Col> 
            </Row>
            <Row>
              <Col style={{ paddingTop: "40px", paddingBottom: "20px" }} md={6}>
                  <Row>
                    <h4>
                    Aulas mais compradas: 
                    </h4>
                  
                   {/* <Col md={6}>
                      <h5>Academia</h5>
                      {listaDeAcademias.map((elem,index) => {
                        console.log(elem)
                        if( index < 5 ){
                        return <p> {elem.nome + ": "} <span>{elem.value}</span></p>
                      }
                      })}
                   </Col> */}
                   {/* <Col md={6}>
                   <h5>PAX</h5>
                      {listaDePauxes.map((elem,index) => {
                        console.log(elem)
                        if( index < 5 ){
                        return <p> {elem.nome + ": "} <span>{elem.value}</span></p>
                      }
                      })}
                   
                   </Col> */}
                   <Col md={8}>
                    <h5>Aulas Pax</h5>
                  <ReactApexChart options={aulasMaisComprPax.options} series={aulasMaisComprPax.series} type="pie" height={300} />
                  </Col> 
                  </Row>
              </Col>
              {/* <Col style={{ paddingTop: "40px", paddingBottom: "20px" }} md={6}>
                  <Row>
                    <h4>
                    Durações mais reservadas: 
                    </h4>
                  </Row>
                  <Row>
                   <Col md={6}>
                   <Col md={6}>
                    <h5>Duracoes mais reservadas</h5>
                  <ReactApexChart options={duracoesMaisReservadas.options} series={duracoesMaisReservadas.series} type="pie" height={300} />
                  </Col> 
                   </Col>
                   <Col md={6}>
                   <h5>Contratos</h5>
                      {listaDeDuracoesContratos.map((elem,index) => {
                        console.log(elem)
                        if( index < 5 ){
                        return <p> {elem.nome + ": "} <span>{elem.value}</span></p>
                      }
                      })}
                   
                   </Col>
                  </Row>
              </Col> */}
              <Row className="align-items-center">
              
             
            
            </Row>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
    
    
  )
}

export default Dashboard
