import { createSlice } from "@reduxjs/toolkit"

export const imagemDaAppSlice = createSlice({
  name: "imagemDaApp",
  initialState: {
    value: "",
  },
  reducers: {
    setimagemDaApp: (state, action) => {
      state.value = JSON.stringify(action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setimagemDaApp } = imagemDaAppSlice.actions

export const selectimagemDaApp = state => state.imagemDaApp.value

export default imagemDaAppSlice.reducer
