import React from "react"
import { Redirect } from "react-router-dom"


// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ReservasScreen from "pages/Reservas/reservas"
import Descontos from "pages/descontos/descontos_main"
import AulasScreen from "pages/Aulas/aulas"
import ProcurarUtilzadoresScreen from "pages/utilizadores/users"
import ContratosScree from "pages/contratos/contratos"
import CriarReservas from "pages/Reservas/criar_reservas"
import VerReservasScreen from "pages/Reservas/verReservas"
import TreinadoresMainScreen from "pages/treinadores/treinadores_main_screen"
import CriarReservasSemRegistoScreen from "pages/Reservas/criar_reservas_sem_registo"
import AdicioanarBancoDeHoras from "pages/utilizadores/adicionar_banco_de_horas"
import ContratosPeriodicos from "pages/contratos/contratos_periodicos"
import DadosDoRequestID from "pages/utilizadores/dados_request_id"
import JogadoresToExcel from "pages/utilizadores/jogadores_to_excel"
import Feriados from "pages/descontos/feriados"
import MoloniPage from "pages/utilizadores/moloni"
import DesativarHoras from "pages/Reservas/desativar_horas"
import ValorDasAulas from "pages/clube/valores_aulas"
import FaturacaoScreen from "pages/clube/faturacao"
import ValoresDosContratosScreen from "pages/clube/valores_contratos"
import PesquisarReservas from "pages/Reservas/pesquisar_reservas"
import VerAulasScreen from "pages/Aulas/ver_aulas_screen"
import VisaoCalendario from "pages/Aulas/aulas_calendario"
import SemPermissoes from "pages/Authentication/SemPermissoes"
import GruposStaff from "pages/clube/GruposStaff"
import SingleStaff from "pages/clube/SingleStaff"
import HistoricoDeAulas from "services/useres/historico_de_aulas"
import ClassesEspeciais from "pages/clube/classes"
import HorarioDeFuncionamento from "pages/clube/horario_de_funcionamento"
import HistoricoDeAulasScreen from "pages/Aulas/historico_de_aulas_screen"
import { adminRole, nutriRole, recepRole, treinRole } from "services/consts"
import ImagemDaAppScreen from "pages/clube/imagem_da_app"
import VerHistoricoDeAulasScreen from "pages/Aulas/ver_historico_de_aulas_screen"
import HistoricoDeJogos from "pages/utilizadores/historico_de_jogos_screen"
import NutricaoScreen from "pages/nutricao/nutrica_screen"
import HistoricoDePagamentos from "pages/utilizadores/historico_de_pagamentos"
import FaturacaoDosTreinadores from "pages/treinadores/faturacao_dos_treinadores"


// SEMPRE QUE SE ALTERAR OS ROLES, ELES TEM ESTAR AQUI INDICADOS!! ORDENADOS A-Z
function getAllGruposStaff(){
  const grupos = [
    "Administrador",
    "Nutricionista",
    "Recepcionista",
    "Treinador"
  ];

  return grupos
}

const staff = getAllGruposStaff()

 const userRoutes = [

  { path: "/dashboard", component: Dashboard, 
    roles: [
      adminRole,
      treinRole,
      nutriRole,
      recepRole
    ] 
  },

  // Rotas das Reservas
  { path: "/adicionarReservas", custom_name:"Criar Reserva",  component: CriarReservas, roles: [adminRole,recepRole] },
  { path: "/reservas", custom_name:"Próximas Reservas", component: ReservasScreen , roles: [adminRole,recepRole]},
  { path: "/pesquisareservas", custom_name:"Pesquisar Reservas",  component: PesquisarReservas, roles: [adminRole,recepRole] },
  { path: "/desativarCampos", custom_name:"Bloquear Horário",  component: DesativarHoras , roles: [adminRole]},
  { path: "/reservas/:id", component: VerReservasScreen, roles: [adminRole,recepRole] },
  { path: "/adicionarReservasSemRegisto", component: CriarReservasSemRegistoScreen, roles: [adminRole,recepRole]},
  
  // Rotas do Clube
  { path: "/descontos", custom_name:"Descontos", component: Descontos, roles: [adminRole] },
  { path: "/imagemDaApp", custom_name:"Imagem", component: ImagemDaAppScreen, roles: [adminRole] },
  { path: "/feriados", custom_name:"Feriados", component: Feriados , roles: [adminRole]},
  { path: "/valorDasAulas", custom_name:"Valor das Aulas", component: ValorDasAulas , roles: [adminRole]},
  { path: "/faturasRef", custom_name:"Faturação", component: FaturacaoScreen , roles: [adminRole]},
  { path: "/valorDosContratos", custom_name:"Valor dos Contratos", component: ValoresDosContratosScreen , roles: [adminRole]},
  { path: "/grupos_staff",custom_name:"Grupos de Staff", component: GruposStaff , roles: [adminRole]},
  { path: "/grupos_staff/:role_param", component: SingleStaff , roles: [adminRole]},
  { path: "/moloni", custom_name:"Moloni", component: MoloniPage , roles: [adminRole]},
  { path: "/classes", custom_name:"Classes ", component: ClassesEspeciais , roles: [adminRole]},
  { path: "/horarioDeFuncionamento", custom_name:"Horário de Funcionamento", component: HorarioDeFuncionamento , roles: [adminRole]},


  // Rotas das Aulas / Calendário
  { path: "/aulas", custom_name:"Lista de Aulas", component: AulasScreen, roles: [adminRole,treinRole,recepRole] },
  { path: "/aulas/:id", component: VerAulasScreen, roles: [adminRole,treinRole,recepRole] },
  { path: "/historicoDeAula/:id", component: VerHistoricoDeAulasScreen, roles: [adminRole,treinRole] },
  { path: "/visaoCalendario", custom_name:"Visão Calendário", component: VisaoCalendario , roles: [adminRole,treinRole,recepRole]},
  { path: "/historicoDeAula", custom_name:"Histórico de Aulas", component: HistoricoDeAulasScreen, roles: [adminRole,treinRole]},
  { path: "/historicoDeAulas/:id", component: HistoricoDeAulas, roles: [adminRole,treinRole ]  },

  // Rotas dos Jogadores
  { path: "/procurarUtilizadores", custom_name:"Procurar Jogadores", component: ProcurarUtilzadoresScreen, roles: [adminRole,treinRole,recepRole ] },
  { path: "https://webapp.greatpadel.pt/", custom_name:"Registar Jogador", roles: [adminRole,recepRole]},
  { path: "/adicionarBancoDeHoras", custom_name:"Carregamentos", component: AdicioanarBancoDeHoras , roles: [adminRole]},
  { path: "/dadosDoRequestID", custom_name:"Dados do Request ID", component: DadosDoRequestID , roles: [adminRole]},
  { path: "/email_jogadores", custom_name:"Enviar Emails", component: JogadoresToExcel, roles: [adminRole] },
  { path: "/historicoDejogos/:email", custom_name:"Enviar Emails", component: HistoricoDeJogos, roles: [adminRole] },
  { path: "/historicoDePagamentos/:email/:id", custom_name:"Historico de Pagamentos", component: HistoricoDePagamentos, roles: [adminRole] },

  // Rotas dos Treinadores
  { path: "/treinadores", custom_name:"Lista de Treinadores", component: TreinadoresMainScreen , roles: [adminRole]},
  { path: "/treinadoresFatura", custom_name:"Faturacao Treinadores", component: FaturacaoDosTreinadores , roles: [adminRole]},


  // Rotas dos Contratos  
  { path: "/contratos", custom_name:"Livres", component: ContratosScree , roles: [adminRole]},
  { path: "/contratosPeriodicos", custom_name:"Períodicos", component: ContratosPeriodicos, roles: [adminRole] },
  
  { path: "/nutricao", custom_name:"Nutrição", component: NutricaoScreen, roles: [adminRole,nutriRole] },
  
  
  
  

  //profile
  { path: "/profile", component: UserProfile, roles: [adminRole] },

  // Erro de validação da ROLE
  { path: "/sem_permissoes", component: SemPermissoes, roles: [adminRole,treinRole] },

  // this route should be at the end of all other routes
  { path: "/", roles: [ adminRole, treinRole ], exact: true, component: () => <Redirect to="/dashboard" /> },

  
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

]

export { userRoutes, authRoutes }
