import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"


import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"


// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import {
  getLocalizacaoFromEmail,
  getLocalValues,
} from "services/localizacao/localizacao_services"
import {
  selectdescontos,
  setdescontos,
} from "store/descontos/descontos_reducer"
import { setlocalizacao } from "store/localizacao/localizacao_reducer"
import { setclasses } from "store/localizacao/classes_reducer"
import { setclubeid } from "store/localizacao/clube_id_reducer"
import { settreinadores } from "store/treinadores/treinadores_reducer"
import { setferiados } from "store/descontos/feriados"
import { setbloquearReservas } from "store/bloquear_reservas/bloquear_reservas_reducer"
import { setaulasvalores } from "store/clube/valores_aulas_reducer"
import { asdas } from "services/reservas/reservas_services"
import { setclassesespeciais } from "store/localizacao/classes_especiais"
import { setfaturasref } from "store/clube/faturas_referencias_reducer"
import { setcontratosvalores } from "store/clube/valores_dos_contratos_reducer"
import { setcarregamentos } from "store/clube/carregamentos_reducer_services"
import SemPermissoes from "pages/Authentication/SemPermissoes"
import { setgruposstaff, setRoles, userRoles } from "store/localizacao/grupos_staff"
import { backUp, logOut } from "services/clube/clube_services"
import { sethorarioDeFuncionamento } from "store/clube/horario_de_funcionamento_reducer"
import { setCurrentUserAuth } from "store/middlewarerotas/CurrentUserAuth" 
import { setsetuserroles } from "store/clube/user_roles_reducer"
import { setimagemDaApp } from "store/clube/imagem_da_app_reducer"

// Activating fake backend
//fakeBackend()

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

//const app = initializeApp(firebaseConfig);
initFirebaseBackend(firebaseConfig)

const App = props => {
  const [email, setemail] = useState("")
  var dispatch = useDispatch()

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const [authRole, setauthRole] = useState("")

  async function getValues() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      console.log("A INCIAAR APP")
      setemail(obj.email)
      try {
        await getLocalizacaoFromEmail(obj.email).then(async value => {
          console.log("CHECK ROLES");
          var user_role = value.user_role
          console.warn("ROLE DO UTILIZADOR: ", user_role)
          if(typeof user_role != "undefined"){
            setauthRole(user_role)
            dispatch(setCurrentUserAuth(user_role))
          } else {
           await logOut()
          }
          
          dispatch(setlocalizacao(value.localizacao))
          firebase
            .firestore()
            .collection("localizacao")
            .where("localizacao", "==", value.localizacao)
            .limit(1)
            .onSnapshot(values => {
              var imagemDaApp = values.docs.at(0).data().imagemDaApp
              var userRoles = values.docs.at(0).data().userRoles
              var horarioDeFuncionamento = values.docs.at(0).data().horarioDeFuncionamento
              var faturasRef = values.docs.at(0).data().faturasRef
              var carregamentos = values.docs.at(0).data().valoresDosCarregamentos
              var valoresDosContratosScreen = values.docs.at(0).data().valoresDosContratos
              var classesEspeciais = values.docs.at(0).data().classesEspeciais
              var bloquear = values.docs.at(0).data().horariosBloqueadosJson
              var valoresDasAulas = values.docs.at(0).data().valoresDasAulas
              var descontos = values.docs.at(0).data().descontos
              var classes = values.docs.at(0).data().classes
              var clubeid = values.docs.at(0).id
              var treinadores = values.docs.at(0).data().treinadores
              var localizacao = values.docs.at(0).data().localizacao
              var feriados = values.docs.at(0).data().feriados
              console.log("Valores do local: ", descontos, valoresDasAulas)
              console.log("BLOQUEAR: ", bloquear)
              dispatch(setimagemDaApp(imagemDaApp))
              dispatch(setsetuserroles(userRoles))
              dispatch(sethorarioDeFuncionamento(horarioDeFuncionamento))
              dispatch(setcarregamentos(carregamentos))
              dispatch(setcontratosvalores(valoresDosContratosScreen))
              dispatch(setfaturasref(faturasRef))
              dispatch(setclassesespeciais(classesEspeciais))
              dispatch(setbloquearReservas(bloquear))
              dispatch(setaulasvalores(valoresDasAulas))
              dispatch(setdescontos(descontos))
              dispatch(setclasses(classes))
              dispatch(setclubeid(clubeid))
              dispatch(settreinadores(treinadores))
              dispatch(setlocalizacao(localizacao))
              dispatch(setferiados(feriados))
              
              // Construída uma lógica para ordenar os roles alfabeticamente
              const rolesDataObject = values.docs.at(0).data().userRoles; 

              // Pega nos nomes dos cargos e ordena A-Z
              const sortedRoleNames = Object.keys(rolesDataObject).sort((a, b) => a.localeCompare(b));
              
              // Instancia um novo objeto para guardar os cargos ordenados
              const sortedRolesObject = {};
              sortedRoleNames.forEach(roleName => {
                sortedRolesObject[roleName] = rolesDataObject[roleName];
              });
              
              // Guarda os cargos ordenados no redux
              dispatch(setRoles(sortedRolesObject));
              
             
            })
        })
      } catch (error) {
        console.log("ERRO")
        console.log(error)
      }
    }
  }


  

  // TODO: Função que vai buscar o role do utilizador à base de dados pelo email.
  async function getUserRole(email) {

    setauthRole("Administrador")
    dispatch(setCurrentUserAuth(authRole))

    return authRole
  }


  useEffect(() => {
    getValues()
    getUserRole(email)
  }, [email])

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {authRole && userRoutes.map((route, idx) => {
                // Se o utilizador tiver a role necessária para aceder à rota, então mostra a rota
                const hasRoles = route.roles.includes(authRole);
                if (hasRoles) {
                  return (
                    <Authmiddleware
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      exact
                    />
                  );
                }
                else{
                  return (
                  <SemPermissoes 
                      path={route.path}
                      layout={Layout}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      exact
                  />);
                }
          })}


        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
