import { Aula } from 'models/aula';
import { Contrato } from 'models/contrato';
import { ProcuraReserva } from 'models/reserva';
import React, { useEffect, useState } from 'react';
import { Check, Pencil } from 'react-bootstrap-icons';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import { useSelector } from 'react-redux';
import TimePicker from 'react-time-picker';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { createAula, updateContratoPeriodico } from 'services/aulas/aulas_services';
import { contrato, convertCamps, nomesCampos } from 'services/consts';
import { adicionarContratoAoUser, createContrato } from 'services/contratos/contratos_services';
import { checkIfUserExists, checkIfUserExistsEmaiLOrNum, retornaCamposIndisponíveisNaHora, retornaCamposIndisponíveisNaHoraENDP } from 'services/reservas/reservas_services';
import { pesquisarJogadoresByEmailParaEntidades } from 'services/useres/jogadores_services';
import { selectcontratosvalores } from 'store/clube/valores_dos_contratos_reducer';
import { selectlocalizacao } from 'store/localizacao/localizacao_reducer';

function EditarContratosPeriodicoModal(props) {
    const [isOpen, setIsOpen] = useState(false)
    const localizacao = useSelector(selectlocalizacao)
    const [isCamposIndisLoading, setIsCamposIndisLoading] = useState(false)

    var listAuxD = Object.entries(props.data.alunosData)
    
    const [email1, setEmail1] = useState(listAuxD[0][0])
    const [email2, setEmail2] = useState(listAuxD[1][0])
    const [email3, setEmail3] =useState(listAuxD[2][0])
    const [email4, setEmail4] = useState(listAuxD[3][0])

    const [nome1, setNome1] = useState(listAuxD[0][1].nome)
    const [nome2, setNome2] = useState(listAuxD[1][1].nome)
    const [nome3, setNome3] = useState(listAuxD[2][1].nome)
    const [nome4, setNome4] =useState(listAuxD[3][1].nome)
  
    const [cc1, setCC1] = useState(listAuxD[0][1].cc)
    const [cc2, setCC2] = useState(listAuxD[1][1].cc)
    const [cc3, setCC3] = useState(listAuxD[2][1].cc)
    const [cc4, setCC4] = useState(listAuxD[3][1].cc)

    const [check1, setCheck1] = useState(null)
    const [check2, setCheck2] = useState(null)
    const [check3, setCheck3] = useState(null)
    const [check4, setCheck4] = useState(null)

    const [numeroTele1, setnumeroTele1] = useState(listAuxD[0][1].nrTele ?? "")
    const [numeroTele2, setnumeroTele2] = useState(listAuxD[1][1].nrTele ?? "")
    const [numeroTele3, setnumeroTele3] = useState(listAuxD[2][1].nrTele ?? "")
    const [numeroTele4, setnumeroTele4] = useState(listAuxD[3][1].nrTele ?? "")

    const [diaFinal, setDiaFinal] = useState( typeof props.data.expiryAt == "undefined" ? null : props.data.expiryAt.toDate())  
  
    const [colorBTN1 , setColorBTN1] = useState("secondary")
    const [colorBTN2 , setColorBTN2] = useState("secondary")
    const [colorBTN3 , setColorBTN3] = useState("secondary")
    const [colorBTN4 , setColorBTN4] = useState("secondary")

    async function emailNumeroCheck(email, setEmailV, setNomeV, setCCV, setNrTeleV) {
      var primeiroNome = ""
      var ultimoNome = ""
      var numeroTele = ""
      var isEmail1Aux = await checkIfUserExistsEmaiLOrNum(email)
  
      console.log(email)
      console.log("isEmail1")
      console.log(isEmail1Aux) 
      
      if (isEmail1Aux.length > 1) {
        alert("Mais de um user encontrado com este numero\nDiga qual email deseja reservar")
        return false
      }
      if (isEmail1Aux.length == 0) {
        alert("Os dados não estão associados a nenhuma conta")
        return false
      }
      var isEmail1 = isEmail1Aux.at(0)
      setEmailV(isEmail1.email)
      if (typeof isEmail1.primeiroNome != "undefined") {
        primeiroNome = isEmail1.primeiroNome + " "
      }
      if (typeof isEmail1.ultimoNome != "undefined") {
        ultimoNome = isEmail1.ultimoNome
      }
      if (typeof isEmail1.numeroDoCC != "undefined") {
        setCCV(isEmail1.numeroDoCC)
      } else {
        setCCV(null)
      }
  
      if (typeof isEmail1.numeroTele != "undefined") {
        numeroTele = isEmail1.numeroTele
        setNrTeleV(isEmail1.numeroTele)
      } else {
        setNrTeleV("")
      }
      setNomeV(primeiroNome + ultimoNome)
    
      if (
        typeof isEmail1.primeiroNome != "undefined" &&
        typeof isEmail1.ultimoNome != "undefined" &&
        typeof isEmail1.numeroDoCC != "undefined"
      ) {
        return true
      } else {
        return false
      }
    }

    async function emailCheck(email, ccId) {
      var primeiroNome = ""
      var ultimoNome= ""
      var isEmail1 = await checkIfUserExists(email)
  
      if(Object.keys(isEmail1).length == 0){
        alert('O email não está registado')
        return false
      }
      if(typeof isEmail1.primeiroNome != "undefined") {
        primeiroNome = isEmail1.primeiroNome + " "
      }
      if(typeof isEmail1.ultimoNome != "undefined") {
        ultimoNome = isEmail1.ultimoNome
      }
      if(typeof isEmail1.numeroDoCC != "undefined") {
        setCC1(isEmail1.numeroDoCC)
      } else {
        document.getElementById(ccId).value = null
      }
      setNome1(primeiroNome + ultimoNome)
      
      if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
        return true
      } 
      else {
        return false;
      }
    }

    async function emailCheck2(email, ccId) {
      var primeiroNome = ""
      var ultimoNome= ""
      var isEmail1 = await checkIfUserExists(email)
  
      if(Object.keys(isEmail1).length == 0){
        alert('O email não está registado')
        return false
      }
      if(typeof isEmail1.primeiroNome != "undefined") {
        primeiroNome = isEmail1.primeiroNome + " "
      }
      if(typeof isEmail1.ultimoNome != "undefined") {
        ultimoNome = isEmail1.ultimoNome
      }
      if(typeof isEmail1.numeroDoCC != "undefined") {
        setCC2(isEmail1.numeroDoCC)
      } else {
        document.getElementById(ccId).value = null
      }
      setNome2(primeiroNome + ultimoNome)
  
      if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
        return true
      } 
      else {
        return false;
      }
    }
  
    async function emailCheck3(email, ccId) {
      var primeiroNome = ""
      var ultimoNome= ""
      var isEmail1 = await checkIfUserExists(email)
  
      if(Object.keys(isEmail1).length == 0){
        alert('O email não está registado')
        return false
      }
      if(typeof isEmail1.primeiroNome != "undefined") {
        primeiroNome = isEmail1.primeiroNome + " "
      }
      if(typeof isEmail1.ultimoNome != "undefined") {
        ultimoNome = isEmail1.ultimoNome
      }
      if(typeof isEmail1.numeroDoCC != "undefined") {
        setCC3(isEmail1.numeroDoCC)
      } else {
        document.getElementById(ccId).value = null
      }
      setNome3(primeiroNome + ultimoNome)
  
      if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
        return true
      } 
      else {
        return false;
      }
    }
  
    async function emailCheck4(email, ccId) {
      var primeiroNome = ""
      var ultimoNome= ""
      var isEmail1 = await checkIfUserExists(email)
  
      if(Object.keys(isEmail1).length == 0){
        alert('O email não está registado')
        return false
      }
      if(typeof isEmail1.primeiroNome != "undefined") {
        primeiroNome = isEmail1.primeiroNome + " "
      }
      if(typeof isEmail1.ultimoNome != "undefined") {
        ultimoNome = isEmail1.ultimoNome
      }
      if(typeof isEmail1.numeroDoCC != "undefined") {
        setCC4(isEmail1.numeroDoCC)
      } else {
        document.getElementById(ccId).value = null
      }
      setNome4(primeiroNome + ultimoNome)
  
      if(typeof isEmail1.primeiroNome != "undefined" && typeof isEmail1.ultimoNome != "undefined" && typeof isEmail1.numeroDoCC != "undefined" ) {
        return true
      } 
      else {
        return false;
      }
    }

    const [numeroDeEmailsAenviar, setNumeroDeEmailsAenviar] = useState(1)
    const [emailsI, setEmailsI] = useState([])
    const [camposDisponiv, setCamposDisponiv] = useState([])
    const [isAtiva, setIsAtiva] = useState(props.data.isAtiva)
    const [diaDaSemana, setDiaDaSemana] = useState(props.data.weekDay)

    const handleChange = event => {
      if (event.target.checked) {
        alert("Adicione um campo")
      } else {
        setCampoDaReserva(null)
        setCamposDisponiv([])
        setCampoEscolhido([]) 
        alert("Ao alterar o estado para inativo, o campo associado será removido ")
      }
      setIsAtiva(current => !current)
    }
    /* const handleChange = event => {
    if (event.target.checked) {
      
      alert("Adicione um campo à aula")
    } else {
      setCampos([])
      setCamposDisp([])
      setCampoEscolhido([]) 
      alert("Ao alterar o estado da aula para inativo, o campo associado à aula será removido da mesma ")
    }
    setIsAtiva(current => !current)
  } */

    const [horaInicial, setHoraInicial] = useState(props.data.horaInicial)
    const [horaFinal, setHoraFinal] = useState(props.data.horaFinal)
    const [nomeDoContrato,setNomeDoContrato] = useState(props.data.notas) 
    const [campoEscolhido, setCampoEscolhido] = useState(props.data.campos)
    const [campoDaReserva, setCampoDaReserva] = useState(props.data.campos)


    const [tipoDeContratos, setTipoDeContratos] = useState("")
    const tipoDeContratosStringfied = useSelector(selectcontratosvalores)
    const tipoDeContratosJson = JSON.parse(tipoDeContratosStringfied)
    const mapContratosV = [" "].concat(Object.entries(tipoDeContratosJson))

    

    var weekday = new Array(8)
    weekday[0] = " "
    weekday[1] = "Segunda"
    weekday[2] = "Terça"
    weekday[3] = "Quarta"
    weekday[4] = "Quinta"
    weekday[5] = "Sexta"
    weekday[6] = "Sábado"
    weekday[7] = "Domingo"

  const removeCamposEsc = value => {
    setCampoEscolhido(campoEscolhido.filter(item => item !== value))
  }

  async function adicionarContrato() {
    const aula = {}

  if( document.getElementById("email1Input").value == "" || document.getElementById("email1Input").value == null ) {
      alert("O email 1 não pode estar vazio não pode estar vazio")
  }
  else if( document.getElementById("email2Input").value == "" || document.getElementById("email2Input").value == null ) {
      alert("O email 2 não pode estar vazio não pode estar vazio")
  }
  else if( document.getElementById("email3Input").value == "" || document.getElementById("email3Input").value == null ) {
      alert("O email 3 não pode estar vazio não pode estar vazio")
  }
  else if( document.getElementById("email4Input").value == "" || document.getElementById("email4Input").value == null ) {
      alert("O email 4 não pode estar vazio não pode estar vazio")
  } else {
  
    var listAlunos = []
    listAlunos.concat();
    listAlunos.push(document.getElementById("email1Input").value)
    listAlunos.push(document.getElementById("email2Input").value)
    listAlunos.push(document.getElementById("email3Input").value)
    listAlunos.push(document.getElementById("email4Input").value)

    aula.alunosData = {}
    aula.alunosData[document.getElementById("email1Input").value] = {}
    aula.alunosData[document.getElementById("email1Input").value].cc = cc1
    aula.alunosData[document.getElementById("email1Input").value].nome = nome1
    aula.alunosData[document.getElementById("email1Input").value].nrTele = numeroTele1

    aula.alunosData[document.getElementById("email2Input").value] = {}
    aula.alunosData[document.getElementById("email2Input").value].cc = cc2
    aula.alunosData[document.getElementById("email2Input").value].nome = nome2
    aula.alunosData[document.getElementById("email2Input").value].nrTele = numeroTele2

    aula.alunosData[document.getElementById("email3Input").value] = {}
    aula.alunosData[document.getElementById("email3Input").value].cc = cc3
    aula.alunosData[document.getElementById("email3Input").value].nome = nome3
    aula.alunosData[document.getElementById("email3Input").value].nrTele = numeroTele3

    aula.alunosData[document.getElementById("email4Input").value] = {}
    aula.alunosData[document.getElementById("email4Input").value].cc = cc4
    aula.alunosData[document.getElementById("email4Input").value].nome = nome4
    aula.alunosData[document.getElementById("email4Input").value].nrTele = numeroTele4

   
    aula.expiryAt = diaFinal
    aula.isAtiva = isAtiva
    aula.alunos = listAlunos
    aula.notas = nomeDoContrato
    aula.weekDay =
    document.getElementById("weekdaySelect").selectedIndex
    aula.localizacao = localizacao
    aula.campos = campoEscolhido
    aula.horaFinal = horaFinal
    aula.horaInicial = horaInicial
    console.log("campoEscolhido")

    console.log("----- Contrato ------")
    console.log(aula)

    var res = await updateContratoPeriodico(props.id, aula)
    if(res) {
      alert("Contrato adicionado com sucesso")
    }
    else {
      alert("Erro a adicionar contrato")
    }
  }
  }

  function minsDiffs(horaInicial, horaFinal, weekDay) {
    var hi = new Date()
    var hf = new Date()
    var himins = horaInicial.substring(3, 5)
    var hihour = horaInicial.substring(0, 2)
    var hfmins = horaFinal.substring(3, 5)
    var hfhour = horaFinal.substring(0, 2)
    hi.setHours(hihour)
    hi.setMinutes(himins)
    hf.setHours(hfhour)
    hf.setMinutes(hfmins)

    var diffMs = hf - hi
    var duracao = Math.round(diffMs / 60000) // minutes
    console.log(duracao + " minutes ")

    var today = hi.getDay()
    if (today == 0) {
      today = 7
    }

    if (today > weekDay) {
      weekDay = weekDay + 7
    }

    var daysDiff = weekDay - today

    console.log(hi.getDay())
    console.log(weekDay)
    console.log(daysDiff)

    var dia = hi.getDate()
    var mes = hi.getMonth()
    var ano = hi.getFullYear()
    var checkDate = new Date(ano, mes, dia + daysDiff, hihour, himins)
    console.log(checkDate)

    return [duracao, checkDate]
  }

  const numeroDeCampos= 6
  function removeCamposRepetidos(campoosInsdis) {
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i
      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }
    setCamposDisponiv(listaDeCampos)
  }

  /* useEffect(() => {
    var listAux = []
    for (let index = 1; index <= 6; index++) {
      var campo = "Campo " + index
      listAux.push(campo)
    }

    setCamposDisponiv(listAux)
    return () => {
      setCamposDisponiv([]) // This worked for me
    }
  }, []) */

    function atualizaDados() {

    setEmail1(listAuxD[0][0])
    setEmail2(listAuxD[1][0])
    setEmail3(listAuxD[2][0])
    setEmail4(listAuxD[3][0])

    setNome1(listAuxD[0][1].nome)
    setNome2(listAuxD[1][1].nome)
    setNome3(listAuxD[2][1].nome)
    setNome4(listAuxD[3][1].nome)
  
     setCC1(listAuxD[0][1].cc)
     setCC2(listAuxD[1][1].cc)
     setCC3(listAuxD[2][1].cc)
     setCC4(listAuxD[3][1].cc)

     setCheck1(null)
     setCheck2(null)
     setCheck3(null)
     setCheck4(null)

     setnumeroTele1(listAuxD[0][1].nrTele ?? "")
     setnumeroTele2(listAuxD[1][1].nrTele ?? "")
     setnumeroTele3(listAuxD[2][1].nrTele ?? "")
     setnumeroTele4(listAuxD[3][1].nrTele ?? "")

    setDiaFinal( typeof props.data.expiryAt == "undefined" ? null : props.data.expiryAt.toDate())  
  
     setColorBTN1("secondary")
     setColorBTN2("secondary")
     setColorBTN3("secondary")
     setColorBTN4("secondary")

    setHoraInicial(props.data.horaInicial) 
    setHoraFinal(props.data.horaFinal)
    setNomeDoContrato(props.data.notas) 
    setCampoEscolhido(props.data.campos)
    setCampoDaReserva(props.data.campos)

    setTipoDeContratos("")

    }

    function limpaDados() {

      setCheck1(null)
      setEmail1("")
      setCC1("")
      setNome1("")
      setnumeroTele1("")
      setColorBTN1("secondary")
  
      setCheck2(null)
      setEmail2("")
      setCC2("")
      setNome2("")
      setnumeroTele2("")
      setColorBTN2("secondary")
  
      setCheck3(null)
      setEmail3("")
      setCC3("")
      setNome3("")
      setnumeroTele3("")
      setColorBTN3("secondary")
  
      setCheck4(null)
      setEmail4("")
      setCC4("")
      setNome4("")
      setnumeroTele4("")
      setColorBTN4("secondary")
  
      setNomeDoContrato("")
      setHoraInicial("00:00")
      setHoraFinal("00:00")
      setCampoEscolhido([])
      removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
      setDiaFinal(new Date())
  
    }
  

    return (  
        <React.Fragment>
         
            <Button color= 'warning' onClick={() => {
              atualizaDados()
          setIsOpen(!isOpen)
        }}>
               <Pencil></Pencil> 
            </Button>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            limpaDados()
            setIsOpen(!isOpen)
          }}>
            <ModalHeader
            color="primary"
            toggle={() => {
                setIsOpen(!isOpen)
            }}
            >
            Editar contrato periódicos
            </ModalHeader>
        <ModalBody>
          
        <Form>
        <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isAtiva}
                  value={isAtiva}
                  onChange={handleChange}
                  id="isAulaAtiva"
                  name="subscribe"
                />{" "}
                Ativa
              </Label>
            </FormGroup>
          <FormGroup>
            <Row>
                <Col md={3}>
                  <p>Nome do Contrato</p>
                </Col>
                <Col md={9} >
                  <Input
                   value={nomeDoContrato}
                   type="text"
                    name="nomedoContrato"
                    id="nomedoContrato"
                   onChange={(e) => {
                    setNomeDoContrato(e.target.value)
                   }}
                  ></Input>
                </Col>
            </Row>
          </FormGroup>
            <h5>Jogador 1</h5>
          <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email1}
                    onChange={e => {
                      setEmail1(e.target.value)
                    }}
                    type="email"
                    name="email1Input"
                    id="email1Input"
                  />
                </Col>
                <Col md={2}>
                          <Button id="ckeckButton1" color={colorBTN1} onClick={async() => {
                            var check = await emailNumeroCheck(email1,
                              setEmail1,
                              setNome1,
                              setCC1,
                              setnumeroTele1,
                               )

                            setCheck1(check)
                            if(check) {
                              setColorBTN1("primary")
                            } else {
                              setColorBTN1("secondary")
                            }
                          }} >
                            <Check></Check>
                          </Button>
                          </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <p>Telemóvel</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={numeroTele1}
                    onChange={e => {
                      setnumeroTele1(e.target.value)
                    }}
                    type="number"
                    name="nrTele1"
                    id="nrTele1"
                  />
                </Col>
                <Col md={2}>
                          <Button id="ckeckButton1" color={colorBTN1} onClick={async() => {
                            var check = await emailNumeroCheck(numeroTele1,
                              setEmail1,
                              setNome1,
                              setCC1,
                              setnumeroTele1,
                               )

                            setCheck1(check)
                            if(check) {
                              setColorBTN1("primary")
                            } else {
                              setColorBTN1("secondary")
                            }
                          }} >
                            <Check></Check>
                          </Button>
                          </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome1}
                    onChange={e => {
                      setNome1(e.target.value)
                    }}
                    type="text"
                    name="nome1Input"
                    id="nome1Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc1}
                    onChange={e => {
                      setCC1(e.target.value)
                    }}
                    type="number"
                    name="cc1Input"
                    id="cc1Input"
                  />
                </Col>
                
              </Row>
              
            </FormGroup>
            
            <h5>Jogador 2</h5>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email2}
                    onChange={e => {
                      setEmail2(e.target.value)
                    }}
                    type="email"
                    name="email2Input"
                    id="email2Input"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton2" color={colorBTN2} onClick={async() => {
                    var check = await emailNumeroCheck(email2,
                      setEmail2,
                      setNome2,
                      setCC2,
                      setnumeroTele2,
                       )

                    setCheck2(check)
                    if(check) {
                      setColorBTN2("primary")
                    } else {
                      setColorBTN2("secondary")
                    }
                  }}  >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <p>Telemóvel</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={numeroTele2}
                    onChange={e => {
                      setnumeroTele2(e.target.value)
                    }}
                    type="number"
                    name="nrTele2"
                    id="nrTele2"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton2" color={colorBTN2} onClick={async() => {
                    var check = await emailNumeroCheck(numeroTele2,
                      setEmail2,
                      setNome2,
                      setCC2,
                      setnumeroTele2,
                       )

                    setCheck2(check)
                    if(check) {
                      setColorBTN2("primary")
                    } else {
                      setColorBTN2("secondary")
                    }
                  }}  >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={nome2}
                    onChange={e => {
                      setNome2(e.target.value)
                    }}
                    type="text"
                    name="nome2Input"
                    id="nome2Input"
                  />
                </Col>
                
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc2}
                    onChange={e => {
                      setCC2(e.target.value)
                    }}
                    type="number"
                    name="cc2nput"
                    id="cc2Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            
            
            <h5>Jogador 3</h5>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email3}
                    onChange={e => {
                      setEmail3(e.target.value)
                    }}
                    type="email"
                    name="email3Input"
                    id="email3Input"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton3" color={colorBTN3} onClick={async() => {
                    var check = await emailNumeroCheck(email3,
                      setEmail3,
                      setNome3,
                      setCC3,
                      setnumeroTele3, )
                    setCheck3(check)
                    if(check) {
                      setColorBTN3("primary")
                    } else {
                      setColorBTN3("secondary")
                    }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
              
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <p>Telemóvel</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={numeroTele3}
                    onChange={e => {
                      setnumeroTele3(e.target.value)
                    }}
                    type="number"
                    name="nrTele3"
                    id="nrTele3"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton3" color={colorBTN3} onClick={async() => {
                    var check = await emailNumeroCheck(numeroTele3,
                      setEmail3,
                      setNome3,
                      setCC3,
                      setnumeroTele3,
                       )

                    setCheck3(check)
                    if(check) {
                      setColorBTN3("primary")
                    } else {
                      setColorBTN3("secondary")
                    }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome3}
                    onChange={e => {
                      setNome3(e.target.value)
                    }}
                    type="text"
                    name="nome3Input"
                    id="nome3Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc3}
                    onChange={e => {
                      setCC3(e.target.value)
                    }}
                    type="number"
                    name="cc3Input"
                    id="cc3Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            
            <h5>Jogador 4</h5>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Email</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={email4}
                    onChange={e => {
                      setEmail4(e.target.value)
                    }}
                    type="email"
                    name="email4Input"
                    id="email4Input"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton4" color={colorBTN4} onClick={async() => {
                    var check = await emailNumeroCheck(email4,
                      setEmail4,
                      setNome4,
                      setCC4,
                      setnumeroTele4,
                       )

                    setCheck4(check)
                    if(check) {
                      setColorBTN4("primary")
                    } else {
                      setColorBTN4("secondary")
                    }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup >
              <Row>
                <Col md={3}>
                  <p>Telemóvel</p>
                </Col>
                <Col md={7}>
                  <Input
                    value={numeroTele4}
                    onChange={e => {
                      setnumeroTele4(e.target.value)
                    }}
                    type="number"
                    name="nrTele4"
                    id="nrTele4"
                  />
                </Col>
                <Col md={2}>
                    <Button id="ckeckButton4" color={colorBTN4} onClick={async() => {
                    var check = await emailNumeroCheck(numeroTele4,
                      setEmail4,
                      setNome4,
                      setCC4,
                      setnumeroTele4,
                       )

                    setCheck4(check)
                    if(check) {
                      setColorBTN4("primary")
                    } else {
                      setColorBTN4("secondary")
                    }
                    }} >
                <Check></Check>
                </Button>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nome</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome4}
                    onChange={e => {
                      setNome4(e.target.value)
                    }}
                    type="text"
                    name="nome4Input"
                    id="nome4Input"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>CC</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={cc4}
                    onChange={e => {
                      setCC4(e.target.value)
                    }}
                    type="number"
                    name="cc4Input"
                    id="cc4Input"
                  />
                </Col>
              </Row>
            </FormGroup>
           
            {/* <FormGroup row>
              <Label sm={3} for="preco">
                Preço
              </Label>
              <Col sm={4}>
                <div className="input-group mb-3">
                  <input
                    onChange={e => console.log(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="6.50"
                    step="0.01"
                    pattern="^\d+(?:\.\d{1,2})?$"
                    id="preco"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      €
                    </span>
                  </div>
                </div>
              </Col>
            </FormGroup> */}
            <FormGroup style={{ paddingTop: "20px"}} >
              <Row>
                <Col md={3}>
                  <p>Dia da semana</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={weekday[diaDaSemana]}
                    onChange={e => {
                      setCampoEscolhido([])
                      setCampoDaReserva(null)
                      removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                      setDiaDaSemana(
                        document.getElementById("weekdaySelect").selectedIndex
                      )
                      console.log(
                        document.getElementById("weekdaySelect").selectedIndex
                      )
                    }}
                    type="select"
                    name="select"
                    id="weekdaySelect"
                  >
                    {weekday.map((element, index) => {
                      return <option key={index}>{element}</option>
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row>
                <Label md={3}>
                  <p>Hora Inicial</p>
                </Label>
                <Col md={9}>
                <TimePicker 
                onChange={(e) => {
                  setCampoEscolhido([])
                  setCampoDaReserva(null)
                  removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                  setHoraInicial(e)
                }}
                    value={horaInicial}
                    disableClock={true} >
                    </TimePicker>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label md={3}>
                  <p>Hora Final</p>
                </Label>
                <Col md={9}>
                <TimePicker 
                onChange={(e) => {
                  setCampoEscolhido([])
                  setCampoDaReserva(null)
                  removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                  setHoraFinal(e)
                }}
                    value={horaFinal}
                    disableClock={true} >
                    </TimePicker>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label md={3}>
                  <p>Expira em:</p>
                </Label>
                <Col md={9}>
                <DatePicker 
                onChange={setDiaFinal}
                    value={diaFinal}
                    disableClock={true} >
                    </DatePicker>
                </Col>
            </FormGroup>
            <FormGroup>
              <h5>Campo do Contrato:  {campoDaReserva == null ? null :  convertCamps[campoDaReserva]}</h5>
            </FormGroup>
            {isCamposIndisLoading ? <Spinner color="primary"></Spinner> :
            <Button
                style={{ marginBottom: "20px" }}
                color="primary"
                onClick={async () => {
                  setIsCamposIndisLoading(true)
                  if (horaInicial == "00:00") {
                    alert("É necessário escolher a hora inicial ")
                    setIsCamposIndisLoading(false)
                  }
                  if (horaFinal == "00:00") {
                    alert("É necessário escolher a hora final ")
                    setIsCamposIndisLoading(false)
                  } else if (diaDaSemana == 0 ) {
                    alert("É necessário escolher o dia da semana ")
                    setIsCamposIndisLoading(false)
                  }else if(nomeDoContrato.trim() == "" || nomeDoContrato == null) {
                    alert("O nome não pode estar vazio")
                    setIsCamposIndisLoading(false)
                  } else {
                    var reserva = minsDiffs(
                      horaInicial,
                      horaFinal,
                      diaDaSemana
                    ) 
                    var procuraReserva = new ProcuraReserva()
                    procuraReserva.duracaoDaReserva = reserva[0]
                    procuraReserva.horaDaReserva = reserva[1]
                    procuraReserva.localizacao = "Great Padel Vila Verde"

                    await retornaCamposIndisponíveisNaHoraENDP(
                      procuraReserva
                    ).then((value) => {
                      console.log(value)
                      removeCamposRepetidos(value['Ocupados'])  
                    }) 
                    setIsCamposIndisLoading(false)
                  }
                }}
              >
                Mostrar campos disponíveis
              </Button> }
            
            <FormGroup>
              <Row>
                        {camposDisponiv.map((value, index) => {
                          var campoToShow = value.split(" ")
                          console.log(campoToShow)
                          return (
                            <Col
                              key={index}
                              md={4}
                              style={{ paddingBottom: "10px" }}
                            >
                              <Button
                                outline={campoEscolhido == value ? false : true}
                                color="primary"
                                onClick={() => {
                                  if (campoEscolhido != value) {
                                    setCampoEscolhido([value])
                                  } else {
                                    setCampoEscolhido(null)
                                  }
                                }}
                              >
                                {convertCamps[value]}
                              </Button>
                            </Col>
                          )
                        })}
                      </Row>
            </FormGroup>
            </Form>
            <ModalFooter>
              
          <Button
            onClick={async () => {
               await adicionarContrato() 
            }}
            color="primary"
          >
            Adicionar Contrato
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              limpaDados()
              setIsOpen(!isOpen)
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
        </ModalBody>
        </Modal>
        </React.Fragment>
    );
}

export default EditarContratosPeriodicoModal;