import AdicionarValorDeAulas from "components/modals/Adicioanr_valores_de_aulas"
import AdicionarValorDeContratos from "components/modals/adicionar_valores_de_Contratos"
import EditarFeriadosModal from "components/modals/editar_feriado_modal"
import React from "react"
import { Pencil, Trash2Fill } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"
import { Button, Col, Container, Row, Table } from "reactstrap"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"
import { selectcontratosvalores } from "store/clube/valores_dos_contratos_reducer"
import { selectferiados } from "store/descontos/feriados"

function ValoresDosContratosScreen() {
  const { t, i18n } = useTranslation()
  const contratos = useSelector(selectcontratosvalores)

  const contratosJson = JSON.parse(contratos)
  const mapContratos = Object.entries(contratosJson)

  const sortedList = mapContratos.sort((a, b) => {
    if (a > b) {
      return 1
    } else return -1
  })

  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }

  console.log(sortedList)
  console.log("sortedList")

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Great Padel - Contratos Valores</title>
        </MetaTags>


        <Container fluid>
          <div className="page-title-box">

            {/* Título */}
            <Row className="align-items-center">
              <Col md={10}>
                <h6 className="page-title">{t("Valores dos Contratos")}</h6>
              </Col>
              <Col md={2}>
                <AdicionarValorDeContratos />
              </Col>
            </Row>

            
          </div>


          {/* Tabela */}
          <Row className="pt-2">
              <Table bordered striped responsive className="myList" style={stylesTable}>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Periodo</th>
                    <th>Valor</th>
                    <th>N° Jogos</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedList.map((elem, index) => {
                    console.log(elem)
                    return (
                      <tr key={elem[0] + index} >
                        <td>{elem[0]}</td>
                        <td>{elem[1].periodo }</td>
                        <td>{elem[1].valor + " €"}</td>
                        <td>{elem[1].nrJogosPSemana }</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ValoresDosContratosScreen
