import firebase from "firebase/app"
import "firebase/firestore"
import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Spinner,
  Container,
  Table,
} from "reactstrap"
import TimePicker from "react-time-picker"
import { Aula } from "models/aula"
import {
  getAulaById,
  updateAula,
} from "services/aulas/aulas_services"
import AdicionarAlunos from "components/modals/adicionar_alunos_modal"
import { useSelector } from "react-redux"
import { selecttreinadores } from "store/treinadores/treinadores_reducer"
import { adminRole, aulasCollection, convertCamps, historicoDeAulasCollection, niveis, recepRole } from "services/consts"
import InfoAlunosModal from "components/modals/info_alunos_modal"
import {
  retornaCamposIndisponíveisNaHora,
} from "services/reservas/reservas_services"
import { ProcuraReserva } from "models/reserva"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import DatePicker from "react-date-picker"
import { selectCurrentUserAuth } from "store/middlewarerotas/CurrentUserAuth"

function VerHistoricoDeAulasScreen(props) {
  let { id } = useParams()
  const userRole = useSelector(selectCurrentUserAuth)

  const [renderas, setRenderas] = useState(true)
  var treinadores = useSelector(selecttreinadores)
  const [nomeDaAula, setNomeDaAula] = useState("")
  const [horaInicial, setHoraInicial] = useState(null)
  const [horaFinal, setHoraFinal] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [campoEscolhido, setCampoEscolhido] = useState(null)
  const [nivel, setNivel] = useState(null)
  const [professor, setProfessor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [diaDaSemana, setDiaDaSemana] = useState(0)
  const [isAtiva, setIsAtiva] = useState(null)
  const [tipoDeAula, setTipoDeAula] = useState(null)
  const [alunos, setAlunos] = useState([])
  const [alunoData, setAlunoData] = useState(null)
  const [alunosData, setAlunosData] = useState(null)
  const [alunosPax, setAlunosPax] = useState(null)
  const [notas, setNotas] = useState("")
  const [campos, setCampos] = useState([])
  const [aula, setAula] = useState(null)
  const [alunosDataJsonArr, setAlunosDataJsonArr] = useState([])

  const [contratos, setContratos] = useState({})
  const [valoresParaDividir, setValoresParaDividir] = useState({})

  async function getTefById() {
    try {
      return firebase
        .firestore()
        .collection(historicoDeAulasCollection)
        .doc(id)
        .onSnapshot(snap => {
          var res = snap.data().aula
          setContratos(snap.data().contratos)
          console.log( snap.data().contratos );
          setNomeDaAula(res.nome)
          setStartDate(res.diaInicial.toDate())
          setEndDate(res.diaFinal.toDate())
          setCampoEscolhido(res.campos)
          setNivel(res.nivel)
          setProfessor(res.professor)
          setIsLoading(false)
          setDiaDaSemana(res.weekDay)
          setIsAtiva(res.isAtiva)
          setTipoDeAula(res.tipo)
          setAlunoData(res.alunoData)
          setAlunos(res.alunos)
          if (typeof res.alunoData != "undefined") {
            var alunosDataJsonAuxssss = Object.entries(res.alunoData)
            setAlunosDataJsonArr(alunosDataJsonAuxssss)
          }
          /* setAlunosData(res.alunosData) */
          /*  setAlunosPax(res.alunosPAX) */
          setCampos(res.campos)

          setAula(res)
          if (typeof res.notas != "undefined") {
            setNotas(res.notas)
          } else {
            setNotas("")
          }
          setHoraFinal(res.horaFinal)
          setHoraInicial(res.horaInicial)

          rendera()
          setRenderas(true)
        })
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async function getReByID() {
    var res = await getAulaById(id)
    console.log(res)
    setNomeDaAula(res.nome)

    setStartDate(res.diaInicial.toDate())
    setEndDate(res.diaFinal.toDate())
    setCampoEscolhido(res.campos)
    setNivel(res.nivel)
    setProfessor(res.professor)
    setIsLoading(false)
    setDiaDaSemana(res.weekDay)
    setIsAtiva(res.isAtiva)
    setTipoDeAula(res.tipo)
    setAlunoData(res.alunoData)
    setAlunos(res.alunos)
    var alunosDataJsonAuxssss = Object.entries(res.alunoData)
    setAlunosDataJsonArr(alunosDataJsonAuxssss)
    console.log(alunosDataJsonAuxssss)
    /* setAlunosData(res.alunosData) */
    /*  setAlunosPax(res.alunosPAX) */
    setCampos(res.campos)

    setHoraFinal(res.horaFinal)
    setHoraInicial(res.horaInicial)
    setAula(res)
    if (typeof res.notas != "undefined") {
      setNotas(res.notas)
    } else {
      setNotas("")
    }
    
  }

  const handleChange = event => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked")
    } else {
      console.log("⛔️ Checkbox is NOT checked")
    }
    setIsAtiva(current => !current)
  }

  const [camposDisp, setCamposDisp] = useState([])
  const numeroDeCampos = 6

  var weekday = new Array(7)
  weekday[0] = " "
  weekday[1] = "Segunda"
  weekday[2] = "Terça"
  weekday[3] = "Quarta"
  weekday[4] = "Quinta"
  weekday[5] = "Sexta"
  weekday[6] = "Sábado"
  weekday[7] = "Domingo"

  /*   const [camposDisponiv, setCamposDisponiv] = useState([]) */

  function minsDiffs(horaInicial, horaFinal, weekDay) {
    var hi = new Date()
    var hf = new Date()
    var himins = horaInicial.substring(3, 5)
    var hihour = horaInicial.substring(0, 2)
    var hfmins = horaFinal.substring(3, 5)
    var hfhour = horaFinal.substring(0, 2)
    hi.setHours(hihour)
    hi.setMinutes(himins)
    hf.setHours(hfhour)
    hf.setMinutes(hfmins)

    var diffMs = hf - hi
    var duracao = Math.round(diffMs / 60000) // minutes
    console.log(duracao + " minutes ")

    var today = hi.getDay()
    if (today == 0) {
      today = 7
    }

    if (today > weekDay) {
      weekDay = weekDay + 7
    }

    var daysDiff = weekDay - today

    console.log(hi.getDay())
    console.log(weekDay)
    console.log(daysDiff)

    var dia = hi.getDate()
    var mes = hi.getMonth()
    var ano = hi.getFullYear()
    var checkDate = new Date(ano, mes, dia + daysDiff, hihour, himins)
    console.log(checkDate)

    return [duracao, checkDate]
  }

  function removeCamposRepetidos(campoosInsdis) {
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i

      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }

    setCamposDisp(listaDeCampos)
  }

  const removeCamposEsc = value => {
    setCampoEscolhido(campoEscolhido.filter(item => item !== value))
  }

  useEffect(async () => {
    await getTefById()
  }, [])


  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }


  const rendera = () => {
    if (renderas == false) {
      return null
    } else
      return (
        <React.Fragment>
          
            <div className="page-content">
              <Container style={{ padding: "40px" }} fluid>
                <Form>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={isAtiva}
                        value={isAtiva}
                        id="isAulaAtiva"
                        name="subscribe"
                      />{" "}
                      Ativa
                    </Label>
                  </FormGroup>
                  {/* <Button onClick={() => {
                    let valorAPagar = 0 
                    alunosDataJsonArr.forEach((elemnt) => {
                      console.log(contratos[elemnt[0]])
                      const valor = parseFloat(contratos[elemnt[0]].valor)
                      const numeroDeAulas = parseInt(contratos[elemnt[0]].numeroDeAulas)
                      valorAPagar = valorAPagar + valor / numeroDeAulas
                    })
                    console.log("Valor a pagar: ", valorAPagar)
                  }} > ASD ASD </Button> */}
                  <FormGroup>
                   {/*  <Button onClick={() => {
                      recuperaAula("LqEj8PwfEHMdxJie4FVK")
                    }}> Fazer backup da aula </Button> */}
                    <Row>
                      <Col md={3}>
                        <p> Tipo </p>
                      </Col>
                      <Col md={9}>
                        <h5 style={{ fontWeight: "bold" }}>{tipoDeAula}</h5>
                      </Col>
                      {/* <Input
                        value={tipoDeAula}
                        onChange={e => {
                          setTipoDeAula(e.target.value)
                          console.log(document.getElementById("tipoAula").value)
                        }}
                        type="select"
                        name="select"
                        id="tipoAula"
                      >
                        <option>Experimental</option>
                        <option>Academia</option>
                        <option>PAX</option>
                      </Input> */}
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Nome da Aula</p>
                      </Col>
                      <Col md={9}>
                        <p> {nomeDaAula}</p>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup hidden={tipoDeAula == "Experimental" ? false : true} >
                    <Row>
                      <Col md={3}>
                        <p>Expira em</p>
                      </Col>
                      <Col md={9}>
                        <DatePicker 
                            disabled
                          value={endDate}
                        
                        ></DatePicker>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Hora Inicial</p>
                      </Col>
                      <Col md={9}>
                        <TimePicker
                        disabled
                         
                          value={horaInicial}
                          disableClock={true}
                        ></TimePicker>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Row>
                    <Col md={3}>
                      <p>Hora Final</p>
                    </Col>
                    <Col md={9}>
                      <TimePicker
                      disabled
                        
                        value={horaFinal}
                        disableClock={true}
                      ></TimePicker>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Dia da semana</p>
                      </Col>
                      <Col md={9}>
                        <p>
                          {weekday[diaDaSemana]}
                        </p>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Row>
                    <Col md={3}>
                      <p>Localização</p>
                    </Col>
                    <Col md={9}>
                      <FormGroup>
                        <p>
                         Great Padel Vila Verde
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Professor</p>
                      </Col>
                      <Col md={9}>
                        <p>{professor}
                        </p>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Nivel</p>
                      </Col>
                      <Col md={9}>
                        <p>
                          {nivel}
                        </p>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                      <p style={{ fontWeight: "bold",  }}>
                        Campo da aula: {convertCamps[campos]}
                      </p>
                  </FormGroup>


                  {/* ALUNOS */}
                  <FormGroup  className="mt-5">
                    <Row hidden={userRole == recepRole}>
                      <Col  className="d-flex align-items-center">
                        <h4>Alunos</h4>
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Table bordered striped responsive className="myList" style={stylesTable}>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Valor</th>
                            <th>Contrato</th>
                            <th>Válido até</th>
                          </tr>
                        </thead>
                        <tbody>
                            {alunosDataJsonArr == 0 ? (
                            <p>Nenhum aluno inscrito</p>
                          ) : (
                            alunosDataJsonArr.map((aluno, index) => {
                              console.log(tipoDeAula)
                              let isAcademia = false
                              if(tipoDeAula != "Experimental"){
                              isAcademia = contratos[aluno[0]].expiryAt == undefined ? false : true
                            }
                              return (
                                <tr
                                  style={{ padding: "10px" }}
                                  key={index + aluno}
                                >
                                 <td > {aluno[1].nome}</td>
                                  <td >
                                    {aluno[1].academia == "Sim" ? (
                                      typeof aluno[1].proxPag == "undefined" ||
                                      aluno[1].proxPag.toDate() < new Date() ? (
                                        <p style={{ color: "red" }}>{aluno[0]}</p>
                                      ) : (
                                        <p style={{ color: "green" }}>{aluno[0]}</p>
                                      )
                                    ) : (
                                      <p> {aluno[0]}</p>
                                    )}
                                  </td>
                                  <td>
                                    {aluno[1].tel}
                                  </td> 
                                  
                                  { tipoDeAula == "Experimental" ? <td> {null}</td> :  <td> {contratos[aluno[0]].valor + "€"} </td>}
                                  { tipoDeAula == "Experimental" ? <td> {null}</td> :  <td>{contratos[aluno[0]].tipo }</td> }
                                  <td>{tipoDeAula == "Experimental" ? <td> {null}</td>  : isAcademia ? contratos[aluno[0]].expiryAt.toDate().toLocaleString().substring(0,10) : null} </td> 
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    </Row>
                   
                  </FormGroup>
                  <Row hidden={userRole == adminRole}>
                      <Row> Valores a distribuir </Row>
                  </Row>
                  <Label for="exampleText">Notas</Label>
                  <p>
                  {notas}
                  </p>
                </Form>

               
              </Container>
            </div>
          
        </React.Fragment>
      )
  }
  return rendera()
}

export default VerHistoricoDeAulasScreen
