import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Button, Container, Modal, Row, Table } from "reactstrap"
import { selectclubeid } from "store/localizacao/clube_id_reducer";
import firebase from "firebase/app"
import "firebase/firestore"
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { removerEmailAosRoles, removerRoleDoUser } from "services/localizacao/localizacao_services";
import Swal from "sweetalert2";
import { selectRoles } from "store/localizacao/grupos_staff";
import { selectsetuserroles } from "store/clube/user_roles_reducer";

function SingleStaff() {


    // Ir buscar à URL o parametro dado.
    const { role_param } = useParams()

    // Guardar os utilizadores desta role
    const [usersStaff, setUsersStaff] = useState([]);

    // Reducer
    const clubeID = useSelector(selectclubeid)
    const useRoles = useSelector(selectsetuserroles)
    const userRoles = JSON.parse(useRoles)
    const [emails, setEmails] = useState([])

    // Cenas da Modal de apagar user
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [user, setUser] = useState(null);
    
    async function apagaUtilizadorRole(user){
        try{
            const roleRemoveEmail = await removerEmailAosRoles(clubeID, role_param, user);
            const emailRemovidoUser = await removerRoleDoUser(user);

            if((emailRemovidoUser) && (roleRemoveEmail)){
                Swal.fire({
                    title: "Sucesso!",
                    text: `O utilizador ${user} foi removido do grupo de ${role_param}`,
                    icon: "success",
                    confirmButtonText: "Fechar",
                });
            }
            else{
                Swal.fire({
                    title: "Erro!",
                    text: `O utilizador ${user} não foi removido do grupo de ${role_param}`,
                    icon: "error",
                    confirmButtonText: "Fechar",
                });
            }
        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        setEmails(userRoles[role_param])
    },[])


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h6 className="page-title">Utilizadores do grupo {role_param}</h6>
              </div>
              <div>
                <Link
                  className="btn btn-primary dropdown-toggle waves-effect waves-light"
                  to="/grupos_staff/"
                >
                  Voltar atrás
                </Link>
              </div>
            </div>
          </div>
          <Row>
            <Table striped bordered responsive>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                 {
                    emails.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>{user}</td>
                                <td>
                                    <button 
                                        className="btn btn-danger"
                                        onClick={() => {
                                            toggle()
                                            setUser(user)
                                        }}
                                        >Remover</button>
                                </td>
                            </tr>
                        )
                    })
                } 
                </tbody>

            </Table>
           
          </Row>
        
        </Container>
      </div>

        <Modal isOpen={isOpen} centered>
            <div className="modal-header">
                <h4 className="modal-title mt-0 text-center mx-4" id="myModalLabel">
                    Tem a certeza que quer remover o utilizador deste grupo? ({role_param})
                </h4>
                <button
                    type="button"
                    onClick={() => {
                        toggle();
                    }}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                </button>
            </div>
            <div className="modal-body text-center">
                <div className="mb-3">
                    <p>Irá remover o email <strong><u>{user}</u></strong> do grupo <strong><u>{role_param}</u></strong></p>
                </div>
            </div>
            <div className="modal-footer justify-content-center">
                <button type="submit" className="btn btn-danger" onClick={() => apagaUtilizadorRole(user)}>
                        Remover
                </button>
                <button
                    type="button"
                    onClick={() => {
                        toggle();  
                    }}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                >
                    Cancelar
                </button>
            </div>
        </Modal>



    </>
  )
}

export default SingleStaff
