import React, { useEffect, useState } from "react"
import { Button, Col, Container, Input, Row ,Spinner} from "reactstrap"
import { useTranslation } from "react-i18next"
import {
  enviaEmailParaTodosPorCF,
  enviaEmailParaUmJogador,
  enviaEmailsQueDeramErro,
  enviarEmaiParaTodos,
} from "services/email/email_services"
import {  getAlllUsers } from "services/useres/jogadores_services"
import $ from "jquery"
import 'bootstrap'
import 'bootstrap/'
import 'popper.js'
import axios from "axios"
import { useSelector } from "react-redux"
import { selectclubeid } from "store/localizacao/clube_id_reducer"
import { addImagemToStorage } from "services/clube/clube_services"

function JogadoresToExcel() {

  const [fileName, setFileName] = useState("")
  const [eValue, setEValue] = useState("")
  const [anexo, setAnexo] = useState("")
  const [file, setFile] = useState("")

  const clubeId = useSelector(selectclubeid)

  /* function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      setBase64(reader.result)
    };
    
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 } */

 async function CDNUpload() {
    if(file != ""){
    var formData = new FormData();
    formData.append('ficheiroEmail', file);
    formData.append('requestKey', process.env.REACT_APP_CDN_API_KEY);
    return await axios
    .post('https://cdn.greatpadel.pt/uploadFicheiro', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
      return response.data;
      
      })
      .catch(function (error) {
      console.log(error);
      return ""
    });
  } else {
    return ""
  }
}

 
  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("")
  const [base64, setBase64] = useState("")


  useEffect(() => {

    const qtdScripts = document.querySelectorAll('#script3_hardcoded_summernote');

    qtdScripts.forEach((script) => {
      script.remove();
    });

        const script3 = document.createElement("script")
        script3.id = "script3_hardcoded_summernote"
        // create a mnaul script
        const scriptText = document.createTextNode(`
          $('#summernote').summernote({
            placeholder: 'Escreva aqui o conteúdo do email',
            height: 300
          });
          `);
        script3.appendChild(scriptText);
        document.body.appendChild(script3)
      
  }, [])
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">
                  {t("Enviar email a todos os utilizadores")}
                </h6>
              </Col>
            </Row>
            <Row style={{ paddingTop: "60px" }}>
              <Col md={2}>
                <h5>Assunto</h5>
              </Col>
              <Col>
                <Input type="text" name="text" id="subject"></Input>
              </Col>
            </Row>
            <Row style={{ paddingTop: "40px" }}>
              <Col md={2}>
                <h5>Corpo</h5>
              </Col>
              <Col>
              <textarea id="summernote" />
              </Col>
            </Row>
            <Row style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              <Col md={12}>
                 <h5>Efetuar upload de Ficheiro </h5>
                <input type="file"  onChange={(e) => {
                  if(e.target.files[0].size > 1500000)
                   {
                    alert("Ficheiro grande demais")
                    document.getElementById("ficheiroEmail").value = ""
                   } else {
                  console.log(e.target.files[0].name)
                  console.log(e.target.files[0])
                  setFile(e.target.files[0])
                  setFileName(e.target.files[0].name)
                }
                }} name="ficheiroEmail" id="ficheiroEmail" accept ="image/*"/>
              
              </Col>
            </Row>
            <Row>
               <Col md={3}>
                {isLoading ? <Spinner color="primary"></Spinner> : <Button
                  color="primary"
                  onClick={async () => {
                    setIsLoading(true)
                    await addImagemToStorage(clubeId,fileName,file).then(async (res) => {
                      console.log(res)
                    await enviaEmailParaTodosPorCF(
                        document.getElementById('summernote').value, 
                        document.getElementById("subject").value.trim(),
                        res,
                        fileName,
                        false
                      ) 
                    });
                    setIsLoading(false)
                  }}
                >
                  Enviar Email
                </Button>}
              </Col>  
              
              {/* Email TESTE! */}
              <Col md={3}>
                { isLoading ? <Spinner color="primary"></Spinner> : <Button
                  color="primary"
                  onClick={async () => {
                    setIsLoading(true)
                    await addImagemToStorage(clubeId,fileName,file).then(async (res) => {
                      await enviaEmailParaTodosPorCF(
                        document.getElementById('summernote').value, // BODY HTML
                        document.getElementById("subject").value.trim(),
                        res,
                        fileName,
                        true
                      ) 
                    });
                    setIsLoading(false)
                  }}
                >
                  Enviar Email Teste
                </Button>}
              </Col>
               
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default JogadoresToExcel