import React, { useEffect, useState } from "react"
import { Pencil } from "react-bootstrap-icons"
import { useSelector } from "react-redux"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { editaValoresDasAulas } from "services/clube/clube_services"
import { selectaulasvalores } from "store/clube/valores_aulas_reducer"
import { selectclubeid } from "store/localizacao/clube_id_reducer"

function EditarValoresDasAulas(props) {
  const [isOpen, setIsOpen] = useState(false)
  const aulasvalores = useSelector(selectaulasvalores)
  const clubeId = useSelector(selectclubeid)
  const mapAV = JSON.parse(aulasvalores)

  const [preco, setPreco] = useState(props.data[1].valor)
  const [nome, setNome] = useState(props.data[0])

  const [tipoDeAula, setTipoDeAula] = useState(props.tipo)
  const [idade, setIdade] = useState(props.data[1].idade)
  const [horario, setHorario] = useState(props.data[1].horario)
  const [duracao, setDuracao] = useState(props.data[1].duracao)
  const [fidelizacao, setFidelizacao] = useState(props.data[1].fidelizacao)
  const [numeroDeAulasPax, setNumeroDeAulasPax] = useState(props.tipo == "PAX" ? props.data[1].numeroDeAulas : "" );
  const [numeroDeAulasAcademia, setNumeroDeAulasAcademia] = useState(props.tipo == "Academia" ? props.data[1].numeroDeAulas : "" );
  const [numeroDeJogadores, setNumeroDeJogadores] = useState( props.data[1].numeroDeJogadores != undefined ? props.data[1].numeroDeJogadores : "" )

  const styles={ paddingBottom: "5px", paddingTop: "5px" }

  function toggle() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <Button style={{ marginRight: "5px" }} color="warning" onClick={() => toggle()}>
      <Pencil size={15}></Pencil>
      </Button>
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader
          toggle={() => {
            toggle()
          }}
        >
          Adicionar preço de aula
        </ModalHeader>
        <ModalBody>
        <Form>
          <h5>{nome}</h5>
        <FormGroup style={styles}  >
              <Row>
                <Col md={3}>
                 <Label> Tipo </Label>
                </Col>
                <Col md={9}>
                  <Input
                    value={tipoDeAula}
                    onChange={e => {
                      setTipoDeAula(document.getElementById("tipoAula").value)
                    }}
                    type="select"
                    name="select"
                    id="tipoAula"
                  >
                    <option></option>
                    <option>Academia</option>
                    <option>PAX</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
         
            {/* <FormGroup style={styles}  hidden={tipoDeAula.trim() == "" ? true : false}>
              <Row>
                <Col md={3}>
                  <Label>Descrição</Label>
                </Col>
                <Col md={9}>
                  <Input
                    value={nome}
                    onChange={e => {
                      setNome(e.target.value)
                    }}
                  ></Input>
                </Col>
              </Row>
            </FormGroup> */}

            <FormGroup style={styles}  hidden={ tipoDeAula == "Academia" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label> Idade </Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={idade}
                    onChange={e => {
                      setIdade(document.getElementById("idade").value)
                    }}
                    type="select"
                    name="select"
                    id="idade"
                  >
                    <option></option>
                    <option>Adultos</option>
                    <option>Kids</option>
                    <option>Juniores</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={styles}  hidden={ tipoDeAula == "Academia" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label>Horário</Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={horario}
                    onChange={e => {
                      setHorario(document.getElementById("horario").value)
                    }}
                    type="select"
                    name="select"
                    id="horario"
                  >
                    <option></option>
                    <option>Peak</option>
                    <option>Off Peak</option>
                   
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={styles}  hidden={ tipoDeAula == "Academia" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label>Duração</Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={duracao}
                    onChange={e => {
                      setDuracao(document.getElementById("duracao").value)
                    }}
                    type="select"
                    name="select"
                    id="duracao"
                  >
                    <option></option>
                    <option>60</option>
                    <option>90</option>
                   
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={styles}  hidden={ tipoDeAula == "Academia" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label>Número de Aulas</Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={numeroDeAulasAcademia}
                    onChange={e => {
                      setNumeroDeAulasAcademia(document.getElementById("nrAulasAcademia").value)
                    }}
                    type="select"
                    name="select"
                    id="nrAulasAcademia"
                  >
                    <option></option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>

                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={styles}  hidden={ tipoDeAula == "PAX" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label>Número de Aulas</Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={numeroDeAulasPax}
                    onChange={e => {
                      setNumeroDeAulasPax(document.getElementById("nrAulasPax").value)
                    }}
                    type="select"
                    name="select"
                    id="nrAulasPax"
                  >
                    <option></option>
                    <option>1</option>
                    <option>5</option>
                    <option>10</option>
                    
                   
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={styles}  hidden={ tipoDeAula == "Academia" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label>Fidelização</Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={fidelizacao}
                    onChange={e => {
                      setFidelizacao(document.getElementById("fidelizacao").value)
                    }}
                    type="select"
                    name="select"
                    id="fidelizacao"
                  >
                    <option></option>
                    <option>Sim</option>
                    <option>Não</option>
                    
                    
                   
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup style={styles}  hidden={ tipoDeAula == "PAX" ? false : true}>
              <Row>
                <Col md={3}>
                 <Label>Número de Jogadores</Label>
                </Col>
                <Col md={9}>
                  <Input
                  value={numeroDeJogadores}
                    onChange={e => {
                      setNumeroDeJogadores(document.getElementById("numeroDeJogadores").value)
                    }}
                    type="select"
                    name="select"
                    id="numeroDeJogadores"
                  >
                    <option></option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>

                  </Input>
                </Col>
              </Row>
            </FormGroup>
            

            <FormGroup style={styles} hidden={ tipoDeAula == "" ? true : false} >
              <Row>
                <Col md={3}>
                  <Label>Valor</Label>
                </Col>
                <Col md={8}>
                  <Input
                    type="number"
                    value={preco}
                    id="precoId"
                    onChange={e => {
                      setPreco(e.target.value)
                    }}
                  ></Input>
                </Col>
                <Col md={1}>€</Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        
          <Button
            color="primary"
            onClick={async () => {

              console.log(nome)
              console.log(preco)
              console.log(tipoDeAula)
             
              if (nome.trim() != "" && preco.trim() != "" && tipoDeAula.trim() != "") {

                if(tipoDeAula == "PAX") {
                  if(numeroDeJogadores.trim() != "" && numeroDeAulasPax.trim()  != "" ) {
                    var map = {}
                  map[tipoDeAula] = {}
                  map[tipoDeAula][nome] = {}
                  map[tipoDeAula][nome].numeroDeJogadores = numeroDeJogadores
                  map[tipoDeAula][nome].numeroDeAulas = numeroDeAulasPax
                  map[tipoDeAula][nome].valor = preco

                  var res = await editaValoresDasAulas(clubeId, map)
                  if (res) {
                    alert("Valores adicionados com sucesso")
                    toggle()
                  } else {
                    alert("Erro a adicionar valores")
                    toggle()
                  } 
                  } else {
                    alert("Todos os valores tem que estar preenchidos")
                    return
                  }
                  
                } else {
                if( fidelizacao.trim()  != "" && duracao.trim()  != "" && numeroDeAulasAcademia.trim()  != ""  ) {
                  var map = {}
                map[tipoDeAula] = {}
                map[tipoDeAula][nome] = {}
                map[tipoDeAula][nome].idade = idade
                map[tipoDeAula][nome].horario = horario
                map[tipoDeAula][nome].duracao = duracao
                map[tipoDeAula][nome].fidelizacao = fidelizacao
                map[tipoDeAula][nome].numeroDeAulas = numeroDeAulasAcademia
                map[tipoDeAula][nome].valor = preco

                var res = await editaValoresDasAulas(clubeId, map)
                if (res) {
                  alert("Valores adicionados com sucesso")
                  toggle()
                } else {
                  alert("Erro a adicionar valores")
                  toggle()
                } 
                } else {
                  alert("Todos os valores tem que estar preenchidos")
                  return
                }
                
              } }else {
                alert("Todos os valores tem que estar preenchidos")
              }
            }}
          >
            {" "}
            Editar{" "}
          </Button>
          <Button
            onClick={() => {
              toggle()
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default EditarValoresDasAulas
