import { createSlice } from "@reduxjs/toolkit"

export const aulasvaloresSlice = createSlice({
  name: "aulasvalores",
  initialState: {
    value: "",
  },
  reducers: {
    setaulasvalores: (state, action) => {
      state.value = JSON.stringify(action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setaulasvalores } = aulasvaloresSlice.actions

export const selectaulasvalores = state => state.aulasvalores.value

export default aulasvaloresSlice.reducer
